import React  from 'react';
import { Link } from 'react-router-dom';
import './support.scss';


const Support = () => {
  return (
    <>
    <section className="supportNeed">
         <div className="container">
             <div className="row justify-content-center">
                 <div className="col-md-12 col-lg-12">
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='sideBar'>
                                <h5>I need Support For:</h5>
                                <div className='tabBox'>
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-previousJob-tab" data-bs-toggle="pill" data-bs-target="#pills-previousJob" type="button" role="tab" aria-controls="pills-previousJob" aria-selected="true">Previous Job<span><i className="fas fa-chevron-right"></i></span></button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-upcomingJob-tab" data-bs-toggle="pill" data-bs-target="#pills-upcomingJob" type="button" role="tab" aria-controls="pills-upcomingJob" aria-selected="false">Upcoming Job <span><i className="fas fa-chevron-right"></i></span></button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-payout-tab" data-bs-toggle="pill" data-bs-target="#pills-payout" type="button" role="tab" aria-controls="pills-payout" aria-selected="false">Payout <span><i className="fas fa-chevron-right"></i></span></button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-profileUpdate-tab" data-bs-toggle="pill" data-bs-target="#pills-profileUpdate" type="button" role="tab" aria-controls="pills-profileUpdate" aria-selected="false">Profile Update <span><i className="fas fa-chevron-right"></i></span></button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-others-tab" data-bs-toggle="pill" data-bs-target="#pills-others" type="button" role="tab" aria-controls="pills-others" aria-selected="false">Others <span><i className="fas fa-chevron-right"></i></span></button>
                                        </li>
                                        <li className="nav-item">
                                            <div className='ticketBtn'><Link className="nav-link" to="/previousTicket">Previous Ticket</Link></div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='contentBox'>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-previousJob" role="tabpanel" aria-labelledby="pills-previousJob">
                                        <div className='termsBox'>
                                            <h6>Select Job</h6>
                                                <div className='row'>
                                                    <div className='col-md-6'>
                                                        <Link to="/reportIssue">
                                                        <div className='descBox'>
                                                            <h6>Mall Security Job</h6>
                                                            <p style={{color:'#858585'}}><i className="fas fa-map-marker-alt" style={{marginRight: '5px'}}></i>123,street Name,City Name</p>
                                                            <p className='totEarn'><span>Start Date :</span> 12/09/2021</p>
                                                            <p className='totEarn'><span>Tot. Earning :</span> $250</p>
                                                            <p className='jobRating'>
                                                                <span className='left' style={{color: '#000'}}>Job Rating </span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star-half"></i></span>
                                                            </p>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <Link to="/reportIssue">
                                                        <div className='descBox'>
                                                            <h6>Mall Security Job</h6>
                                                            <p style={{color:'#858585'}}><i className="fas fa-map-marker-alt" style={{marginRight: '5px'}}></i>123,street Name,City Name</p>
                                                            <p className='totEarn'><span>Start Date :</span> 12/09/2021</p>
                                                            <p className='totEarn'><span>Tot. Earning :</span> $250</p>
                                                            <p className='jobRating'>
                                                                <span className='left' style={{color: '#000'}}>Job Rating </span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star-half"></i></span>
                                                            </p>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <Link to="/reportIssue">
                                                        <div className='descBox'>
                                                            <h6>Mall Security Job</h6>
                                                            <p style={{color:'#858585'}}><i className="fas fa-map-marker-alt" style={{marginRight: '5px'}}></i>123,street Name,City Name</p>
                                                            <p className='totEarn'><span>Start Date :</span> 12/09/2021</p>
                                                            <p className='totEarn'><span>Tot. Earning :</span> $250</p>
                                                            <p className='jobRating'>
                                                                <span className='left' style={{color: '#000'}}>Job Rating </span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star-half"></i></span>
                                                            </p>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                    <div className='col-md-6'>
                                                        <Link to="/reportIssue">
                                                        <div className='descBox'>
                                                            <h6>Mall Security Job</h6>
                                                            <p style={{color:'#858585'}}><i className="fas fa-map-marker-alt" style={{marginRight: '5px'}}></i>123,street Name,City Name</p>
                                                            <p className='totEarn'><span>Start Date :</span> 12/09/2021</p>
                                                            <p className='totEarn'><span>Tot. Earning :</span> $250</p>
                                                            <p className='jobRating'>
                                                                <span className='left' style={{color: '#000'}}>Job Rating </span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star"></i></span>
                                                                <span className='left'><i className="fas fa-star-half"></i></span>
                                                            </p>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-upcomingJob" role="tabpanel" aria-labelledby="pills-upcomingJob">
                                        <div className='termsBox'>
                                            <h6>Terms Of Use</h6>
                                            <p>Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.</p>
                                            <p> Gingerbread chupa chups icing croissant oat cake tootsie roll cake muffin. Caramels shortbread tootsie roll chocolate bar liquorice chupa chups. Carrot cake tiramisu apple pie sesame snaps bonbon cake chocolate jelly. Toffee apple pie jelly-o cotton candy dragée bonbon macaroon pastry candy canes.Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.</p>
                                            <p>Gingerbread chupa chups icing croissant oat cake tootsie roll cake muffin. Caramels shortbread tootsie roll chocolate bar liquorice chupa chups. Carrot cake tiramisu apple pie sesame snaps bonbon cake chocolate jelly. Toffee apple pie jelly-o cotton candy dragée bonbon macaroon pastry candy canes.  </p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-payout" role="tabpanel" aria-labelledby="pills-payout">
                                        <div className='termsBox'>
                                            <h6>Terms Of Use</h6>
                                            <p>Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.</p>
                                            <p> Gingerbread chupa chups icing croissant oat cake tootsie roll cake muffin. Caramels shortbread tootsie roll chocolate bar liquorice chupa chups. Carrot cake tiramisu apple pie sesame snaps bonbon cake chocolate jelly. Toffee apple pie jelly-o cotton candy dragée bonbon macaroon pastry candy canes.Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.</p>
                                            <p>Gingerbread chupa chups icing croissant oat cake tootsie roll cake muffin. Caramels shortbread tootsie roll chocolate bar liquorice chupa chups. Carrot cake tiramisu apple pie sesame snaps bonbon cake chocolate jelly. Toffee apple pie jelly-o cotton candy dragée bonbon macaroon pastry candy canes.  </p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-profileUpdate" role="tabpanel" aria-labelledby="pills-profileUpdate">
                                        <div className='termsBox'>
                                            <h6>Terms Of Use</h6>
                                            <p>Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.</p>
                                            <p> Gingerbread chupa chups icing croissant oat cake tootsie roll cake muffin. Caramels shortbread tootsie roll chocolate bar liquorice chupa chups. Carrot cake tiramisu apple pie sesame snaps bonbon cake chocolate jelly. Toffee apple pie jelly-o cotton candy dragée bonbon macaroon pastry candy canes.Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.</p>
                                            <p>Gingerbread chupa chups icing croissant oat cake tootsie roll cake muffin. Caramels shortbread tootsie roll chocolate bar liquorice chupa chups. Carrot cake tiramisu apple pie sesame snaps bonbon cake chocolate jelly. Toffee apple pie jelly-o cotton candy dragée bonbon macaroon pastry candy canes.  </p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-others" role="tabpanel" aria-labelledby="pills-others">
                                        <div className='termsBox'>
                                            <h6>Terms Of Use</h6>
                                            <p>Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.</p>
                                            <p> Gingerbread chupa chups icing croissant oat cake tootsie roll cake muffin. Caramels shortbread tootsie roll chocolate bar liquorice chupa chups. Carrot cake tiramisu apple pie sesame snaps bonbon cake chocolate jelly. Toffee apple pie jelly-o cotton candy dragée bonbon macaroon pastry candy canes.Gingerbread cookie lollipop fruitcake chupa chups fruitcake pie. Gummies danish oat cake chocolate chocolate cake marzipan jelly-o. Cotton candy pastry gummies macaroon tiramisu biscuit lollipop bonbon toffee. Icing chocolate carrot cake muffin brownie sugar plum. Sweet roll tootsie roll pastry chocolate bar dragée muffin topping cake jelly beans. Topping dessert pastry cupcake macaroon.</p>
                                            <p>Gingerbread chupa chups icing croissant oat cake tootsie roll cake muffin. Caramels shortbread tootsie roll chocolate bar liquorice chupa chups. Carrot cake tiramisu apple pie sesame snaps bonbon cake chocolate jelly. Toffee apple pie jelly-o cotton candy dragée bonbon macaroon pastry candy canes.  </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
             </div>
         </div>
      </section>
    </>
  )
}

export default Support;
