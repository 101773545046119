import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./tracker.scss";
import ApiClient from "../../api/apiClient";
import Cookies from "universal-cookie";
import {
  API_GetAllJobsList,
  API_GetJobDetails,
  googleAddressKey,
} from "../../api/Constant";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import NoDataImg from "../../assets/img/no_data_found.png";

const Tracker = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [jobid, setJobId] = useState([0]);
  const [jobDetails, setjobDetails] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [from, setFrom] = useState("");
  const cookie = new Cookies();
  const userdata = JSON.parse(localStorage.getItem("userInfo"));
  // const token = userdata.tokens.refresh.token;
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  var pageNo = 1;

  useEffect(() => {
    if (token) {
      callGetAllJobsList();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
  }, []);

  // Page Change Function
  const handlePageChange = (pageNumber) => {
    // console.log(`active page is ${pageNumber}`);
    // setPageNumber(pageNumber);
    pageNo = pageNumber;
    callGetAllJobsList();
    // this.setState({activePage: pageNumber});
  };

  // Get All Job LIst
  async function callGetAllJobsList() {
    const data = "";
    const res = await ApiClient.get(
      API_GetAllJobsList +
        `?page=${pageNo}&sortBy=asc&limit=${10}&searchBy=&jobType=&isComplete=4`,
      data,
      `Bearer ${token}`
    );
    //console.log("job list", res);
    if (res.data.code === 200) {
      setJobs(res.data.data.docs);
      setjobDetails(res.data.data.docs[0]);
      settotalItemsCount(res.data.data.total);
      //console.log(jobDetails);
    } else {
      toast.error(res.message);
    }
  }
  // Function To Get Job Detalis By Job Id
  async function callGetJobDetails(id) {
    const data = "";
    const res = await ApiClient.get(
      API_GetJobDetails + `${id}`,
      data,
      `Bearer ${token}`
    );
    //console.log("job details", res);
    if (res.data.code === 200) {
      setjobDetails(res.data.data);
      //console.log(jobDetails);
    } else {
      toast.error(res.message);
    }
  }

  // Set Job Id On Click
  const setJobsid = (i, id) => {
    //console.log(i);
    //console.log(id);
    callGetJobDetails(id);
    setJobId(i);
  };

  // Date Filter
  const selectedFromDate = (f) => {
    //console.log(f);
    setFrom(moment.utc(f).format("DD-MM-YYYY"));
    const data = jobs.filter((res) => {
      return (
        moment.utc(res.jobDate).format("DD-MM-YYYY") >=
        moment.utc(f).format("DD-MM-YYYY")
      );
    });
    setJobs(data);
  };

  const selectedToDate = (t) => {
    //console.log(t);
    const data = jobs.filter((res) => {
      return (
        moment.utc(res.jobDate).format("DD-MM-YYYY") <=
        moment.utc(t).format("DD-MM-YYYY")
      );
    });
    setJobs(data);
  };

  return (
    <>
      <Header />
      <section className="tracker_awrded_job">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12"></div>
          </div>
          {jobs.length > 0 ? (
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12">
                <div className="row justify-content-center">
                  <div className="col-md-3">
                    <div className="left_sidebar">
                      <h5 style={{ marginBottom: "0" }}>Tracker</h5>
                      {jobs.map((res, i) => {
                        return (
                          <div className="nav_box">
                            <div
                              className="nav flex-column nav-pills"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <button
                                // className="nav-link active"
                                class={
                                  "nav-link" + (jobid === i ? " active" : "")
                                }
                                id="first-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#first"
                                type="button"
                                role="tab"
                                key={i}
                                aria-controls="first"
                                aria-selected="true"
                                onClick={(e) => {
                                  setJobsid(i, res?.id);
                                }}
                              >
                                {res.jobTitle}
                                <span>
                                  <i className="fa-solid fa-location-dot"></i>{" "}
                                  {res.locationId?.locationName}
                                </span>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {jobs.length !== 0 && (
                      <div className="mt-2">
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-5">
                    <div className="content">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className={
                            "tab-pane fade" +
                            (jobid.length !== 0 ? "show active" : "")
                          }
                          id="first"
                          role="tabpanel"
                          aria-labelledby="first-tab"
                        >
                          <h5 className="awrded_job_title">
                            {jobDetails?.jobTitle}{" "}
                            {/* <span>
                              {" "}
                              <i className="fa-solid fa-clock"></i>{" "}
                              {jobDetails?.jobDate}
                            </span> */}
                          </h5>
                          <p className="adderess">
                            {jobDetails?.locationId?.locationName}
                          </p>
                          <div className="desc">
                            <div className="flexBox">
                              <p>
                                <span>Status</span> :{" "}
                                {jobDetails?.status == 0
                                  ? "Awaiting Approval"
                                  : jobDetails?.status == 1
                                  ? "Approved"
                                  : jobDetails?.status == 2
                                  ? "De-Activate(Reject)"
                                  : jobDetails?.status == 3
                                  ? "Cancel"
                                  : jobDetails?.status == 4
                                  ? "Ongoing"
                                  : ""}
                              </p>
                            </div>
                          </div>

                          <div className="mapBox">
                            <iframe
                              src={`https://www.google.com/maps/embed/v1/place?key=${googleAddressKey}&q=${jobDetails?.locationId?.lat},${jobDetails?.locationId?.long}`}
                              frameborder="0"
                              style={{
                                color: "red",
                                borderRadius: "10px",
                                width: "100%",
                                height: "200px",
                                border: "1px solid #d3d3d3",
                              }}
                            ></iframe>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="two"
                          role="tabpanel"
                          aria-labelledby="two-tab"
                        ></div>
                        <div
                          className="tab-pane fade"
                          id="three"
                          role="tabpanel"
                          aria-labelledby="three-tab"
                        ></div>
                        <div
                          className="tab-pane fade"
                          id="four"
                          role="tabpanel"
                          aria-labelledby="four-tab"
                        ></div>
                        <div
                          className="tab-pane fade"
                          id="five"
                          role="tabpanel"
                          aria-labelledby="five-tab"
                        ></div>
                        <div
                          className="tab-pane fade"
                          id="six"
                          role="tabpanel"
                          aria-labelledby="six-tab"
                        ></div>
                        <div
                          className="tab-pane fade"
                          id="seven"
                          role="tabpanel"
                          aria-labelledby="seven-tab"
                        ></div>
                        <div
                          className="tab-pane fade"
                          id="eight"
                          role="tabpanel"
                          aria-labelledby="eight-tab"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="nodata_img">
                  <img
                    src={NoDataImg}
                    className="img-fluid"
                    alt="No Data Found"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Tracker;
