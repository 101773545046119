import React, { useState, useRef, useEffect } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./job_rating.scss";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { API_GetRating } from "../../api/Constant";
import ApiClient from "../../api/apiClient";
import moment from "moment";
import ReactPaginate from "react-paginate";
import Pagination from "react-js-pagination";
import { Rating } from "react-simple-star-rating";
import NoDataImg from "../../assets/img/no_data_found.png";

const cookie = new Cookies();
const tdata = JSON.parse(localStorage.getItem("userInfo"));
const token = tdata?.tokens?.refresh?.token;
const userId = tdata?.user?.id;

const JobRating = () => {
  let item = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [ratings, setRatings] = useState([]);
  let [ratingsId, setRatingsId] = useState([0]);
  const [rDetails, setRdetails] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  let activated = true;
  var pageNo = 1;

  useEffect(() => {
    //console.log(item);
    if (token) {
      callGetAllRatingList(
        item?.state ? item?.state : tdata?.tokens?.refresh?.token
      );
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      // navigate(`/login`);
    }
  }, [item || token]);

  // Function to handle when a job is selected
  const selectedJob = (id, i) => {
    //console.log(id); // Log selected job's id
    //console.log(i); // Log selected job's index
    activated = false; // Set 'activated' to false (make sure to use state instead)
    setRatingsId(i); // Set 'ratingsId' using state
    callGetJobRatingDetails(id); // Fetch job rating details using API
  };

  // Function to fetch job rating details using API
  async function callGetJobRatingDetails(rid) {
    const data = "";
    const res = await ApiClient.get(
      API_GetRating + `/${rid}`,
      data,
      `Bearer ${item?.state ? item?.state : tdata?.tokens?.refresh?.token}`
    );
    // Handle API response
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      navigate(`/login`);
    } else if (res.data.code === 200) {
      setRdetails(res.data.data); // Set job rating details using state
    } else {
      toast.error(res.message);
    }
  }

  // Function to handle rating selection
  const handleRating = () => {
    // Implement your logic to handle rating here
    // setRatingValue(2.5); // Example: Set a rating value using state
  };

  // Function to handle page change in pagination
  const handlePageChange = (pageNumber) => {
    // Update the 'pageNo' value with the selected page number
    pageNo = pageNumber;
    // Call function to fetch and update the rating list based on the selected page number
    callGetAllRatingList(
      item?.state ? item?.state : tdata?.tokens?.refresh?.token
    );
  };

  // Function to fetch rating list using API based on the selected page number
  const callGetAllRatingList = async (token) => {
    const data = "";
    const res = await ApiClient.get(
      // API endpoint to fetch rating list with pagination parameters
      API_GetRating + `?page=${pageNo}&sortBy=asc&limit=${10}&searchBy=`,
      data,
      `Bearer ${token}`
    );
    // Handle API response
    if (res.data.code === 200) {
      setRatings(res.data.data.docs); // Set the rating list using state
      setRdetails(res.data.data.docs[0]); // Set the details of the first rating using state
      settotalItemsCount(res.data.data.total); // Set the total count of items using state
    } else {
      toast.error(res.message);
    }
  };

  return (
    <>
      <Header />
      <section className="job_rating">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              {ratings.length > 0 ? (
                <div className="row justify-content-center">
                  <div className="col-md-5 col-lg-4">
                    <div className="left_sidebar">
                      <h5>My Ratings</h5>
                      {ratings.length > 0 ? (
                        ratings.map((rlist, id) => {
                          return (
                            <div className="nav_box">
                              <div
                                className="nav flex-column nav-pills"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                              >
                                <button
                                  class={
                                    "nav-link" +
                                    (ratingsId === id ? " active" : "")
                                  }
                                  id={"first-tab" + id}
                                  key={id}
                                  data-bs-toggle="pill"
                                  data-bs-target="#first"
                                  type="button"
                                  role="tab"
                                  aria-controls="first"
                                  aria-selected="true"
                                  onClick={(e) => {
                                    selectedJob(rlist?.id, id);
                                  }}
                                >
                                  {rlist?.jobId?.jobTitle}
                                  <span>
                                    <i className="fas fa-map-marker-alt"></i>{" "}
                                    {rlist?.jobId?.locationId?.locationName}
                                  </span>
                                </button>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <h6>No Data</h6>
                      )}
                    </div>
                    {ratings?.length > 0 && (
                      <div className="mt-3">
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-7 col-lg-7">
                    <div className="content">
                      <div className="tab-content" id="v-pills-tabContent">
                        <div
                          className={
                            "tab-pane fade" +
                            (ratingsId.length !== 0 ? "show active" : "")
                          }
                          id="first"
                          role="tabpanel"
                          aria-labelledby="first-tab"
                        >
                          <div className="rating_box">
                            <div className="row">
                              <div className="col-md-6">
                                <h4>Rating Detail</h4>
                              </div>
                              <div className="col-md-6">
                                <p className="date_para">
                                  <i
                                    className="fas fa-calendar"
                                    style={{ color: "#000" }}
                                  ></i>{" "}
                                  {moment
                                    .utc(rDetails?.createdAt)
                                    .format("DD-MM-YYYY")}
                                </p>
                              </div>
                            </div>
                            <div className="title_box">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      type="text"
                                      readOnly
                                      value={
                                        rDetails?.guardId?.firstName +
                                        " " +
                                        rDetails?.guardId?.lastName
                                      }
                                      placeholder="Name"
                                      aria-label="default input example"
                                    />
                                    <span>
                                      <i className="fas fa-briefcase"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      type="text"
                                      readOnly
                                      value={moment
                                        .utc(rDetails?.createdAt)
                                        .format("DD-MM-YYYY")}
                                      placeholder="Date"
                                      aria-label="default input example"
                                    />
                                    <span>
                                      <i className="fas fa-calendar"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      type="text"
                                      readOnly
                                      value={
                                        rDetails?.jobId?.locationId
                                          ?.locationName
                                      }
                                      placeholder="Location"
                                      aria-label="default input example"
                                    />
                                    <span>
                                      <i className="fas fa-map-marker-alt"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="starRate_box">
                                    <h6>Job Rating</h6>
                                    <b style={{ margin: "0 8px" }}>:</b>
                                    <div className="starRate">
                                      <Rating
                                        onClick={handleRating}
                                        // ratingValue={ratingValue}
                                        initialValue={rDetails?.rating}
                                        allowHalfIcon
                                        readonly
                                      />
                                    </div>
                                  </div>
                                </div>
                                {rDetails?.shiftId?.endReasonId && (
                                  <div className="col-md-12 mb-3">
                                    <div className="reason_box">
                                      <h6>
                                        {rDetails?.shiftId?.endReasonId?.name}
                                      </h6>
                                    </div>
                                  </div>
                                )}
                                <div className="col-md-12 mb-3">
                                  <div className="reason_box">
                                    <h6>Description</h6>

                                    <p>{rDetails?.shiftId?.shiftEndComment}</p>
                                  </div>
                                </div>
                                {rDetails?.rating < 3 && (
                                  <div className="col-md-12">
                                    <div className="reason_box">
                                      <h6>Reason For Minimum Job Rating</h6>
                                      <p>
                                        {rDetails?.description === "" ||
                                        rDetails?.description === undefined
                                          ? "No Data"
                                          : rDetails?.description}
                                      </p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <div className="nodata_img">
                      <img
                        src={NoDataImg}
                        className="img-fluid"
                        alt="No Data Found"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JobRating;
