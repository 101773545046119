import React, { useEffect, useState ,useHistory} from 'react';
import {Link} from 'react-router-dom';
import './edit_card.scss';

const EditCard = () => {
  return (
    <>
    <section className="edit_card">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="loginForm">
                        <form>
                            <h4>Edit Debit/Credit Card</h4>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text" className="form-control"   aria-describedby="nameHelp" placeholder="John Doe" />
                                        <span><i className="fas fa-user"></i></span>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text"  className="form-control"  placeholder="1234XXX XXX 4567" />
                                        <span><i className="fas fa-credit-card"></i></span>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text"  className="form-control"  placeholder="123" />
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className="form-group">
                                        <input type="text"  className="form-control"  placeholder="12/11/2021" />
                                        <span><i className="fas fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className="loginBtn">
                                <Link to="/my_profile" >Add Card </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default EditCard;
