import React, { useState, useRef, useEffect } from "react";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/prime.css";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import "./add_job.scss";
import ApiClient from "../../api/apiClient";
import {
  API_ADDJOB,
  API_GetJobRoleList,
  API_Speciality,
  API_WorkLocationList,
  API_GetJobPrice,
  API_GetProfile,
  API_AddCard,
  API_AddAmount,
} from "../../api/Constant";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import moment, { utc } from "moment";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Payment from "../Payment/Payment";
import Box from "@mui/material/Box";

window.$ = window.jQuery = require("jquery");

const $ = window.$;
var locationID = "";
// var customerRate = JSON.parse(localStorage.getItem("rate"));

const SearchbarDropdown = (props) => {
  const { options, onInputChange } = props;
  const ulRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.addEventListener("click", (event) => {
      event.stopPropagation();
      ulRef.current.style.display = "flex";
      onInputChange(event);
    });
    document.addEventListener("click", (event) => {
      ulRef.current.style.display = "none";
    });
  }, []);

  return (
    <div className="search-bar-dropdown">
      <input
        id="search-bar"
        type="text"
        className="form-control"
        placeholder="Job location"
        ref={inputRef}
        onChange={(e) => {
          // //console.log(e);
          onInputChange(e);
        }}
      />
      <ul id="results" className="list-group" ref={ulRef}>
        {options.map((option, index) => {
          return (
            <button
              type="button"
              key={index}
              onClick={(e, index) => {
                inputRef.current.value = option.name;
                locationID = option.id;
              }}
              className="list-group-item list-group-item-action"
            >
              {option.name}
            </button>
          );
        })}
      </ul>
    </div>
  );
};

const AddJob = () => {
  const [title, setTitle] = useState("");
  const [jobEndDate, setJobEndDate] = useState("");
  const today = new Date();
  var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
  const [jobDate, setJobDate] = useState(
    moment.utc(tomorrow).format("yyyy-MM-DD")
  );
  const [transportation, setTransportation] = useState(true);

  const [ErrorTitle, setErrorTitle] = useState(null);
  const [ErrorJobType, setErrorJobType] = useState(null);
  const [ErrorJobDate, setErrorJobDate] = useState(null);
  const [ErrorShiftOneStartTime, setErrorShiftOneStartTime] = useState(null);
  const [ErrorShiftOneEndTime, setErrorShiftOneEndTime] = useState(null);
  // const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  // const token = cookie.get("token");
  const [specialitys, setSpecialitys] = useState([]);
  // var filterData = [];
  const [experience, setExperience] = useState([]);
  let [rate, setRate] = useState("");
  const [openTimeEdit, setOpenTimeEdit] = useState(0);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [dresscode, setDresscode] = useState("");
  const [numberofgards, setNumberofgards] = useState("");
  const [shiftperday, setshiftperday] = useState("");
  const [jobType, setJobType] = useState("");
  const [totalAmount, setTotalAmount] = useState({});
  var endTimee = "";
  let [showT, setShowT] = useState(false);
  const [profile, setProfile] = useState("");
  const navigate = useNavigate();
  const [customDates, setCustomDates] = useState([
    moment.utc(tomorrow).format("yyyy-MM-DD"),
  ]);
  const [location, setLocation] = useState("");
  const [isLoading, setIsloading] = useState(false);
  const [shiftError, setShiftError] = useState([]);
  const [smShow, setSmShow] = useState(false);
  const [smShowCard, setSmShowCard] = useState(false);
  const [cardReqErr, setCardReqErr] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [cardId, setCardId] = useState("");
  const [cardIdError, setCardIdError] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [amountRegexError, setAmountRegexError] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const clientSectet = tdata?.user?.stripe_customer_id;
  const userId = tdata?.user?.id;
  let cardData = "";
  const [open, setOpen] = React.useState(false);
  const [changeStartTime, setChangeStartTime] = useState("");
  const [changeEndTime, setChangeEndTime] = useState("");
  const [isFound, setIsFound] = useState(false);
  const [currentResp, setCurrentResp] = useState({});
  const [iterValue, setIterValue] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [isReview, setIsReview] = useState(false);

  // //console.log(changeStartTime, "start time");
  // //console.log(changeEndTime, "end time");

  const handleClickOpen = (res, i) => {
    setOpenModal(true);
    setChangeEndTime(res.endTime.split("T")[1].replace(":00.000Z", ""));
    setChangeStartTime(res.startTime.split("T")[1].replace(":00.000Z", ""));

    setIterValue(i);
    setCurrentResp(res);
  };

  const handleClose = () => {
    setChangeStartTime("");
    setChangeEndTime("");
    setIterValue(null);
    setCurrentResp({});
    setOpenModal(false);
  };

  // This function handles changes to shift timings and updates corresponding calculations.
  const handleChangeTime = async (res = currentResp, i = iterValue) => {
    //console.log(i, res, "tesstt");

    // Clone the 'totalAmount' object to avoid mutating the original data
    let totalAmountData = { ...totalAmount };

    // Extract shift data for the specific iteration
    let shiftData = totalAmountData.shifts[i];

    // Extract start and end dates from the shift data
    let shiftDataStartDate = shiftData.startTime.split("T")[0];
    let shiftDataEndDate = shiftData.endTime.split("T")[0];

    // Build new start and end time strings
    let startTime = shiftDataStartDate + "T" + changeStartTime + ":00.000Z";
    let endTime = shiftDataEndDate + "T" + changeEndTime + ":00.000Z";

    // Calculate shift hours
    var hours = (new Date(startTime) - new Date(endTime)) / 1000;
    hours /= 60 * 60;
    hours = Math.abs(Math.round(hours));
    //console.log(hours, "hours");

    // Check for shift duration constraints and display warnings if necessary
    if (hours < 4) {
      toast.warning(
        `Shift ${i + 1} end time should be at least 4 hrs greater than Shift ${
          i + 1
        } start time`
      );
      return;
    } else if (hours > 12) {
      toast.warning(
        `Shift ${
          i + 1
        } exceeded the max shift hours limit. Shift hours should be minimum 4 hours and maximum 12 hours allowed `
      );
      return;
    }

    // Update shift data properties based on calculations
    shiftData.startTime = startTime;
    shiftData.endTime = endTime;
    shiftData.totalShiftHours = hours;
    shiftData.shiftChargesForCustomer =
      hours *
      shiftData.customerRate *
      (shiftData.holidayCharges > 0 ? shiftData.holidayCharges : 1);
    shiftData.shiftChargesForGuard =
      hours *
      shiftData.guardRate *
      (shiftData.holidayCharges > 0 ? shiftData.holidayCharges : 1);

    // Update 'totalAmountData' jobType and make a PATCH request to update job price
    totalAmountData["jobType"] = 3;
    setIsloading(true);
    const response = await ApiClient.patch(
      API_GetJobPrice,
      totalAmountData,
      `Bearer ${token}`
    );

    // Handle responses and navigation
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (response?.data?.code === 200) {
      setIsloading(false);
      var updatedValues = response?.data?.data;
      setTotalAmount((totalAmount) => {
        handleClose();
        setChangeStartTime("");
        setChangeEndTime("");
        return { ...totalAmount, ...updatedValues };
      });
    } else {
      setIsloading(false);
    }
  };

  // This function sets start and end time when the edit modal opens
  const handleOpen = (res) => {
    setChangeEndTime(res.endTime.split("T")[1].replace(":00.000Z", ""));
    setChangeStartTime(res.startTime.split("T")[1].replace(":00.000Z", ""));
  };

  // This function converts multi-date input to a formatted array of custom dates
  const getMultiDate = (e) => {
    const cDate = [];
    for (let i = 0; i < e.length; i++) {
      cDate.push(e[i].year + "/" + e[i].month + "/" + e[i].day);
    }
    setCustomDates(cDate);
  };

  // This useEffect hook triggers when 'customDates' changes and calls a function
  // to check the calendar month if custom dates are present.
  useEffect(() => {
    if (customDates.length > 0) {
      checkCalendarMonth();
    }
  }, [customDates]);

  // This function checks if all selected custom dates are in the same month.
  const checkCalendarMonth = () => {
    let found = false;
    let selectedMonth = customDates[0].split("/")[1];

    // Loop through custom dates to compare months
    for (let i = 0; i < customDates.length; i++) {
      let month = customDates[i].split("/")[1];

      // If a date's month is different from the selected month, set 'found' to true
      if (month !== selectedMonth) {
        setIsFound(true); // Indicate that different months are selected
        found = true;
        break;
      }
    }

    // If dates are found in different months, handle the situation
    if (found) {
      // Clear the 'customDates' array and show a warning toast
      while (customDates.length > 0) {
        customDates.pop();
      }
      toast.warning("Please choose dates in the same month");
    } else {
      setIsFound(false); // Indicate that all dates are in the same month
    }
  };

  var cusratedata = "";

  // const [open, setOpen] = React.useState(false);
  const [openGuard, setOpenGuard] = useState(false);

  const handleTooltipCloseGuard = () => {
    setOpenGuard(false);
  };
  const handleTooltipOpenGuard = () => {
    setOpenGuard(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  // var customerRate = JSON.parse(localStorage.getItem("rate"));
  const [documentFields, setDocumentFields] = useState([
    {
      speciality: "",
      experience: "",
      exprienceArray: [],
      role: "",
      guardRate: "20",
      customerRate: "",
    },
  ]);
  const [guardProfile, setGuardProfileErrors] = useState([{}]);
  const [guardProfiles, setGuardProfileError] = useState([{}]);
  const [options, setOptions] = useState([]);
  const [list, setList] = useState([]);
  const [roles, setRole] = useState([]);
  const [description, setDescription] = useState("");
  const [ErrorDescription, setErrorDescription] = useState(null);
  const [isGuardRowSelected, setIsGuardRowSelected] = useState(false);

  const [shiftSchedule, setShiftSchedule] = useState([
    {
      startTime: "",
      endTime: "",
    },
  ]);

  // Initialize an array to store default options based on 'list' data
  const defaultOptions = [];
  list.map((item) =>
    defaultOptions.push({ name: item.locationName, id: item.id })
  );

  // Handle input change to update the displayed options based on user input
  const onInputChange = (event, index) => {
    setOptions(
      defaultOptions.filter((option) =>
        option.name.includes(event.target.value)
      )
    );
  };

  // useEffect hook to check if all fields in 'documentFields' are filled, setting 'isGuardRowSelected'
  useEffect(() => {
    for (let i in documentFields) {
      if (Object.values(documentFields[i]).every((x) => x !== "")) {
        setIsGuardRowSelected(true);
        break;
      } else {
        setIsGuardRowSelected(false);
      }
    }
  }, [documentFields]);

  // Callback function to handle card addition from a child component
  const cardAdded = (crd) => {
    if (crd) {
      setSmShowCard(false);
    } else {
      setSmShowCard(false);
    }
  };

  // Handle select all functionality for guard rows
  const handleGuardSelectAll = (e) => {
    let emtyObj = {};
    let emtyArry = [];

    if (e.target.checked) {
      // Find a non-empty row to clone for selection
      for (let i in documentFields) {
        if (Object.values(documentFields[i]).every((x) => x !== "")) {
          emtyObj = documentFields[i];
          break;
        }
      }

      // Populate 'emtyArry' with cloned rows
      for (let i in documentFields) {
        emtyArry.push(emtyObj);
      }
      setDocumentFields(emtyArry); // Update 'documentFields' with the cloned rows
    } else {
      // Create new empty rows if the select all is unchecked
      for (let i in documentFields) {
        if (i !== "0") {
          emtyArry.push({
            speciality: "",
            experience: "",
            role: "",
            guardRate: "",
            customerRate: "",
          });
        } else {
          emtyArry.push(documentFields[0]);
        }
      }
      setDocumentFields(emtyArry); // Update 'documentFields' with the new empty rows
    }
  };

  // useEffect hook to perform initial setup when the component mounts
  useEffect(() => {
    if (token) {
      // Call necessary API functions if token is available
      getAllCards();
      callApiSpeciality();
      callworkloactionList();
      callGetJobRoleList();
    } else {
      // Display a warning and navigate to login if token is missing
      toast.warning("Your session has expired. Kindly log in again.", "yellow");
      navigate(`/login`);
    }
  }, []);

  // Function to fetch all cards from the API
  const getAllCards = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_AddCard + `?custId=${clientSectet}`,
      data,
      `Bearer ${token}`
    );

    // Handle responses and update the state
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res.data.code === 200) {
      setCardList(res?.data?.data);
      callMyProfileApi();
      if (res?.data?.data.length < 1) {
        setCardReqErr(true);
        sessionStorage.setItem("length", res?.data?.data.length);
      } else {
        setCardReqErr(false);
      }
    } else {
      toast.error(res.message);
    }
  };

  // Function to handle selected job for a card
  const selectedJob = (val) => {
    if (val === "") {
      setCardIdError(true);
      setCardId("");
      return;
    } else {
      setCardIdError(false);
      setCardId(val);
    }
  };

  // Function to handle selected amount for a card
  const selectedAmount = (a) => {
    const numbers = /^[1-9]\d{0,9}(?:\.\d{1,2})?$/;
    if (a === "") {
      setAmountError(true);
      setAmountRegexError(false);
      setAmount("");
      return;
    }
    if (!numbers.test(a)) {
      setAmountError(false);
      setAmountRegexError(true);
    } else {
      setAmountRegexError(false);
      setAmountError(false);
      setAmount(a);
    }
  };

  // Function to add balance to a card
  const addBalance = async () => {
    selectedAmount(amount);
    selectedJob(cardId);

    if (amount !== "" && cardId !== "" && !amountRegexError) {
      const data = {
        amount: amount,
        cardId: cardId,
        custId: clientSectet,
        userId: userId,
      };
      setCardLoading(true);
      let response = await ApiClient.post(
        API_AddAmount,
        data,
        `Bearer ${token}`
      );

      if (response?.data?.code === 401) {
        toast.error(response?.data?.message);
        navigate(`/login`);
      }
      if (response?.data?.code === 200) {
        setCardLoading(false);
        setSmShow(false);
        document.getElementById("card").value = "";
        setCardId("");
        document.getElementById("amount").value = "";
        setAmount("");
        toast.success(response?.data?.message);
      } else {
        setCardLoading(false);
        toast.error(response?.data?.message);
      }
    }
  };

  // Function to convert and calculate the difference between two dates in hours
  const dateConverter = (startDate, timeEnd) => {
    const newStartDate = new Date(startDate);
    const newEndDate = new Date(timeEnd);
    let result = moment.utc(newStartDate).diff(newEndDate, "hours");
    return result;
  };

  // Function to fetch job role list from the API
  async function callGetJobRoleList() {
    const data = "";
    const res = await ApiClient.get(
      API_GetJobRoleList,
      data,
      `Bearer ${token}`
    );
    if (res.data.code === 200) {
      setRole(res.data.data);
    }
  }

  // Function to fetch work location list from the API
  async function callworkloactionList() {
    const data = "";
    const res = await ApiClient.get(
      API_WorkLocationList +
        `?page=${1}&sortBy=asc&limit=${10}&searchBy=&unitId=`,
      data,
      `Bearer ${token}`
    );
    if (res.data.code === 200) {
      setList(res.data.data.docs);
    }
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
  }

  // Function to handle job title validation
  const handleJobTitle = (value) => {
    if (value === "" || !value.trim()) {
      setErrorTitle("*Please enter job title.");
    } else {
      setErrorTitle("");
    }
  };

  // Functions to handle validation for various fields
  const _description = (num) => {
    if (num === "") {
      setErrorDescription("*Please enter job title.");
    } else {
      setErrorDescription(null);
    }
  };

  const _jobDate = (num) => {
    if (num === "") {
      setErrorJobDate("*Please enter job date.");
    } else {
      setErrorJobDate(null);
    }
  };

  const _jobEndDate = (num) => {
    if (num === "") {
      setErrorJobDate("*Please enter job end date.");
    } else {
      setErrorJobDate(null);
    }
  };

  const _shiftOneStartTime = (num) => {
    if (num === "") {
      setErrorShiftOneStartTime("*Please enter shift 1 start time.");
    } else {
      setErrorShiftOneStartTime(null);
    }
  };

  const _shiftOneEndTime = (num) => {
    if (num === "") {
      setErrorShiftOneEndTime("*Please enter shift 1 end time.");
    } else {
      setErrorShiftOneEndTime(null);
    }
  };

  // Function to handle the selection of a location
  const selectedLoc = (v) => {
    locationID = v; // Make sure to review this assignment
    setLocation(v);
  };

  // Function to validate form input and submit the job
  const validate = async () => {
    if (isFound) {
      toast.warning("Please choose date in same month");
      return;
    }
    const len = sessionStorage.getItem("length");
    // //console.log(len);
    getAllCards();
    if (cardReqErr && len === "0") {
      setSmShowCard(true);
      toast.warning("No Card Found please add card first", "yellow");
      return;
    }

    var isDocument = await checkDocumentValidationOnSubmit();
    if (title === "" || !title.trim()) {
      setErrorTitle("*Please enter job title.");
    } else if (isDocument || jobDate) {
      var jobsDate = new Date(jobDate) + 1;
      // //console.log(jobsDate, "jobdate1");
      var shiftData = shiftSchedule;
      var tempData = [];
      for (let i = 0; i < shiftData.length; i++) {
        const endTime = shiftData[i].startTime;
        const f = endTime.split(":");
        var edate = parseFloat(f[0]) + 4;
        const exactEndDate =
          edate <= 9 ? "0" + edate + ":" + f[1] : edate + ":" + f[1];

        // const exactEndDate = parseFloat(shiftData[i].endTime) + 4;
        // var inc = moment.utc(d).add(1, "days");
        // const milis = moment.utc(d).format("x");
        const today = new Date(jobDate);
        var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        const d = moment.utc(tomorrow).format("YYYY-MM-DD");
        // //console.log(d);
        // //console.log(endTime);
        // //console.log(exactEndDate);
        // if (exactEndDate >= 24) {
        const e = exactEndDate;
        const s = e.split(":");
        // //console.log(s[0]);
        const eTime = shiftData[i].endTime;
        const sep = eTime.split(":");
        const startDate =
          moment.utc(jobDate).format("yyyy-MM-DD") +
          "T" +
          shiftData[i].startTime +
          ":00.000Z";
        let timeEnd = "";
        if (f[0] > 12 && sep[0] < 12) {
          timeEnd =
            moment.utc(d).format("yyyy-MM-DD") +
            "T" +
            shiftData[i].endTime +
            ":00.000Z";
        } else if (f[0] === sep[0]) {
          timeEnd =
            moment.utc(d).format("yyyy-MM-DD") +
            "T" +
            shiftData[i].endTime +
            ":00.000Z";
        } else if (f[0] > sep[0]) {
          timeEnd =
            moment.utc(d).format("yyyy-MM-DD") +
            "T" +
            shiftData[i].endTime +
            ":00.000Z";
        } else {
          timeEnd =
            moment.utc(jobDate).format("yyyy-MM-DD") +
            "T" +
            shiftData[i].endTime +
            ":00.000Z";
        }

        const newStartDate = new Date(startDate);
        const newEndDate = new Date(timeEnd);
        let result = moment.utc(newStartDate).diff(newEndDate, "hours");
        const pos = Math.abs(result);
        // //console.log(result);
        // //console.log(pos);
        // //console.log(timeEnd);
        if (pos < 4) {
          toast.warning(
            `Shift ${
              i + 1
            } end time should be atleast 4 hrs greater than Shift ${
              i + 1
            } start time`
          );
          shiftError.push(
            "end time should be atleast 4 hrs greater than Shift"
          );
        } else if (pos > 12) {
          toast.warning(
            `Shift ${
              i + 1
            } exceeded the max shift hours limit. Shift hours should be minimum 4 hours and maximum 12 hours allowed `
          );
          shiftError.push(
            "exceeded the max shift hours limit. Shift hours should be minimum 4 hours and maximum 12 hours allowed"
          );
        } else {
          tempData.push({
            startTime: startDate,
            endTime: timeEnd,
          });
          if (tempData.length === shiftperday) {
            shiftError.splice(0, shiftError.length);
          }
          // console.log(tempData);
        }
      }

      if (location === "") {
        toast.warning("Please Select Location");
        return;
      }

      if (jobType === "") {
        toast.warning("Please Select Job Type");
        setErrorJobType("Please Select Job Type");
        return;
      }

      // if (transportation === "") {
      //   toast.warning("Please Select Transpotation");
      //   return;
      // }

      if (dresscode === "") {
        toast.warning("Please Select Dress Code");
        return;
      }

      if (numberofgards === "") {
        toast.warning("Please select no of guards");
        return;
      }

      if (shiftperday === "") {
        toast.warning("Please select shift per day");
        return;
      }

      // console.log(shiftSchedule.startTime, "starttime");
      // if (shiftSchedule.startTime === undefined) {
      //   toast.warning("Please select start shift time");
      //   return;
      // }
      // console.log(shiftSchedule.endTime, "endTime");

      // if (shiftSchedule.endTime === undefined) {
      //   toast.warning("Please select end shift time");
      //   return;
      // }
      for (let i = 0; i < documentFields.length; i++) {
        const element = documentFields[i];
        if (element?.role === "") {
          toast.warning("Please select guard role");
          return;
        }
      }

      for (let i = 0; i < shiftSchedule.length; i++) {
        // console.log(shiftSchedule[i].startTime, "starttime");
        if (shiftSchedule[i].startTime === "") {
          toast.warning("Please select  shift start time");
          return;
        }
        if (shiftSchedule[i].endTime === "") {
          toast.warning("Please select  shift end time");
          return;
        }
      }

      // const cookie = new Cookies();
      // const tokens = cookie.get("token");
      // const userId = cookie.get("userID");
      const data = {
        jobTitle: title,
        locationId: location,
        jobType: jobType,
        description: description,
        transportation: "",
        isVehicleRequired: transportation,
        dressCode: dresscode,
        noOfGaurd: numberofgards,
        shifPerDay: shiftperday,
        customDates: customDates,
        gaurdProfile: documentFields,
        jobDate: jobDate,
        jobEndDate: jobEndDate,
        shifts: tempData,
        customJobData: totalAmount,
      };
      // console.log('job data=========',data);
      // console.log("shiftError", data.shifts.length);

      if (data.shifts.length === parseInt(shiftperday)) {
        const res = await ApiClient.post(API_ADDJOB, data, `Bearer ${token}`);
        if (res?.data?.code === 401) {
          // setIsloading(false);
          toast.error(res?.data?.message);
          navigate(`/login`);
        }
        if (res?.data?.code === 200) {
          // console.log(res, "add job res");
          navigate(`/alljobs`);
          toast.success(res?.data?.message);
        } else if (res?.data?.code === 401) {
          toast.error(res?.data?.message);
          navigate(`/login`);
        } else {
          // toast.error(res?.data?.message);
          confirm(res?.data?.message);
        }
      }
    }
  };
  // Function to display a confirmation dialog
  const confirm = async (title) => {
    Swal.fire({
      title: title,
      // text: "You want to logout?",
      icon: "warning",
      showDenyButton: true,
      confirmButtonText: "Add Funds",
      denyButtonText: "Ok",
      confirmButtonColor: "#3085d6",
      denyButtonColor: "#6e7881",
    }).then((result) => {
      if (result.isConfirmed) {
        setSmShow(true);
      }
    });
    // console.log('delete list id', id)
  };
  // Function to handle changes in transportation settings
  const transpotationChanges = (v) => {
    // console.log(v);
    if (v === "true") {
      setTransportation(true);
    }

    if (v === "false") {
      setTransportation(false);
    }
  };

  // Function to fetch the price of a job
  const getPriceOfJob = async () => {
    if (isFound) {
      toast.warning("Please choose date in same month");
      return;
    }
    var isDocument = await checkDocumentValidationOnSubmit();
    if (title === "") {
      setErrorTitle("*Please enter job title.");
    } else if (isDocument || jobDate) {
      var jobsDate = new Date(jobDate) + 1;
      // console.log(jobsDate, "jobdate1");
      //console.log(shiftSchedule, "shiftSchedule");
      var shiftData = shiftSchedule;
      var tempData = [];
      for (let i = 0; i < shiftData.length; i++) {
        const endTime = shiftData[i].startTime;
        const f = endTime.split(":");
        var edate = parseFloat(f[0]) + 4;
        const exactEndDate =
          edate <= 9 ? "0" + edate + ":" + f[1] : edate + ":" + f[1];

        // const exactEndDate = parseFloat(shiftData[i].endTime) + 4;
        // var inc = moment.utc(d).add(1, "days");
        // const milis = moment.utc(d).format("x");
        const today = new Date(jobDate);
        var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        const d = moment.utc(tomorrow).format("YYYY-MM-DD");
        // console.log(d);
        // console.log(endTime);
        // console.log(exactEndDate);
        // if (exactEndDate >= 24) {
        const e = exactEndDate;
        const s = e.split(":");
        // console.log(s[0]);
        const eTime = shiftData[i].endTime;
        const sep = eTime.split(":");
        const startDate =
          moment.utc(jobDate).format("yyyy-MM-DD") +
          "T" +
          shiftData[i].startTime +
          ":00.000Z";
        let timeEnd = "";
        if (f[0] > 12 && sep[0] < 12) {
          timeEnd =
            moment.utc(d).format("yyyy-MM-DD") +
            "T" +
            shiftData[i].endTime +
            ":00.000Z";
        } else if (f[0] === sep[0]) {
          timeEnd =
            moment.utc(d).format("yyyy-MM-DD") +
            "T" +
            shiftData[i].endTime +
            ":00.000Z";
        } else if (f[0] > sep[0]) {
          timeEnd =
            moment.utc(d).format("yyyy-MM-DD") +
            "T" +
            shiftData[i].endTime +
            ":00.000Z";
        } else {
          timeEnd =
            moment.utc(jobDate).format("yyyy-MM-DD") +
            "T" +
            shiftData[i].endTime +
            ":00.000Z";
        }

        const newStartDate = new Date(startDate);
        const newEndDate = new Date(timeEnd);
        let result = moment.utc(newStartDate).diff(newEndDate, "hours");
        const pos = Math.abs(result);
        // console.log(result);
        // console.log(pos);
        // console.log(timeEnd);
        if (pos < 4) {
          toast.warning(
            `Shift ${
              i + 1
            } end time should be atleast 4 hrs greater than Shift ${
              i + 1
            } start time`
          );
          shiftError.push(
            "end time should be atleast 4 hrs greater than Shift"
          );
        } else if (pos > 12) {
          toast.warning(
            `Shift ${i + 1} 
            exceeded the max shift hours limit. Shift hours should be minimum 4 hours and maximum 12 hours allowed `
          );
          shiftError.push(
            "exceeded the max shift hours limit. Shift hours should be minimum 4 hours and maximum 12 hours allowed"
          );
        } else {
          tempData.push({
            startTime: startDate,
            endTime: timeEnd,
          });
          if (tempData.length === shiftperday) {
            shiftError.splice(0, shiftError.length);
          }
          // console.log(tempData);
        }
      }
      // var shiftData = shiftSchedule;
      // var tempData = [];
      // for (let i = 0; i < shiftData.length; i++) {
      //   const endTime = shiftData[i].startTime;
      //   const f = endTime.split(":");
      //   var edate = parseFloat(f[0]) + 4;
      //   const exactEndDate =
      //     edate <= 9 ? "0" + edate + ":" + f[1] : edate + ":" + f[1];

      //   // const exactEndDate = parseFloat(shiftData[i].endTime) + 4;
      //   // var inc = moment.utc(d).add(1, "days");
      //   // const milis = moment.utc(d).format("x");
      //   const today = new Date(jobDate);
      //   var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
      //   const d = moment.utc(tomorrow).format("YYYY-MM-DD");
      //   console.log(d);
      //   console.log(endTime);
      //   console.log(exactEndDate);
      //   // if (exactEndDate >= 24) {
      //   const e = exactEndDate;
      //   const s = e.split(":");
      //   console.log(s[0]);
      //   const eTime = shiftData[i].endTime;
      //   const sep = eTime.split(":");
      //   const startDate =
      //     moment.utc(jobDate).format("yyyy-MM-DD") +
      //     "T" +
      //     shiftData[i].startTime +
      //     ":00.000Z";
      //   let timeEnd = "";
      //   if (f[0] > 12 && sep[0] < 12) {
      //     timeEnd =
      //       moment.utc(d).format("yyyy-MM-DD") +
      //       "T" +
      //       shiftData[i].endTime +
      //       ":00.000Z";
      //   } else if (f[0] === sep[0]) {
      //     timeEnd =
      //       moment.utc(d).format("yyyy-MM-DD") +
      //       "T" +
      //       shiftData[i].endTime +
      //       ":00.000Z";
      //   } else if (f[0] > sep[0]) {
      //     timeEnd =
      //       moment.utc(d).format("yyyy-MM-DD") +
      //       "T" +
      //       shiftData[i].endTime +
      //       ":00.000Z";
      //   } else {
      //     timeEnd =
      //       moment.utc(jobDate).format("yyyy-MM-DD") +
      //       "T" +
      //       shiftData[i].endTime +
      //       ":00.000Z";
      //   }

      //   const newStartDate = new Date(startDate);
      //   const newEndDate = new Date(timeEnd);
      //   let result = moment.utc(newStartDate).diff(newEndDate, "hours");
      //   const pos = Math.abs(result);
      //   console.log(result);
      //   console.log(pos);
      //   console.log(timeEnd);
      //   if (pos < 4) {
      //     toast.warning(
      //       `Shift ${
      //         i + 1
      //       } end time should be atleast 4 hrs greater than Shift ${
      //         i + 1
      //       } start time`
      //     );
      //     shiftError.push(
      //       "end time should be atleast 4 hrs greater than Shift"
      //     );
      //   } else if (pos > 10) {
      //     toast.warning(
      //       `Shift ${
      //         i + 1
      //       } exceeded the max shift hours limit. Shift hours should be minimum 4 hours and maximum 10 hours allowed `
      //     );
      //     shiftError.push(
      //       "exceeded the max shift hours limit. Shift hours should be minimum 4 hours and maximum 10 hours allowed"
      //     );
      //   } else {
      //     tempData.push({
      //       startTime: startDate,
      //       endTime: timeEnd,
      //     });
      //     console.log(tempData);
      //   }
      // }
    }
    const data = {
      jobTitle: title,
      locationId: location,
      jobType: jobType,
      description: description,
      transportation: "",
      isVehicleRequired: transportation,
      dressCode: dresscode,
      noOfGaurd: numberofgards,
      shifPerDay: shiftperday,
      customDates: customDates,
      gaurdProfile: documentFields,
      jobDate: jobDate,
      jobEndDate: jobEndDate,
      shifts: tempData,
    };
    // console.log(data);
    if (data?.shifts?.length === parseInt(shiftperday)) {
      setIsloading(true);
      const res = await ApiClient.post(
        API_GetJobPrice,
        data,
        `Bearer ${token}`
      );
      // console.log("line 528 res: ",res);
      if (res?.data?.code === 401) {
        // setIsloading(false);
        toast.error(res?.data?.message);
        navigate(`/login`);
      }
      if (res?.data?.code === 200) {
        setIsloading(false);
        setTotalAmount(res?.data?.data);
        // console.log("line 531 total amount: ", totalAmount.totalAmount)
        totalAmount?.shifts?.length !== 0 ? setShowT(true) : setShowT(false);
        setIsReview(true);
        // console.log("line 533 total amount: ", totalAmount?.shifts?.length)
      } else {
        setIsloading(false);
      }
    }
  };
  // Function to check document validation on form submission
  const checkDocumentValidationOnSubmit = async () => {
    var valid = true;
    let errorMsg = documentFields.map((data, key) => {
      let error = {};
      // console.log("check value=====", key, "data", data);
      if (data.speciality === "") {
        error.errorspeciality = "Speciality is required";
        valid = false;
      } else {
        error.errorspeciality = "";
      }
      if (data.experience === "") {
        error.errorexprience = "Exprience is required";
        valid = false;
      } else {
        error.errorexprience = "";
      }
      if (data.rate === "") {
        error.errorrate = "Rate is required";
        valid = false;
      } else {
        error.errorrate = "";
      }
      if (data.role === "") {
        error.errorrole = "Role is required";
        valid = false;
      } else {
        error.errorrole = "";
      }
      if (shiftSchedule.startTime === "") {
        error.errorrole = " is required";
        valid = false;
      } else {
        error.errorrole = "";
      }
      return error;
    });
    let errorMsgg = (data, key) => {
      // console.log(data);
      let error = {};
      // console.log("check value=====", key, "data", data);
      if (dresscode === "") {
        error.errorDresscode = "Dresscode is required";
        valid = false;
      } else {
        error.errorDresscode = "";
      }
      if (numberofgards === "") {
        error.errornumberofguard = "Number of gards is required";
        valid = false;
      } else {
        error.errornumberofguard = "";
      }
      if (shiftperday === "") {
        error.errorshiftperday = "shiftperday is required";
        valid = false;
      } else {
        error.errorshiftperday = "";
      }
      return error;
    };
    setGuardProfileError(errorMsgg);
    setGuardProfileErrors(errorMsg);
    return valid;
  };
  // Function to handle changes in the shift schedule
  const schrduleshift = (e, id) => {
    const value = [...shiftSchedule];
    value[id][e.target.name] = e.target.value;
    // console.log(value);
    setShiftSchedule(value);
  };
  // Function to generate an array of documentFields based on user input
  const maping = (e) => {
    var tempArray = [];
    for (var i = 0; i < parseInt(e.target.value); i++) {
      tempArray.push({
        speciality: "",
        experience: "",
        role: "",
        guardRate: "",
        customerRate: "",
      });
    }
    setDocumentFields(tempArray);
  };
  // Function to generate an array of shiftSchedule based on user input
  const shitCheduleMaping = (e) => {
    var tempArray = [];
    for (var i = 0; i < parseInt(e.target.value); i++) {
      tempArray.push({
        startTime: "",
        endTime: "",
      });
    }
    setShiftSchedule(tempArray);
    // console.log(tempArray);
  };
  // Function to call the API to fetch specialities
  async function callApiSpeciality() {
    const data = {
      isTravelTo: 20,
      workSpecialities: "Bank",
      isGoWith: 1,
      vestSize: 2,
      lat: 289.0,
      lng: 289.0,
    };
    const res = await ApiClient.get(API_Speciality, data, `Bearer ${token}`);
    // console.log("specolity res", res.data.data)
    if (res.data.code === 200) {
      setSpecialitys(res.data.data);
    } else {
      setIsError(true);
      setError(res.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  }

  // Function to handle selection of job type
  const selectJobType = (e) => {
    // Update the job type in state
    setJobType(e.target.value);
  };

  // Function to handle selection of experience for a specific guard document
  const selectedExperiance = (i, e) => {
    const value = [...documentFields];
    var spcdata = [...specialitys];

    // Loop through specialities and their experiences to find the selected experience
    for (let p of spcdata) {
      for (let cus of p.experiences) {
        if (cus._id === e.target.value) {
          // Update customer and guard rates for the selected experience
          value[i]["customerRate"] = cus.customerRate;
          value[i]["guardRate"] = cus.guardRate;
          setDocumentFields(value);
          return cus;
        }
      }
    }
  };

  // Function to handle selection of speciality for a specific guard document
  const selectedSpeciality = (index, e) => {
    var spcdata = [...specialitys];
    const value = [...documentFields];
    var da = [];

    // Find the selected speciality and update experience information
    for (let d of spcdata) {
      if (d.id === e.target.value) {
        da[index] = d;
        value[index].exprienceArray = d;
        setExperience(da);
        return d;
      }
    }
  };

  // Function to handle changes in form inputs (select fields)
  const handleChange = async (index, e) => {
    const value = [...documentFields];

    if (e.target.type === "select-one") {
      // Update the selected value in the documentFields array
      value[index][e.target.name] = e.target.value;
    }

    setDocumentFields(value);
  };

  // Function to handle changes in guard experience information
  const handleExp = (index, e) => {
    const value = [...documentFields];

    // Update the selected value in the documentFields array
    value[index][e.target.name] = e.target.value;
    setDocumentFields(value);
  };

  const selectTransportaion = (e) => {
    // alert(e.target.value)
    setTransportation(e.target.value);
  };

  // Function to fetch user profile data from the API
  async function callMyProfileApi() {
    // Retrieve user information from local storage
    const userInfo = localStorage.getItem("userInfo");
    const refToken = JSON.parse(userInfo);
    const data = ""; // Data payload (if needed) for the API request

    // Call the API to get user profile data using the refresh token
    const response = await ApiClient.get(
      API_GetProfile + refToken.user.id,
      data,
      `Basic ${refToken.tokens.refresh.token}`
    );

    // Check if the API response is successful
    if (response.data.code === 200) {
      // Update the state with the fetched profile data
      setProfile(response.data.data);
    } else {
      // Display an alert if the API request fails
      setIsloading(false);
      alert(response.data.message);
    }
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const EditShift = (res, i) => {};

  // const callDeleteAPI = async (id) => {
  //   const data = "";
  //   // setLoading(true);
  //   const ress = await ApiClient.delete(
  //     API_DeleteNewDocument + id,
  //     data,
  //     `Bearer ${token}`
  //   );
  //   // console.log('delete list ress', JSON.stringify(ress))
  //   if (ress.data.code === 200) {
  //     toast.success(ress.data.message, "green");
  //     // setLocationList(locationList)/
  //     const showList = documentList.filter((ele) => ele.id !== id);
  //     setDocumentList(showList);
  //     // setLoading(false);
  //   } else {
  //     // alert(ress.data.message)
  //     toast.error(ress.data.message, "red");
  //     setIsError(true);
  //     setError(ress.data.message);
  //     setTimeout(() => {
  //       setError("");
  //       setIsError(false);
  //     }, 5000);
  //   }
  // };

  return (
    <>
      {/* {console.log("specolity res specialitys", specialitys)} */}
      <Header />
      <section className="add_job">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9">
              <div className="signupForm">
                <form>
                  <div className="job_title">
                    <div className="row align-items-lg-center ">
                      <div className="col-md-12 text-center">
                        <h4>Add Job</h4>
                      </div>
                      {iserror ? (
                        <div className="alert alert-danger">{error}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Job Title*"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                            handleJobTitle(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-briefcase"></i>
                        </span>
                      </div>
                      {ErrorTitle != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                              margin: " 0px 0px 12px",
                            }}
                          >
                            {ErrorTitle}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="Job_locate">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="card"
                            onChange={(e) => {
                              selectedLoc(e.target.value);
                            }}
                          >
                            <option value="" selected>
                              Select Job location
                            </option>
                            {list?.map((r, i) => {
                              return (
                                <option value={r?.id}>{r?.locationName}</option>
                              );
                            })}
                          </select>

                          <span className="attachIcon">
                            <i className="fas fa-caret-down"></i>
                          </span>
                        </div>
                        {/* <SearchbarDropdown
                          options={options}
                          onInputChange={onInputChange}
                        /> */}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <select
                          // value={jobType}
                          className="form-control"
                          onChange={(e) => selectJobType(e)}
                        >
                          <option selected value="">
                            Job Type
                          </option>
                          <option value="1">One Day</option>
                          {/* <option value="2">Long Term</option> */}
                          <option value="3">Custom</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                        {}
                        {/* {ErrorJobType != null ? (
                          <div>
                            <p
                              style={{
                                color: "red",
                                fontSize: 13,
                                fontFamily: "GothamMedium",
                                marginHorizontal: 35,
                                textAlign: "left",
                              }}
                            >
                              {ErrorJobType}
                            </p>
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                    {/* <div className="col-12 col-md-6">
                      <div className="form-group">
                        <select
                          className="form-control"
                          onChange={(e) => selectTransportaion(e)}
                        >
                          <option selected>Transportation</option>
                          <option value="1">Public</option>
                          <option value="2">Own Vehicle</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div> */}
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <select
                          className="form-control"
                          aria-label="Default select example"
                          name="Dresscode"
                          //  type="Dresscode"
                          defaultValue="Dresscode"
                          value={dresscode}
                          onChange={(e) => setDresscode(e.target.value)}
                        >
                          <option selected value="">
                            Dress Code
                          </option>
                          <option value="1">HV Vest</option>
                          <option value="2">Casual</option>
                          <option value="3">Suited</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                      <ErrorMessage
                        messerror={
                          guardProfiles.length >= 1 &&
                          guardProfiles.errorDresscode
                        }
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="no_of_guards_box">
                        <div className="form-group">
                          <select
                            className="form-control"
                            aria-label="Default select example"
                            name="Numberofgards"
                            // type="Numberofgards"
                            defaultValue="Numberofgards"
                            value={numberofgards}
                            onChange={(e) => {
                              setNumberofgards(e.target.value);
                              maping(e);
                            }}
                            // onChange={(e)=>noOfGuards(e)}
                          >
                            <option selected value="">
                              No. Of Guards
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                          <span>
                            <i className="fas fa-caret-down"></i>
                          </span>
                        </div>
                        <ClickAwayListener
                          onClickAway={handleTooltipCloseGuard}
                        >
                          <div className="handle_tool_box">
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipCloseGuard}
                              open={openGuard}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="Please choose how many guards you need per shift. For e.g, You need a guard from 9Am to 5Pm and another from 5Pm to 1Am, then you will need 2 guards. If you need 2 guards per shift in this same example, then you would select 4 guards.
                              "
                            >
                              <span
                                onClick={handleTooltipOpenGuard}
                                className="handle_tooltip_span"
                              >
                                {" "}
                                <i class="fa fa-info-circle"></i>
                              </span>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      </div>
                      <ErrorMessage
                        messerror={
                          guardProfiles.length >= 1 &&
                          guardProfiles.errornumberofguard
                        }
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="no_of_guards_box">
                        <div className="form-group">
                          <select
                            className="form-control"
                            aria-label="Default select example"
                            name="shiftperday"
                            // type="shiftperday"
                            defaultValue="shiftperday"
                            value={shiftperday}
                            onChange={(e) => {
                              setshiftperday(e.target.value);
                              shitCheduleMaping(e);
                            }}
                          >
                            <option selected value="">
                              Shift Per Day
                            </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            {/* <option value="5">5</option>
                            <option value="6">6</option> */}
                          </select>
                          <span>
                            <i className="fas fa-caret-down"></i>
                          </span>
                        </div>

                        <ClickAwayListener onClickAway={handleTooltipClose}>
                          <div className="handle_tool_box">
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={handleTooltipClose}
                              open={open}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title="9Am to 5pm is one shift, 9Am to 5Pm and 5Pm to 1Am on the same day would be two shifts."
                            >
                              <span
                                onClick={handleTooltipOpen}
                                className="handle_tooltip_span"
                              >
                                {" "}
                                <i class="fa fa-info-circle"></i>
                              </span>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      </div>
                      <ErrorMessage
                        messerror={
                          guardProfiles.length >= 1 &&
                          guardProfiles.errorshiftperday
                        }
                      />
                    </div>
                    <div className="col-md-12">
                      {jobType === "3" ? (
                        <div className="row align-items-center">
                          <div className="col-md-12">
                            <div className="guard_title">
                              <h5>Shift Profile</h5>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <h6 className="title_inner">Job Date</h6>
                          </div>
                          <div className="col-12 col-md-4">
                            <div className="form-group custom_date_select">
                              <DatePicker
                                minDate={moment
                                  .utc(tomorrow)
                                  .format("yyyy-MM-DD")}
                                multiple
                                placeholder="yyyy-MM-DD"
                                inline={true}
                                id="customDate"
                                // onFocusedDateChange={setFocusedDate}
                                // onClose={() => setFocusedDate(undefined)}
                                // showOtherDays
                                // sort
                                value={moment
                                  .utc(tomorrow)
                                  .format("yyyy-MM-DD")}
                                style={{
                                  backgroundColor: "aliceblue",
                                  height: "24px",
                                  borderRadius: "8px",
                                  fontSize: "14px",
                                  padding: "3px 10px",
                                }}
                                onChange={(e) => {
                                  getMultiDate(e);
                                }}
                                // maxDate={new Date().setDate(31 || 30 || 29 || 28)}
                              />
                              <span>
                                <i className="fas fa-calendar"></i>
                              </span>
                            </div>
                            {ErrorJobDate != null ? (
                              <div>
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: 13,
                                    fontFamily: "GothamMedium",
                                    marginHorizontal: 35,
                                    textAlign: "left",
                                  }}
                                >
                                  {ErrorJobDate}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {jobType !== "3" ? (
                        <div className="row align-items-center">
                          <div className="col-md-12">
                            <div className="guard_title">
                              <h5>Shift Profile</h5>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="guard_title border-0 p-0 mt-0">
                              <h5>
                                {jobType === "2"
                                  ? "Job Start Date"
                                  : "Job Date"}
                              </h5>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            <div className="form-group date">
                              <input
                                type="date"
                                className="form-control date"
                                aria-describedby=""
                                placeholder="dd-mm-yyyy"
                                id="jdate"
                                // value={jobDate}
                                min={moment.utc(tomorrow).format("yyyy-MM-DD")}
                                onChange={(e) => {
                                  setJobDate(e.target.value);
                                  _jobDate(e.target.value);
                                }}
                                // onBlur={(e) => (e.currentTarget.type = "date")}
                                // onFocus={(e) => (e.currentTarget.type = "date")}
                              />
                              <span style={{ cursor: "pointer" }}>
                                <a href="javascript:void(0)">
                                  {" "}
                                  <i className="fas fa-calendar"></i>
                                </a>
                              </span>
                            </div>
                            {ErrorJobDate != null ? (
                              <div>
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: 13,
                                    fontFamily: "GothamMedium",
                                    marginHorizontal: 35,
                                    textAlign: "left",
                                  }}
                                >
                                  {ErrorJobDate}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}

                      {jobType === "2" ? (
                        <div className="row align-items-center">
                          <div className="col-md-3">
                            <div className="guard_title border-0 p-0 mt-0">
                              <h5>Job End Date</h5>
                            </div>
                          </div>
                          <div className="col-12 col-md-4">
                            {/* <label for="Job End Date">Job</label> */}
                            <div className="form-group">
                              <input
                                type="date"
                                className="form-control"
                                aria-describedby=""
                                placeholder={"Job End Date"}
                                value={jobEndDate}
                                min={jobDate}
                                onFocus={(e) => (e.currentTarget.type = "date")}
                                onBlur={(e) => (e.currentTarget.type = "date")}
                                onChange={(e) => {
                                  setJobEndDate(e.target.value);
                                  _jobEndDate(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-calendar"></i>
                              </span>
                            </div>
                            {ErrorJobDate != null ? (
                              <div>
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: 13,
                                    fontFamily: "GothamMedium",
                                    marginHorizontal: 35,
                                    textAlign: "left",
                                  }}
                                >
                                  {ErrorJobDate}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </div>

                    {numberofgards !== "" ? (
                      <div className="col-12">
                        <div className="guard_title">
                          <h5>Guard Profile</h5>

                          {isGuardRowSelected &&
                            parseInt(numberofgards) > 1 && (
                              <div className="same_for_all">
                                <input
                                  type="checkbox"
                                  name="gaurd"
                                  onChange={handleGuardSelectAll}
                                />
                                <p>Apply to all guards</p>
                              </div>
                            )}
                        </div>
                      </div>
                    ) : null}

                    {numberofgards !== ""
                      ? documentFields.map((identificationField, i) => {
                          // console.log(identificationField);
                          return (
                            <>
                              <div className="col-12 col-md-3">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    aria-label="Default select example"
                                    name="speciality"
                                    defaultValue="speciality"
                                    value={identificationField.speciality}
                                    onChange={(e) => {
                                      handleChange(i, e);
                                      selectedSpeciality(i, e);
                                    }}
                                  >
                                    <option selected value="">
                                      Speciality
                                    </option>
                                    {specialitys.map((list, index) => {
                                      // console.log("select list", list, "id", id)
                                      return (
                                        <option key={index} value={list.id}>
                                          {list.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <span>
                                    <i className="fas fa-caret-down"></i>
                                  </span>
                                </div>

                                <ErrorMessage
                                  messerror={
                                    guardProfile.length - 1 >= i &&
                                    guardProfile[i].errorspeciality
                                  }
                                  // style={{margin:"0px 0px 12px"}}
                                />
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    name="experience"
                                    value={identificationField.experience}
                                    onChange={(e) => {
                                      handleExp(i, e);
                                      selectedExperiance(i, e);
                                    }}
                                    defaultValue="experience"
                                  >
                                    <option selected value="">
                                      Experience
                                    </option>
                                    {/* <option value="4">Experience 1</option> */}
                                    {identificationField?.exprienceArray?.experiences.map(
                                      (elist, id) => {
                                        return (
                                          <option key={id} value={elist._id}>
                                            {elist.min +
                                              " - " +
                                              elist.max +
                                              " years"}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                  <span>
                                    <i className="fas fa-caret-down"></i>
                                  </span>
                                </div>
                                <ErrorMessage
                                  messerror={
                                    guardProfile.length - 1 >= i &&
                                    guardProfile[i].errorexprience
                                  }
                                />
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="rate"
                                    key={i}
                                    className="form-control"
                                    value={
                                      "$" + identificationField.customerRate
                                    }
                                    onChange={(e) => handleChange(i, e)}
                                    readOnly
                                  />
                                  {/* <select
                                className="form-control"
                                // name="role"
                                // type="Rate"
                                // value={identificationField.role}
                                // onChange={(e) => handleChange(i, e)}
                                // defaultValue="role"
                              >
                                <option selected>Rate</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                <option value="4">Four</option>
                              </select> */}
                                  {/* <span>
                                <i className="fas fa-caret-down"></i>
                              </span> */}
                                </div>
                                <ErrorMessage
                                  messerror={
                                    guardProfile.length - 1 >= i &&
                                    guardProfile[i].errorrate
                                  }
                                />
                              </div>
                              <div className="col-12 col-md-3">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    aria-label="Default select example"
                                    name="role"
                                    // type="Speciality"
                                    defaultValue="role"
                                    value={identificationField.role}
                                    onChange={(e) => handleChange(i, e)}
                                  >
                                    <option selected disabled value="">
                                      Role
                                    </option>
                                    {roles.map((list, id) => {
                                      // console.log("select list", list, "id", id)
                                      return (
                                        <option value={list.id}>
                                          {list.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  <span>
                                    <i className="fas fa-caret-down"></i>
                                  </span>
                                </div>

                                <ErrorMessage
                                  messerror={
                                    guardProfile.length - 1 >= i &&
                                    guardProfile[i].errorrole
                                  }
                                />
                              </div>
                              <div className="deskHide">
                                {documentFields.length > 1 ? (
                                  <div>
                                    <br /> <br />{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </>
                          );
                        })
                      : null}
                  </div>

                  <div className="row align-items-center">
                    <div className="col-md-12">
                      <div className="guard_title border-0 p-0 mt-0">
                        <h5>Shift Details</h5>
                      </div>
                      {/* <h5 className="title_inner">Shift Details</h5> */}
                    </div>
                    {shiftSchedule.map((listTime, id) => {
                      return (
                        <>
                          <div className="col-12 col-md-3">
                            <label>{`Shift ${id + 1} Start Time`}</label>
                            <div className="form-group time">
                              <input
                                type="time"
                                name="startTime"
                                className="form-control startTime"
                                aria-describedby=""
                                id="startTime"
                                // placeholder={`Shift ${id + 1} Start Time`}
                                // placeholder="Shift 1 Start Time"
                                value={listTime.startTime}
                                // onFocus={(e) => (e.currentTarget.type = "time")}
                                // onBlur={(e) => (e.currentTarget.type = "time")}
                                onChange={(e) => {
                                  schrduleshift(e, id);
                                  _shiftOneStartTime(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-clock"></i>
                              </span>
                            </div>
                            {ErrorShiftOneStartTime != null ? (
                              <div>
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: 13,
                                    fontFamily: "GothamMedium",
                                    marginHorizontal: 35,
                                    textAlign: "left",
                                  }}
                                >
                                  {ErrorShiftOneStartTime}
                                </p>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-12 col-md-3">
                            <label>{`Shift ${id + 1} End Time`}</label>
                            <div className="form-group time">
                              <input
                                type="time"
                                name="endTime"
                                className="form-control startTime"
                                aria-describedby=""
                                id="endTime"
                                // placeholder={`Shift ${id + 1} End Time`}
                                value={listTime.endTime}
                                // onFocus={(e) => {
                                //   e.currentTarget.type = "time";
                                // }}
                                // onBlur={(e) => (e.currentTarget.type = "time")}
                                onChange={(e) => {
                                  schrduleshift(e, id);
                                  // console.log(e.target.value);
                                  _shiftOneEndTime(e.target.value);
                                  // getPriceOfJob();
                                }}
                              />
                              <span>
                                <i className="fas fa-clock"></i>
                              </span>
                            </div>
                            {ErrorShiftOneEndTime != null ? (
                              <div>
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: 13,
                                    fontFamily: "GothamMedium",
                                    marginHorizontal: 35,
                                    textAlign: "left",
                                  }}
                                >
                                  {ErrorShiftOneEndTime}
                                </p>
                              </div>
                            ) : null}
                          </div>
                          {/* <div className="row align-items-center">
                            <div className="col-md-2">
                              <h6 className="title_inner">Total Amount</h6>
                            </div>
                            <div className="col-12 col-md-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Total Amount Deducted For this job"
                                  value={"$ " + totalAmount}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div> */}
                          {/* <div className="col-md-12">
                            <div className="form-group">
                              <input
                                type="text"
                                placeholder="Total Amount Deducted For this job"
                                value={totalAmount}
                                readOnly
                              />
                            </div>
                          </div> */}
                        </>
                      );
                    })}
                  </div>
                  {jobType === "3" && (
                    <p className="note">
                      <b>Note:</b> If you need custom start times on specific
                      dates, please enter the first start time on the left for
                      the first shift. After doing so please click "review job
                      details" Here you can use the EDIT button to customize the
                      start times further.{" "}
                    </p>
                  )}

                  <div className="row justify-content-center">
                    <div className="col-md-3">
                      <div className="guard_title border-0 p-0 mt-0">
                        <h5>Additional Job Details</h5>
                      </div>
                      {/* <h6 className="title_inner">Job Description</h6> */}
                    </div>
                    <div className="col-12 col-md-9">
                      <div className="form-group">
                        <textarea
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Job Description"
                          value={description}
                          maxLength="300"
                          onChange={(e) => {
                            setDescription(e.target.value);
                            _description(e.target.value);
                          }}
                        />
                        {/* <span>
                          <i className="fas fa-briefcase"></i>
                        </span> */}
                      </div>
                      {ErrorDescription != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {ErrorDescription}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="Box">
                        <div className="row">
                          <div className="col-8">
                            <div className="guard_title border-0 p-0 mt-0">
                              <h5>
                                Do you require the guard to have his own
                                vehicle:
                              </h5>
                            </div>
                          </div>
                          <div className="col-4">
                            <ul>
                              <li>
                                <div className="form-check-new">
                                  <input
                                    className="form-check-new-input"
                                    checked={transportation === true}
                                    value="true"
                                    name="transpotation"
                                    type="checkbox"
                                    onChange={(e) =>
                                      transpotationChanges(e.target.value)
                                    }
                                  />
                                  <label
                                    className="form-check-new-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check-new">
                                  <input
                                    className="form-check-new-input"
                                    type="checkbox"
                                    checked={transportation === false}
                                    value="false"
                                    name="transpotation"
                                    onChange={(e) =>
                                      transpotationChanges(e.target.value)
                                    }
                                  />
                                  <label
                                    className="form-check-new-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    Doesn't matter{" "}
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="nextBtnNew">
                        <h6 className="title_inner_right">
                          <a
                            onClick={() => {
                              getPriceOfJob();
                            }}
                          >
                            Review Job Details
                          </a>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center mt-2">
                    {showT && (
                      <div className="row ">
                        <div className="col-md-12">
                          <div className="table-responsive  border-1">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  {/* <th>Shft Date</th> */}
                                  <th>Shift</th>
                                  <th>Shift Date</th>
                                  <th>Shift Time</th>
                                  <th>Hours</th>
                                  {/* <th>End Date</th> */}
                                  <th>Price/Hours</th>
                                  <th>Holiday Charges</th>
                                  <th>Total</th>
                                  <th>Action</th>
                                </tr>
                              </thead>

                              {isLoading && (
                                <tbody>
                                  <tr>
                                    <td colSpan={6} className="text-center">
                                      Loading...
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                              {!isLoading && (
                                <tbody>
                                  {totalAmount?.shifts?.length !== 0 ? (
                                    totalAmount?.shifts?.map((res, i, id) => {
                                      return (
                                        <tr key={i}>
                                          <td>{i + 1}</td>
                                          <td>
                                            {moment
                                              .utc(res?.jobStartDate)
                                              .format("yyyy-MM-DD") +
                                              // .format("h:mm, A")
                                              " -- " +
                                              moment
                                                .utc(res?.jobEndDate)
                                                .format("yyyy-MM-DD")}
                                          </td>
                                          <td>
                                            {(moment
                                              .utc(res?.startTime)
                                              .format("h:mm A") ===
                                            "Invalid date"
                                              ? "00"
                                              : moment
                                                  .utc(res?.startTime)
                                                  .format("h:mm A")) +
                                              " -- " +
                                              (moment
                                                .utc(res?.endTime)
                                                .format("h:mm A") ===
                                              "Invalid date"
                                                ? "00"
                                                : moment
                                                    .utc(res?.endTime)
                                                    .format("h:mm A"))}
                                          </td>
                                          <td>{res?.totalShiftHours}</td>
                                          <td>${res?.customerRate}</td>
                                          <td>{res?.holidayCharges + "x"}</td>
                                          <td>
                                            $
                                            {res?.shiftChargesForCustomer?.toFixed(
                                              2
                                            )}
                                          </td>
                                          {jobType === "3" && (
                                            <>
                                              <td
                                                style={{ cursor: "pointer" }}
                                                // data-bs-toggle="modal"
                                                // data-bs-target={
                                                //   "#exampleModal" + i
                                                // }
                                                onClick={() =>
                                                  handleClickOpen(res, i)
                                                }
                                                // onClick={() => handleOpen(res)}
                                                // onClick={() => handleOpen(res, i)}
                                              >
                                                Edit
                                              </td>
                                            </>
                                          )}
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan={5} className="text-center">
                                        No Data Found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                        <Dialog
                          open={openModal}
                          onClose={handleClose}
                          aria-labelledby={`alert-dialog-title`}
                          aria-describedby={`alert-dialog-description`}
                        >
                          <DialogTitle id={`alert-dialog-title`}>
                            Edit Shift
                          </DialogTitle>
                          <DialogContent>
                            <DialogContentText id={`alert-dialog-description`}>
                              <div className="row">
                                <div className="col-6">
                                  <label>Start time</label>

                                  <div className="form-group time">
                                    <input
                                      type="time"
                                      name="startTime"
                                      value={changeStartTime}
                                      className="form-control startTime"
                                      aria-describedby=""
                                      id="startTime"
                                      onChange={(e) =>
                                        setChangeStartTime(e.target.value)
                                      }
                                    />
                                    <span>
                                      <i className="fas fa-clock"></i>
                                    </span>
                                  </div>
                                </div>

                                <div className="col-6">
                                  <label>End time</label>

                                  <div className="form-group time">
                                    <input
                                      type="time"
                                      name="endTime"
                                      value={changeEndTime}
                                      className="form-control startTime"
                                      aria-describedby=""
                                      id="endTime"
                                      onChange={(e) =>
                                        setChangeEndTime(e.target.value)
                                      }
                                    />
                                    <span>
                                      <i className="fas fa-clock"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                              // onClick={handleClose}
                              onClick={() => handleChangeTime()}
                              autoFocus
                            >
                              Save Changes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </div>
                    )}
                    {showT && (
                      <div className="row justify-content-center">
                        <div className="col-md-12">
                          <div className="table-responsive border-1">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Total Days</th>
                                  <th>Amount</th>
                                  <th>HST %</th>
                                  <th>Total HST</th>
                                  <th>Total Amount</th>
                                  <th>Payable Amount</th>
                                </tr>
                              </thead>
                              {isLoading && (
                                <tbody>
                                  <tr>
                                    <td colSpan={5} className="text-center">
                                      Loading...
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                              {!isLoading && (
                                <tbody>
                                  <tr>
                                    {/* <td>{i + 1}</td> */}
                                    <td>{totalAmount?.totalDays}</td>
                                    <td>
                                      $
                                      {totalAmount?.withoutTotalAmount?.toFixed(
                                        2
                                      )}
                                    </td>
                                    <td>{totalAmount?.hstPercentage}</td>
                                    <td>
                                      ${totalAmount?.totalHst?.toFixed(2)}
                                    </td>
                                    <td>
                                      ${totalAmount?.totalAmount?.toFixed(2)}
                                    </td>
                                    <td>
                                      ${totalAmount?.payableAmount?.toFixed(2)}
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="nextBtn">
                        {/* <Link to="/long_term_Job"> */}
                        {isReview && (
                          <a type="button" onClick={() => validate()}>
                            Submit <span></span>
                          </a>
                        )}
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            Add Balance
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div class="modal-body">
            <div className="cardBox">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <select
                      className="form-control"
                      id="card"
                      onChange={(e) => {
                        selectedJob(e.target.value);
                      }}
                    >
                      <option value="" selected>
                        Select Card
                      </option>
                      {cardList?.map((r, i) => {
                        return (
                          <option value={r?.id}>
                            {r?.brand} - XXXX XXXX XXXX {r?.last4}
                          </option>
                        );
                      })}
                    </select>

                    <span className="attachIcon">
                      <i className="fas fa-caret-down"></i>
                    </span>
                  </div>
                  {cardIdError && (
                    <div className="invalid-feedback">Please select card</div>
                  )}
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      id="amount"
                      placeholder="Amount*"
                      onChange={(e) => {
                        selectedAmount(e.target.value);
                      }}
                    />
                  </div>
                  {amountError && (
                    <div className="invalid-feedback">Please enter amount</div>
                  )}
                  {amountRegexError && (
                    <div className="invalid-feedback">
                      Please enter valid amount
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="pay_submit_box">
            <button
              type="button"
              class="pay_submit_btn"
              onClick={(e) => {
                addBalance();
              }}
            >
              {cardLoading ? "Please Wait..." : "Add Balance"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        show={smShowCard}
        onHide={() => setSmShowCard(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        style={{ height: "500px" }}
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            Add Card
          </h5>
        </Modal.Header>
        <Modal.Body>
          <Payment cardAdded={cardAdded} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddJob;
