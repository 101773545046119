import React, { useState, useEffect } from "react";
import LogoIcon from "../../components/Logo/Logo";
import { Link, useNavigate } from "react-router-dom";
import "./register.scss";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import ApiClient from "../../api/apiClient";
// import DocsImg from '../../assets/img/docs.png';
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "@material-ui/core/Modal";
import {
  API_GetProfile,
  API_UpdateUserProfileAfterSignup,
} from "../../api/Constant";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const string = /^[a-zA-Z]{3,}(?: [a-zA-Z]+){0,2}$/;
const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const numbers = /^[0-9]*$/;

const Registration = () => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  const d = useLocation();
  const navigate = useNavigate();
  const [fullName, setFullName] = useState({ value: "", err: "" });
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [names, setNames] = useState({ value: "", err: "" });
  const [email, setEmail] = useState({ value: "", err: "" });
  const [phoneNumber, setPhoneNumber] = useState({ value: "", err: "" });
  const [dates, setDates] = useState({ value: "", err: "" });
  const [licenseFile, setLicenseFile] = useState({ name: "Choose file" });
  const [licensePreviewUrl, setLicensePreviewUrl] = useState("");
  const [add, setAdd] = useState("Select Identification Type");
  const [profilePhoto, setProfilePhoto] = useState("");
  const [profileData, setProfileData] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [documentFields, setDocumentFields] = useState([
    {
      idType: "",
      idNumber: "",
      fileName: "",
    },
  ]);
  const [identityFile, setIdentityFile] = useState([]);
  const [documentErrors, setDocumentErrors] = useState([{}]);
  let [loading, setLoading] = useState(false);
  let [notification, setNotification] = useState(false);

  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;

  // //console.log('tokes', token, 'userId', userId)
  useEffect(async () => {
    //console.log(d);
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    } else {
      getProfileData();
    }
  }, [userId]);

  const fname = (str) => {
    if (str === "") {
      setNames({ value: str, err: "*Please enter name." });
    } else if (!string.test(str)) {
      setNames({ value: str, err: "*Please enter valid name." });
    } else {
      setNames({ value: str, err: null });
    }
  };

  const _emailvalidate = (mail) => {
    if (mail === "") {
      setEmail({ value: mail, err: "*Please enter email." });
    } else if (!emailRegex.test(mail)) {
      setEmail({ value: mail, err: "*Please enter valid email." });
    } else {
      setEmail({ value: mail, err: null });
    }
  };

  const _mobile = (num) => {
    if (num === "") {
      setPhoneNumber({ value: num, err: "*Please enter home phone number." });
    } else if (!numbers.test(num)) {
      setPhoneNumber({
        value: num,
        err: "*Please enter valid home phone number.",
      });
    } else if (num.length < 10) {
      setPhoneNumber({
        value: num,
        err: "*Please enter valid home phone number.",
      });
    } else {
      setPhoneNumber({ value: num, err: null });
    }
  };

  const _date = (num) => {
    if (num === "") {
      setDates({ value: num, err: "*Please enter DOB." });
    } else {
      setDates({ value: num, err: null });
    }
  };
  // Registration Function
  const validate = async () => {
    //console.log("notification====", notification);
    // let isDocument= false
    var isDocument = await checkDocumentValidationOnSubmit();
    //console.log(isDocument, "isDocument");

    if (email.value === "") {
      setEmail({ value: email.value, err: "*Please enter email." });
    } else {
      // const token = localStorage.getItem("userId");
      // const refToken = JSON.parse(token);
      if (isDocument && !notification) {
        var formData = new FormData();
        formData.append("Identification_Type", add);
        formData.append("profileImage", profilePhoto);
        formData.append("identityDocuments", JSON.stringify(documentFields));
        identityFile.map((item, i) =>
          formData.append(`identityFile${i}`, item)
        );
        // formData.append("isRegister",true)
        setLoading(true);
        const response = await ApiClient.multipart(
          "patch",
          API_UpdateUserProfileAfterSignup + userId,
          formData,
          `Basic ${token}`
        );
        // //console.log(JSON.stringify(response), "response after signup");
        setLoading(false);
        if (response.data.code === 200) {
          // navigate(`/work_location`,{isRegister:true});
          toast.success(response.data.message, "green");
          navigate(`/add_location?isHeadOffice=` + 1);
          setNames({ value: "", err: "" });
          setEmail({ value: "", err: "" });
          setPhoneNumber({ value: "", err: "" });
          setDates({ value: "", err: "" });
        } else {
          //console.log(response.data.message);
          toast.error(response.data.message, "red");
          // return;
        }
      } else {
        //console.log("call else===", notification);
        var formData = new FormData();
        formData.append("isIdentityDocumentsSkip", notification);
        // formData.append("isRegister",true)
        setLoading(true);
        const response = await ApiClient.multipart(
          "patch",
          API_UpdateUserProfileAfterSignup + userId,
          formData,
          `Basic ${token}`
        );
        // //console.log(JSON.stringify(response), "response after signup");
        setLoading(false);
        if (response.data.code === 200) {
          // navigate(`/work_location`,{isRegister:true});
          toast.success(response.data.message, "green");
          navigate(`/add_location?isHeadOffice=` + 1);
          setNames({ value: "", err: "" });
          setEmail({ value: "", err: "" });
          setPhoneNumber({ value: "", err: "" });
          setDates({ value: "", err: "" });
        } else {
          //console.log(response.data.message);
          toast.error(response.data.message, "red");
          // return;
        }
      }
      // navigate(`/add_location?isHeadOffice=` + 1);
    }
  };

  // Show Hide Notification
  const handlePushNotification = (value) => {
    setNotification((notification = !notification));
  };

  // Handle License File Function
  const handleLicenseFile = async (e) => {
    // setLicenseFile(e.target.files[0])
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setLicenseFile(file);
      setLicensePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Handle Add Document Document Function
  const handleAddDocumentFields = async () => {
    if (documentFields.length > 3) {
      toast.warn("Max 4 documents allowed");
      return;
    } else {
      setDocumentFields([
        ...documentFields,
        { idType: "", idNumber: "", fileName: "" },
      ]);
    }
  };
  // Handle Remove Document Document Function
  const removeIdentificationRow = async (index) => {
    const value = [...documentFields];
    if (value.length > 1) {
      //console.log("value====", value);
      value.splice(index, 1);
      setDocumentFields(value);
    }
    const file = [...identityFile];
    if (file.length > 1) {
      //console.log("file====", file);
      file.splice(index, 1);
      setIdentityFile(file);
    }
  };

  // Handle Selected Document FIle
  const handleChange = async (index, e) => {
    //console.log("e", e.target.type, documentFields);
    const value = [...documentFields];
    if (e.target.type === "text" || e.target.type == "select-one") {
      //console.log(value);
      const fitype = value.filter((res) => {
        return res.idType === e.target.value;
      });

      const finum = value.filter((res) => {
        return res.idNumber === e.target.value;
      });
      //console.log(fitype);
      if (fitype.length === 1) {
        toast.warn("Same type of identification proof is already exist");
        return;
      } else {
        if (e.target.name === "idType") {
          value[index]["idType"] = e.target.value;
        }
      }

      if (finum.length === 1) {
        toast.warn("Same document no is already exist");
        return;
      } else {
        if (e.target.name === "idNumber") {
          value[index]["idNumber"] = e.target.value;
        }
      }
    } else {
      // const size = e.target.files[0].size / 1048576;

      let allImages = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/tiff",
        "image/bpg",
        "image/webp",
      ];

      if (allImages.indexOf(e.target.files[0]?.type) === -1) {
        toast.warning("File type is not allowed");
        return;
      }

      // if (size > 2) {
      //   toast.warning("Max 2MB size allowed");
      //   return;
      // }
      const allowedBytes = 2 * 1024 * 1024;
      if (e.target.files[0].size > allowedBytes) {
        alert("Not allowed more than 2 MB");
        return;
      }
      const file = [...identityFile];
      file[index] = e.target.files[0];

      const fiimg = value.filter((res) => {
        return res.fileName === e.target.files[0].name;
      });
      if (fiimg.length === 1) {
        toast.warn("Same image is already exist");
        return;
      } else {
        value[index]["fileName"] = e.target.files[0].name;
        setIdentityFile(file);
      }
      // value[index][e.target.name] = e.target.files[0].name;
    }
    // //console.log("value", value);
    setDocumentFields(value);
    checkDocumentValidationOnSubmit();
  };
  // Document Validation Function
  const checkDocumentValidationOnSubmit = async () => {
    var valid = true;
    const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
    let errorMsg = documentFields.map((data, key) => {
      let error = {};
      // //console.log('check value=====', key)
      if (data.idNumber === "") {
        error.errorIdNumber = "Identification number is required";
        valid = false;
      } else if (alphanumericRegex.test(data.idNumber) === false) {
        error.errorIdNumber = "Please enter valid identification number";
        valid = false;
      } else {
        error.errorIdNumber = "";
      }
      if (data.idType === "") {
        error.errorIdType = "Identification type is required";
        valid = false;
      } else {
        error.errorIdType = "";
      }
      if (data.fileName === "") {
        error.errorFileName = "Identification file is required";
        valid = false;
      } else {
        error.errorFileName = "";
      }
      return error;
    });
    // //console.log('errorMsg===', errorMsg, valid)
    setDocumentErrors(errorMsg);
    return valid;
  };
  // Get Profile Data
  const getProfileData = async () => {
    // const response = await getGuardProfile({ id: id });
    const response = await ApiClient.get(
      API_GetProfile + userId,
      `Basic ${token}`
    );
    if (response.data.code === 200) {
      //console.log("data=====", response.data.data);
      if (response.data && response.data.data) {
        setEmail({ value: response.data.data.email, err: "" });
        setPhoneNumber({ value: response.data.data.mobileNumber, err: "" });
        setProfileData(response.data.data);
        setFirstName(
          response.data.data.firstName === undefined
            ? ""
            : response.data.data.firstName
        );
        setMiddleName(
          response.data.data.middleName === undefined
            ? ""
            : response.data.data.middleName
        );
        setLastName(
          response.data.data.lastName === undefined
            ? ""
            : response.data.data.lastName
        );
        setFullName({
          value:
            response.data.data.firstName +
              " " +
              response.data.data.middleName ===
            undefined
              ? ""
              : response.data.data.middleName +
                  " " +
                  response.data.data.lastName ===
                undefined
              ? ""
              : response.data.data.lastName,
          err: "",
        });
        // console.log(
        //   response.data.data.firstName + " " + response.data.data.middleName ===
        //     undefined
        //     ? ""
        //     : response.data.data.middleName +
        //         " " +
        //         response.data.data.lastName ===
        //       undefined
        //     ? ""
        //     : response.data.data.lastName
        // );
      }
    }
  };
  // Handle Selected Profile Photo
  const handleProfilePhoto = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const size = e.target.files[0].size / 1048576;

    let allImages = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/tiff",
      "image/bpg",
      "image/webp",
    ];

    if (allImages.indexOf(file?.type) === -1) {
      toast.warning("File type is not allowed");
      return;
    }

    if (size > 2) {
      toast.warning("Max 2MB size allowed");
      return;
    }

    reader.onloadend = () => {
      setProfilePhoto(file);
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Wel_Header />
      <section className="registrationPage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9">
              <div className="regForm">
                <form>
                  <div className="regBox">
                    <div className="row">
                      <div className="col-12">
                        <h4>Registration</h4>
                        <div className="editDetail">
                          <a
                            onClick={(e) => {
                              navigate("/");
                            }}
                            type="button"
                          >
                            <i class="fa-solid fa-arrow-left"></i>
                            <span>Back To Home</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="avatar">
                    <div className="row align-items-center">
                      {/* <div className="col-12">
                        <div className="profileImg">
                          <span>
                          <input
                            type="file" id="uploadPhoto"
                            className="form-control profile-select d-none"
                            htmlFor="exampleInputtext"
                            aria-describedby="textHelp"
                            name="profilePhoto"
                            onChange={(e) => handleProfilePhoto(e)}
                            accept="image/*"
                          />
                            <i className="fas fa-user-circle user_icon"></i>
                          </span>
                          <div className="icon">
                            <div className="camIcon">
                              <a href="#">
                                <i className="fas fa-pen text-white"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <input
                          type="file"
                          id="uploadPhoto"
                          className="form-control profile-select d-none"
                          htmlFor="exampleInputtext"
                          aria-describedby="textHelp"
                          name="profilePhoto"
                          onChange={(e) => handleProfilePhoto(e)}
                          accept="image/*"
                        />

                        <label htmlFor="uploadPhoto" className="profileImg">
                          {imagePreviewUrl ? (
                            <img src={imagePreviewUrl} />
                          ) : (
                            <span>
                              <i className="fas fa-user-circle user_icon"></i>
                            </span>
                          )}
                          <div className="icon">
                            <div className="camIcon">
                              <label htmlFor="uploadPhoto">
                                <i className="fas fa-pen text-white"></i>
                              </label>
                            </div>
                          </div>
                        </label>
                      </div>
                      <div className="col-12">
                        <div className="row align-items-center">
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputtext"
                                aria-describedby="textHelp"
                                value={
                                  firstName + " " + middleName + " " + lastName
                                }
                                placeholder="First Name"
                                disabled
                              />
                              <span>
                                <i className="fas fa-user"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={names.err} />
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                id="exampleInputtext"
                                aria-describedby="textHelp"
                                value={email.value}
                                placeholder="email"
                                disabled
                                // onChange={(e) => {
                                //   // setEmail(e.target.value);
                                //   _emailvalidate(e.target.value);
                                // }}
                              />
                              <span>
                                <i className="fas fa-envelope"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={email.err} />
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputtext"
                                aria-describedby="textHelp"
                                value={phoneNumber.value}
                                placeholder="Mobile No"
                                disabled
                                // onChange={(e) => {
                                //   // setPhoneNumber(e.target.value);
                                //   _mobile(e.target.value);
                                // }}
                              />
                              <span>
                                <i className="fas fa-phone-alt"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={phoneNumber.err} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {d?.state?.d === "Small Business" ? (
                    <div className="identityBox">
                      <div className="row justify-content-center">
                        {/* <div className="col-12">
                          <div className="identityTitle">
                            <h5>Business Title</h5>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  ) : null}

                  <div className="identityBox">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="notiFi">
                          <h6>Upload ID</h6>
                          <div className="button r" id="button-1">
                            <input
                              type="checkbox"
                              checked={notification === true}
                              onChange={(e) =>
                                handlePushNotification(e.target.value)
                              }
                              value="notification"
                              className="checkbox"
                            />
                            <div className="knobs"></div>
                            <div className="layer"></div>
                          </div>
                        </div>
                        {!notification && (
                          <div className="identityTitle">
                            {/* <h5>Upload Identity Proof</h5> */}
                            <div className="editDetail add-btn-box">
                              <a
                                className="btn addBtn"
                                type="button"
                                onClick={handleAddDocumentFields}
                              >
                                <span style={{ marginRight: "5px" }}>
                                  <i className="fas fa-plus-circle"></i>
                                </span>
                                Add
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {!notification && (
                    <div>
                      {documentFields.map((identificationField, i) => {
                        //console.log(identificationField);
                        return (
                          <>
                            <div className="row">
                              <div className="col-12">
                                <div className="identiBox" key={i}>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <select
                                          className="form-control"
                                          aria-label="Default select example"
                                          name="idType"
                                          value={identificationField.idType}
                                          defaultValue="Select Identification Type"
                                          onChange={(e) => handleChange(i, e)}
                                        >
                                          <option selected value="">
                                            Select Identification Type
                                          </option>
                                          <option value="Drivers license">
                                            Drivers license
                                          </option>
                                          {/* <option value="Social insurance number">
                                            Social insurance number
                                          </option> */}
                                          <option value="Permanent resident card">
                                            Permanent resident card
                                          </option>
                                          <option value="Other">Other</option>
                                          <option value="Canadian passport">
                                            Canadian passport
                                          </option>
                                        </select>
                                        <span>
                                          <i className="fas fa-caret-down"></i>
                                        </span>
                                      </div>
                                      {/* <div className="invalid-feedback mt-1">
                                        {((documentErrors.length - 1) >= i) && documentErrors[i].errorIdType}
                                      </div> */}
                                      <ErrorMessage
                                        messerror={
                                          documentErrors.length - 1 >= i &&
                                          documentErrors[i].errorIdType
                                        }
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleInputtext"
                                          aria-describedby="textHelp"
                                          onChange={(e) => handleChange(i, e)}
                                          name="idNumber"
                                          value={identificationField.idNumber}
                                          maxLength={15}
                                          placeholder="Identification No."
                                        />
                                      </div>
                                      {/* <div className="invalid-feedback mt-1">
                                        {((documentErrors.length - 1) >= i) && documentErrors[i].errorIdNumber}
                                      </div> */}
                                      <ErrorMessage
                                        messerror={
                                          documentErrors.length - 1 >= i &&
                                          documentErrors[i].errorIdNumber
                                        }
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label
                                          className="update_files"
                                          htmlFor={`file` + i}
                                        >
                                          <input
                                            type="file"
                                            className="form-control d-none"
                                            aria-describedby="textHelp"
                                            name="fileName"
                                            id={`file` + i}
                                            // value={identificationField.fileName}
                                            placeholder="Upload Identification"
                                            onChange={(e) => handleChange(i, e)}
                                            accept="image/*"
                                            // style={{"visibility":"hidden"}}
                                          />
                                          {identificationField?.fileName
                                            ? identificationField?.fileName
                                            : "No file chosen"}
                                          <span>
                                            <i className="fas fa-paperclip"></i>
                                          </span>
                                        </label>
                                      </div>
                                      {/* <div className="invalid-feedback mt-1">
                                        {((documentErrors.length - 1) >= i) && documentErrors[i].errorFileName}
                                      </div> */}
                                      <ErrorMessage
                                        messerror={
                                          documentErrors.length - 1 >= i &&
                                          documentErrors[i].errorFileName
                                        }
                                      />
                                    </div>
                                  </div>
                                  {documentFields &&
                                  documentFields.length > 1 ? (
                                    <span
                                      className="closeBtn"
                                      onClick={(e) =>
                                        removeIdentificationRow(i)
                                      }
                                    >
                                      <i
                                        className="fas fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* <div className="row" key={i}>
                            <div className="col-4">
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  value={identificationField.idType}
                                  onChange={(e) => handleChange(i, e)}
                                >
                                  <option selected>Select Identification Type</option>
                                  <option value="Drivers license">
                                    Drivers license
                                  </option>
                                  <option value="Permanent resident card">
                                    Permanent resident card
                                  </option>
                                  <option value="Other">
                                    Other
                                  </option>
                                  <option value="Canadian passport">
                                    Canadian passport
                                  </option>
                                </select>
                                <span>
                                  <i className="fas fa-caret-down"></i>
                                </span>
                              </div>
                              <div className="invalid-feedback mt-1">
                                {((documentErrors.length - 1) >= i) && documentErrors[i].errorIdType}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleInputtext"
                                  aria-describedby="textHelp"
                                  onChange={(e) => handleChange(i, e)}
                                  name="idNumber"
                                  value={identificationField.idNumber}
                                  maxLength={20}
                                  placeholder="Identification No."
                                />
                              </div>
                              <div className="invalid-feedback mt-1">
                                {((documentErrors.length - 1) >= i) && documentErrors[i].errorIdNumber}
                              </div>
                            </div>
                            <div className="col-4">
                              <div className="form-group">
                                <div className="inputFile form-control">
                                  <label className="fileName">
                                    {licenseFile.name}
                                  </label>
                                  <label className="selectFile" htmlFor="uploadFfile">
                                    <input
                                      type="file"
                                      id="uploadFfile"
                                      className="d-none"
                                      htmlFor="exampleInputtext"
                                      aria-describedby="textHelp"
                                      name="Upload File"
                                      onChange={(e) => handleLicenseFile(e)}
                                      accept="image/*"
                                    ></input>
                                    <i className="fas fa-paperclip"></i>
                                  </label>
                                </div>
                              </div>
                            </div>
                            </div>

                            {documentFields && documentFields.length > 1 ? (
                              <span
                                className="closeBtn"
                                onClick={(e) => removeIdentificationRow(i)}
                              >
                                <i className="fas fa-times" aria-hidden="true"></i>
                              </span>
                            ) : (
                              ""
                            )} */}
                          </>
                        );
                      })}
                    </div>
                  )}
                  <div className="nextBtn">
                    {!notification ? (
                      <a type="button" onClick={() => validate()}>
                        Save & Proceed
                      </a>
                    ) : (
                      <a
                        type="button"
                        onClick={() =>
                          // navigate(`/add_location?isHeadOffice=` + 1)
                          validate()
                        }
                      >
                        Save & Proceed
                      </a>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Registration;
