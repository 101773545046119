import React from 'react';
import {Link} from 'react-router-dom';
import './add_docs.scss';

const AddDocs = () => {
  return (
    <>
      <section className="add_document">
         <div className="container">
             <div className="row justify-content-center">
                 <div className="col-md-8 col-lg-8">
                     <div className="content">
                        <form>
                            <div className="title">
                                <div className="backArrow"><Link to="/my_document"><span className='left'><i className="fas fa-arrow-left"></i></span></Link></div>
                                <div className="heading"><h1>Add Documents</h1></div>
                            </div>
                            <div className="form-group">
                                <select  className="form-control" >
                                    <option selected>Identification Proof Type</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <span className='caret_icon'><i className="fas fa-caret-down"></i></span>
                            </div>
                            <div className="form-group">
                                <input className="form-control" type="text" placeholder="Document No." aria-label="default input example" />
                            </div>  
                            <div className="form-group">
                                <div className="form-control" ><span>Attach File</span></div>
                                <label for="uploadAttach" className="attachIcon"><span><i className="fas fa-paperclip"></i></span></label>
                                <input type="file" id="uploadAttach" className="d-none" accept="image/*" />
                            </div>
                            <div className="addMore">
                                <button type="submit" className="submitBtn">Submit</button>
                            </div>
                        </form>
                     </div>
                 </div>
             </div>
         </div>
      </section>
    </>
  )
}

export default AddDocs;
