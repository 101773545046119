import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import "./wallet_balance.scss";
import {
  API_GetUserWallet,
  API_AddCard,
  API_AddAmount,
} from "../../api/Constant";
import ApiClient from "../../api/apiClient";
import { toast } from "react-toastify";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import moment from "moment";

const WalletBalance = () => {
  const navigate = useNavigate();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;
  const clientSectet = tdata?.user?.stripe_customer_id;
  const [cardList, setCardList] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [transectionHis, setTransectionHis] = useState([]);
  const [cardId, setCardId] = useState("");
  const [cardIdError, setCardIdError] = useState(false);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [totalItemsCount, settotalItemsCount] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const numbers = /^[1-9]\d{0,9}(?:\.\d{1,2})?$/;
  // var total = "";

  useEffect(() => {
    if (token) {
      getUserWalletData();
      getAllCards();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
    // setToken(sessionStorage.getItem("token"))
  }, []);

  // Page Change Function
  const handlePageChange = (pageNumber) => {
    // //console.log(`active page is ${pageNumber}`);
    setPageNo(pageNumber);
    // pageNo = pageNumber;
    getUserWalletData();
    // this.setState({activePage: pageNumber});
  };

  // Get All Card List
  const getAllCards = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_AddCard + `?custId=${clientSectet}`,
      data,
      `Bearer ${token}`
    );
    // console.log("card list", res);
    if (res.data.code === 200) {
      setCardList(res?.data?.data);
      const lengthCard = [];
      lengthCard.push(res?.data?.data);
      if (lengthCard.length < 1) {
        toast.warning("No Card Found please add card first", "yellow");
      }
      // settotalItemsCount(res?.data?.data?.length);
      // toast.success(res?.data?.message);
    } else {
      toast.error(res.message);
    }
  };

  // Get User Wallet Data Function
  const getUserWalletData = async () => {
    setIsloading(true);
    const data = "";
    let response = await ApiClient.get(
      API_GetUserWallet + `${userId}?page=${pageNo}&limit=${10}`,
      data,
      `Bearer ${token}`
    );
    // console.log(response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/login`);
    }
    if (response?.data?.code === 200) {
      setIsloading(false);
      setWallet(response?.data?.data?.wallet);
      setTransectionHis(response?.data?.data?.transactionHistory?.docs);
      settotalItemsCount(response?.data?.data?.transactionHistory?.total);
      //console.log(totalItemsCount);
      // total = response?.data?.data?.transactionHistory?.total;
    } else {
      setIsloading(false);
    }
  };

  // Validation For Add Amount
  const selectedJob = (val) => {
    //console.log(val);
    if (val === "") {
      setCardIdError(true);
      setCardId("");
      return;
    } else {
      setCardIdError(false);
      setCardId(val);
    }
  };

  const selectedAmount = (a) => {
    //console.log(a);
    if (a === "") {
      setAmountError(true);
      setAmount("");
      return;
    } else {
      setAmountError(false);
      setAmount(a);
    }
  };

  // Function For Handle Add Balance In Wallet
  const addBalance = async () => {
    setCardIdError(true);
    setAmountError(true);

    if (cardId === "") {
      setCardIdError(true);

      return;
    } else {
      setCardIdError(false);
    }

    if (amount === "") {
      setAmountError(true);
      return;
    } else {
      setAmountError(false);
    }

    if (!numbers.test(amount)) {
      toast.warn("Please enter valid price");
      return;
    }

    const data = {
      amount: amount,
      cardId: cardId,
      custId: clientSectet,
      userId: userId,
    };
    setLoading(true);
    let response = await ApiClient.post(API_AddAmount, data, `Bearer ${token}`);

    // //console.log(response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/login`);
    }
    if (response?.data?.code === 200) {
      setLoading(false);
      getUserWalletData();
      document.getElementById("card").value = "";
      setCardId("");
      document.getElementById("amount").value = "";
      setAmount("");
      toast.success(response?.data?.message);
    } else {
      setLoading(false);
      toast.error(response?.data?.message);
    }
  };

  return (
    <>
      <Header />
      <section className="wallet_balance">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="content">
                <div className="withdrawInner">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="title">
                        <h5>
                          Wallet Amount :{" "}
                          <span>
                            ${wallet?.totalAmount?.toFixed(2)}
                            {/* <sub>$</sub> 400{" "} */}
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <select
                          className="form-control"
                          id="card"
                          onClick={(e) => {
                            selectedJob(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            Select Card
                          </option>
                          {cardList?.map((r, i) => {
                            return (
                              <option value={r?.id}>
                                {r?.brand} - XXXX XXXX XXXX {r?.last4}
                              </option>
                            );
                          })}
                        </select>

                        <span className="attachIcon">
                          <i className="fas fa-caret-down"></i>
                        </span>
                        {cardIdError ? (
                          <div className="invalid-feedback">
                            Please select card
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          className="form-control amount_input"
                          type="number"
                          id="amount"
                          min={1}
                          placeholder="Amount*"
                          aria-label="default input example"
                          onChange={(e) => {
                            selectedAmount(e.target.value);
                          }}
                        />
                        {amountError ? (
                          <div className="invalid-feedback">
                            Please enter amount
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {cardList.length !== 0 ? (
                        <div className="submit_btn">
                          <a
                            type="button"
                            onClick={(e) => (!loading ? addBalance() : "")}
                          >
                            {loading ? "Please wait..." : " Add Amount"}
                          </a>
                        </div>
                      ) : (
                        <div className="submit_btn">
                          <a
                            type="button"
                            onClick={(e) => {
                              navigate("/payment");
                            }}
                          >
                            Add Card
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="transaction_box">
                  <div className="row justify-content-center">
                    <div className="col-md-11 col-lg-10">
                      <div className="table-responsive border-1">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Job Title / Title</th>
                              <th>Time / Date</th>
                              <th>Status</th>
                              <th>Transaction</th>
                            </tr>
                          </thead>

                          {isLoading && (
                            <tbody>
                              <tr>
                                <td className="text-justify" colSpan={5}>
                                  Loading...
                                </td>
                              </tr>
                            </tbody>
                          )}
                          {!isLoading && (
                            <tbody>
                              {transectionHis.length !== 0 ? (
                                transectionHis?.map((res, i) => {
                                  return (
                                    <tr>
                                      <td>{res?.title}</td>
                                      <td>
                                        {moment
                                          .utc(res?.createdAt)
                                          .format("DD-MM-YYYY")}
                                      </td>
                                      <td>{res?.debit ? "Debit" : "Credit"}</td>
                                      <td
                                        className={
                                          res?.debit
                                            ? "instant_text"
                                            : "recive_text"
                                        }
                                      >
                                        ${res?.amount?.toFixed(2)}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td className="text-justify" colSpan={5}>
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                    {wallet?.transactionHistory?.length !== 0 && (
                      <div className="mt-2">
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WalletBalance;
