import React, { useEffect, useState } from "react";
import Docs from "../../assets/img/docs.png";
import Header from "../../components/Header/Header";
import "./report_issue.scss";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import Pics3 from "../../assets/img/imge placeholder.png";
import ApiClient from "../../api/apiClient";
import ImageUploading from "react-images-uploading";

import {
  API_AddTicket,
  API_AllJobsDropDown,
  API_GetTicket,
  url,
} from "../../api/Constant";

const ReportIssue = () => {
  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  const navigate = useNavigate();
  const [jobList, setJobList] = useState([]);
  const [ticketList, setTicketList] = useState([]);
  const [jobId, setJobId] = useState("");
  const [jobIdError, setJobIdError] = useState(false);
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const [tickettype, setTicketType] = useState("");
  const [tickettypeError, setTicketTypeError] = useState(false);
  const [desc, setDesc] = useState("");
  const [descError, setDescError] = useState(false);
  const [img, setImg] = useState([]);
  const [imgError, setImgError] = useState(false);
  let [imagePreviewUrl, setImagePreviewUrl] = useState(Pics3);
  const [isLoading, setIsLoading] = useState(false);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [images, setImages] = useState([]);

  var pageNo = 1;
  var jtype = 0;

  useEffect(async () => {
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    } else {
      getJobLists();
      getAllTickets();
    }
  }, []);

  // Create Ticket Function
  const createTicket = async () => {
    setJobIdError(true);
    setTitleError(true);
    setDescError(true);
    setImgError(true);
    if (ticketType === "") {
      setTicketTypeError(true);
      return;
    } else {
      setTicketTypeError(false);
    }
    //console.log(tickettype);

    if (tickettype === "3" || tickettype === "4" || tickettype === "5") {
      setJobIdError(false);
    } else {
      if (jobId === "") {
        setJobIdError(true);
        return;
      } else {
        setJobIdError(false);
      }
    }

    if (title === "") {
      setTitleError(true);
      return;
    } else {
      setTitleError(false);
    }
    if (desc === "") {
      setDescError(true);
      return;
    } else {
      setDescError(false);
    }
    if (tickettype === "3" || tickettype === "4") {
      setImgError(false);
    } else {
      if (img.length < 1) {
        setImgError(true);
        return;
      } else {
        setImgError(false);
      }
    }

    var bodyFormData = new FormData();
    bodyFormData.append("ticketTitle", title);
    bodyFormData.append("ticketType", tickettype);
    bodyFormData.append("description", desc);
    for (let i = 0; i < img.length; i++) {
      bodyFormData.append("images", img[i]);
    }

    var bodyFormDatanew = new FormData();
    bodyFormDatanew.append("jobId", jobId);
    bodyFormDatanew.append("ticketTitle", title);
    bodyFormDatanew.append("ticketType", tickettype);
    bodyFormDatanew.append("description", desc);
    for (let i = 0; i < img.length; i++) {
      bodyFormDatanew.append("images", img[i]);
    }
    //console.log(tickettype);
    setIsLoading(true);
    let res = await ApiClient.post(
      API_AddTicket,
      tickettype === "3" || tickettype === "4" || tickettype === "5"
        ? bodyFormData
        : bodyFormDatanew,
      `Bearer ${token}`
    );
    setIsLoading(false);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res.data.code == 200) {
      setJobId("");
      // if (tickettype !== "3" || tickettype !== "4" || tickettype !== "5") {
      //   document.getElementById("tid").value = "";
      // }
      setTicketType("");
      document.getElementById("ttype").value = "0";
      setDescError("");
      document.getElementById("tdesc").value = "";
      setTitle("");
      document.getElementById("ttitle").value = "";
      images.splice(0, images.length);
      setImg([]);
      // //console.log("img", img);
      // document.getElementById("uploadAttach").value = null;
      setImagePreviewUrl(Pics3);
      getAllTickets();
      toast.success(res?.data?.message);
      document.getElementById("tid").value = "";
    }
  };

  // Get All Ticket List
  const getAllTickets = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_GetTicket + `?page=${pageNo}&sortBy=asc&limit=${10}&searchBy=`,
      data,
      `Bearer ${token}`
    );
    // console.log("report list", res);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res?.data.code == 200) {
      setTicketList(res?.data?.data?.docs);
      settotalItemsCount(res?.data?.data?.total);
    }
  };

  // Handle Page Change Function
  const handlePageChange = (pageNumber) => {
    pageNo = pageNumber;
    getAllTickets();
  };

  // Get Job LIst
  const getJobLists = async () => {
    const data = "";
    let response = await ApiClient.get(
      API_AllJobsDropDown +
        `?page=1&sortBy=asc&limit=${20}&isComplete=${jtype}`,
      data,
      `Bearer ${token}`
    );
    //console.log(response);
    if (response.data.code === 200) {
      setJobList(response?.data?.data?.docs);
    }
  };

  // Handle TIcket Type
  const ticketType = (val) => {
    if (val === "") {
      setTicketTypeError(true);
      return;
    } else {
      setTicketTypeError(false);
      setTicketType(val);
    }
  };

  const selectedJob = (val) => {
    if (val === "") {
      setJobIdError(true);
      return;
    } else {
      setJobIdError(false);
      setJobId(val);
    }
  };

  const selectedTitle = (val) => {
    if (val === "") {
      setTitleError(true);
      return;
    } else {
      setTitleError(false);
      setTitle(val);
    }
  };

  const selectedDesc = (val) => {
    if (val === "") {
      setDescError(true);
      return;
    } else {
      setDescError(false);
      setDesc(val);
    }
  };

  // Filter Ticket LIst Function
  const selectedTicketType = (val) => {
    //console.log(val);
    setTicketType(val);
    // jtype = val;
    // getJobLists();
    if (val === "0") {
      //console.log("val = 0");
      jtype = 0;
      getJobLists();
    }
    if (val === "1") {
      //console.log("val = 1");
      jtype = 2;
      getJobLists();
    }
    if (val === "2") {
      //console.log("val = 2");
      jtype = 1;
      getJobLists();
    }
  };

  const maxNumber = 4;

  // Handle Image Change
  const onChangeimg = (imageList, addUpdateIndex) => {
    let imageCollection = [];
    // data for submit
    //console.log(imageList, addUpdateIndex);
    while (img.length > 0) {
      img.pop();
    }
    for (let i = 0; i < imageList.length; i++) {
      img?.push(imageList[i].file);
    }
    //console.log("number of images 233: ", img);
    setImages(imageList);

    if (img.length < 1) {
      setImgError(true);
      return;
    } else {
      setImgError(false);
    }

    // setImg(imageCollection);
  };

  return (
    <>
      <Header />
      <section className="report_issue">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="content">
                <div className="heading">
                  <h1>Report Issue</h1>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <select
                        className="form-control"
                        id="ttype"
                        onChange={(e) => {
                          selectedTicketType(e.target.value);
                        }}
                      >
                        <option value="0" selected>
                          Current Job
                        </option>
                        <option value="1">Previous Job</option>
                        <option value="2">Upcoming Job</option>
                        <option value="3">Profile Update</option>
                        <option value="4">Payout</option>
                        <option value="5">Others</option>
                      </select>

                      <span className="attachIcon">
                        <i className="fas fa-caret-down"></i>
                      </span>
                      {tickettypeError ? (
                        <div className="invalid-feedback">
                          Please select ticket type
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {tickettype !== "3" &&
                  tickettype !== "4" &&
                  tickettype !== "5" ? (
                    <div className="col-md-4">
                      <div className="form-group">
                        <select
                          className="form-control"
                          id="tid"
                          onClick={(e) => {
                            selectedJob(e.target.value);
                          }}
                        >
                          <option value="" selected>
                            Select Job
                          </option>
                          {jobList.length !== 0 ? (
                            jobList?.map((r, i) => {
                              return (
                                <option key={i} value={r?.jobId?.id}>
                                  {r?.jobId?.jobTitle}
                                </option>
                              );
                            })
                          ) : (
                            <option value="" disabled>
                              No Data Found
                            </option>
                          )}
                        </select>

                        <span className="attachIcon">
                          <i className="fas fa-caret-down"></i>
                        </span>
                        {jobIdError ? (
                          <div className="invalid-feedback">
                            Please select job
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : null}

                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        id="ttitle"
                        name="tickettitle"
                        placeholder="Ticket Title"
                        onKeyUp={(e) => {
                          selectedTitle(e.target.value);
                        }}
                      />
                      {titleError ? (
                        <div className="invalid-feedback">
                          Title is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="form-group">
                      <textarea
                        className="form-control textArea"
                        rows="3"
                        placeholder="Description"
                        id="tdesc"
                        onKeyUp={(e) => {
                          selectedDesc(e.target.value);
                        }}
                      ></textarea>
                      {descError ? (
                        <div className="invalid-feedback">
                          Description is required
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {tickettype !== "3" && tickettype !== "4" ? (
                    <div className="col-md-4">
                      <div className="form-group attachInput">
                        {/* <div className="form-control">
                        <span>Attach Photo</span>
                      </div> */}
                        <label
                          htmlFor="uploadAttach"
                          className="attach_input_icon mb-2"
                        >
                          <span>Attach Photo</span>
                          {/* <FaPaperclip /> */}
                        </label>
                        {/* <input
                        type="file"
                        id="uploadAttach"
                        className="d-none"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e)}
                      /> */}

                        <div>
                          <ImageUploading
                            multiple
                            value={images}
                            onChange={onChangeimg}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                            acceptType={["jpg", "png", "webp", "gif", "jpeg"]}
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                            }) => (
                              // write your building UI
                              <div className="upload__image-wrapper">
                                <button
                                  style={isDragging ? { color: "red" } : null}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                  className="Drop_images"
                                >
                                  Click or Drop here
                                </button>
                                &nbsp;
                                <button
                                  onClick={onImageRemoveAll}
                                  className="remove_images"
                                >
                                  Remove all images
                                </button>
                                <div className="upload_img_box">
                                  {imageList.map((image, index) => (
                                    <div key={index} className="image-item">
                                      <img
                                        src={image.data_url}
                                        alt=""
                                        width="100"
                                      />
                                      <div className="image-item__btn-wrapper">
                                        {/* <button className="edit_btn" onClick={() => onImageUpdate(index)}><i className="fas fa-pen"></i></button> */}
                                        <button
                                          className="trash_btn"
                                          onClick={() => onImageRemove(index)}
                                        >
                                          <i class="fa-solid fa-circle-xmark"></i>
                                        </button>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </ImageUploading>
                        </div>

                        <ul>
                          <li>
                            {/* <img src={imagePreviewUrl} className="img-fluid" />{" "}
                          <a href="">x</a> */}
                          </li>
                        </ul>
                        {imgError ? (
                          <div className="invalid-feedback">
                            Please select image
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="addMore">
                  <button
                    type="button"
                    disabled={isLoading}
                    className="submitBtn"
                    onClick={() => createTicket()}
                  >
                    {isLoading ? "Please wait..." : "Submit Details"}
                  </button>
                </div>
              </div>
              <div className="ticket_box">
                <h6>Previous Tickets</h6>
                <div className="table-responsive border-1">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Ticket Title</th>
                        <th>Job Title</th>
                        <th>Date</th>
                        <th>Attachment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ticketList.length !== 0 ? (
                        ticketList.map((res, i) => {
                          return (
                            <tr>
                              <td>{res?.ticketTitle}</td>
                              <td>
                                {res?.jobId?.jobTitle
                                  ? res?.jobId?.jobTitle
                                  : "--"}
                              </td>
                              <td>
                                {moment
                                  .utc(res?.ticketDate)
                                  .format("DD-MM-YYYY")}
                              </td>
                              <td>
                                <div className="attach_docs">
                                  {res?.images?.length !== 0
                                    ? res?.images?.map((im, id) => {
                                        return (
                                          <img
                                            src={
                                              im == ""
                                                ? Pics3
                                                : url +
                                                  "/media/preview?filename=" +
                                                  im
                                            }
                                            className="img-fluid"
                                          />
                                        );
                                      })
                                    : "No Image"}

                                  {/* <span>Docs_file.doc</span> */}
                                </div>
                              </td>
                              <td>
                                <a
                                  type="button"
                                  onClick={(e) => {
                                    navigate(`/ticket_detail/${res?.id}`);
                                  }}
                                >
                                  View More
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td style={{ textAlign: "center" }} colSpan={6}>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {ticketList.length !== 0 && (
                  <div className="mt-2">
                    <Pagination
                      color="primary"
                      activePage={pageNo}
                      itemsCountPerPage={10}
                      totalItemsCount={totalItemsCount}
                      pageRangeDisplayed={5}
                      onChange={(e) => handlePageChange(e)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ReportIssue;
