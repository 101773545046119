import React, { useEffect, useState, useHistory } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./add_notification.scss";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { API_AddNotificationToJob } from "../../api/Constant";
import ApiClient from "../../api/apiClient";
const cookie = new Cookies();
const tdata = JSON.parse(localStorage.getItem("userInfo"));
const token = tdata?.tokens?.refresh?.token;

const AddNotification = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  let [title, setTitle] = useState("");
  let [desc, setDesc] = useState("");

  // Function to submit notification data
  const submit = async () => {
    // Check if the title field is empty
    if (title == "") {
      toast.warning("Title is required");
      return;
    }

    // Check if the description field is empty
    if (desc == "") {
      toast.warning("Description is required");
      return;
    }

    // Create a data object with the title, description, and job ID
    const data = {
      title: title,
      description: desc,
      jobId: id,
    };

    // Send a POST request to add a notification to the job
    const res = await ApiClient.post(
      API_AddNotificationToJob,
      data,
      `Bearer ${token}`
    );

    // Check if the response code is 200 (success)
    if (res.data.code === 200) {
      //console.log(res, "add Notification res");
      // Clear the title and description fields
      setTitle("");
      document.getElementById("title").value = "";
      setDesc("");
      document.getElementById("desc").value = "";
      // Display a success toast message
      toast.success(res?.data?.message);
      // Navigate to the notification page for the specific job
      // navigate(`/notification/${res?.data?.data?.jobId}`);
    } else if (res?.data?.code === 401) {
      // If the response code is 401, display an error toast and navigate to the login page
      toast.error(res?.data?.message);
      navigate(`/login`);
    } else {
      // If the response code is not 200 or 401, display an error toast
      toast.error(res.message);
    }
    // console.log(data);
  };

  return (
    <>
      <Header />
      <section className="add_Notification">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="loginForm">
                <form>
                  <h4>Add Notification</h4>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="title"
                          className="form-control"
                          aria-describedby="nameHelp"
                          placeholder="Title"
                          onKeyUp={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <textarea
                          rows=""
                          cols=""
                          id="desc"
                          className="form-control text_area"
                          placeholder="Description"
                          onKeyUp={(e) => {
                            setDesc(e.target.value);
                          }}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-2">
                      <div className="nextBtn">
                        {/* <Link to="/long_term_Job"> */}
                        <a type="button" onClick={() => submit()}>
                          Add <span></span>
                        </a>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddNotification;
