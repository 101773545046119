import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Docs from "../../assets/img/user.png";
import Header from "../../components/Header/Header";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import "./my_document.scss";
import ApiClient from "../../api/apiClient";
import {
  API_AddNewDocument,
  API_DeleteNewDocument,
  API_DelectAll,
  url,
} from "../../api/Constant";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "@material-ui/core/Modal";

const MyDocument = () => {
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  React.useEffect(() => {
    if (token) {
      callDocumentListApi();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
  }, []);
  const [documentFields, setDocumentFields] = useState([
    {
      idType: "",
      idNumber: "",
      fileName: "",
    },
  ]);
  const navigate = useNavigate();
  const [documentList, setDocumentList] = useState([]);
  const [add, setAdd] = useState("Identification Proof Type");
  const [identityFile, setIdentityFile] = useState([]);
  const [documentErrors, setDocumentErrors] = useState([{}]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [pageNu, setPageNu] = useState(1);
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isFile, setIsFile] = useState(false);

  var pageNo = 1;

  const cookie = new Cookies();
  // const token = cookie.get("token");
  // const userId = cookie.get("userId");
  //console.log(documentList, "documentList");

  // Get Document List
  async function callDocumentListApi() {
    const data = "";
    setLoading(true);
    const res = await ApiClient.get(
      API_AddNewDocument +
        `?page=${pageNo}&userId=${userId}&sortBy=asc&limit=${10}&searchBy=`,
      data,
      `Bearer ${token}`
    );
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    //console.log("Get Doc list", res);
    if (res.data.code === 200) {
      setLoading(false);
      settotalItemsCount(res.data.data.total);
      setDocumentList(res.data.data.docs);
    }
  }

  // Add Document Function
  const validate = async () => {
    var isDocument = await checkDocumentValidationOnSubmit();
    // //console.log("documentFields====", identityFile);
    //console.log("documentFields", documentFields);
    if (documentList.length === 4) {
      toast.warn("Max 4 documents allowed");
      return;
    }
    const f = documentList.filter((res) => {
      return res.documentType === documentFields[0].idType;
    });
    const df = documentList.filter((res) => {
      return res.documentNumber === documentFields[0].idNumber;
    });

    //console.log(f);
    //console.log(df);
    if (f.length === 1) {
      toast.warn("Same type of identification proof is already saved");
      return;
    }

    if (df.length === 1) {
      toast.warn("Same document no is already saved");
      return;
    }

    //console.log(f);
    //console.log(df);

    if (isDocument) {
      var formData = new FormData();
      formData.append("userId", userId);
      formData.append("documentType", documentFields[0].idType);
      formData.append("documentNumber", documentFields[0].idNumber);
      formData.append("file", identityFile[0]);
      setIsLoading(true);
      const response = await ApiClient.multipart(
        "post",
        API_AddNewDocument,
        formData,
        `Basic ${token}`
      );

      if (response && response.data.code === 200) {
        setIsLoading(false);
        toast.success(response.data.message, "green");
        setDocumentFields([
          {
            idType: "",
            idNumber: "",
            fileName: "",
          },
        ]);
        document.getElementById("fileName").value = "";
        // setFileName("");
        callDocumentListApi();
      }
      if (response && response.data.code === 409) {
        setIsLoading(false);
        toast.error(response.data.message, "red");
        setDocumentFields([
          {
            idType: "",
            idNumber: "",
            fileName: "",
          },
        ]);
        callDocumentListApi();
      }
    }
  };

  // Document Validation Function
  const checkDocumentValidationOnSubmit = async () => {
    var valid = true;
    const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
    let errorMsg = documentFields.map((data, key) => {
      let error = {};
      if (data.idNumber === "") {
        error.errorIdNumber = "Identification number is required";
        valid = false;
      } else if (alphanumericRegex.test(data.idNumber) === false) {
        error.errorIdNumber = "Please enter valid identification number";
        valid = false;
      } else {
        error.errorIdNumber = "";
      }
      if (data.idType === "") {
        error.errorIdType = "Identification type is required";
        valid = false;
      } else {
        error.errorIdType = "";
      }

      if (data.fileName === "") {
        error.errorFileName = "Identification file is required";
        valid = false;
      } else {
        error.errorFileName = "";
      }
      return error;
    });
    // //console.log('errorMsg===', errorMsg, valid)
    setDocumentErrors(errorMsg);
    return valid;
  };

  // Handle Selected Image
  const handleChange = async (index, e) => {
    // //console.log("e tytry", e.target.type, documentFields);
    const value = [...documentFields];
    if (e.target.type === "text" || e.target.type === "select-one") {
      value[index][e.target.name] = e.target.value;
    } else {
      const allowedBytes = 2 * 1024 * 1024;
      let allImages = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/tiff",
        "image/bpg",
        "image/webp",
      ];

      if (allImages.indexOf(e.target.files[0]?.type) === -1) {
        toast.warning("File type is not allowed");
        return;
      }
      if (e.target.files[0].size > allowedBytes) {
        alert("Size is more than 2 MB");
        setIsFile(true);
        return;
      }
      setIsFile(false);

      const file = [...identityFile];
      file[index] = e.target.files[0];
      setIdentityFile(file);
      value[index][e.target.name] = e.target.files[0].name;
    }
    // //console.log("value", value);
    setDocumentFields(value);
    checkDocumentValidationOnSubmit();
  };

  // Handle Page Change Function
  const handlePageChange = (pageNumber) => {
    //console.log(`active page is ${pageNumber}`);
    setPageNu(pageNumber);
    pageNo = pageNumber;
    callDocumentListApi();
  };

  // Delete Document Warning Function
  const deleteList = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteAPI(id);
      }
    });
    callDocumentListApi();
  };

  // Delete Document Function
  const callDeleteAPI = async (id) => {
    const data = "";
    // setLoading(true);
    const ress = await ApiClient.delete(
      API_DeleteNewDocument + id,
      data,
      `Bearer ${token}`
    );
    if (ress?.data?.code === 401) {
      // setIsloading(false);
      toast.error(ress?.data?.message);
      navigate(`/login`);
    }
    // //console.log('delete list ress', JSON.stringify(ress))
    if (ress.data.code === 200) {
      toast.success(ress.data.message, "green");
      // setLocationList(locationList)/
      const showList = documentList.filter((ele) => ele.id !== id);
      //console.log(showList, "showList");
      setDocumentList(showList);
      // setLoading(false);
    } else {
      // alert(ress.data.message)
      toast.error(ress.data.message, "red");
      setIsError(true);
      setError(ress.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
    callDocumentListApi();
  };

  // Function to handle "Select All" checkbox
  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setIsCheckAll(!isCheckAll);

    // Update the documentList with the checked state for all items
    let allValue = documentList.map((li) => ({
      ...li,
      isCheck: checked,
    }));
    setDocumentList(allValue);
  };

  // Function to handle checkbox state change for individual items
  const handleChecked = (e) => {
    const { id, checked } = e.target;

    // Update the documentList with the new checkbox state for the specific item
    let docList = documentList.map((item) =>
      item.id === id ? { ...item, isCheck: checked } : item
    );
    setDocumentList(docList);
  };

  // Function to handle the delete action for selected items
  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the API for deletion
        handleDeleteApiCall();
      }
    });
  };

  // Function to perform the API call for bulk deletion
  const handleDeleteApiCall = async () => {
    let emptyArry = [];

    // Create an array of IDs for selected items
    documentList.forEach((i) => {
      if (i.isCheck) {
        emptyArry.push(i.id);
      }
    });

    // Perform the deletion API call
    const ress = await ApiClient.post(
      API_DelectAll,
      { documentIds: emptyArry },
      `Bearer ${token}`
    );

    // Handle the API response
    if (ress.data.code === 200) {
      toast.success(ress.data.message, "green");
      // Filter out deleted items from the documentList
      const showList = documentList.filter((ele) => ele.isCheck === false);
      setDocumentList(showList);
      setLoading(false);
    } else {
      toast.error(ress.data.message, "red");
      setIsError(true);
      setError(ress.data.message);
    }
    // Call the API again to update the document list
    callDocumentListApi();
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Header />
      <section className="myDoc">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="myDocBox">
                <div className="row">
                  <div className="col-md-12">
                    <div className="topBox">
                      <h4>My Documents</h4>
                    </div>
                    {iserror ? (
                      <div className="alert alert-danger">{error}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  {documentFields?.map((identificationField, i) => {
                    return (
                      <>
                        <div className="col-md-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="idType"
                              value={identificationField?.idType}
                              onChange={(e) => handleChange(i, e)}
                              defaultValue="Identification Proof Type"
                            >
                              <option selected value="">
                                Identification Proof Type
                              </option>
                              <option value="Drivers license">
                                Drivers license
                              </option>

                              <option value="Permanent resident card">
                                Permanent resident card
                              </option>
                              <option value="Other">Other</option>
                              <option value="Canadian passport">
                                Canadian passport
                              </option>
                            </select>
                          </div>
                          <ErrorMessage
                            messerror={
                              documentErrors.length - 1 >= i &&
                              documentErrors[i].errorIdType
                            }
                          />
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="idNumber"
                              maxLength={15}
                              placeholder="Document No.*"
                              value={identificationField.idNumber}
                              aria-label="default input example"
                              onChange={(e) => handleChange(i, e)}
                            />
                            <span>
                              <i className="fas fa-file"></i>
                            </span>
                          </div>
                          <ErrorMessage
                            messerror={
                              documentErrors.length - 1 >= i &&
                              documentErrors[i].errorIdNumber
                            }
                          />
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            {isFile ? (
                              <input
                                type="file"
                                className="form-control choose"
                                htmlFor="exampleInputtext"
                                aria-describedby="textHelp"
                                name="fileName"
                                id="fileName"
                                value=""
                                accept="image/*"
                                placeholder="Upload Identification"
                                onChange={(e) => handleChange(i, e)}
                              />
                            ) : (
                              <input
                                type="file"
                                className="form-control choose"
                                htmlFor="exampleInputtext"
                                aria-describedby="textHelp"
                                name="fileName"
                                id="fileName"
                                accept="image/*"
                                placeholder="Upload Identification"
                                onChange={(e) => handleChange(i, e)}
                              />
                            )}
                            <span>
                              <i className="fas fa-paperclip"></i>
                            </span>
                          </div>
                          <ErrorMessage
                            messerror={
                              documentErrors.length - 1 >= i &&
                              documentErrors[i].errorFileName
                            }
                          />
                        </div>
                      </>
                    );
                  })}
                  <div className="col-md-3">
                    <div className="addDocs_btn">
                      {/* <Link to=""> */}
                      <a type="button" onClick={() => validate()}>
                        {isloading ? "Please Wait..." : "Add Document"}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="uploded_docs">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Uploaded Documents</h5>
                    </div>
                    <div className="addDocs_btn">
                      {documentList.filter((i) => i?.isCheck === true).length >
                      0 ? (
                        <a
                          type="button"
                          onClick={(e) => handleDelete(e)}
                          style={{ background: "red" }}
                        >
                          Delete
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive border-1">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              <input
                                type="checkbox"
                                id="selectAll"
                                onChange={handleSelectAll}
                                checked={
                                  documentList.filter(
                                    (i) => i?.isCheck !== true
                                  ).length < 1
                                }
                              />
                            </th>
                            <th>Document Title</th>
                            <th>Document No.</th>

                            <th>Attachment</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {loading && (
                          <tbody>
                            <tr>
                              <td colSpan={5} className="text-center">
                                Loading...
                              </td>
                            </tr>
                          </tbody>
                        )}
                        {!loading && (
                          <tbody>
                            {documentList.length !== 0 ? (
                              documentList.map((item, id, index) => {
                                return (
                                  <tr key={item.index}>
                                    <td>
                                      <input
                                        type="checkbox"
                                        key={id}
                                        id={item.id}
                                        onChange={(e) => handleChecked(e)}
                                        checked={item?.isCheck}
                                      />
                                    </td>
                                    <td>{item.documentType}</td>
                                    <td>{item.documentNumber}</td>

                                    <td>
                                      <div className="attach_docs">
                                        <img
                                          src={
                                            item?.file === "undefined" ||
                                            item?.file === undefined
                                              ? Docs
                                              : `${url}/media/preview?filename=${item?.file}`
                                          }
                                          className="img-fluid"
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <div className="action_btn">
                                        <a
                                          type="button"
                                          onClick={() => deleteList(item.id)}
                                          className="del_btn"
                                        >
                                          <i className="fas fa-trash"></i>
                                        </a>
                                        <a
                                          type="button"
                                          onClick={() =>
                                            navigate(`/edit_document`, {
                                              state: { item },
                                            })
                                          }
                                          className="edit_btn"
                                        >
                                          <i className="fas fa-edit"></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <div className="mt-2">
                      {documentList.length != 0 && (
                        <Pagination
                          color="primary"
                          activePage={pageNu}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyDocument;
