import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./all_notification.scss";
import ApiClient from "../../api/apiClient";
import Cookies from "universal-cookie";
import { API_GetAllNotification } from "../../api/Constant";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import NoDataImg from "../../assets/img/no_data_found.png";
import Modal from "@material-ui/core/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";

const AllNotification = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  //   //console.log(id);
  const [nlist, setNlist] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [isLoading, setIsloading] = useState(false);
  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;
  // var pageNo = 1;
  const [pageNo, setPageNo] = useState(1);

  // Effect hook to fetch notification list on component mount
  useEffect(() => {
    if (token) {
      getNotificationList(); // Fetch the list of notifications
    } else {
      toast.warning("Your session has expired. Please login again.", "yellow");
      navigate(`/login`); // Redirect to login page
    }
  }, []);

  // Function to handle page change for notification list pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber); // Update the current page number
    getNotificationList(); // Fetch the updated notification list
  };

  // Function to fetch the list of notifications
  async function getNotificationList() {
    const data = "";
    setIsloading(true); // Set loading state
    const res = await ApiClient.get(
      API_GetAllNotification + `?jobId=&searchBy=&limit=10&page=${pageNo}`,
      data,
      `Bearer ${token}`
    );
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      navigate(`/login`); // Redirect to login page if authentication error
    }
    if (res?.data?.code === 200) {
      setIsloading(false); // Set loading state to false
      setNlist(res?.data?.data?.docs); // Update the notification list
      settotalItemsCount(res?.data?.data?.total); // Update total item count for pagination
      //console.log(nlist); // Log the updated notification list
    } else {
      setIsloading(false); // Set loading state to false
      toast.error(res?.data?.message);
    }
  }

  return (
    <>
      <div>
        <Modal
          open={isLoading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Header />
      {!isLoading && (
        <section className="notification_Alert">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-9">
                <div className="content">
                  <div className="title">
                    <div className="backArrow"></div>
                    <div className="heading">
                      <h4>Notification Alerts</h4>
                    </div>
                  </div>
                  {nlist?.length !== 0 ? (
                    nlist?.map((res, i) => {
                      return (
                        <div className="notifyBox confirmJob">
                          <div className="row"></div>

                          <div className="row">
                            <div className="col-md-12">
                              <h6>{res?.jobId?.jobTitle}</h6>
                              <div className="time">
                                <p>
                                  <span className="left">
                                    <i className="fas fa-clock"></i>
                                  </span>{" "}
                                  {moment(res?.createdAt).format(
                                    "DD-MM-YYYY, h:mm:ss A"
                                  )}
                                </p>
                                {/* <span>01:30 PM</span> */}
                              </div>
                              <p>{res?.title}</p>
                              <br />
                              <p>{res?.description}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="row">
                      <div className="col-md-12">
                        <div className="nodata_img">
                          <img
                            src={NoDataImg}
                            className="img-fluid"
                            alt="No Data Found"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2">
                {nlist.length != 0 && (
                  <Pagination
                    color="primary"
                    activePage={pageNo}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default AllNotification;
