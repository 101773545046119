import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import "./my_units.scss";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import ApiClient from "../../api/apiClient";
import { API_GETUNITLIST, API_DeleteUnitList } from "../../api/Constant";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";

const MyUnit = () => {
  const [unitList, setUnitList] = useState([]);
  const navigate = useNavigate();
  const cookie = new Cookies();
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  const [totalItemsCount, settotalItemsCount] = useState(0);
  var pageNo = 1;

  React.useEffect(() => {
    if (token) {
      callUnitListApi();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
  }, []);

  // call WorkLocation API
  async function callUnitListApi() {
    const data = "";
    const res = await ApiClient.get(
      API_GETUNITLIST + `?page=${pageNo}&sortBy=asc&limit=${10}&searchBy=`,
      data,
      `Bearer ${token}`
    );
    // console.log("Get UnitList Data", JSON.stringify(res));
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res.data.code === 200) {
      settotalItemsCount(res.data.data.total);
      setUnitList(res.data.data.docs);
    } else {
      setIsError(true);
      setError(res.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  }

  // Page Change Function
  const handlePageChange = (pageNumber) => {
    //console.log(`active page is ${pageNumber}`);
    // setPageNumber(pageNumber);
    pageNo = pageNumber;
    callUnitListApi();
    // this.setState({activePage: pageNumber});
  };

  // Delete Warning Function
  const deleteList = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteAPI(id);
      }
    });
    // console.log('delete list id', id)
  };
  // Delete Unit Function
  const callDeleteAPI = async (id) => {
    const data = "";
    const ress = await ApiClient.delete(
      API_DeleteUnitList + id,
      data,
      `Bearer ${token}`
    );
    // console.log("delete unit list ress", ress);
    if (ress?.data?.code === 401) {
      // setIsloading(false);
      toast.error(ress?.data?.message);
      navigate(`/login`);
    }
    if (ress.data.code === 200) {
      toast.success(ress.data.message, "green");
      const showList = unitList.filter((ele) => ele.id !== id);
      setUnitList(showList);
    } else {
      toast.error(ress.data.message, "red");
      setIsError(true);
      setError(ress.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  };

  return (
    <>
      <Wel_Header />
      <section className="myUnit">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="myDocBox">
                <div className="uploded_docs">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>My Units</h5>
                    </div>
                    {iserror ? (
                      <div className="alert alert-danger">{error}</div>
                    ) : null}
                  </div>

                  <div className="col-md-12">
                    <div className="table-responsive border-1">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Location Name</th>
                            <th>Adderess</th>
                            <th>Email Address</th>
                            <th>Phone</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {unitList.map((item) => {
                            return (
                              <tr>
                                <td>{item.locationName}</td>
                                <td>{item.locationType}</td>
                                <td>{item.email}</td>
                                <td>{item.mobileNumber}</td>
                                <td>
                                  <div className="action_btn">
                                    <a
                                      href={`/update_unit/${item.id}`}
                                      className="edit_btn"
                                    >
                                      <i className="fas fa-edit"></i>
                                    </a>
                                    <a
                                      onClick={() => deleteList(item.id)}
                                      className="del_btn"
                                    >
                                      <i className="fas fa-trash-alt"></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-2">
                      {unitList.length != 0 && (
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyUnit;
