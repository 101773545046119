import React, { useEffect, useState, useRef } from "react";
// import LogoIcon from "../../components/Logo/Logo";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import Header from "../../components/Header/Header";
import "./add_location.scss";
import {
  // location,
  numbers,
  string,
  // unit_number,
  alphanumeric,
  nameVaidation,
  alpha,
  firstNameAndLastName,
  alphanumericWithSpec,
} from "../../constant";
import { googleAddressKey } from "./../../api/Constant";
import ApiClient from "../../api/apiClient";
import { API_AddWorkLocation } from "../../api/Constant";
import Cookies from "universal-cookie";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "@material-ui/core/Modal";
import { toast } from "react-toastify";

let autoComplete;

const AddLocation = () => {
  const [query, setQuery] = useState("");
  const [locationName, setLocationName] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [building, setBuilding] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [errorLocation, setErrorLocation] = useState(null);
  const [errorUnitNumber, setErrorUnitNumber] = useState(null);
  const [errorBuilding, setErrorBuilding] = useState(null);
  const [errorStreet, setErrorStreet] = useState(null);
  const [errorCity, setErrorCity] = useState(null);
  const [errorProvince, setErrorProvince] = useState(null);
  const [errorZip, setErrorZip] = useState(null);
  const [errorContactPerson, setErrorContactPerson] = useState(null);
  const [errorContactNumber, setErrorContactNumber] = useState(null);
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const autoCompleteRef = useRef(null);
  let [loading, setLoading] = useState(false);
  let [businessName, setBusinessName] = useState("");
  const [errorBuss, setErrorBuss] = useState(null);

  const [searchParams] = useSearchParams();

  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;
  // Function to dynamically load an external script and execute a callback
  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    // Handle script load states for different browsers
    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  // Function to handle the loading of Google Places Autocomplete API script
  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    // Initialize Google Places Autocomplete with component restrictions
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "ca" } }
    );

    // Set fields to be retrieved from the Autocomplete result
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);

    // Listen for place selection event and trigger handlePlaceSelect
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  };

  // Function to handle the selection of a place from Google Autocomplete
  const handlePlaceSelect = async (updateQuery) => {
    // Get selected place's details from Google Autocomplete
    const addressObject = autoComplete.getPlace();

    // Extract latitude and longitude from geometry
    const latitude = addressObject.geometry.location.lat();
    setLatitude(latitude);
    const longitude = addressObject.geometry.location.lng();
    setLongitude(longitude);

    // Extract formatted address and update query
    const query = addressObject.formatted_address;
    updateQuery(query);

    // Log details for debugging
    //console.log(addressObject, query, latitude, longitude);

    // Set location name and extract address components
    setLocationName(addressObject.formatted_address);
    for (let i = 0; i < addressObject.address_components.length; i++) {
      const element = addressObject.address_components[i];
      if (element.types[0] === "country") {
        const country = element.long_name;
        setCountry(country);
      } else if (element.types[0] === "street_number") {
        const streetVal = element.long_name;
        setStreet(streetVal);
        _street(streetVal);
      } else if (element.types[0] === "administrative_area_level_1") {
        const state = element.long_name;
        setProvince(state);
        _province(state);
      } else if (
        element.types[0] === "locality" ||
        element.types[0] === "sublocality_level_1"
      ) {
        const city = element.long_name;
        setCity(city);
        _city(city);
      } else if (element.types[0] === "postal_code") {
        const zip = element.long_name;
        zip.replace(/ /g, "");
        setZip(zip.replace(/ /g, ""));
        _zip(zip.replace(/ /g, ""));
      }
    }
  };

  useEffect(async () => {
    // await getSpeciality();
    // await getGuardProfilef();
    // //console.log('=======================================',JSON.stringify(searchParams.get('isHeadOffice')))
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
    loadScript(
      // `https://maps.googleapis.com/maps/api/js?key=AIzaSyCM3LLCqIN8v0uaQyzMcxvEuKVa3iTZYCY&libraries=places`,
      `https://maps.googleapis.com/maps/api/js?key=${googleAddressKey}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, []);

  // Validate and set location
  const _location = (loc) => {
    if (loc === "") {
      setErrorLocation("*Please enter location.");
    }
    // else if (!location.test(loc)) {
    //   setErrorLocation("*Please enter valid location.");
    // }
    else {
      setErrorLocation("");
    }
  };
  // Validate and set unit number
  const _unitNo = (unit) => {
    if (unit === "") {
      setErrorUnitNumber("*Please enter unit number.");
    }
    if (unit && !alphanumeric.test(unit)) {
      setErrorUnitNumber("*Please enter valid unit number.");
    } else {
      setErrorUnitNumber(null);
    }
  };
  // Validate and set building
  const _building = (building) => {
    if (building === "") {
      setErrorBuilding("*Please enter building.");
    }
    if (building && !alphanumeric.test(building)) {
      setErrorBuilding("*Please enter valid unit number.");
    } else {
      setErrorBuilding(null);
    }
  };
  // Validate and set street
  const _street = (num) => {
    if (num === "") {
      setErrorStreet("*Please enter street.");
    }
    if (num && !alphanumericWithSpec.test(num)) {
      setErrorStreet("*Please enter valid unit number.");
    } else {
      setErrorStreet(null);
    }
  };
  // Validate and set city
  const _city = (num) => {
    if (num === "") {
      setErrorCity("*Please enter city.");
    } else {
      setErrorCity(null);
    }
  };
  // Validate and set province
  const _province = (num) => {
    if (num === "") {
      setErrorProvince("*Please enter province.");
    } else if (!string.test(num)) {
      setErrorProvince("*Please enter valid province.");
    } else {
      setErrorProvince(null);
    }
  };
  // Validate and set postal code
  const _zip = (num) => {
    if (num === "") {
      setErrorZip("*Please enter postal code.");
    }
    if (num.length < 6) {
      setErrorZip("*Please enter valid postal code.");
    } else {
      setErrorZip(null);
    }
  };
  // Validate on contact person
  const _contactPerson = (name) => {
    if (name === "") {
      setErrorContactPerson("*Please enter contact person name.");
    }
    if (name && !firstNameAndLastName.test(name)) {
      setErrorContactPerson("*Please enter valid contact person name.");
      return;
    } else {
      setErrorContactPerson(null);
    }
  };
  // Validate on contact number
  const _contactNumber = (num) => {
    if (num === "") {
      setErrorContactNumber("*Please enter contact number.");
    } else if (!numbers.test(num)) {
      setErrorContactNumber("*Please enter valid contact number.");
      return;
    } else if (num.length < 10) {
      setErrorContactNumber("*Please enter valid contact number.");
      return;
    } else {
      setErrorContactNumber(null);
    }
  };

  // FUnction for add location
  const validate = async () => {
    if (businessName == "") {
      setErrorBuss("*Please enter bussiness name.");
    } else {
      setErrorBuss(null);
    }
    if (locationName == "") {
      setErrorLocation("*Please enter location.");
    }
    // else if (!location.test(locationName)) {
    //   setErrorLocation("*Please enter valid location.");
    // }
    // if (unitNumber === "") {
    //   setErrorUnitNumber("*Please enter unit number.");
    // }
    if (building === "") {
      setErrorBuilding("*Please enter building.");
    }
    if (street === "") {
      setErrorStreet("*Please enter street.");
    }
    if (city === "") {
      setErrorCity("*Please enter city.");
    }
    if (province === "") {
      setErrorProvince("*Please enter province.");
    }
    if (zip === "") {
      setErrorZip("*Please enter postal code.");
    }
    if (contactPerson === "") {
      setErrorContactPerson("*Please enter contact person.");
    }
    if (contactNumber === "") {
      setErrorContactNumber("*Please enter contact number.");
    }
    if (
      locationName &&
      street &&
      city &&
      province &&
      zip &&
      contactPerson &&
      errorContactPerson === null &&
      errorContactNumber === null &&
      contactNumber &&
      businessName
    ) {
      AddLocation();
    }
  };

  // call API_AddLocation
  async function AddLocation() {
    // const token = localStorage.getItem("userId");
    // const refToken = JSON.parse(token);
    // ////console.log('=================', searchParams.get('isHeadOffice'))
    var type = 1;
    if (
      searchParams.get("isHeadOffice") === null ||
      searchParams.get("isHeadOffice") === "null"
    ) {
      type = 1;
    } else {
      type = searchParams.get("isHeadOffice");
    }
    const data = {
      locationName: locationName, //88 Journal Square Jersey City, NJ 07306
      unitNumber: unitNumber,
      businessName: businessName,
      building: building,
      street: street,
      city: city,
      province: province,
      postalCode: zip,
      contactPerson: contactPerson,
      contactNumber: contactNumber,
      lat: latitude,
      long: longitude,
      userId: userId,
      unitId: null,
      type: type, //1 is Work Location, 2 is Head Office
    };
    setLoading(true);
    const res = await ApiClient.post(
      API_AddWorkLocation,
      data,
      `Bearer ${token}`
    );
    //console.log(res, "res");
    setLoading(false);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res.data.code === 200) {
      // console.log('=======================================', JSON.stringify(searchParams.get('isHeadOffice')))
      if (searchParams.get("isHeadOffice") === "2") {
        localStorage.setItem("locationId", res.data.data.id);
        navigate(`/add-unit/` + res.data.data.id);
      } else if (searchParams.get("isHeadOffice") === "null") {
        navigate(`/work_location`);
      } else {
        // navigate(`/work_location?isHeadOffice=` + searchParams.get('isHeadOffice'));
        navigate(`/thankYou?isLicense=` + "full");
        // navigate(`/work_location`);
      }
      setLocationName("");
      setUnitNumber("");
      setBusinessName("");
      setBuilding("");
      setStreet("");
      setCity("");
      setProvince("");
      setZip("");
      setContactPerson("");
      setContactNumber("");
    } else if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      navigate(`/login`);
    } else {
      setIsError(true);
      setError(res.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  }
  // Validate on bussines name
  const getBussinesName = (v) => {
    if (v == "") {
      setErrorBuss("*Please enter business name.");
      return;
    } else {
      setErrorBuss(null);
      setBusinessName(v);
    }
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      {searchParams.get("isHeadOffice") === "null" ? (
        <Header />
      ) : (
        <Wel_Header />
      )}
      {/* <Wel_Header /> */}
      <section className="add_location">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="signupForm">
                <form>
                  <div className="location_title">
                    <div className="row align-items-center">
                      <div className="col-md-12 text-center">
                        {/* <Link to={`/work_location?isHeadOffice=`+searchParams.get('isHeadOffice')} className="backBtn">
                          <span className="left">
                            <i className="fas fa-arrow-left"></i>
                          </span>
                        </Link> */}
                        <h4>
                          {searchParams.get("isHeadOffice") === "1"
                            ? "Add Location"
                            : "Add Head Office"}
                        </h4>
                        {searchParams.get("isHeadOffice") !== "null" && (
                          <div className="editDetail">
                            <a
                              onClick={(e) => {
                                navigate("/");
                              }}
                              type="button"
                            >
                              <i class="fa-solid fa-arrow-left"></i>
                              <span>Back To Home</span>
                            </a>
                          </div>
                        )}

                        {iserror ? (
                          <div className="alert alert-danger">{error}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          onKeyUp={(e) => {
                            getBussinesName(e.target.value);
                          }}
                          placeholder="Business Name/Location*"
                        />
                        <span>
                          <i class="fa fa-briefcase"></i>
                        </span>
                      </div>
                      {errorBuss != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorBuss}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Search Location*"
                          ref={autoCompleteRef}
                          value={query}
                          onChange={(event) => {
                            setQuery(event.target.value);
                            _location(event.target.value);
                          }}
                          // value={locationName}
                          // onChange={(e) => {
                          //   setLocationName(e.target.value);
                          //   _location(e.target.value);
                          // }}
                        />
                        <span>
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      {errorLocation != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorLocation}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          value={unitNumber}
                          maxLength={15}
                          placeholder="Unit No."
                          onChange={(e) => {
                            setUnitNumber(e.target.value);
                            // _unitNo(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-map"></i>
                        </span>
                      </div>
                      {errorUnitNumber != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorUnitNumber}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Building"
                          value={building}
                          onChange={(e) => {
                            setBuilding(e.target.value);
                            _building(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-building"></i>
                        </span>
                      </div>
                      {errorBuilding != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorBuilding}
                          </p>
                        </div>
                      ) : null}
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Street*"
                          value={street}
                          onChange={(e) => {
                            setStreet(e.target.value);
                            _street(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-road"></i>
                        </span>
                      </div>
                      {errorStreet != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorStreet}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="City*"
                          maxLength={15}
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                            _city(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      {errorCity != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorCity}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Province*"
                          maxLength={15}
                          value={province}
                          onChange={(e) => {
                            setProvince(e.target.value);
                            _province(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      {errorProvince != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorProvince}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Postal Code*"
                          maxLength={7}
                          value={zip}
                          onChange={(e) => {
                            setZip(e.target.value);
                            _zip(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      {errorZip != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorZip}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12">
                      <h5>Contact Person</h5>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Contact Person*"
                          maxLength={64}
                          value={contactPerson}
                          onChange={(e) => {
                            setContactPerson(e.target.value);
                            _contactPerson(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      {errorContactPerson != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorContactPerson}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Contact No.*"
                          maxLength={10}
                          value={contactNumber}
                          onChange={(e) => {
                            setContactNumber(e.target.value);
                            _contactNumber(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-phone-alt"></i>
                        </span>
                      </div>
                      {errorContactNumber != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorContactNumber}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="nextBtn">
                    {/* <Link to="/privacy"> */}
                    <a type="button" onClick={() => validate()}>
                      Submit <span></span>
                    </a>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddLocation;
