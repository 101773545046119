import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header';
import './approve_notification.scss';

const Notification = () => {
  return (
    <>
    <Header />
      <section className="approve_alert">
         <div className="container">
             <div className="row justify-content-center">
                 <div className="col-md-12 col-lg-9">
                     <div className="content">
                        <div className="title">
                            <div className="heading"><h4>Notification Alerts</h4></div>
                        </div>
                         <div className='notifyBox confirmJob'>
                             <div className='row'>
                                 <div className='col-md-6'>
                                    <h5>Confirm Job Availability</h5>
                                 </div>
                                 <div className='col-md-6'>
                                     <div className='confirmBtn'>
                                        <Link to="/jobPage2">Click here to confirm <span className='left'><i className="fas fa-arrow-right"></i></span></Link>
                                     </div>
                                 </div>
                             </div>
                             <div className='row'>
                                <div className='col-md-12'>
                                    <h6>Mall Security Guard</h6>
                                    <div className='time'>
                                        <p><span className='left'><i className="fas fa-clock"></i></span> 09:30 AM,12/11/2021</p>
                                        <span>01:30 PM</span>
                                    </div>
                                    <a href="#" className='approve_btn'>Approve</a>
                                </div>
                             </div>
                         </div>
                         <div className='notifyBox'>
                            <h5>Confirm Job Availability <span>01:30 PM</span></h5>
                            <p>Marzipan tootsie roll oat cake croissant muffin gingerbread tart. Cotton candy lemon drops shortbread caramels pudding oat cake macaroon dragée wafer. Pastry cake topping dessert icing. Bear claw tart topping shortbread bear claw sugar plum liquorice bonbon cake.</p>
                            <div className='time'>
                                <a href="#">Approve</a>
                            </div>
                         </div>
                         <div className='notifyBox'>
                            <h5>Confirm Job Availability <span>01:30 PM</span></h5>
                            <p>Marzipan tootsie roll oat cake croissant muffin gingerbread tart. Cotton candy lemon drops shortbread caramels pudding oat cake macaroon dragée wafer. Pastry cake topping dessert icing. Bear claw tart topping shortbread bear claw sugar plum liquorice bonbon cake.</p>
                            <div className='time'>
                                <a href="#">Approve</a>
                            </div>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
      </section>
    </>
  )
}

export default Notification;
