import React, { useState } from "react";
import LogoIcon from "../../components/Logo/Logo";
import { Link, useNavigate } from "react-router-dom";
import "./signup.scss";
import { API_Signup } from "../../api/Constant";
import ApiClient from "../../api/apiClient";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import { string, emailRegex, numbers, alpha } from "../../constant";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "@material-ui/core/Modal";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import moment from "moment";

const Signup = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState({ value: "", err: "" });
  const [middleName, setMiddleName] = useState({ value: "", err: "" });
  const [lastName, setLastName] = useState({ value: "", err: "" });
  const [email, setEmail] = useState({ value: "", err: "" });
  const [mobile, setMobile] = useState({ value: "", err: "" });
  const [pass, setPass] = useState({ value: "", err: "" });
  const [confirmPass, setConfirmPass] = useState({ value: "", err: "" });
  const [registerAs, setRegisterAs] = useState({ value: "", err: "" });
  const [dob, setDOB] = useState(
    moment.utc().subtract(18, "years").format("yyyy-MM-DD")
  );
  const [dobError, setdobError] = useState("");
  // const [add, setAdd] = useState('1');
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  let [loading, setLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowCPassword, setIsShowCPassword] = useState(false);
  var err = "";

  React.useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userInfo);
    const token = userInfo?.tokens?.refresh.token;
    // if (token) {
    //   navigate(`/my_profile`);
    //   toast.warning("You are already logged in");
    // }
  }, []);

  // Validation Functions
  const fname = (str) => {
    if (str === "") {
      setFirstName({ value: str, err: "*Please enter first name." });
    } else if (!alpha.test(str)) {
      setFirstName({ value: str, err: "*Please enter valid first name." });
    } else {
      setFirstName({ value: str, err: "" });
    }
  };
  const mname = (str) => {
    if (str && !string.test(str)) {
      setMiddleName({ value: str, err: "*Please enter valid middle name." });
    } else {
      setMiddleName({ value: str, err: "" });
    }
  };

  const lname = (str) => {
    if (str === "") {
      setLastName({ value: str, err: "*Please enter last name." });
    } else if (!alpha.test(str)) {
      setLastName({ value: str, err: "*Please enter valid last name." });
    } else {
      setLastName({ value: str, err: "" });
    }
  };
  const _emailvalidate = (mail) => {
    if (mail === "") {
      setEmail({ value: mail, err: "*Please enter email." });
    } else if (!emailRegex.test(mail)) {
      setEmail({ value: mail, err: "*Please enter valid email." });
    } else {
      setEmail({ value: mail, err: "" });
    }
  };
  const _mobile = (num) => {
    if (num === "") {
      setMobile({ value: num, err: "*Please enter mobile number." });
    } else if (!numbers.test(num)) {
      setMobile({ value: num, err: "*Please enter valid mobile number." });
    } else if (num.length < 10) {
      setMobile({ value: num, err: "*Please enter valid mobile number." });
    } else {
      setMobile({ value: num, err: "" });
    }
  };
  const _passwordvalidate = (pass) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pass === "") {
      setPass({ value: pass, err: "*Please enter password." });
    } else if (/([A-Z]+)/g.test(pass) && pass.length < 8) {
      setPass({
        value: pass,
        err: "*Password at least 8 characters in length, Lowercase letters (a-z), Uppercase letters (A-Z), Numbers (0-9), Special characters ($@$!%*?&).",
      });
    } else if (!pattern.test(pass)) {
      setPass({
        value: pass,
        err: "*Password at least 8 characters in length, Lowercase letters (a-z), Uppercase letters (A-Z), Numbers (0-9), Special characters ($@$!%*?&).",
      });
    } else {
      setPass({ value: pass, err: "" });
    }
  };
  const _confirmpass = (passs) => {
    if (passs === "") {
      setConfirmPass({
        value: passs,
        err: "*Please enter confirm password.",
      });
    } else if (pass.value != passs) {
      setConfirmPass({
        value: passs,
        err: "*New password and confirm password do not match.",
      });
    } else {
      setConfirmPass({ value: passs, err: "" });
    }
  };
  // Signup Function
  const validate = async () => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (firstName.value === "") {
      setFirstName({
        value: firstName.value,
        err: "*Please enter first name.",
      });
    }
    if (firstName.value && !alpha.test(firstName.value)) {
      setFirstName({
        value: firstName.value,
        err: "*Please enter valid first name.",
      });
      return;
    }
    if (lastName.value === "") {
      setLastName({ value: lastName.value, err: "*Please enter last name." });
    }
    if (lastName.value && !alpha.test(lastName.value)) {
      setLastName({
        value: lastName.value,
        err: "*Please enter valid last name.",
      });
      return;
    }
    if (middleName.value && !string.test(middleName.value)) {
      setMiddleName({
        value: middleName.value,
        err: "*Please enter valid middle name.",
      });
      return;
    }
    if (mobile.value === "") {
      setMobile({ value: mobile.value, err: "*Please enter mobile number." });
    }
    if (mobile.value && !numbers.test(mobile.value)) {
      setMobile({
        value: mobile.value,
        err: "*Please enter valid mobile number.",
      });
      return;
    }
    if (mobile.value && mobile.value.length < 10) {
      setMobile({
        value: mobile.value,
        err: "*Please enter valid mobile number.",
      });
    }
    if (email.value === "") {
      setEmail({ value: email.value, err: "*Please enter email." });
    }
    if (email.value && !emailRegex.test(email.value)) {
      setEmail({ value: email.value, err: "*Please enter valid email." });
      return;
    }
    if (pass.value === "") {
      setPass({ value: pass.value, err: "*Please enter password." });
    }
    if (
      /([A-Z]+)/g.test(pass.value) &&
      pass.length < 8 &&
      !pattern.test(pass.value)
    ) {
      //console.log("pass.value=======", pass.value);
      setPass({
        value: pass.value,
        err: "*Password at least 8 characters in length, Lowercase letters (a-z), Uppercase letters (A-Z), Numbers (0-9), Special characters ($@$!%*?&).",
      });
      return;
    }
    if (confirmPass.value === "") {
      setConfirmPass({
        value: confirmPass.value,
        err: "*Please enter confirm password.",
      });
    } else if (pass.value !== confirmPass.value) {
      setConfirmPass({
        value: confirmPass.value,
        err: "*New password and confirm password do not match.",
      });
      return;
    }
    //console.log("registerAs.value====", registerAs.value);
    if (registerAs.value === "" && !registerAs.value) {
      setRegisterAs({
        value: registerAs.value,
        err: "*Please select register as.",
      });
      return;
    }

    if (/([A-Z]+)/g.test(pass.value) && pass.value.length < 8) {
      setPass({
        value: pass.value,
        err: "*Password at least 8 characters in length, Lowercase letters (a-z), Uppercase letters (A-Z), Numbers (0-9), Special characters ($@$!%*?&).",
      });
      return;
    }

    if (!pattern.test(pass.value)) {
      setPass({
        value: pass.value,
        err: "*Password at least 8 characters in length, Lowercase letters (a-z), Uppercase letters (A-Z), Numbers (0-9), Special characters ($@$!%*?&).",
      });
      return;
    }
    // else if (add.value=="") {setAdd({value:add.value,err:'select device type'}) }
    if (dob) {
      var today = new Date();
      var birthDate = new Date(dob);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      //console.log("call age---", age);
      if (age >= 18) {
        setdobError("");
      } else {
        setdobError("*Please enter valid date of birth.");
        return;
      }
    }
    if (
      firstName.value &&
      lastName.value &&
      email.value &&
      mobile.value &&
      pass.value &&
      confirmPass.value &&
      pass.value === confirmPass.value
    ) {
      callApiToSignup();
    }
  };

  //Call Signup API Method
  async function callApiToSignup() {
    let data = {
      firstName: firstName.value,
      middleName: middleName.value,
      lastName: lastName.value,
      mobileNumber: mobile.value,
      email: email.value.toLowerCase(),
      password: pass.value,
      dob: dob,
      deviceToken: "dfdf",
      deviceId: "",
      deviceType: 3,
      registerAs: registerAs.value,
    };
    setLoading(true);
    let response = await ApiClient.post(
      API_Signup,
      data,
      "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
    );
    setLoading(false);
    if (response.data.code === 200) {
      //console.log(response, "response");
      localStorage.setItem("userInfo", JSON.stringify(response?.data?.data));
      // alert(response.data.message);
      const { user, tokens } = response.data.data;
      const cookie = new Cookies();
      cookie.set("token", tokens.refresh.token);
      cookie.set("userID", user.id);
      const d = user.registerAs;
      if (user.registerAs === "Incorporation") {
        navigate(`/business_location`);
      } else {
        navigate(`/register`, {
          state: { d },
        });
      }
    } else {
      // alert(response.data.message);
      setIsError(true);
      setError(response.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  }

  const handleChange = (event) => {
    if (event === "") {
      setRegisterAs({ value: event, err: "*Please select register as." });
    } else {
      setRegisterAs({ value: event, err: "" });
    }
  };

  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };

  const handleShowCPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowCPassword(true);
    } else {
      setIsShowCPassword(false);
    }
  };

  const handleCheckDOB = (value) => {
    //console.log("call value===", value);
    setDOB(value);
    var today = new Date();
    var birthDate = new Date(value);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    //console.log("call age---", age);
    if (age >= 18) {
      setdobError("");
    } else {
      setdobError("*Please valid date of birth.");
      err = "Age should be greater or equal to 18";
    }
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Wel_Header />
      <section className="signupPage">
        <div className="container">
          <div className="row justify-content-center">
            <div className="editDetail">
              <a
                type="button"
                className="backBtn"
                href="https://go-guard.com/"
              >
                {" "}
                Back To Home
              </a>
            </div>
            <div className="col-md-10 col-lg-8">
              <div className="signupForm">
                <form>
                  <div className="signUpBox">
                    <div className="row align-items-lg-center">
                      <div className="col-md-12 text-center">
                        {/* <Link to="/login" className="backBtn"><span></span></Link>  */}
                        <h4>SignUp</h4>
                        {iserror ? (
                          <div className="alert alert-danger">{error}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            fname(e.target.value);
                          }}
                          id="exampleInputtext"
                          aria-describedby="textHelp"
                          placeholder="First Name*"
                          maxLength={64}
                          value={firstName.value}
                          required
                        />
                        <span>
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <ErrorMessage messerror={firstName.err} />
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          maxLength={64}
                          onChange={(e) => {
                            mname(e.target.value);
                          }}
                          id="exampleInputtext"
                          aria-describedby="textHelp"
                          placeholder="Middle Name"
                          value={middleName.value}
                        />
                        <span>
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <ErrorMessage messerror={middleName.err} />
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          maxLength={64}
                          onChange={(e) => {
                            lname(e.target.value);
                          }}
                          id="exampleInputtext"
                          aria-describedby="textHelp"
                          placeholder="Last Name*"
                          value={lastName.value}
                          required
                        />
                        <span>
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      <ErrorMessage messerror={lastName.err} />
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control"
                          maxLength={180}
                          onChange={(e) => {
                            _emailvalidate(e.target.value);
                          }}
                          id="exampleInputemail"
                          aria-describedby="emailHelp"
                          placeholder="Email Address*"
                          value={email.value}
                          required
                        />
                        <span>
                          <i className="fas fa-envelope"></i>
                        </span>
                      </div>
                      <ErrorMessage messerror={email.err} />
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => {
                            _mobile(e.target.value);
                          }}
                          id="exampleInputnumber"
                          aria-describedby="numberHelp"
                          placeholder="Mobile*"
                          value={mobile.value}
                          maxLength={10}
                          required
                        />
                        <span>
                          <i className="fas fa-phone-alt"></i>
                        </span>
                      </div>
                      <ErrorMessage messerror={mobile.err} />
                    </div>
                    {/* <div className="col-12 col-md-6">
                      <div className="form-group date">
                        <input
                          type="text"
                          className="form-control"
                          onChange={(e) => handleCheckDOB(e.target.value)}
                          id="exampleInputnumber"
                          aria-describedby="numberHelp"
                          placeholder="DOB*"
                          // value={dob}
                          required
                          onFocus={(e) => (e.currentTarget.type = "date")}
                          onBlur={(e) => (e.currentTarget.type = "date")}
                        />
                        <span>
                          <i className="fas fa-birthday-cake"></i>
                        </span>
                      </div>
                      <ErrorMessage messerror={dobError} />
                      {dobError ? <ErrorMessage messerror={err} /> : ""}
                    </div> */}
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          type={!isShowPassword ? "password" : "text"}
                          className="form-control"
                          onChange={(e) => {
                            _passwordvalidate(e.target.value);
                          }}
                          id="exampleInputpassword"
                          aria-describedby="passwordHelp"
                          placeholder="Password*"
                          value={pass.value}
                          required
                        />
                        <span>
                          {!isShowPassword ? (
                            <i
                              className="fas fa-eye"
                              onClick={(e) => handleShowPassword(e, "show")}
                            />
                          ) : (
                            <i
                              className="fas fa-eye-slash"
                              onClick={(e) => handleShowPassword(e, "hide")}
                            />
                          )}
                        </span>
                      </div>
                      <ErrorMessage messerror={pass.err} />
                    </div>
                    {/* <div className="col-6"></div> */}
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          type={!isShowCPassword ? "password" : "text"}
                          className="form-control"
                          onChange={(e) => {
                            _confirmpass(e.target.value);
                          }}
                          id="exampleInputconfirm"
                          aria-describedby="confirmHelp"
                          placeholder="Confirm Password*"
                          value={confirmPass.value}
                          required
                        />
                        <span>
                          {!isShowCPassword ? (
                            <i
                              className="fas fa-eye"
                              onClick={(e) => handleShowCPassword(e, "show")}
                            />
                          ) : (
                            <i
                              className="fas fa-eye-slash"
                              onClick={(e) => handleShowCPassword(e, "hide")}
                            />
                          )}
                        </span>
                      </div>
                      <ErrorMessage messerror={confirmPass.err} />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-12">
                      <h5>I am registering with GoGuard as a</h5>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-8 col-md-6">
                      <div className="form-group">
                        <select
                          className="form-control"
                          value={registerAs.value}
                          onChange={(select) =>
                            handleChange(select.target.value)
                          }
                        >
                          <option selected value="">
                            Please select
                          </option>
                          <option value="Individual">Individual</option>
                          <option value="Incorporation">Incorporation</option>
                          <option value="Small Business">Small Business</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                      <ErrorMessage messerror={registerAs.err} />
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="nextBtn">
                        {/* <Link to="/register"> */}
                        <a type="button" onClick={() => validate()}>
                          SignUp <span></span>
                          <i className="fa-solid fa-arrow-right"></i>
                        </a>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                  <div className="already_Account">
                    <span>
                      {" "}
                      Already Have An Account? <Link to="/login">
                        LogIn
                      </Link>{" "}
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
