import React, { useState } from "react";
import Profile from "../../assets/img/user placeholder.png";
import { Link, useNavigate } from "react-router-dom";
import "./edit_profile.scss";
import { string, emailRegex, numbers } from "../../constant";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import Header from "../../components/Header/Header";
import ApiClient from "../../api/apiClient";
import { API_GetProfile, API_UpdateUserProfile, url } from "../../api/Constant";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import moment from "moment";

const EditPro = () => {
  const navigate = useNavigate();
  const [fname, setFNAme] = useState({ value: "", err: "" });
  const [mname, setMname] = useState({ value: "", err: "" });
  const [lname, setLname] = useState({ value: "", err: "" });
  const [email, setEmail] = useState({ value: "", err: "" });
  const [phoneNumber, setPhoneNumber] = useState({ value: "", err: "" });
  const [dob, setDob] = useState({ value: "", err: "" });
  const [imgSelected, setImgSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [validationerror, setValidationError] = useState(false);
  var verr = false;

  const [profilePhoto, setProfilePhoto] = useState("");
  let [imagePreviewUrl, setImagePreviewUrl] = useState("");

  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;

  // Function to validate first name input
  const firstname = (str) => {
    if (str === "") {
      setFNAme({ value: str, err: "*Please enter first name." });
      verr = true; // Validation error flag
      return;
    } else if (!string.test(str)) {
      setFNAme({ value: str, err: "*Please enter valid first name." });
      verr = true;
      return;
    } else {
      setFNAme({ value: str, err: null }); // Clear error message
      verr = false;
    }
  };

  // Function to set middle name
  const middleName = (str) => {
    setMname({ value: str, err: null }); // Clear error message
  };

  // Function to validate last name input
  const lastName = (str) => {
    if (str === "") {
      setLname({ value: str, err: "*Please enter last name." });
      verr = true;
      //console.log(verr);
      return;
    } else if (!string.test(str)) {
      setLname({ value: str, err: "*Please enter valid last name." });
      verr = true;
      return;
    } else {
      setLname({ value: str, err: null });
      verr = false;
    }
  };

  // Function to validate email input
  const _emailvalidate = (mail) => {
    if (mail === "") {
      verr = true;
      setEmail({ value: mail, err: "*Please enter email." });
      return;
    } else if (!emailRegex.test(mail)) {
      verr = true;
      setEmail({ value: mail, err: "*Please enter valid email." });
      return;
    } else {
      verr = false;
      setEmail({ value: mail, err: null });
    }
  };

  // Function to validate phone number input
  const _mobile = (num) => {
    if (num === "") {
      verr = true;
      setPhoneNumber({ value: num, err: "*Please enter home phone number." });
      return;
    } else if (!numbers.test(num)) {
      verr = true;
      setPhoneNumber({
        value: num,
        err: "*Please enter valid home phone number.",
      });
      return;
    } else if (num.length < 10) {
      verr = true;
      setPhoneNumber({ value: num, err: "*Max 10 digits allowed." });
      return;
    } else {
      verr = false;
      setPhoneNumber({ value: num, err: null });
    }
  };

  // Function to perform overall form validation
  const validate = () => {
    // Validate individual fields using defined functions
    firstname(fname.value);
    lastName(lname.value);
    _emailvalidate(email.value);
    _mobile(phoneNumber.value);

    // Check if any validation errors occurred
    if (verr === true) {
      return; // Exit validation if there are errors
    } else {
      editPro(); // Proceed with editing profile if no errors
    }
  };

  // Effect hook to run when the component mounts
  React.useEffect(() => {
    // Check if a valid token is available
    if (token) {
      callMyProfile(); // Fetch user profile data
    } else {
      toast.warning("Your session has expired. Kindly log in again.", "yellow");
      navigate(`/login`); // Redirect to login page
    }
  }, []);

  // Get My Profile Data
  async function callMyProfile() {
    const token = localStorage.getItem("userInfo");
    const refToken = JSON.parse(token);
    // //console.log(refToken.user.id)
    const data = {
      isTravelTo: 20,
      workSpecialities: "Bank",
      isGoWith: "Public Transport",
      vestSize: "Large",
    };
    const response = await ApiClient.get(
      API_GetProfile + refToken.user.id,
      data,
      `Bearer ${refToken.tokens.refresh.token}`
    );
    // //console.log("get profile res", response.data.data);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/login`);
    }
    if (response.data.code === 200) {
      var dates = moment.utc(response.data.data.dob).format("YYYY-MM-DD");
      //console.log("myprofile data", response.data.data, "dates", dates);
      setFNAme({ value: response.data.data.firstName, err: "" });
      setMname({ value: response.data.data.middleName, err: "" });
      setLname({ value: response.data.data.lastName, err: "" });
      setEmail({ value: response.data.data.email, err: "" });
      //console.log(response.data.data.profileImage);
      setImagePreviewUrl(response.data.data.profileImage);
      if (imagePreviewUrl === undefined) {
        setImagePreviewUrl(Profile);
      }
      setPhoneNumber({ value: response.data.data.mobileNumber, err: "" });
      setDob({ value: dates, err: "" });
    } else {
      // alert(response.data.message)
      setIsError(true);
      setError(response.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  }

  //Call Update user Profile
  async function editPro() {
    //console.log(dob);
    if (
      fname.value !== "" &&
      lname.value !== "" &&
      (dob.err === "" || dob.err === null)
    ) {
      //console.log(token, "reed id ");
      var formData = new FormData();
      formData.append("profileImage", profilePhoto);
      formData.append("firstName", fname.value);
      formData.append("middleName", mname.value);
      formData.append("lastName", lname.value);
      formData.append("email", email.value);
      formData.append("mobileNumber", phoneNumber.value);
      formData.append("dob", dob.value);
      setLoading(true);
      const res = await ApiClient.patch(
        API_UpdateUserProfile + userId,
        formData,
        `Bearer ${token}`
      );
      // //console.log("update user profile", res);
      if (res?.data?.code === 401) {
        // setIsloading(false);
        toast.error(res?.data?.message);
        navigate(`/login`);
      }
      if (res.data.code === 200) {
        setLoading(false);
        // localStorage.setItem("userInfo", JSON.stringify(res?.data?.data));
        navigate(`/my_profile`);
      } else {
        setLoading(false);
        // alert(res.data.messge)
        setIsError(true);
        setError(res.data.message);
        setTimeout(() => {
          setError("");
          setIsError(false);
        }, 5000);
      }
    }
  }

  // Handle Profile Photo
  const handleProfilePhoto = async (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    const size = e.target.files[0].size / 1048576;

    let allImages = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif",
      "image/tiff",
      "image/bpg",
      "image/webp",
    ];

    if (allImages.indexOf(file?.type) === -1) {
      toast.warning("File type is not allowed");
      return;
    }

    if (size > 2) {
      toast.warning("Max 2MB size allowed");
      return;
    }

    reader.onloadend = () => {
      setProfilePhoto(file);
      setImgSelected(true);
      setImagePreviewUrl(reader.result);
    };

    reader.readAsDataURL(file);
  };
  return (
    <>
      <Header />
      <section className="edit_profile">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9">
              <div className="regForm">
                <form>
                  <div className="regBox">
                    <div className="row">
                      <div className="col-12">
                        <h4>Edit Profile</h4>
                      </div>
                    </div>
                  </div>
                  <div className="avatar">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <input
                          type="file"
                          id="uploadPhoto"
                          className="form-control profile-select d-none"
                          htmlFor="exampleInputtext"
                          aria-describedby="textHelp"
                          name="profilePhoto"
                          onChange={(e) => handleProfilePhoto(e)}
                          accept="image/*"
                        />

                        <label htmlFor="uploadPhoto" className="profileImg">
                          <img
                            src={
                              imagePreviewUrl === ""
                                ? Profile
                                : imgSelected
                                ? imagePreviewUrl
                                : `${url}/media/preview?filename=${imagePreviewUrl}`
                            }
                            style={{ width: "100%", height: "100%" }}
                          />
                          <div className="icon">
                            <div className="camIcon">
                              <label htmlFor="uploadPhoto">
                                <i className="fas fa-pen text-white"></i>
                              </label>
                            </div>
                          </div>
                        </label>
                      </div>
                      {iserror ? (
                        <div className="alert alert-danger">{error}</div>
                      ) : null}

                      <div className="col-12">
                        <div className="row align-items-center">
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputtext"
                                aria-describedby="textHelp"
                                value={fname.value}
                                placeholder="First Name"
                                onChange={(e) => {
                                  firstname(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-user"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={fname.err} />
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputtext"
                                aria-describedby="textHelp"
                                value={mname.value}
                                placeholder="Middle Name"
                                onChange={(e) => {
                                  middleName(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-user"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={mname.err} />
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputtext"
                                aria-describedby="textHelp"
                                value={lname.value}
                                placeholder="Last Name"
                                onChange={(e) => {
                                  lastName(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-user"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={lname.err} />
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                id="exampleInputtext"
                                aria-describedby="textHelp"
                                value={email.value}
                                placeholder="example@gmail.com"
                                onChange={(e) => {
                                  _emailvalidate(e.target.value);
                                }}
                                readOnly
                              />
                              <span>
                                <i className="fas fa-envelope"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={email.err} />
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                id="exampleInputtext"
                                aria-describedby="textHelp"
                                value={phoneNumber.value}
                                placeholder="Mob No"
                                readOnly
                                onChange={(e) => {
                                  _mobile(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-phone-alt"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={phoneNumber.err} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nextBtn">
                    {/* <Link to="/my_profile"> */}
                    <a
                      type="button"
                      onClick={() => (loading ? "" : validate())}
                    >
                      {loading ? "Please wait..." : "Update"}
                    </a>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditPro;
