import React, { useEffect, useState } from "react";
import Profile from "../../assets/img/user placeholder.png";
import { Link, useNavigate } from "react-router-dom";
import "./my_profile.scss";
import ApiClient from "../../api/apiClient";
import {
  API_GetProfile,
  API_GetAllJobsList,
  API_GetUserBilings,
  url,
} from "../../api/Constant";
import Header from "../../components/Header/Header";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import moment from "moment";
import { Rating } from "react-simple-star-rating";

const MyProfile = () => {
  const [names, setNames] = useState("");
  const [email, setEmail] = useState("");
  const [jobs, setJobs] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [mobileNum, setMobileNum] = useState("");
  const [profile, setProfile] = useState("");
  const [cusprofile, setCusProfile] = useState("");
  const [billings, setBillings] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const navigate = useNavigate();
  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");

  React.useEffect(() => {
    // //console.log("token====", token);
    if (token) {
      callMyProfileApi();
      callGetAllJobsList();
      getBillings();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
  }, []);

  // Get Job List
  async function callGetAllJobsList() {
    const data = "";
    const res = await ApiClient.get(
      API_GetAllJobsList + `?page=${1}&sortBy=asc&limit=${3}&searchBy=`,
      data,
      `Bearer ${token}`
    );
    // //console.log("job list", res);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res.data.code === 200) {
      setJobs(res.data.data.docs);
      settotalItemsCount(res.data.data.total);
    } else {
      toast.error(res.message);
    }
  }

  // Get Billings List
  const getBillings = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_GetUserBilings + `?page=1&sortBy=asc&limit=${3}&searchBy=`,
      data,
      `Bearer ${token}`
    );
    if (res?.data?.code === 200) {
      // console.log(res);
      setBillings(res?.data?.data?.docs);
    } else {
      toast.error(res?.data?.mesage);
    }
  };

  // Get Profile Data Function
  async function callMyProfileApi() {
    const userInfo = localStorage.getItem("userInfo");
    const refToken = JSON.parse(userInfo);
    const data = "";
    setIsloading(true);
    const response = await ApiClient.get(
      API_GetProfile + refToken.user.id,
      data,
      `Bearer ${refToken.tokens.refresh.token}`
    );
    setIsloading(false);
    //console.log("get profile res", response.data.data);
    if (response.data.code === 200) {
      setProfile(response.data.data.profileImage);
      if (profile === undefined) {
        setProfile(Profile);
      }
      setNames(
        response.data.data.firstName +
          " " +
          response.data.data.middleName +
          " " +
          response.data.data.lastName
      );
      setFirstName(
        response.data.data.firstName === undefined
          ? ""
          : response.data.data.firstName
      );
      setMiddleName(
        response.data.data.middleName === undefined
          ? ""
          : response.data.data.middleName
      );
      setLastName(
        response.data.data.lastName === undefined
          ? ""
          : response.data.data.lastName
      );
      setEmail(response.data.data.email);
      setMobileNum(response.data.data.mobileNumber);
      setCusProfile(response?.data?.data);
      setTimeout(() => {
        handleRating();
      }, 2000);
    } else {
      setIsloading(false);
      alert(response.data.message);
    }
  }

  const handleRating = () => {
    // setRatingValue(2.5);
  };
  return (
    <>
      <div>
        <Modal
          open={isLoading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader
            loading={isLoading}
            height={35}
            width={4}
            color="#36D7B7"
          />
        </Modal>
      </div>
      <Header />
      {!isLoading && (
        <section className="MyProfie">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-10">
                <div className="profile">
                  <div className="row align-items-center">
                    <div className="col-6 col-md-6">
                      <h4>My Profile</h4>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="editProBtn">
                        <Link to="/edit_profile">
                          <span className="left">
                            <i className="fas fa-edit"></i>
                          </span>
                          Edit Profile
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="flexBox">
                    <div className="left">
                      <div className="box">
                        <div className="imgBox">
                          <img
                            src={
                              profile !== "" && profile !== undefined
                                ? url + "/media/preview?filename=" + profile
                                : Profile
                            }
                            alt="image profile"
                          />
                        </div>

                        <div className="contentBox">
                          <p>
                            <span>
                              <i
                                style={{ color: "#000" }}
                                className="fas fa-user"
                              ></i>
                            </span>{" "}
                            {firstName + " " + middleName + " " + lastName}
                          </p>

                          <ul>
                            <li>
                              <span className="left">
                                <i className="fas fa-envelope"></i>
                              </span>{" "}
                              {email}
                            </li>
                            <li>
                              <span className="left">
                                <i className="fas fa-phone-alt"></i>
                              </span>{" "}
                              {mobileNum}
                            </li>
                          </ul>
                          <div className="btnBox">
                            <Link to="/my_document" className="docBtn">
                              My Documents
                            </Link>
                            <Link to="/wallet_balance" className="accBtn">
                              My Wallet
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right">
                      <div className="flexInner">
                        <div className="left">
                          <p>Total Spending</p>
                          <span className="coins">
                            <i className="fas fa-coins"></i> $
                            {cusprofile?.totalSpending?.toFixed(2)}
                          </span>
                        </div>
                        <div className="right">
                          <p>My Wallet</p>
                          <span className="coins">
                            <i className="fas fa-coins"></i> $
                            {cusprofile?.eWallet?.toFixed(2)}
                          </span>
                        </div>
                      </div>
                      <div className="flexInner">
                        <div className="left">
                          <p>All Jobs</p>
                          <span style={{ color: "#000", fontWeight: "500" }}>
                            <i
                              style={{ color: "#000" }}
                              className="fas fa-briefcase me-1"
                            ></i>
                            {totalItemsCount}
                          </span>
                        </div>
                        <div className="right">
                          <a
                            type="button"
                            onClick={(e) => {
                              navigate("/jobRating", { state: token });
                            }}
                          >
                            My Rating{" "}
                            <span className="left">
                              <i
                                style={{ color: "#000" }}
                                className="fas fa-arrow-right"
                              ></i>
                            </span>
                          </a>
                          <div className="starRate">
                            <Rating
                              onClick={handleRating}
                              // ratingValue={ratingValue}
                              initialValue={cusprofile?.rating}
                              allowHalfIcon
                              readonly
                            />
                          </div>

                          <span className="count">
                            ({cusprofile?.rating ? cusprofile?.rating : 0})
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="allJob">
                  <div className="topBox">
                    <h4>
                      All Jobs <span>({totalItemsCount})</span>
                    </h4>
                    <Link to="/allJobs">View All </Link>
                  </div>

                  {jobs.length !== 0
                    ? jobs.map((item, id) => {
                        return (
                          <Link
                            to={
                              item?.status == 0
                                ? "/alljobs"
                                : item?.status == 1
                                ? "/alljobs"
                                : item?.status == 2
                                ? "/alljobs"
                                : item?.status == 3
                                ? "/alljobs"
                                : item?.status == 4
                                ? `/ongoing_job/${item.id}`
                                : item?.status == 5
                                ? "/alljobs"
                                : "/my_profile"
                            }
                          >
                            <div className="box" key={id}>
                              <div className="row align-items-center">
                                <div className="col-md-12">
                                  <div className="job_title_box">
                                    <h5>{item?.jobTitle}</h5>
                                    <span>
                                      <i className="fas fa-angle-right"></i>
                                    </span>
                                  </div>
                                  <p>
                                    <span>Status:</span>{" "}
                                    {item?.status == 0
                                      ? "Awaiting Approval"
                                      : item?.status == 1
                                      ? "Approved"
                                      : item?.status == 2
                                      ? "De-Activate(Reject)"
                                      : item?.status == 3
                                      ? "Cancel"
                                      : item?.status == 4
                                      ? "Ongoing"
                                      : item?.status == 5
                                      ? "Closed"
                                      : item?.status == 6
                                      ? "Upcoming"
                                      : item?.status == 7
                                      ? "Previous"
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      })
                    : "No Data"}
                </div>
              </div>
              <div className="col-md-6 col-lg-5">
                <div className="myEarning">
                  <div className="topBox">
                    <h4>My Transaction</h4>
                    <Link to="/mall_invoice">View All </Link>
                  </div>
                  {billings?.length !== 0 ? (
                    billings?.map((res, i) => {
                      return (
                        <div className="trans_box">
                          <div className="row">
                            <div className="col-md-8">
                              <h5>{res?.title}</h5>
                              <p className="leftPara">
                                <span className="left">
                                  <i className="fas fa-calendar"></i>
                                </span>{" "}
                                {moment
                                  .utc(res?.createdAt)
                                  .format("DD-MM-YYYY")}
                              </p>
                            </div>
                            <div className="col-md-4">
                              {res?.debit ? (
                                <p className="rightPara">-${res?.amount}</p>
                              ) : (
                                <p
                                  className="rightPara"
                                  style={{ color: "#15c73e" }}
                                >
                                  +${res?.amount}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="trans_box">
                      <div className="row">
                        <p>No Data</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default MyProfile;
