import React from "react";
import "./home.scss";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";
import { guardURL } from "../../api/Constant";

const Home = () => {
  const guardURLLink = guardURL;
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  return (
    <>
      <Wel_Header />
      <section className="homeBanner">
        <div className="container">
          <div className="Box">
            <div className="row justify-content-center">
              <div className="col-md-7 col-lg-5 col-11">
                <div className="content lightBg">
                  <h3>Customers</h3>
                  <Link to="/signup" className="proceedLeft">
                    Proceed
                    <span className="left">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-md-7 col-lg-5 col-11">
                {/* <div className="content lightBg"> */}

                <div className="content darkBg">
                  <h3>Guards</h3>
                  <a href={guardURLLink} className="proceedLeft">
                    Proceed
                    <span className="right">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
