import React, { useState } from 'react';
import LogoIcon from '../../components/Logo/Logo';
import {Link} from 'react-router-dom';
import './head_office.scss';

const HeadOffice = () => {
  return (
    <>
    <LogoIcon />
      <section className="add_head_office">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-lg-12 col-md-12">
                      <div className="signupForm">
                          <form>
                                <div className="location_title">
                                    <div className="row align-items-center">
                                        <div className="col-md-12 text-center">
                                            <Link to="/work_location" className="backBtn"><span className='left'><i className="fas fa-arrow-left"></i></span></Link> 
                                            <Link to="/" className='searchBtn'><span className='left'><i className="fas fa-search"></i></span></Link>
                                            <h4>Add Head Office</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='mapBox'>
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"  frameborder="0" style={{color:'red',borderRadius: '20px',width:'100%',height:'100px',border:'1px solid #adadad'}}></iframe>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                        <input type="text" className="form-control"  aria-describedby="" placeholder="Search Location" />
                                        <span><i className="fas fa-map-marker-alt"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                        <input type="text" className="form-control"  aria-describedby="" placeholder="Unit No." />
                                        <span><i className="fas fa-map"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                        <input type="text" className="form-control"  aria-describedby="" placeholder="Building" />
                                        <span><i className="fas fa-building"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                        <input type="text" className="form-control"  aria-describedby="" placeholder="Street" />
                                        <span><i className="fas fa-road"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="form-group">
                                        <input type="text" className="form-control" aria-describedby="" placeholder="City" />
                                        <span><i className="fas fa-map-marker-alt"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="form-group">
                                        <input type="text" className="form-control"  aria-describedby="" placeholder="Province" />
                                        <span><i className="fas fa-map-marker-alt"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="form-group">
                                        <input type="text" className="form-control"  aria-describedby="" placeholder="Zip" />
                                        <span><i className="fas fa-map-marker-alt"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="form-group">
                                        <input type="text" className="form-control"  aria-describedby="" placeholder="Contact Person" />
                                        <span><i className="fas fa-user"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="form-group">
                                        <input type="text" className="form-control"  aria-describedby="" placeholder="Contact No." />
                                        <span><i className="fas fa-phone-alt"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="nextBtn">
                                    <Link to="/addJob">Submit <span></span></Link>
                                </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default HeadOffice;
