import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import Img from "../../assets/img/userPlace.png";
import Pdf from "../../assets/img/pdf.png";
// import Img from "../../../build/static/media/user placeholder.0da3ad62167327d568cb.png"
import "./enotes.scss";
import ApiClient from "../../api/apiClient";
import Cookies from "universal-cookie";
import {
  API_GetAllJobsList,
  API_GetJobDetails,
  API_GetAllEnotes,
  url,
} from "../../api/Constant";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import NoDataImg from "../../assets/img/no_data_found.png";
import LoaderModal from "@material-ui/core/Modal";
import { Modal } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Map from "../Map/Map";

const AllJobs = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [jobid, setJobId] = useState([0]);
  const [jobDetails, setjobDetails] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const cookie = new Cookies();
  const userdata = JSON.parse(localStorage.getItem("userInfo"));
  const [isLoading, setIsloading] = useState(false);
  const [isJobDetails, setIsJobDetails] = useState(false);
  // const token = userdata.tokens.refresh.token;
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;
  const [pageNo, setPageNo] = useState(1);
  const [enotepageNo, setEnotePageNo] = useState(1);
  const [totalHrs, setTotalHrs] = useState(0);
  const [smShow, setSmShow] = useState(false);
  const [guardData, setGuardData] = useState({});
  const [experience, setExperience] = useState("");
  const [daysInExp, setDaysInExp] = useState("");
  const [open, setOpen] = useState(false);
  const [enotes, setEnotes] = useState([]);
  const [enoteId, setEnoteId] = useState([0]);
  const [enoteDetails, setEnoteDetails] = useState([0]);
  // const [title, setTitle] = useState("");
  const [totalEnoteItemsCount, setTotalEnoteItemsCount] = useState(0);
  const [searchParams] = useSearchParams();

  // Effect hook to run when the component mounts
  useEffect(() => {
    if (token) {
      // Check if jobId and page parameters are provided in the URL
      if (
        searchParams.get("jobId") !== null &&
        searchParams.get("page") !== null
      ) {
        callGetAllJobsList(searchParams.get("page")); // Fetch jobs list with specified page
      } else {
        callGetAllJobsList(1); // Fetch jobs list with default page (1)
      }
      //console.log("jid", searchParams.get("jobId"));
      //console.log("p", searchParams.get("page"));
    } else {
      toast.warning("Your session has expired. Kindly log in again.", "yellow");
      navigate(`/login`); // Redirect to login page
    }
  }, []);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    //console.log(`active page is ${pageNumber}`);
    setPageNo(pageNumber); // Update the page number in the state
    callGetAllJobsList(pageNumber); // Fetch jobs list with the new page number
  };

  // Function to fetch jobs list based on page number
  async function callGetAllJobsList(pageNumber) {
    const data = "";
    setIsloading(true); // Set loading state to true
    const res = await ApiClient.get(
      API_GetAllJobsList +
        `?page=${pageNumber}&sortBy=asc&limit=${10}&searchBy=`,
      data,
      `Bearer ${token}`
    );
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      navigate(`/login`); // Redirect to login page
    }
    if (res.data.code === 200) {
      setIsloading(false); // Set loading state to false
      setJobs(res.data.data.docs); // Update jobs list with fetched data
      // Check if jobId and page are available in the URL or use the default values
      if (searchParams.get("page") === null) {
        setJobId(res?.data?.data?.docs[0].id);
        callGetJobDetails(res?.data?.data?.docs[0].id);
        callGetENote(res?.data?.data?.docs[0].id);
      } else {
        setJobId(searchParams.get("jobId"));
        callGetJobDetails(searchParams.get("jobId"));
        callGetENote(searchParams.get("jobId"));
      }
      settotalItemsCount(res.data.data.total); // Set total item count for pagination
    } else if (res?.data?.code === 401) {
      setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    } else {
      setIsloading(false);
      toast.error(res.message);
    }
  }

  // Function to fetch job details based on ID
  async function callGetJobDetails(id) {
    const data = "";
    setIsJobDetails(true); // Set loading state for job details
    const res = await ApiClient.get(
      API_GetJobDetails + `${id}`,
      data,
      `Bearer ${token}`
    );
    if (res.data.code === 200) {
      var total = 0;
      setIsJobDetails(false); // Clear loading state for job details
      res?.data?.data?.shifts?.map((res) => {
        total += res.totalShiftHours;
        setTotalHrs(total); // Update total shift hours
        return res;
      });
      setjobDetails(res.data.data); // Update job details with fetched data
      //console.log(jobDetails);
    } else {
      setIsJobDetails(false); // Clear loading state for job details
      toast.error(res.message);
    }
  }

  // Function to set job ID and fetch corresponding details
  const setJobsid = (i, id) => {
    callGetJobDetails(id); // Fetch job details based on ID
    callGetENote(id); // Fetch eNotes based on job ID
    setJobId(id); // Update the selected job ID
  };

  // Function to fetch eNotes based on job ID
  async function callGetENote(id) {
    const data = "";
    const res = await ApiClient.get(
      API_GetAllEnotes +
        `/job/${id}?page=${enotepageNo}&sortBy=asc&limit=${10}&searchBy=`,
      data,
      `Bearer ${token}`
    );
    //console.log("eNotes list", res);
    if (res.data.code === 200) {
      setEnotes(res.data.data.docs); // Update eNotes list with fetched data
      setTotalEnoteItemsCount(res?.data?.data?.total); // Update total eNotes count
      //console.log(res);
    }
  }

  const handleInfo = () => {
    //console.log("hello");
    <p style={{ color: "red" }}>hello</p>;
  };
  // const calculateHrs = (val) => {
  //   //console.log(val);
  //   let c = val?.reduce((accumulator, item) => {
  //     return accumulator + item.totalShiftHours;
  //   });
  //   //console.log("c", c);
  //   // return c;
  // };

  // Function to calculate total employment experience of a guard
  const showGuardProfile = (guardData) => {
    var totalNoOfDays = 0;

    // Calculate total employment days for each employment detail
    for (let i = 0; i < guardData.employmentDetails.length; i++) {
      const ele = guardData.employmentDetails[i];
      var days = 0;
      var startDate = moment.utc(new Date(ele.from));
      var endDate = moment.utc(new Date(ele.to));
      var sameDate = moment.utc(startDate).isSame(endDate);
      days = endDate.diff(startDate, "days");
      days = sameDate === true ? 1 : days;
      totalNoOfDays += days;
    }
    //console.log(totalNoOfDays, "totalNoOfDays");

    // Calculate years and months from total days
    var years = Math.floor(totalNoOfDays / 365);
    var months = Math.floor((totalNoOfDays % 365) / 30);
    //console.log(years, months, "years months");
    let exp = `${years} years ${months} months`;

    setDaysInExp(totalNoOfDays); // Set total employment days
    //console.log("Guard Data---", guardData, totalNoOfDays);
    setGuardData(guardData); // Set guard data
    setExperience(exp); // Set calculated experience
    setSmShow(true); // Show guard profile modal
  };

  // Function to handle tooltip close event
  const handleTooltipClose = () => {
    setOpen(false);
  };

  // Function to handle tooltip open event
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  // Function to handle pagination for eNotes
  const handleEnotePageChange = (pageNumber) => {
    setEnotePageNo(pageNumber); // Update eNote page number
    callGetENote(jobid); // Fetch eNotes with updated page number
  };

  // Function to generate audio preview URL
  const playAudio = (audio) => {
    return `${url}/media/preview?filename=${audio}`; // Construct audio preview URL
  };

  return (
    <>
      <Header />
      {/* {!isLoading && ( */}
      <section className="all_awrded_job">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12"></div>
          </div>
          {jobs.length > 0 ? (
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12">
                <div className="row justify-content-center">
                  <div className="col-md-3">
                    <div className="left_sidebar">
                      <h5 style={{ marginBottom: "0" }}>Enotes</h5>
                      {jobs.map((res, i) => {
                        return (
                          <div className="nav_box">
                            <div
                              className="nav flex-column nav-pills"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <button
                                // className="nav-link active"
                                class={
                                  "nav-link" +
                                  (jobid === res.id ? " active" : "")
                                }
                                id="first-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#first"
                                type="button"
                                role="tab"
                                key={i}
                                aria-controls="first"
                                aria-selected="true"
                                onClick={(e) => {
                                  setJobsid(i, res?.id);
                                }}
                              >
                                {res.jobTitle}
                                <span>
                                  <i className="fa-solid fa-location-dot"></i>{" "}
                                  {res.locationId?.locationName}
                                </span>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {jobs.length > 0 && (
                      <div className="mt-2">
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-5">
                    {isJobDetails && (
                      <div
                        class="text-center"
                        style={{
                          position: "absolute",
                          left: "45%",
                          padding: "15px",
                          top: "50%",
                        }}
                      >
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    <div className="content">
                      <div
                        className="tab-content total_count_box"
                        id="v-pills-tabContent"
                      >
                        <div
                          className={
                            "tab-pane fade" +
                            (jobid.length !== 0 ? "show active" : "")
                          }
                          id="first"
                          role="tabpanel"
                          aria-labelledby="first-tab"
                        >
                          <h5 className="awrded_job_title">
                            {jobDetails?.jobTitle}{" "}
                            {/* <span>
                              {" "}
                              <i className="fa-solid fa-clock"></i>{" "}
                              {jobDetails?.jobDate}
                            </span> */}
                          </h5>
                          <p className="adderess">
                            {jobDetails?.locationId?.locationName}
                          </p>

                          {/* <p className="Job Type">
                            {jobDetails?.locationId?.jobType}
                          </p> */}
                          <div className="desc">
                            <div className="flexBox">
                              <p>
                                <span>Status</span> :{" "}
                                {jobDetails?.status == 0
                                  ? "Awaiting Approval"
                                  : jobDetails?.status == 1
                                  ? "Approved"
                                  : jobDetails?.status == 2
                                  ? "De-Activate(Reject)"
                                  : jobDetails?.status == 3
                                  ? "Cancel"
                                  : jobDetails?.status == 4
                                  ? "Ongoing"
                                  : jobDetails?.status == 5
                                  ? "Closed"
                                  : jobDetails?.status == 6
                                  ? "Upcoming"
                                  : jobDetails?.status == 7
                                  ? "Previous"
                                  : "N/A"}
                              </p>
                              {jobDetails.jobType !== 3 ? (
                                <div className="row">
                                  <div className="col-md-6">
                                    <p>
                                      <span>Job Start Date</span> :{" "}
                                      {moment
                                        .utc(jobDetails?.jobDate)
                                        .format("DD-MM-YYYY")}
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p>
                                      <span>Job End Date</span> :{" "}
                                      {moment
                                        .utc(jobDetails?.jobEndDate)
                                        .format("DD-MM-YYYY")}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-md-12">
                                    <p>
                                      <span>Job Date</span> :{" "}
                                      {jobDetails.customDates.map(
                                        (date) =>
                                          moment
                                            .utc(date)
                                            .format("DD-MM-YYYY") + " , "
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                              <div className="row">
                                <div className="col-md-6">
                                  <p>
                                    <span>Transpotation</span> :{" "}
                                    {jobDetails?.isVehicleRequired
                                      ? "Own Vehicle"
                                      : "Doesn't matter"}
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    <span>Dress Code</span> :{" "}
                                    {jobDetails?.dressCode == 1
                                      ? "HV Vest"
                                      : jobDetails?.dressCode == 2
                                      ? "Casual"
                                      : jobDetails?.dressCode == 3
                                      ? "Suited"
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    <span>Job Type</span> :{" "}
                                    {jobDetails?.jobType == 1
                                      ? "One Day"
                                      : jobDetails?.jobType == 2
                                      ? "Long Term"
                                      : jobDetails?.jobType == 3
                                      ? "Custom"
                                      : ""}
                                  </p>
                                </div>
                              </div>
                              <p>
                                <span>Job Description</span> :{" "}
                                {jobDetails?.description}
                              </p>
                            </div>
                          </div>
                          <div className="mapBox">
                            <Map jobDetails={jobDetails} />
                          </div>

                          <div className="total_count_box mt-4">
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <div
                                className="handle_tool_box"
                                style={{ float: "right" }}
                              >
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={open}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Clients who require reoccurring security details that extend beyond 7 days will initially be billed for 7 days. On the 7th day, clients will be required to reload their Ewallets in order to secure payment for the remaining days. For example, ABC company requires security for 10 consecutive days. Let's assume the total cost of the job is $2000. In this scenario, clients will be required to pay the cost for 7 days first, and let's assume that cost is $1500. In this case the client will be billed the remaining $500 on the 7th day."
                                >
                                  <span
                                    onClick={handleTooltipOpen}
                                    className="handle_tooltip_span"
                                  >
                                    {" "}
                                    <i class="fa fa-info-circle"></i>
                                  </span>
                                </Tooltip>
                              </div>
                            </ClickAwayListener>

                            <div className="count_inner_box">
                              <div className="lBox">
                                <div className="box">
                                  <h6>Total Amount </h6>
                                  <span>
                                    $
                                    {jobDetails?.withoutTotalAmount?.toFixed(2)}
                                  </span>
                                </div>
                                <div className="box">
                                  <h6>Total Paid</h6>
                                  <span>
                                    $
                                    {jobDetails?.withoutTotalAmount?.toFixed(2)}
                                  </span>
                                </div>
                                <div className="box">
                                  <h6>Per Shift Hours</h6>
                                  <span>{totalHrs.toFixed(2)}</span>
                                </div>
                              </div>
                              <div className="rBox">
                                <div className="box">
                                  <h6>Total HST</h6>
                                  <span>
                                    ${jobDetails?.totalHst?.toFixed(2)}
                                  </span>
                                </div>
                                <div className="box">
                                  <h6>Amount Used</h6>
                                  <span>
                                    ${jobDetails?.totalAmount?.toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="enote_shift_rBox">
                      {enotes?.length > 0 ? (
                        enotes?.map((enote) => {
                          return (
                            <div className="reach_box">
                              <h5>
                                {enote?.guardId?.firstName +
                                  " " +
                                  enote?.guardId?.lastName}
                              </h5>
                              <h6>{enote?.title}</h6>
                              <p>{enote?.description}</p>
                              {enote?.file !== undefined && (
                                <>
                                  <a href={playAudio(enote?.file)} download>
                                    Download Audio
                                  </a>
                                </>
                              )}
                              <p>
                                {moment(enote?.createdAt).format(
                                  "DD-MM-YYYY hh:mm, A"
                                )}
                              </p>
                            </div>
                          );
                        })
                      ) : (
                        <div className="not_data_found">
                          <h5>No Enotes Found</h5>
                        </div>
                      )}
                    </div>
                    {enotes.length > 0 && (
                      <div className="mt-2">
                        <Pagination
                          color="primary"
                          activePage={enotepageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalEnoteItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handleEnotePageChange(e)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="nodata_img">
                  <img
                    src={NoDataImg}
                    className="img-fluid"
                    alt="No Data Found"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {/* )} */}

      <Modal
        className="guard_profile_modal"
        size="l"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {" "}
            Guard Details{" "}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="cardBox">
            <div className="row">
              <div
                className="col-md-12 mb-4"
                style={{ justifyContent: "center", display: "flex" }}
              >
                <div class="guard-imgBox">
                  <img
                    src={
                      guardData?.profileImage !== "" &&
                      guardData?.profileImage !== undefined
                        ? url +
                          "media/preview?filename=" +
                          guardData?.profileImage
                        : Img
                    }
                    alt="image profile"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.firstName}
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.lastName}
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.email}
                    placeholder="Email"
                    maxLength={25}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.mobileNumber}
                    placeholder="Mobile Number"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Experience</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={daysInExp > 365 ? experience : "0-2 years"}
                    placeholder="Experience"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>License Number</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.licenseNumber}
                    placeholder="License Number"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>License Image</label>
                  {guardData?.securityRegistrationDocument !== "" &&
                  guardData?.securityRegistrationDocument !== undefined &&
                  guardData?.securityRegistrationDocument.split(".").at(-1) ===
                    "pdf" ? (
                    <a
                      target="_blank"
                      href={
                        url +
                        "media/preview?filename=" +
                        guardData?.securityRegistrationDocument
                      }
                    >
                      <img src={Pdf} className="img-fluid licenceImg" />
                    </a>
                  ) : (
                    <img
                      style={{
                        width: "150px",
                        height: "100px",
                        marginLeft: "20px",
                      }}
                      src={
                        guardData?.securityRegistrationDocument !== "" &&
                        guardData?.securityRegistrationDocument !== undefined
                          ? url +
                            "media/preview?filename=" +
                            guardData?.securityRegistrationDocument
                          : Img
                      }
                      alt="image profile"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllJobs;
