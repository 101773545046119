import React, { useEffect, useState, useRef } from "react";
// import Navbar from '../../Pages/Header/Navbar';
import Header from "../../components/Header/Header";
// import Footer from '../../Pages/Footer/Footer';
import messImg from "../../assets/img/messImg.png";
// import { FaPaperPlane } from 'react-icons/fa';
// import { Link } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import "./chats.scss";
import Cookies from "universal-cookie";
import { SOCKET_URL } from "../../api/Constant";
import io from "socket.io-client";
import moment from "moment";
import NoDataImg from "../../assets/img/No chats found.png";
import { toast } from "react-toastify";

const Chat = () => {
  const [message, setMessage] = useState("");
  const [room, setSelectedRoom] = useState("");
  const [messageList, setMessageList] = useState([]);
  const [socket, setSocket] = useState(null);
  // const [called, setCalled] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  let [roomList, setRoomList] = useState([]);
  const cookie = new Cookies();
  const userId = cookie.get("userId");
  const customerInfo = cookie.get("customerInfo");
  const messagesEndRef = useRef(null);
  const [searchParams] = useSearchParams();
  let called = false;

  useEffect(() => {
    setSocket(io(SOCKET_URL));
    updateMessageInput("");

    // scrollToBottom();
    // setToken(sessionStorage.getItem("token"))
  }, []);
  // const executeScroll = () => messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  const scrollToBottom = () => {
    if (messageList && messageList.length != 0 && room) {
      messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  // subscribe to the socket event
  useEffect(() => {
    if (!socket) return;
    socket.on("connect", () => {
      setSocketConnected(socket.connected);
      subscribeToDateEvent();
      updateRoomList();
    });
    socket.on("disconnect", () => {
      setSocketConnected(socket.connected);
    });
    handleSocketConnection();
  }, [socket]);

  // manage socket connection
  const handleSocketConnection = () => {
    if (socketConnected) socket.disconnect();
    else {
      socket.connect();
    }
  };

  // Function to update the list of rooms using socket.io
  const updateRoomList = () => {
    socket.emit("get_rooms", { userId: userId });
    socket.on("rooms", (data) => {
      //console.log("room list====", data);
      setRoomList(data);
      scrollToBottom();
      if (searchParams.get("jobId") !== null) {
        filterRoom(searchParams.get("jobId"), data);
        called = true;
      }
    });
    socket.on("new_group", (newGroup) => {
      //console.log("newGroup list====", newGroup);
    });
    socket.on("receiveMessage", (receiveMessage) => {
      // //console.log('receiveMessage list====', receiveMessage)
      if (messageList.indexOf(receiveMessage.lastMessage) === -1) {
        messageList.push(receiveMessage.lastMessage);
        setMessageList(messageList);
        scrollToBottom();
      }
    });
    socket.on("deliveredMessage", (deliveredMessage) => {
      //console.log("deliveredMessage list====", deliveredMessage);
    });
  };
  // subscribe to socket date event
  const subscribeToDateEvent = () => {
    socket.emit("init", {
      userId: userId,
      userName: customerInfo.firstName + " " + customerInfo.lastName,
      type: "customer",
    });
    updateRoomList();
  };

  // Function to update the message input state
  const updateMessageInput = (input) => {
    setMessage(input);
  };

  // Function to handle key press event (Enter key)
  const handleKeyPress = (event) => {
    if ((event && event.key === "Enter") || event === message) {
      if (message === "") {
        return;
      }
      // Emit a message to the server to send the message to the room
      socket.emit("sendMessage", {
        roomId: room._id,
        jobId: room.jobId._id,
        senderId: userId,
        message: message,
        senderType: "customer",
      });

      // Listen for the 'messageSave' event to handle the saved message
      socket.on("messageSave", (data) => {
        if (messageList.indexOf(data.lastMessage) === -1) {
          messageList.push(data.lastMessage);
          setMessageList(messageList);
        }
        updateMessageInput("");

        // Listen for the 'receiveMessage' event to handle received messages
        socket.on("receiveMessage", (receiveMessage) => {
          if (messageList.indexOf(receiveMessage.lastMessage) === -1) {
            messageList.push(receiveMessage.lastMessage);
            setMessageList(messageList);
            scrollToBottom();
          }
        });

        // Listen for the 'deliveredMessage' event to handle delivered messages
        socket.on("deliveredMessage", (deliveredMessage) => {
          scrollToBottom();
        });
      });

      // Update the room list and message list
      updateRoomList();
    }
  };

  // Function to get messages from a selected room
  const getMessages = (selectedRoom) => {
    var lastMessageTimeStamp = "";
    setSelectedRoom(selectedRoom);

    // If there are existing messages, get the timestamp of the last message
    if (message && message.length !== 0) {
      lastMessageTimeStamp = message[0].timeStamp;
    }

    // Emit a request to get chats for the selected room
    socket.emit("get_chats", {
      userId: userId,
      jobId: selectedRoom?.jobId?._id,
      roomId: selectedRoom?._id,
      lastMessageTimeStamp,
    });

    // Listen for the 'chats' event to get the chat data and update the message list
    socket.on("chats", (data) => {
      setMessageList(data.docs);
      scrollToBottom();
    });
    scrollToBottom();
  };

  // Function to filter rooms based on a given ID
  const filterRoom = (id, room) => {
    const f = room?.filter((r) => {
      return id === r?.jobId?._id;
    });

    if (f?.length > 0) {
      const id2 = room?.indexOf(f[0]);
      room.splice(id2, 1);
      room.unshift(f[0]);
      roomList = [];
      setRoomList(room);
      getMessages(f[0]);
    } else {
      if (!called) {
        toast.warn("Chat not found");
      }
    }
  };

  // Function to get the name of a participant by their ID
  const getName = (id) => {
    for (let i = 0; i < room.participants.length; i++) {
      if (room.participants[i]._id === id) {
        return (
          room.participants[i].firstName + " " + room.participants[i].lastName
        );
      }
    }
  };

  return (
    <>
      <Header />
      <section className="city_mall">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="content">
                <div className="innerBox">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Job Chats</h5>
                    </div>
                    {room && room.jobId && room.jobId.jobTitle && (
                      <div className="col-md-8">
                        <div className="title">
                          <div className="heading">
                            <h1>
                              {room && room.jobId && room.jobId.jobTitle
                                ? room.jobId.jobTitle
                                : ""}
                            </h1>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="left_sidebar">
                      <div className="nav_box">
                        <div
                          className="nav flex-column nav-pills"
                          id="v-pills-tab"
                          role="tablist"
                          aria-orientation="vertical"
                        >
                          {roomList.map((item, index) => {
                            return (
                              <button
                                key={index}
                                className={
                                  "nav-link" +
                                  (room?._id === item?._id ? " active" : "")
                                }
                                id="first-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#first"
                                type="button"
                                role="tab"
                                aria-controls="first"
                                aria-selected="true"
                                onClick={(e) => getMessages(item)}
                              >
                                {item.jobId && item.jobId.jobTitle
                                  ? item.jobId.jobTitle
                                  : ""}
                                <span>
                                  {" "}
                                  {item.lastMessage && item.lastMessage.message
                                    ? item.lastMessage.message
                                    : "No Last Message"}
                                </span>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="tab-content" id="v-pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="first"
                        role="tabpanel"
                        aria-labelledby="first-tab"
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="historyBox">
                              <div className="MessageBox">
                                <div className="row">
                                  {messageList.length !== 0 ? (
                                    messageList.map((item, index) => {
                                      return (
                                        <div
                                          className="col-md-12"
                                          key={index}
                                          style={{
                                            float: "left",
                                            clear: "both",
                                          }}
                                        >
                                          <div
                                            ref={messagesEndRef}
                                            className={
                                              item.senderId === userId
                                                ? "receiverBox"
                                                : "senderBox"
                                            }
                                          >
                                            {item.senderId != userId && (
                                              <h6>{getName(item.senderId)}</h6>
                                            )}
                                            {item.senderId === userId && (
                                              <h6>
                                                {customerInfo.firstName +
                                                  " " +
                                                  customerInfo.lastName}
                                              </h6>
                                            )}
                                            <p>{item.message}</p>
                                            <div className="time">
                                              <span>
                                                {moment
                                                  .utc(item.timeStamp)
                                                  .fromNow()}
                                              </span>
                                            </div>
                                            {item.senderId != userId && (
                                              <img
                                                src={messImg}
                                                alt="messageImage"
                                                className="img-fluid"
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })
                                  ) : (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="nodata_img">
                                          <img
                                            src={NoDataImg}
                                            className="img-fluid"
                                            alt="No Data Found"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {room && room.jobId && room.jobId.jobTitle && (
                            <div className="col-md-12">
                              <div className="textMessage">
                                <div className="form-group">
                                  <input
                                    type="text"
                                    value={message}
                                    onKeyPress={(e) => handleKeyPress(e)}
                                    onChange={(e) =>
                                      updateMessageInput(e.target.value)
                                    }
                                    className="form-control"
                                    id="exampleInputtext"
                                    maxLength=""
                                    aria-describedby="textHelp"
                                    placeholder="Type Message Here..."
                                  />
                                  <a onClick={(e) => handleKeyPress(message)}>
                                    <i className="fa-solid fa-paper-plane"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Footer /> */}
    </>
  );
};

export default Chat;
