import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import { toast } from "react-toastify";
import "./my_rating.scss";
import Cookies from "universal-cookie";
import { API_GetRating } from "../../api/Constant";
import ApiClient from "../../api/apiClient";
import moment from "moment";
const cookie = new Cookies();
const tdata = JSON.parse(localStorage.getItem("userInfo"));
const token = tdata?.tokens?.refresh?.token;
const userId = tdata?.user?.id;

const MyRating = () => {
  const [ratings, setRatings] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      callGetAllRatingList();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      // navigate(`/login`);
    }
  }, []);

  // Function to fetch and populate a list of ratings
  async function callGetAllRatingList() {
    const data = "";

    // Perform the API call to get the list of ratings
    const res = await ApiClient.get(API_GetRating, data, `Bearer ${token}`);

    // Handle API response
    if (res?.data?.code === 401) {
      // If unauthorized, navigate to login
      toast.error(res?.data?.message);
      navigate(`/login`);
    } else if (res.data.code === 200) {
      // If success, set the retrieved ratings in the state
      setRatings(res.data.data.docs);
    } else {
      // If any other response, show an error toast
      toast.error(res.message);
    }
  }

  return (
    <>
      <Header />
      <section className="my_Rating">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="rateBox">
                <div className="row">
                  <div className="col-md-12">
                    <h5>My Ratings</h5>
                  </div>
                </div>
                <div className="row">
                  {ratings.length !== 0
                    ? ratings.map((rating, i) => {
                        return (
                          <div className="col-md-6 col-lg-3">
                            {/* <Link to={`/jobRating/${rating.id}`}> */}
                            <Link to={`/jobRating`}>
                              <div className="flexBox">
                                <h6>{rating?.jobId?.jobTitle}</h6>
                                <p className="mapText">
                                  <i className="fas fa-map-marker-alt"></i>
                                  {rating?.jobId?.locationId?.locationName}
                                </p>
                                <p className="starRate">
                                  Job Rating
                                  {new Array(rating?.rating)
                                    .fill(null)
                                    .map(() => (
                                      <i className="fas fa-star"></i>
                                    ))}
                                  {/* <i
                                    className="fas fa-star"
                                    style={{ marginLeft: "6px" }}
                                  ></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i>
                                  <i className="fas fa-star"></i> */}
                                </p>
                                <p className="date">
                                  <i className="fas fa-calendar dateIcon"></i>
                                  {moment
                                    .utc(rating?.createdAt)
                                    .format("DD-MM-YYYY")}
                                </p>
                              </div>
                            </Link>
                          </div>
                        );
                      })
                    : "No Data"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyRating;
