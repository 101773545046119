import React from "react";
import "./wel_header.scss";
import InnerHead_img from "../../assets/img/inner_header.png";

const Wel_Header = () => {
  return (
    <>
      <section className="inner_header_section fixed-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <a href="/">
                <div className="inner_imgbox">
                  <img src={InnerHead_img} className="img-fluid" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wel_Header;
