import React, { useState, useEffect } from "react";
import "./header.scss";
import Logo from "../../assets/img/logo.png";
import UserIcon from "../../assets/img/user.png";
import { Link, useNavigate } from "react-router-dom";
import ApiClient from "../../api/apiClient";
import { API_LOGOUT, API_GetProfile, url } from "../../api/Constant";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const Header = () => {
  const navigate = useNavigate();
  let [menu, setMenu] = useState(false);
  const [cusprofile, setCusProfile] = useState("");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

 // This useEffect hook is used to perform actions when the component mounts.
// It checks if the 'userInfo' is available. If it is, it calls 'callMyProfileApi',
// otherwise, it displays a warning message and navigates to the login page.
useEffect(() => {
  if (userInfo) {
    callMyProfileApi(); // Call the function to retrieve user profile data
  } else {
    // Display a warning toast and navigate to the login page
    toast.warning(
      "Your session has expired. Kindly log in again.",
      "yellow"
    );
    navigate(`/login`);
  }
}, []);

// This asynchronous function handles the logout process.
async function Logout() {
  const token = localStorage.getItem("userInfo");
  const refToken = JSON.parse(token);
  const data = {
    refreshToken: refToken.tokens.refresh.token,
  };
  
  // Make a POST request to the logout API endpoint with the refresh token.
  const response = await ApiClient.post(
    API_LOGOUT,
    data,
    "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
  );
  console.log(response, "logout res");

  if (response.data.code) {
    // Clear local storage, navigate to login, and display a success toast
    localStorage.clear();
    navigate(`/login`);
    toast.success(response.data.message, "green");
  } else {
    // Display an error toast if the logout request fails
    toast.error(response.data.message, "red");
  }
}

// This asynchronous function retrieves the user's profile data.
async function callMyProfileApi() {
  const userInfo = localStorage.getItem("userInfo");
  const refToken = JSON.parse(userInfo);
  const data = "";
  
  // Make a GET request to the user profile API endpoint.
  const response = await ApiClient.get(
    API_GetProfile + refToken.user.id,
    data,
    `Basic ${refToken.tokens.refresh.token}`
  );
  console.log("get profile res", response.data.data);

  if (response.data.code === 200) {
    // Set the retrieved user profile data using the 'setCusProfile' state function.
    setCusProfile(response?.data?.data);
  } else {
    // Display an error toast if the profile request fails
    toast.error(response?.data?.message);
  }
}

// This function handles the delete action and displays a confirmation modal.
const deleteList = async (id) => {
  Swal.fire({
    title: "Are you sure",
    text: "You want to logout?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
  }).then((result) => {
    if (result.isConfirmed) {
      Logout(); // Call the Logout function if the user confirms
    }
  });
};


  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light sticky-top">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={Logo} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/alljobs">
                  <span className="left">
                    <i className="fas fa-briefcase"></i>
                  </span>{" "}
                  My Jobs
                </Link>
              </li>
              <li className="nav-item">
            </li>
              <li className="nav-item">
                <Link className="nav-link" to="/addJob">
                  <span className="left">
                    <i className="fas fa-briefcase"></i>
                  </span>{" "}
                  Post a job
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/chat">
                  <span className="left">
                    <i className="fas fa-comment"></i>
                  </span>{" "}
                  Messages
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link notifi_icon" to="/notifications">
                  <span className="left">
                    <i class="fa-solid fa-bell"></i>
                  </span>{" "}
                  <span className="alert_message"></span>
                </Link>
              </li>
              <li className="nav-item">
                <a
                  className={
                    "nav-link hamburger" + (menu === true ? " active" : "")
                  }
                  onClick={(e) => {
                    setMenu((menu = !menu));
                  }}
                  id="sidebar-toggle"
                  type="button"
                  data-bs-toggle="toggle"
                  aria-expanded="false"
                >
                  <span></span>
                </a>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/my_profile">
                  <span className="userImg">
                    {cusprofile?.profileImage !== "" &&
                    cusprofile?.profileImage !== undefined ? (
                      <img
                        src={`${url}/media/preview?filename=${cusprofile?.profileImage}`}
                        style={{ width: "28px", height: "28px" }}
                      />
                    ) : (
                      <i
                        style={{ color: "#06868c" }}
                        className="fas fa-user"
                      ></i>
                    )}
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={"right_sidebar" + (menu === true ? " add_sidebar" : "")}
          id="sidebar_wrapper"
        >
          <div className="sidebar_box">
            <ul className="sidebar_menu">
              <li>
                <Link className="sidebar-item" to="/my_profile">
                  <span className="left">
                    <i className="fas fa-user"></i>
                  </span>
                  My Profile
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/work_location">
                  <span className="left">
                    <i className="fas fa-map-marker-alt"></i>
                  </span>
                  My Locations
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/mall_invoice">
                  <span className="left">
                    <i className="fas fa-coins"></i>
                  </span>
                  My Billings
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/wallet_balance">
                  <span className="left">
                    <i className="fas fa-credit-card"></i>
                  </span>
                  My Wallet
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/notifications">
                  <span className="left">
                    <i className="fas fa-bell"></i>
                  </span>
                  Alerts
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/alljobs">
                  <span className="left">
                    <i className="fas fa-calendar"></i>
                  </span>
                  Job History
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/payment">
                  <span className="left">
                    <i className="fas fa-credit-card"></i>
                  </span>
                  Payments
                </Link>
              </li>
              <li>
                <Link className="sidebar-item" to="/setting">
                  <span className="left">
                    <i className="fas fa-cog"></i>
                  </span>
                  Settings
                </Link>
              </li>
              <li>
                {/* <Link className="sidebar-item" to="/support"> */}
                <Link className="sidebar-item" to="/report_issue">
                  <span className="left">
                    <i className="fas fa-phone-alt"></i>
                  </span>
                  Support
                </Link>
              </li>
              <div className="logout">
                <button onClick={() => deleteList()}>Logout</button>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
