import React from 'react';
import Logoimg from '../../assets/img/logo.png';
import './logo.scss';

const LogoIcon = () => {
  return (
    <>
      <section className="logoIcon">
         <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="imgBox">
                        <img src={Logoimg} className="img-fluid" alt="Logo" />
                    </div>
                </div>
            </div>
         </div>
     </section> 
    </>
  )
}

export default LogoIcon;
