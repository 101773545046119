import React, { useState, useRef, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  LoadScriptNext,
} from "@react-google-maps/api";

const Map = ({ jobDetails, height, width }) => {
  const [map, setMap] = useState({
    zoom: 15,
  });

  // Map open function
  const openMap = (loc) => {
    window.open(`https://maps.google.com/maps?q=${loc}&z=15`, "_blank");
  };

  return (
    <>
      <GoogleMap
        mapContainerStyle={{
          width: width ? width : "100%",
          height: height ? height : "400px",
        }}
        center={{
          lat: jobDetails?.locationId?.lat,
          lng: jobDetails?.locationId?.long,
        }}
        zoom={map.zoom}
        onLoad={(map) => setMap(map)}
      >
        <Marker
          onClick={() => {
            openMap(jobDetails?.locationId?.locationName);
          }}
          icon={
            "https://res.cloudinary.com/df7gwlrj4/image/upload/c_scale,h_32,w_32/v1682486049/logoicon_bznp2l.png"
          }
          position={{
            lat: jobDetails?.locationId?.lat,
            lng: jobDetails?.locationId?.long,
          }}
          title={jobDetails?.locationId?.locationName}
          url={`https://maps.google.com/maps?q=${jobDetails?.locationId?.locationName}&z=15&output=embed&icon=https://maps.google.com/mapfiles/kml/shapes/parking_lot_maps.png`}
        />
      </GoogleMap>
    </>
  );
};

export default Map;
