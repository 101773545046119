import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./privacy.scss";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import ApiClient from "../../api/apiClient";
import { API_PrivacyPolicy } from "../../api/Constant";
import parse from "html-react-parser";

const Privacy = () => {
  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  const navigate = useNavigate();
  const [description, setDescription] = React.useState("");

  React.useEffect(() => {
    if (token) {
      callPrivacyPolicy();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
  }, []);

  // call Privacy Policy API
  async function callPrivacyPolicy() {
    const data = "";
    const res = await ApiClient.get(
      API_PrivacyPolicy,
      data,
      "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
    );
    //console.log("privacy policy description", res);
    if (res.data.code === 200) {
      setDescription(res.data.data.description);
    } else {
      setDescription(res.data.message);
    }
  }

  return (
    <>
      <section className="privacy">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="content">
                <div className="title">
                  <div className="heading">
                    <h1>Privacy Policy</h1>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: parse(description) }}>
                    {/* Marzipan tootsie roll oat cake croissant muffin gingerbread tart. Cotton candy lemon drops shortbread caramels pudding oat cake macaroon dragée wafer. Pastry cake topping dessert icing. Bear claw tart topping shortbread bear claw sugar plum liquorice bonbon cake.Pastry cake topping dessert icing. Bear claw tart topping shortbread bear claw sugar plum liquorice bonbon cake */}
                  </p>
                  <p>
                    {/* Marzipan tootsie roll oat cake croissant muffin gingerbread tart. Cotton candy lemon drops shortbread caramels pudding oat cake macaroon dragée wafer. Pastry cake topping dessert icing. Bear claw tart topping shortbread bear claw sugar plum liquorice bonbon cake.Pastry cake topping dessert icing. Bear claw tart topping shortbread bear claw sugar plum liquorice bonbon cake */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Privacy;
