import React, { useState } from "react";
import LogoIcon from "../../components/Logo/Logo";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import { Link } from "react-router-dom";
import "./long_termJob.scss";

const LongTermJob = () => {
  const [title, setTitle] = useState("");
  const [jobDate, setJobDate] = useState("");
  const [shiftOneStartTime, setShiftOneStartTime] = useState("");
  const [shiftOneEndTime, setShiftOneEndTime] = useState("");
  const [shiftTwoStartTime, setShiftTwoStartTime] = useState("");
  const [shiftTwoEndTime, setShiftTwoEndTime] = useState("");

  const [ErrorTitle, setErrorTitle] = useState(null);
  const [ErrorJobDate, setErrorJobDate] = useState(null);
  const [ErrorShiftOneStartTime, setErrorShiftOneStartTime] = useState(null);
  const [ErrorShiftOneEndTime, setErrorShiftOneEndTime] = useState(null);
  const [ErrorShiftTwoStartTime, setErrorShiftTwoStartTime] = useState(null);
  const [ErrorShiftTwoEndTime, setErrorShiftTwoEndTime] = useState(null);

  const _title = (num) => {
    if (num === "") {
      setErrorTitle("*Please enter job title.");
    } else {
      setErrorTitle(null);
    }
  };

  const _jobDate = (num) => {
    if (num === "") {
      setErrorJobDate("*Please enter job date.");
    } else {
      setErrorJobDate(null);
    }
  };

  const _shiftOneStartTime = (num) => {
    if (num === "") {
      setErrorShiftOneStartTime("*Please enter shift 1 start time.");
    } else {
      setErrorShiftOneStartTime(null);
    }
  };

  const _shiftOneEndTime = (num) => {
    if (num === "") {
      setErrorShiftOneEndTime("*Please enter shift 1 end time.");
    } else {
      setErrorShiftOneEndTime(null);
    }
  };

  const _shiftTwoStartTime = (num) => {
    if (num === "") {
      setErrorShiftTwoStartTime("*Please enter shift 2 start time.");
    } else {
      setErrorShiftTwoStartTime(null);
    }
  };

  const _shiftTwoEndTime = (num) => {
    if (num === "") {
      setErrorShiftTwoEndTime("*Please enter shift 2 end time.");
    } else {
      setErrorShiftTwoEndTime(null);
    }
  };
  const validate = (values) => {
    console.log(
      title,
      " ",
      jobDate,
      " ",
      shiftOneStartTime,
      " ",
      shiftOneEndTime,
      " ",
      shiftTwoStartTime,
      " ",
      shiftTwoEndTime
    );
  };
  return (
    <>
      <Wel_Header />
      <section className="long_TermJob">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-9">
              <div className="signupForm">
                <form>
                  <div className="job_title">
                    <div className="row align-items-lg-center ">
                      <div className="col-md-12 text-center">
                        {/* <Link to="/edit_location" className="backBtn">
                          <span className="left">
                            <i className="fas fa-arrow-left"></i>
                          </span>
                        </Link> */}
                        <h4>Add Long Term Job</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-md-start  justify-content-center">
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Job Title"
                          value={title}
                          onChange={(e) => {
                            setTitle(e.target.value);
                            _title(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-briefcase"></i>
                        </span>
                      </div>
                      {ErrorTitle != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {ErrorTitle}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>Job Location</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>Long Term Job</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>Transportation</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>Dress Code</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div>
                    {/* <div className="col-4">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>Rotation</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div> */}
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>No. Of Guards</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>Shift Per Day</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="guard_title">
                        <h5>Guard Profile</h5>
                        <a href="#">
                          <span>
                            <i className="fas fa-plus-circle"></i>
                          </span>
                          <span className="add-span">Add</span>
                        </a>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>Speciality</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>Experience</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="form-group">
                        <select className="form-control">
                          <option selected>Rate</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </select>
                        <span>
                          <i className="fas fa-caret-down"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row align-items-center">
                      <div className="col-md-2">
                        <h6 className="title_inner">Job Details</h6>
                      </div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby=""
                            placeholder="Job Date"
                            value={jobDate}
                            onChange={(e) => {
                              setJobDate(e.target.value);
                              _jobDate(e.target.value);
                            }}
                          />
                          <span>
                            <i className="fas fa-calendar"></i>
                          </span>
                        </div>
                        {ErrorJobDate != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {ErrorJobDate}
                          </p>
                        </div>
                      ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-2">
                        <h6 className="title_inner">Shifts Details</h6>
                      </div>
                      <div className="col-12 col-md-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              aria-describedby=""
                              placeholder="Shift 1 Start Time"
                              value={shiftOneStartTime}
                              onChange={(e) => {
                                setShiftOneStartTime(e.target.value);
                                _shiftOneStartTime(e.target.value);
                              }}
                            />
                            <span>
                              <i className="fas fa-clock"></i>
                            </span>
                          </div>
                          {ErrorShiftOneStartTime != null ? (
                          <div>
                            <p
                              style={{
                                color: "red",
                                fontSize: 13,
                                fontFamily: "GothamMedium",
                                marginHorizontal: 35,
                                textAlign: "left",
                              }}
                            >
                              {ErrorShiftOneStartTime}
                            </p>
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12 col-md-4">
                          <div className="form-group">
                              <input
                              type="text"
                              className="form-control"
                              aria-describedby=""
                              placeholder="Shift 1 End Time"
                              value={shiftOneEndTime}
                              onChange={(e) => {
                                  setShiftOneEndTime(e.target.value);
                                  _shiftOneEndTime(e.target.value);
                              }}
                              />
                              <span>
                              <i className="fas fa-clock"></i>
                              </span>
                          </div>
                              {ErrorShiftOneEndTime != null ? (
                              <div>
                              <p
                                  style={{
                                  color: "red",
                                  fontSize: 13,
                                  fontFamily: "GothamMedium",
                                  marginHorizontal: 35,
                                  textAlign: "left",
                                  }}
                              >
                                  {ErrorShiftOneEndTime}
                              </p>
                              </div>
                          ) : null}
                      </div>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-2"></div>
                      <div className="col-12 col-md-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby=""
                            placeholder="Shift 2 Start Time"
                            value={shiftTwoStartTime}
                            onChange={(e) => {
                              setShiftTwoStartTime(e.target.value);
                              _shiftTwoStartTime(e.target.value);
                            }}
                          />
                          <span>
                            <i className="fas fa-clock"></i>
                          </span>
                        </div>
                          {ErrorShiftTwoStartTime != null ? (
                            <div>
                              <p
                                style={{
                                  color: "red",
                                  fontSize: 13,
                                  fontFamily: "GothamMedium",
                                  marginHorizontal: 35,
                                  textAlign: "left",
                                }}
                              >
                                {ErrorShiftTwoStartTime}
                              </p>
                            </div>
                          ) : null}
                      </div>
                      <div className="col-12 col-md-4">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              aria-describedby=""
                              placeholder="Shift 2 End Time"
                              value={shiftTwoEndTime}
                              onChange={(e) => {
                                setShiftTwoEndTime(e.target.value);
                                _shiftTwoEndTime(e.target.value);
                              }}
                            />
                            <span>
                              <i className="fas fa-clock"></i>
                            </span>
                          </div>
                          {ErrorShiftTwoEndTime != null ? (
                            <div>
                              <p
                                style={{
                                  color: "red",
                                  fontSize: 13,
                                  fontFamily: "GothamMedium",
                                  marginHorizontal: 35,
                                  textAlign: "left",
                                }}
                              >
                                {ErrorShiftTwoEndTime}
                              </p>
                            </div>
                          ) : null}
                      </div>
                    </div>
                  <div className="row justify-content-center">
                    <div className="col-md-6">
                      <div className="nextBtn">
                      {/* <Link to="/my_profile"> */}
                      <a type="button" onClick={() => validate()}>
                        Submit <span></span>
                      </a>
                      {/* </Link> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LongTermJob;
