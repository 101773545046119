import React from 'react';
// import {Link} from 'react-router-dom';
import Header from '../../components/Header/Header';
import './unawarded.scss';

const UnAwrdedJob = () => {
    return (
        <>
            <Header />
            <section className="unawrded_job">
                <div className="container">
                    <div className="row mb-2">
                        <div className="col-md-12">
                            <div className="dateFormat">
                                <div className="form-group me-2">
                                    <input
                                        type="date"
                                        className="form-control"
                                        aria-describedby="textHelp"
                                        name="to"
                                        placeholder="TO"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="date"
                                        className="form-control"
                                        aria-describedby="textHelp"
                                        name="to"
                                        placeholder="TO"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12 col-lg-12">
                            <div className="row justify-content-center">
                                <div className='col-md-3'>
                                    <div className="left_sidebar">
                                        <h5 style={{ marginBottom: '0' }}>All Jobs</h5>
                                        <div className="nav_box">
                                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                <button className="nav-link active" id="first-tab" data-bs-toggle="pill" data-bs-target="#first" type="button" role="tab" aria-controls="first" aria-selected="true">
                                                    Mall Security Job
                                                    <span><i className="fa-solid fa-location-dot"></i> 123,Pheonix Mall,City,State,PIN</span>
                                                </button>
                                                <button className="nav-link" id="two-tab" data-bs-toggle="pill" data-bs-target="#two" type="button" role="tab" aria-controls="two" aria-selected="false">
                                                    Mall Security Job
                                                    <span><i className="fa-solid fa-location-dot"></i> 123,Pheonix Mall,City,State,PIN</span>
                                                </button>
                                                <button className="nav-link" id="three-tab" data-bs-toggle="pill" data-bs-target="#three" type="button" role="tab" aria-controls="three" aria-selected="false">
                                                    Mall Security Job
                                                    <span><i className="fa-solid fa-location-dot"></i> 123,Pheonix Mall,City,State,PIN</span>
                                                </button>
                                                <button className="nav-link" id="four-tab" data-bs-toggle="pill" data-bs-target="#four" type="button" role="tab" aria-controls="four" aria-selected="false">
                                                    Mall Security Job
                                                    <span><i className="fa-solid fa-location-dot"></i> 123,Pheonix Mall,City,State,PIN</span>
                                                </button>
                                                <button className="nav-link" id="five-tab" data-bs-toggle="pill" data-bs-target="#five" type="button" role="tab" aria-controls="five" aria-selected="false">
                                                    Mall Security Job
                                                    <span><i className="fa-solid fa-location-dot"></i> 123,Pheonix Mall,City,State,PIN</span>
                                                </button>
                                                <button className="nav-link" id="six-tab" data-bs-toggle="pill" data-bs-target="#six" type="button" role="tab" aria-controls="six" aria-selected="false">
                                                    Mall Security Job
                                                    <span><i className="fa-solid fa-location-dot"></i> 123,Pheonix Mall,City,State,PIN</span>
                                                </button>
                                                <button className="nav-link" id="seven-tab" data-bs-toggle="pill" data-bs-target="#seven" type="button" role="tab" aria-controls="seven" aria-selected="false">
                                                    Mall Security Job
                                                    <span><i className="fa-solid fa-location-dot"></i> 123,Pheonix Mall,City,State,PIN</span>
                                                </button>
                                                <button className="nav-link" id="eight-tab" data-bs-toggle="pill" data-bs-target="#eight" type="button" role="tab" aria-controls="eight" aria-selected="false">
                                                    Mall Security Job
                                                    <span><i className="fa-solid fa-location-dot"></i> 123,Pheonix Mall,City,State,PIN</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-5'>
                                    <div className='content'>
                                        <div className="tab-content" id="v-pills-tabContent">
                                            <div className="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                                                <h5>Mall Security Job</h5>
                                                <p className='adderess'>123,street Name, City Name</p>
                                                <div className='desc'>
                                                    <div className='flexBox'>
                                                        <p><span>Status</span> : Unawarded</p>
                                                        <p><span>Start Time</span> : 10:00 AM</p>
                                                    </div>
                                                    <div className='flexBox spaceRight'>
                                                        <p><span>Speciality</span> : Mall Security</p>
                                                        <p><span>Guards</span> : No Guards</p>
                                                    </div>
                                                </div>
                                                <div className='mapBox'>
                                                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319" frameborder="0" style={{ color: 'red', borderRadius: '10px', width: '100%', height: '200px', border: '1px solid #d3d3d3' }}></iframe>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                                            </div>
                                            <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab">
                                            </div>
                                            <div className="tab-pane fade" id="four" role="tabpanel" aria-labelledby="four-tab">
                                            </div>
                                            <div className="tab-pane fade" id="five" role="tabpanel" aria-labelledby="five-tab">
                                            </div>
                                            <div className="tab-pane fade" id="six" role="tabpanel" aria-labelledby="six-tab">
                                            </div>
                                            <div className="tab-pane fade" id="seven" role="tabpanel" aria-labelledby="seven-tab">
                                            </div>
                                            <div className="tab-pane fade" id="eight" role="tabpanel" aria-labelledby="eight-tab">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="reach_box">
                                        <div className='shift_title'>
                                            <h6>Shift 1</h6>
                                            <span>10:30 AM,01/01/2021</span>
                                        </div>
                                        <div className='row align-items-center'>
                                            <div className='col-md-12'>
                                                <div className='box'>
                                                    <label for="" className="col-form-label" style={{ fontWeight: '700' }}>Guard 1 :</label>
                                                    <div className="form-group">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Parameter Search</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='box'>
                                                    <label for="" className="col-form-label" style={{ fontWeight: '700' }}>Guard 2 :</label>
                                                    <div className="form-group">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Parameter Search</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='box'>
                                                    <label for="" className="col-form-label" style={{ fontWeight: '700' }}>Guard 3 :</label>
                                                    <div className="form-group">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Parameter Search</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="reach_box">
                                        <div className='shift_title'>
                                            <h6>Shift 1</h6>
                                            <span>10:30 AM,01/01/2021</span>
                                        </div>
                                        <div className='row align-items-center'>
                                            <div className='col-md-12'>
                                                <div className='box'>
                                                    <label for="" className="col-form-label" style={{ fontWeight: '700' }}>Guard 1 :</label>
                                                    <div className="form-group">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Parameter Search</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='box'>
                                                    <label for="" className="col-form-label" style={{ fontWeight: '700' }}>Guard 2 :</label>
                                                    <div className="form-group">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Parameter Search</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='box'>
                                                    <label for="" className="col-form-label" style={{ fontWeight: '700' }}>Guard 3 :</label>
                                                    <div className="form-group">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Parameter Search</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="reach_box">
                                        <div className='shift_title'>
                                            <h6>Shift 1</h6>
                                            <span>10:30 AM,01/01/2021</span>
                                        </div>
                                        <div className='row align-items-center'>
                                            <div className='col-md-12'>
                                                <div className='box'>
                                                    <label for="" className="col-form-label" style={{ fontWeight: '700' }}>Guard 1 :</label>
                                                    <div className="form-group">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Parameter Search</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='box'>
                                                    <label for="" className="col-form-label" style={{ fontWeight: '700' }}>Guard 2 :</label>
                                                    <div className="form-group">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Parameter Search</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='box'>
                                                    <label for="" className="col-form-label" style={{ fontWeight: '700' }}>Guard 3 :</label>
                                                    <div className="form-group">
                                                        <select className="form-select" aria-label="Default select example">
                                                            <option selected>Parameter Search</option>
                                                            <option value="1">One</option>
                                                            <option value="2">Two</option>
                                                            <option value="3">Three</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UnAwrdedJob;