import React, { useState } from "react";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import { Link } from "react-router-dom";
import img404 from "../../assets/img/404.png";
import "./page404.scss";

const Page404 = () => {
  return (
    <>
      <Wel_Header />
      <section className="page_404">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="regForm">
                <div>
                  <img src={img404} className="img-fluid" />
                </div>
                <h3>Oops! This Page Is Not Found.</h3>
                <p>The requested page does not exist.</p>
                <div className="nextBtn">
                  <a href="/">
                    <i class="fa-solid fa-house"></i>
                    <span>Back to Home</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Page404;
