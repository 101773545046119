// import Facebook from "../../images/facebook.png";
// import Twitter from "../../images/twitter.png";
// import Linkedin from "../../images/linkedin.png";
// import LogoIcon from "../../images/thnkYouLogo.png";
// import LineIcon from "../../images/line.png";
// import circleLine from "../../images/circleLine.png";
import ArroB from "../../assets/img/arrowB.png";
import ArroT from "../../assets/img/arrowT.png";
import LineImg from "../../assets/img/thankU_line.png";
import UserIcon from "../../assets/img/thank_user.png";
import PdfIcon from "../../assets/img/thank_pdf.png";
import RightArrow from "../../assets/img/thank_right.png";
import BriefIcon from "../../assets/img/thank_showcase.png";
import Header_inner from "../../Pages/Sec_Header/Header_inner";
import thankpdf from "../../assets/img/thank_pdf-mob-.png";
import thankuuser from "../../assets/img/thank_user-mob-.png";
import thankrightmob from "../../assets/img/thank_right-mob-.png";
import thankushowcase from "../../assets/img/thank_showcase-mob.png";
// import WelcomeFooter from '../../Pages/Wel_Footer/WelcomeFooter';
import "./thankYou.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { FaEnvelope } from "react-icons/fa";

const ThankYou = () => {
  const [searchParams] = useSearchParams();
  const msgType = searchParams.get("isLicense");
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  return (
    <>
      <Header_inner />
      <section className="top_sec">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-md-11">
              <h1>Thank you for registering with GoGuard</h1>
              <p>
                We are pleased to inform you that your profile is under
                verification
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="thank_you">
        <img src={LineImg} alt="" className="img-fluid line_img" />
        <div className="container-fluid">
          {/* <div className="row">
            <div className="col-md-11">
              <h1>Thankyou for Registering GoGuard</h1>
              <p>We are pleased to inform you that your profile is under verification</p>
            </div>
          </div> */}
          <div className="row justify-content-center">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-3">
                  <div className="box">
                    <div className="midBox">
                      <div className="upperBox">
                        <h2>01.</h2>
                        <p>
                          We are pleased to inform you that your profile is
                          under verification
                        </p>
                        <img
                          src={ArroB}
                          alt=""
                          className="img-fluid icon ArroB"
                        />
                      </div>
                      <h4>
                        Profile <span>Approval</span>
                      </h4>
                      <img src={UserIcon} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="box1">
                    <div className="midBox">
                      <div className="upperBox">
                        <h2>02.</h2>
                        <p>You might be asked to add a few more documents</p>
                        <img src={ArroT} alt="" className="img-fluid ArroT" />
                      </div>
                      <h4>
                        Add <span>Documents</span>
                      </h4>
                      <img src={PdfIcon} alt="" className="img-fluid icon" />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="box">
                    <div className="midBox">
                      <div className="upperBox">
                        <h2>03.</h2>
                        <p>Your profile would be approved before Autumn'22</p>
                        <img
                          src={ArroB}
                          alt=""
                          className="img-fluid icon ArroB"
                        />
                      </div>
                      <h4>
                        Get <span>Approved</span>
                      </h4>
                      <img src={RightArrow} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="box1">
                    <div className="midBox">
                      <div className="upperBox">
                        <h2>04.</h2>
                        <p>
                          Our system goes live in Autumn'22 and you can post
                          jobs!!
                        </p>
                        <img src={ArroT} alt="" className="img-fluid ArroT" />
                      </div>
                      <h4>
                        Post <span>Jobs</span>
                      </h4>
                      <img src={BriefIcon} alt="" className="img-fluid icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="than_you_mob">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="box_mob">
                <img src={thankuuser} alt="" className="img-fluid" />
                <div className="upperBox_mob">
                  <h2>01.</h2>
                  <p>
                    We are pleased to inform you that your profile is under
                    verification
                  </p>
                  <img src={ArroB} alt="" className="img-fluid icon ArroB" />
                </div>
                <h4>
                  Profile <span>Approval</span>
                </h4>
              </div>
            </div>
            <div className="col-12">
              <div className="box_mob">
                <img src={thankpdf} alt="" className="img-fluid icon" />
                <div className="upperBox_mob">
                  <h2>02.</h2>
                  <p>You might be asked to add a few more documents</p>
                  <img src={ArroB} alt="" className="img-fluid icon ArroB" />
                </div>
                <h4>
                  Add <span>Documents</span>
                </h4>
              </div>
            </div>
            <div className="col-12">
              <div className="box_mob">
                <img src={thankrightmob} alt="" className="img-fluid" />
                <div className="upperBox_mob">
                  <h2>03.</h2>
                  <p>Your profile would be approved before Autumn'22</p>
                  <img src={ArroB} alt="" className="img-fluid icon ArroB" />
                </div>
                <h4>
                  Get <span>Approved</span>
                </h4>
              </div>
            </div>
            <div className="col-12">
              <div className="box_mob">
                <img src={thankushowcase} alt="" className="img-fluid icon" />
                <div className="upperBox_mob">
                  <h2>04.</h2>
                  <p>
                    Our system goes live in Autumn'22 and you can post jobs!!
                  </p>
                  <img src={ArroB} alt="" className="img-fluid icon ArroB" />
                </div>
                <h4 className="apply_job">
                  Post<span>Jobs</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sec_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="secBox">
                <span>
                  Kindly wait until your profile is approved. For any query,
                  contact{" "}
                </span>
                <a href="mailto:support@go-guard.com">
                  <i className="fas fa-envelope"></i> support@go-guard.com
                </a>
              </div>
            </div>
            <div className="col-md-12">
              <div className="backToHome">
                <a href="/">Back To Home</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <WelcomeFooter /> */}
    </>
  );
};

export default ThankYou;
