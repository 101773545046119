import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Img from "../../assets/img/userPlace.png";
import Pdf from "../../assets/img/pdf.png";
import "./ongoing_job.scss";
import ApiClient from "../../api/apiClient";
import Cookies from "universal-cookie";
import { API_GetAllJobsList, API_GetJobDetails, url } from "../../api/Constant";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import moment from "moment";
import Map from "../Map/Map";

const OngoingJob = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [jobDetails, setjobDetails] = useState([]);
  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  const [smShow, setSmShow] = useState(false);
  const [guardData, setGuardData] = useState({});
  const [daysInExp, setDaysInExp] = useState("");
  const [experience, setExperience] = useState("");
  //console.log(id);

  useEffect(() => {
    //   if (id) {

    //   }
    if (token) {
      callGetJobDetails(id);
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
  }, []);

  // Function to calculate and display guard's total experience
  const showGuardProfile = (guardData) => {
    var totalNoOfDays = 0;

    // Calculate total days of employment for each employment detail
    for (let i = 0; i < guardData.employmentDetails.length; i++) {
      const ele = guardData.employmentDetails[i];
      var days = 0;
      var startDate = moment.utc(new Date(ele.from));
      var endDate = moment.utc(new Date(ele.to));
      var sameDate = moment.utc(startDate).isSame(endDate);
      days = endDate.diff(startDate, "days");
      days = sameDate === true ? 1 : days;
      totalNoOfDays += days;
    }

    // Calculate years and months from total days
    var years = Math.floor(totalNoOfDays / 365);
    var months = Math.floor((totalNoOfDays % 365) / 30);
    let exp = `${years} years ${months} months`;

    // Update state with the calculated values
    setDaysInExp(totalNoOfDays);
    setGuardData(guardData);
    setExperience(exp);
    setSmShow(true);
  };

  // Function to fetch and display job details
  async function callGetJobDetails(jobid) {
    const data = "";
    const res = await ApiClient.get(
      API_GetJobDetails + `${jobid}`,
      data,
      `Bearer ${token}`
    );

    // Handle API response
    if (res?.data?.code === 401) {
      // If unauthorized, navigate to login
      toast.error(res?.data?.message);
      navigate(`/login`);
    } else if (res.data.code === 200) {
      // If success, update the state with the fetched job details
      setjobDetails(res.data.data);
    } else {
      // If any other response, show an error toast
      toast.error(res.message);
    }
  }

  return (
    <>
      <Header></Header>
      <section className="ongoing_job">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="title">
                <div className="row align-items-center">
                  <div className="col-md-9">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="content">
                          <h4>Ongoing Job Details</h4>
                          <h5>{jobDetails?.jobTitle}</h5>
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="content">
                          <p>
                            <span className="left">
                              <i className="fas fa-map-marker-alt"></i>
                            </span>{" "}
                            {jobDetails?.locationId?.locationName}
                          </p>
                          <p>
                            <span>Dress Code</span> :{" "}
                            {jobDetails?.dressCode == 1
                              ? "HV Vest"
                              : jobDetails?.dressCode == 2
                              ? "Casual"
                              : jobDetails?.dressCode == 3
                              ? "Suited"
                              : ""}
                          </p>
                          <p>
                            <span>Transpotation</span> :{" "}
                            {jobDetails?.isVehicleRequired
                              ? "Own Vehicle"
                              : "Doesn't matter"}
                          </p>
                          <p>
                            <span>Job Status :</span> Ongoing
                          </p>
                          <p>{/* <span>Speciality :</span> Mall Security */}</p>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="content mt-5">
                          <p>
                            <span>Start Date :</span>{" "}
                            {moment
                              .utc(jobDetails?.jobDate)
                              .format("DD-MM-YYYY")}
                          </p>
                          <p>
                            <span>Job Type :</span>{" "}
                            {jobDetails?.jobType == 1
                              ? "One Day"
                              : jobDetails?.jobType == 2
                              ? "Long Term"
                              : jobDetails?.jobType == 3
                              ? "Custom"
                              : ""}
                          </p>
                          <p>
                            <span>Description :</span> {jobDetails?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div
                      className="mapBox"
                      style={{
                        color: "red",
                        borderRadius: "10px",
                        width: "100%",
                        height: "200px",
                        border: "1px solid #d3d3d3",
                      }}
                    >
                      <Map
                        height={"100%"}
                        width={"200px"}
                        jobDetails={jobDetails}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-10">
              <div className="dateBox">
                <div className="row">
                  <div className="col-md-12">
                    <div className="tabBtn">
                      <Link to={`/enotes?jobId=${id}&page=${1}`}>E Notes</Link>
                      <Link to={`/notification/${id}`}>Alerts</Link>
                      {/* <Link to="/approve_alert">Approval</Link> */}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    {jobDetails?.shifts?.map((d, i) => {
                      return (
                        <div className="shiftBox">
                          <div className="shift_title">
                            <div className="row align-items-center">
                              <div className="col-6 col-md-6">
                                <h4>Shift {i + 1}</h4>
                              </div>
                            </div>
                          </div>
                          <div className="box">
                            <div className="row">
                              <div className="col-md-4">
                                <h5>Date</h5>
                                <p>
                                  <span className="left">
                                    <i className="fas fa-calendar"></i>
                                  </span>{" "}
                                  {moment
                                    .utc(d?.jobStartDate)
                                    .format("DD-MM-YYYY")}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <h5>Start Time</h5>
                                <p>
                                  <span className="left">
                                    <i className="fas fa-clock"></i>
                                  </span>{" "}
                                  {moment.utc(d?.startTime).format("h:mm:ss a")}
                                </p>
                              </div>
                              <div className="col-md-4">
                                <h5>End Time</h5>
                                <p>
                                  <span className="left">
                                    <i className="fas fa-clock"></i>
                                  </span>{" "}
                                  {moment.utc(d?.endTime).format("h:mm:ss a")}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="box">
                            <div className="row">
                              {d?.approvedGuardIds.length !== 0 ? (
                                d?.approvedGuardIds?.map((gid, i) => {
                                  return (
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <div className="on_going_shiftBox">
                                          <label
                                            for=""
                                            className="ongoing_img_label"
                                            style={{ fontWeight: "700" }}
                                            onClick={() =>
                                              showGuardProfile(gid)
                                            }
                                          >
                                            <div className="imgBox_ongoing">
                                              <img
                                                src={
                                                  gid?.profileImage !== "" &&
                                                  gid?.profileImage !==
                                                    undefined
                                                    ? url +
                                                      "media/preview?filename=" +
                                                      gid?.profileImage
                                                    : Img
                                                }
                                                alt="image profile"
                                                height={"20px"}
                                                width={"20px"}
                                              />
                                            </div>
                                            {gid?.firstName} {gid?.lastName}
                                          </label>
                                          <div>
                                            <select className="form-control">
                                              <option selected>
                                                {d?.role?.name}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="col-md-6">
                                  <div className="form-group row">
                                    <label
                                      for=""
                                      className="col-sm-4 col-form-label"
                                      style={{ fontWeight: "700" }}
                                    >
                                      No Guard Found:
                                    </label>
                                    <div className="col-sm-8">
                                      <select className="form-control">
                                        <option selected>
                                          {d?.speciality?.name}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="guard_profile_modal"
        size="l"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {" "}
            Guard Details{" "}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="cardBox">
            <div className="row">
              <div
                className="col-md-12 mb-4"
                style={{ justifyContent: "center", display: "flex" }}
              >
                <div class="guard-imgBox">
                  <img
                    src={
                      guardData?.profileImage !== "" &&
                      guardData?.profileImage !== undefined
                        ? url +
                          "media/preview?filename=" +
                          guardData?.profileImage
                        : Img
                    }
                    alt="image profile"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.firstName}
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.lastName}
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.email}
                    placeholder="Email"
                    maxLength={25}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.mobileNumber}
                    placeholder="Mobile Number"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Experience</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={daysInExp > 365 ? experience : "0-2 years"}
                    placeholder="Experience"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>License Number</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.licenseNumber}
                    placeholder="License Number"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>License Image</label>
                  {guardData?.securityRegistrationDocument !== "" &&
                  guardData?.securityRegistrationDocument !== undefined &&
                  guardData?.securityRegistrationDocument.split(".").at(-1) ===
                    "pdf" ? (
                    <a
                      target="_blank"
                      href={
                        url +
                        "media/preview?filename=" +
                        guardData?.securityRegistrationDocument
                      }
                    >
                      <img src={Pdf} className="img-fluid licenceImg" />
                    </a>
                  ) : (
                    <img
                      style={{
                        width: "150px",
                        height: "100px",
                        marginLeft: "20px",
                      }}
                      src={
                        guardData?.securityRegistrationDocument !== "" &&
                        guardData?.securityRegistrationDocument !== undefined
                          ? url +
                            "media/preview?filename=" +
                            guardData?.securityRegistrationDocument
                          : Img
                      }
                      alt="image profile"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OngoingJob;
