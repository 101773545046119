import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/Header";
import ApiClient from "../../api/apiClient";
import Cookies from "universal-cookie";
import { API_GetAllOnGoingJobsList } from "../../api/Constant";
import { toast } from "react-toastify";
import moment from "moment";
import "./ongoingJob_list.scss";
import Pagination from "react-js-pagination";

const OngoingJob = () => {
  const [jobs, setJobs] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  var pageNo = 1;

  useEffect(() => {
    if (token) {
      callGetAllJobsList();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      // navigate(`/login`);
    }
  }, []);

  // Handle Page Change Function
  const handlePageChange = (pageNumber) => {
    // console.log(`active page is ${pageNumber}`);
    // setPageNumber(pageNumber);
    pageNo = pageNumber;
    callGetAllJobsList();
    // this.setState({activePage: pageNumber});
  };

  // Get All Job List
  async function callGetAllJobsList() {
    const data = "";
    const res = await ApiClient.get(
      API_GetAllOnGoingJobsList +
        `?page=${pageNo}&sortBy=asc&limit=${10}&searchBy=&jobType=4`,
      data,
      `Bearer ${token}`
    );
    //console.log("job list", res);
    if (res.data.code === 200) {
      setJobs(res.data.data.docs);
      settotalItemsCount(res.data.data.total);
      //   setjobDetails(res.data.data.docs[0]);
    } else {
      toast.error(res.message);
    }
  }
  return (
    <>
      <Header />
      <section className="my_Rating">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="rateBox">
                <div className="row">
                  <div className="col-md-12">
                    <h5>On Going Job List</h5>
                  </div>
                </div>
                <div className="row">
                  {jobs.length !== 0 ? (
                    jobs.map((onJob, i) => {
                      return (
                        <div className="col-md-6 col-lg-3">
                          <Link to={`/ongoing_job/${onJob?.id}`}>
                            <div className="flexBox">
                              <h6>{onJob?.jobTitle}</h6>
                              <p className="mapText">
                                <i className="fas fa-map-marker-alt"></i>{" "}
                                {onJob?.locationId?.locationName}
                              </p>
                              <br />
                              {/* <p className="starRate">
                              Job Rating
                              <i
                                className="fas fa-star"
                                style={{ marginLeft: "6px" }}
                              ></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </p> */}
                              <p className="date">
                                <i className="fas fa-calendar dateIcon"></i>
                                {"  "}
                                {moment
                                  .utc(onJob?.jobDate)
                                  .format("DD-MM-YYYY")}
                              </p>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <h3>No Data</h3>
                  )}
                </div>
              </div>
              {jobs.length !== 0 && (
                <div className="mt-2">
                  <Pagination
                    color="primary"
                    activePage={pageNo}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OngoingJob;
