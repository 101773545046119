import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Docs from "../../assets/img/docs.png";
import Header from "../../components/Header/Header";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import "./edit_document.scss";
import ApiClient from "../../api/apiClient";
import { API_AddNewDocument, API_DeleteNewDocument } from "../../api/Constant";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "@material-ui/core/Modal";
import { useLocation } from "react-router-dom";

const EditDocument = () => {
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  const item = useLocation();
  const id = item?.state?.item?.id;
  React.useEffect(() => {
    //console.log(item);
    setDocumentFields([
      {
        idType: item?.state?.item?.documentType,
        idNumber: item?.state?.item?.documentNumber,
        fileName: item?.state?.item?.file,
        // fileName: "",
      },
    ]);
    // document.getElementById("fileNme").ariaLabel = item?.state?.item?.file;
    if (token) {
      callDocumentListApi();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
  }, []);
  const [documentFields, setDocumentFields] = useState([
    {
      idType: "",
      idNumber: "",
      fileName: "",
    },
  ]);
  const navigate = useNavigate();
  const [documentList, setDocumentList] = useState([]);
  const [add, setAdd] = useState("Identification Proof Type");
  const [identityFile, setIdentityFile] = useState([]);
  const [documentErrors, setDocumentErrors] = useState([{}]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  var pageNo = 1;

  const cookie = new Cookies();
  // const token = cookie.get("token");
  // const userId = cookie.get("userId");

  // Function to call the API to fetch the document list
  async function callDocumentListApi() {
    const data = "";
    const res = await ApiClient.get(
      API_AddNewDocument +
        `?page=${pageNo}&userId=${userId}&sortBy=asc&limit=${10}&searchBy=`,
      data,
      `Bearer ${token}`
    );

    // Handle API response
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res.data.code === 200) {
      settotalItemsCount(res.data.data.total);
      setDocumentList(res.data.data.docs);
    }
  }

  // Function to perform validation before submitting a document
  const validate = async () => {
    var isDocument = await checkDocumentValidationOnSubmit();

    // Filter documentList to find documents with the same type
    const f = documentList.filter((res) => {
      return (
        res.documentType === documentFields[0].idType &&
        res.id !== item?.state?.item?.id
      );
    });

    // Filter documentList to find documents with the same number
    const df = documentList.filter((res) => {
      return (
        res.documentNumber === documentFields[0].idNumber &&
        res.id !== item?.state?.item?.id
      );
    });

    // Display warnings for duplicate document type or number
    if (f.length >= 1) {
      toast.warn("Same type of identification proof is already saved");
      return;
    }

    if (df.length >= 1) {
      toast.warn("Same document number is already saved");
      return;
    }

    if (isDocument) {
      var formData = new FormData();
      formData.append("userId", userId);
      formData.append("documentType", documentFields[0].idType);
      formData.append("documentNumber", documentFields[0].idNumber);
      formData.append(
        "file",
        identityFile[0] === undefined
          ? item?.state?.item?.file
          : identityFile[0]
      );

      // Call API to update the document
      setLoading(true);
      const response = await ApiClient.multipart(
        "patch",
        API_AddNewDocument + `/${id}`,
        formData,
        `Bearer ${token}`
      );
      setLoading(false);

      // Handle API response
      if (response?.data?.code === 401) {
        toast.error(response?.data?.message);
        navigate(`/login`);
      }
      if (response && response.data.code === 200) {
        navigate("/my_document");
        setLoading(false);
        toast.success(response.data.message, "green");
        setDocumentFields([
          {
            idType: "",
            idNumber: "",
            fileName: "",
          },
        ]);
        callDocumentListApi();
      }
    }
  };

  // Function to validate document fields before submitting
  const checkDocumentValidationOnSubmit = async () => {
    var valid = true;
    const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
    let errorMsg = documentFields.map((data, key) => {
      let error = {};
      if (data.idNumber === "") {
        error.errorIdNumber = "Identification number is required";
        valid = false;
      } else if (alphanumericRegex.test(data.idNumber) === false) {
        error.errorIdNumber = "Please enter valid identification number";
        valid = false;
      } else {
        error.errorIdNumber = "";
      }
      if (data.idType === "") {
        error.errorIdType = "Identification type is required";
        valid = false;
      } else {
        error.errorIdType = "";
      }
      if (data.fileName && item?.state?.item?.file === "") {
        error.errorFileName = "Identification file is required";
        valid = false;
      } else {
        error.errorFileName = "";
      }
      return error;
    });
    setDocumentErrors(errorMsg);
    return valid;
  };

  // Function to handle changes in document fields
  const handleChange = async (index, e) => {
    const value = [...documentFields];
    if (e.target.type === "text" || e.target.type === "select-one") {
      value[index][e.target.name] = e.target.value;
    } else {
      const allowedBytes = 2 * 1024 * 1024;
      let allImages = [
        "image/png",
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/tiff",
        "image/bpg",
        "image/webp",
      ];

      // Check if the uploaded file type is allowed
      if (allImages.indexOf(e.target.files[0]?.type) === -1) {
        toast.warning("File type is not allowed");
        return;
      }

      // Check if the uploaded file size is within limits
      if (e.target.files[0].size > allowedBytes) {
        alert("Size is more than 2 MB");
        return;
      }

      const file = [...identityFile];
      file[index] = e.target.files[0];
      setIdentityFile(file);
      value[index][e.target.name] = e.target.files[0].name;
    }
    setDocumentFields(value);
    checkDocumentValidationOnSubmit();
  };

  // Function to handle page changes for document list pagination
  const handlePageChange = (pageNumber) => {
    //console.log(`active page is ${pageNumber}`);
    pageNo = pageNumber;
    callDocumentListApi();
  };

  // Function to confirm and delete a document entry
  const deleteList = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteAPI(id);
      }
    });
  };

  // Function to call the API for deleting a document entry
  const callDeleteAPI = async (id) => {
    const data = "";
    const ress = await ApiClient.delete(
      API_DeleteNewDocument + id,
      data,
      `Bearer ${token}`
    );

    if (ress?.data?.code === 401) {
      toast.error(ress?.data?.message);
      navigate(`/login`);
    }
    if (ress.data.code === 200) {
      toast.success(ress.data.message, "green");
      const showList = documentList.filter((ele) => ele.id !== id);
      setDocumentList(showList);
    } else {
      toast.error(ress.data.message, "red");
      setIsError(true);
      setError(ress.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Header />
      <section className="myDoc">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="myDocBox">
                <div className="row">
                  <div className="col-md-12">
                    <div className="topBox">
                      <h4>My Documents</h4>
                    </div>
                    {iserror ? (
                      <div className="alert alert-danger">{error}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  {documentFields.map((identificationField, i) => {
                    // //console.log(identificationField, "identificationField")
                    return (
                      <>
                        <div className="col-md-3">
                          <div className="form-group">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              name="idType"
                              value={identificationField.idType}
                              onChange={(e) => handleChange(i, e)}
                              defaultValue="Identification Proof Type"
                              aria-readonly
                            >
                              {/* <option selected>Identification Proof Type</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option> */}
                              <option selected value="">
                                Identification Proof Type
                              </option>
                              <option value="Drivers license">
                                Drivers license
                              </option>

                              <option value="Permanent resident card">
                                Permanent resident card
                              </option>
                              <option value="Other">Other</option>
                              <option value="Canadian passport">
                                Canadian passport
                              </option>
                            </select>
                          </div>
                          <ErrorMessage
                            messerror={
                              documentErrors.length - 1 >= i &&
                              documentErrors[i].errorIdType
                            }
                          />
                        </div>
                        <div className="col-md-3">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="idNumber"
                              maxLength={15}
                              placeholder="Document No.*"
                              value={identificationField.idNumber}
                              aria-label="default input example"
                              onChange={(e) => handleChange(i, e)}
                            />
                            <span>
                              <i className="fas fa-file"></i>
                            </span>
                          </div>
                          <ErrorMessage
                            messerror={
                              documentErrors.length - 1 >= i &&
                              documentErrors[i].errorIdNumber
                            }
                          />
                        </div>

                        <div className="col-md-3">
                          <div className="form-group">
                            <label className="update_files">
                              <input
                                type="file"
                                className="form-control d-none"
                                htmlFor="exampleInputtext"
                                aria-describedby="textHelp"
                                name="fileName"
                                // value={identificationField.fileName}
                                id="fileName"
                                accept="image/*"
                                placeholder="Upload Identification"
                                onChange={(e) => handleChange(i, e)}
                              />
                              {/* {console.log(
                                identificationField.fileName,
                                "test"
                              )} */}
                              {identificationField?.fileName !== "undefined" &&
                              identificationField?.fileName !== undefined
                                ? identificationField?.fileName
                                : "No file chosen"}
                              {/* {identityFile.length === 0
                                ? item?.state?.item?.file
                                : ""} */}
                              <span>
                                <i className="fas fa-paperclip"></i>
                              </span>
                            </label>
                          </div>
                          <ErrorMessage
                            messerror={
                              documentErrors.length - 1 >= i &&
                              documentErrors[i].errorFileName
                            }
                          />
                        </div>
                      </>
                    );
                  })}
                  <div className="col-md-3">
                    <div className="addDocs_btn">
                      {/* <Link to=""> */}
                      <a type="button" onClick={() => validate()}>
                        {loading ? "Please Wait..." : "Update Document"}
                      </a>
                      {/* </Link> */}
                    </div>
                  </div>

                  {/* <div className='col-md-3'>
                        <div className="form-group">
                            <input className="form-control" type="text" placeholder="License No." aria-label="default input example" />
                            <span><i className="fas fa-file"></i></span>
                        </div>
                    </div> */}
                </div>

                {/* <div className="uploded_docs">
                  <div className="row">
                    <div className="col-md-12">
                      <h5>Uploaded Documents</h5>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="table-responsive border-1">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>Document Title</th>
                            <th>Document No.</th>
                            <th>Attachment</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documentList.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.documentType}</td>
                                <td>{item.documentNumber}</td>
                                <td>
                                  <div className="attach_docs">
                                    <img src={Docs} className="img-fluid" />
                                    <span>Docs_file.doc</span>
                                  </div>
                                </td>
                                <td>
                                  <div className="action_btn">
                                    <a
                                      onClick={() => deleteList(item.id)}
                                      className="del_btn"
                                    >
                                      <i className="fas fa-trash"></i>
                                    </a>
                                    <a type="button" onClick={() =>
                                          navigate(`/jobPage`, {
                                            state: { item },
                                          })
                                        } className="edit_btn">
                                      <i className="fas fa-edit"></i>
                                    </a>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-2">
                      {documentList.length != 0 && (
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditDocument;
