import Wel_Header from "../../Pages/Wel_header/Wel_header";
import Header from "../../components/Header/Header";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PdfIcon from "../../assets/img/pdf.png";
import ApiClient from "../../api/apiClient";
import {
  API_DELETEWORKLOCATION,
  API_WorkLocationList,
  API_GetProfile,
  API_GETUNITLIST,
  API_DeleteUnitList,
  API_UpdateUserProfile,
  url,
} from "../../api/Constant";
import {
  nameVaidation,
  emailRegex,
  alphanumeric,
  re,
  alpha,
  numbers,
  firstNameAndLastName,
} from "../../constant";
// import LogoIcon from "../../components/Logo/Logo";
import "./work_location.scss";
import Cookies from "universal-cookie";
import Modal from "@material-ui/core/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
// import { Pagination } from 'react-bootstrap';
import Pagination from "react-js-pagination";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

const WorkLocation = () => {
  const navigate = useNavigate();
  const [locationList, setLocationList] = useState([]);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  // const [pageNumber, setPageNumber] = useState(1);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [totalItem, settotalItem] = useState(0);
  let [loading, setLoading] = useState(false);
  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  var pageNo = 1;
  var page = 1;
  const [searchParams] = useSearchParams();
  const [isLoading, setIsloading] = useState(false);
  const [cusprofile, setCusProfile] = useState("");
  const [unitList, setUnitList] = useState([]);
  const [corporationName, setCorporationName] = useState("");
  const [corporationNo, setCorporationNo] = useState("");
  const [hst, setHst] = useState("");
  // let [dismiss, setDismiss] = useState(false);
  var dismiss = false;
  useEffect(() => {
    if (token) {
      callWorkLocationAPI();
      callMyProfileApi();
      callUnitListApi();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
  }, [token]);

  //console.log(cusprofile.registerAs, "testt");
  // call WorkLocation API
  async function callWorkLocationAPI() {
    const userDetils = localStorage.getItem("userInfo");
    const useremail = JSON.parse(userDetils);
    // //console.log(useremail.user.email,"userEmail")
    setEmail(useremail.user.email);
    const data = "";
    setLoading(true);
    const res = await ApiClient.get(
      API_WorkLocationList +
        `?page=${pageNo}&sortBy=asc&limit=${10}&searchBy=&unitId=`,
      data,
      `Bearer ${token}`
    );
    //console.log(res);
    if (res.data.code === 200) {
      setLoading(false);
      settotalItemsCount(res.data.data.total);
      // setPageNumber(res.data.data.page);
      // pageNo = res.data.data.page;
      setLocationList(res.data.data.docs);
      // if (res.data && res.data.data && res.data.data.docs.length === 0) {
      //   var isHeadOffice = searchParams.get("isHeadOffice");
      //   navigate(`/add_location?isHeadOffice=` + isHeadOffice);
      // }
    } else {
      setLoading(false);
      setIsError(true);
      setError(res.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  }

  // //console.log(JSON.stringify(locationList), "location list id ");
  // Delete Warning
  const deleteList = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteAPI(id);
      }
    });
    // //console.log('delete list id', id)
  };
  // Delete Worklocation Function
  const callDeleteAPI = async (id) => {
    const data = "";
    setLoading(true);
    const ress = await ApiClient.delete(
      API_DELETEWORKLOCATION + id,
      data,
      `Bearer ${token}`
    );
    if (ress?.data?.code === 401) {
      // setIsloading(false);
      toast.error(ress?.data?.message);
      navigate(`/login`);
    }
    // //console.log('delete list ress', JSON.stringify(ress))
    if (ress.data.code === 200) {
      toast.success(ress.data.message, "green");
      callWorkLocationAPI();
      setLoading(false);
    } else {
      toast.error(ress.data.message, "red");
    }
  };
  // Page Chane Function
  const handlePageChange = (pageNumber) => {
    // //console.log(`active page is ${pageNumber}`);
    // setPageNumber(pageNumber);
    pageNo = pageNumber;
    callWorkLocationAPI();
    // this.setState({activePage: pageNumber});
  };
  // Page Chane Function
  const handlePageChanges = (pageNumber) => {
    // //console.log(`active page is ${pageNumber}`);
    // setPageNumber(pageNumber);
    page = pageNumber;
    callUnitListApi();
    // this.setState({activePage: pageNumber});
  };
  // Get User Profile Function
  async function callMyProfileApi() {
    const userInfo = localStorage.getItem("userInfo");
    const refToken = JSON.parse(userInfo);
    //console.log(refToken.user.id);
    const data = "";
    setIsloading(true);
    const response = await ApiClient.get(
      API_GetProfile + refToken.user.id,
      data,
      `Basic ${refToken.tokens.refresh.token}`
    );
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/login`);
    }
    setIsloading(false);
    // //console.log("get profile res", response.data.data);
    if (response.data.code === 200) {
      setCusProfile(response?.data?.data);
    } else {
      setIsloading(false);
      alert(response.data.message);
    }
  }

  // Get Unit List
  async function callUnitListApi() {
    const data = "";
    const res = await ApiClient.get(
      API_GETUNITLIST + `?page=${page}&sortBy=asc&limit=${10}&searchBy=`,
      data,
      `Bearer ${token}`
    );
    // //console.log("Get UnitList Data", res);
    if (res.data.code === 200) {
      settotalItem(res.data.data.total);
      setUnitList(res.data.data.docs);
    } else {
      setIsError(true);
      setError(res.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  }

  // Function For Showing Image in full screen
  const openFancyBox = (img) => {
    //console.log(img);
    let dataUrl = img;
    if (!dataUrl) return;
    Fancybox.show([{ src: dataUrl, type: "image" }], { closeButton: "inside" });
  };

  // Delete Unit Warning Function
  const deleteUnit = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUnitAPI(id);
      }
    });
  };

  // Delete Unit Functiom
  const deleteUnitAPI = async (id) => {
    const data = "";
    setLoading(true);
    const ress = await ApiClient.delete(
      API_DeleteUnitList + id,
      data,
      `Bearer ${token}`
    );
    // console.log('delete list ress', JSON.stringify(ress))
    if (ress.data.code === 200) {
      callUnitListApi();
      setLoading(false);
      toast.success(ress.data.message, "green");
    } else {
      toast.error(ress.data.message, "red");
    }
  };

  // Set Data in edit mode
  const editBussDetails = (v) => {
    setCorporationName(v?.businessName);
    setCorporationNo(v?.businessType);
    setHst(v?.HSTNo);
    document.getElementById("corporation").value = v?.businessName;
    document.getElementById("incorporation").value = v?.businessType;
    document.getElementById("hst").value = v?.HSTNo;
  };

  // Validation Functions
  const editedCorporationName = (cn) => {
    if (cn === "") {
      toast.warning("Corporation Name Is Required");
      return;
    } else {
      setCorporationName(cn);
    }
  };
  const editedCorporationNo = (cno) => {
    if (cno === "") {
      toast.warning("Corporation No Is Required");
      return;
    } else if (cno && !alphanumeric.test(cno)) {
      toast.warning("Please enter valid Corporation No");
      return;
    } else {
      setCorporationNo(cno);
    }
  };
  const editedHst = (hst) => {
    if (hst === "") {
      toast.warning("HST No Is Required");
      return;
    } else {
      setHst(hst);
    }
  };

  // Update Function
  const updateBussinessDetails = async () => {
    if (corporationName === "") {
      toast.warning("Corporation Name Is Required");
      return;
    }

    if (corporationNo === "") {
      toast.warning("Corporation No Is Required");
      return;
    }

    if (hst === "") {
      toast.warning("HST No Is Required");
      return;
    }

    const data = [];
    const obj = {
      HSTNo: hst,
      businessName: corporationName,
      businessRegisterNumber: "",
      businessType: corporationNo,
    };

    data.push(obj);

    const body = new FormData();
    body.append("businessDetails", JSON.stringify(data));
    setLoading(true);
    const response = await ApiClient.patch(
      API_UpdateUserProfile + userId,
      body,
      `Basic ${token}`
    );

    // console.log(response);
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/login`);
    }

    if (response.data.code === 200) {
      setLoading(false);
      dismiss = true;
      // callUnitListApi();
      // setLoading(false);
      callMyProfileApi();
      // document.getElementById("exampleModal").modal("hide");
      toast.success(response.data.message, "green");
    } else {
      setLoading(false);
      dismiss = false;
      toast.error(response.data.message, "red");
    }
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Header />
      {loading === false && (
        <section className="work_location">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12">
                <div className="location_title">
                  <div className="row align-items-lg-center">
                    <div className="col-md-12">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h4>Work Location</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {cusprofile.registerAs === "Individual" ||
                  cusprofile.registerAs === "Business" ? (
                    ""
                  ) : (
                    <div className="col-md-3">
                      <div className="left_sidebar">
                        <h5>Business Details</h5>
                        <div className="nav_box">
                          {cusprofile?.businessDetails?.length !== 0
                            ? cusprofile?.businessDetails?.map((res, i) => {
                                return (
                                  <div
                                    key={i}
                                    class="nav flex-column nav-pills"
                                    id="v-pills-tab"
                                    role="tablist"
                                    aria-orientation="vertical"
                                  >
                                    <button
                                      class="nav-link active"
                                      id="v-pills-first-tab"
                                      data-bs-toggle="pill"
                                      data-bs-target="#v-pills-first"
                                      type="button"
                                      role="tab"
                                      aria-controls="v-pills-first"
                                      aria-selected="true"
                                    >
                                      <span className="business_name">
                                        Corporation Name: {res?.businessName}{" "}
                                        <a
                                          className="action_btn"
                                          type="button"
                                          onClick={(e) => {
                                            editBussDetails(res);
                                          }}
                                          data-bs-toggle="modal"
                                          data-bs-target="#exampleModal"
                                        >
                                          <i className="fas fa-edit"></i>
                                        </a>
                                      </span>
                                      <span>
                                        Incorporation Number:{" "}
                                        {res?.businessType}
                                      </span>
                                      <span className="hst_number">
                                        HST Number: {res?.HSTNo}
                                      </span>
                                    </button>
                                  </div>
                                );
                              })
                            : "No Data"}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-4">
                    {cusprofile.registerAs === "Individual" ||
                    cusprofile.registerAs === "Business" ? (
                      ""
                    ) : (
                      <div className="my_unitBox">
                        <div className="title_box">
                          <div className="row align-items-center">
                            <div className="col-8">
                              <h5 className="awrded_job_title">
                                Contact Details
                              </h5>
                            </div>
                            {cusprofile?.userType !== 1 && (
                              <div className="col-4">
                                <div className="addBtn">
                                  <a
                                    type="button"
                                    onClick={(e) => {
                                      navigate(`/addUnit`);
                                    }}
                                  >
                                    Add<span></span>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="content">
                          <div class="tab-content" id="v-pills-tabContent">
                            {unitList?.length !== 0
                              ? unitList.map((res, idx) => {
                                  return (
                                    <div
                                      class="tab-pane fade show active"
                                      id="v-pills-first"
                                      role="tabpanel"
                                      aria-labelledby="v-pills-first-tab"
                                    >
                                      <div className="unit_box">
                                        <h6 className="unit_per_name">
                                          <span>Contact Person:</span>{" "}
                                          {res?.contactPerson}
                                        </h6>
                                        <h6>
                                          <span>Phone:</span>{" "}
                                          {res?.mobileNumber}
                                        </h6>
                                        <h6>
                                          <span>Email Address:</span>{" "}
                                          {res?.email}
                                        </h6>
                                        <div className="attach_docs">
                                          <h6>
                                            <span>Id Proof:</span>
                                          </h6>
                                          <div className="unit_outerBox">
                                            {res.businessProofDocuments?.map(
                                              (doc) => {
                                                return (
                                                  <div className="unit_contBox">
                                                    <p
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={(e) =>
                                                        openFancyBox(
                                                          `${url}/media/preview?filename=${doc?.file}`
                                                        )
                                                      }
                                                    >
                                                      ID No:{" "}
                                                      <b>{doc?.idNumber}</b>
                                                    </p>

                                                    <p>
                                                      Identification Type:{" "}
                                                      {
                                                        doc?.businessDocumentType
                                                      }
                                                    </p>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                        {cusprofile?.userType !== 1 && (
                                          <div className="action_btn">
                                            <h6>
                                              <span>Action:</span>
                                              <a
                                                type="button"
                                                onClick={(e) => {
                                                  navigate(
                                                    `/update_unit/${res?.id}`
                                                  );
                                                }}
                                                className="edit_btn"
                                              >
                                                <i className="fas fa-edit"></i>
                                              </a>
                                              <a
                                                type="button"
                                                onClick={(e) => {
                                                  deleteUnit(res?.id);
                                                }}
                                                className="del_btn"
                                              >
                                                <i className="fas fa-trash-alt"></i>
                                              </a>
                                            </h6>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  );
                                })
                              : "No Data"}
                          </div>
                        </div>
                      </div>
                    )}{" "}
                    <div className="mt-2">
                      {unitList?.length !== 0 && (
                        <Pagination
                          color="primary"
                          activePage={page}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItem}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChanges(e)}
                        />
                      )}
                    </div>
                  </div>

                  <div className="col-md-5">
                    <div className="my_locaBox">
                      <div className="title_box">
                        <div className="row align-items-center">
                          <div className="col-8">
                            <h5 className="awrded_job_title">Work Locations</h5>
                          </div>
                          <div className="col-4">
                            <div className="addBtn">
                              <a
                                type="button"
                                onClick={(e) => {
                                  navigate(`/add_location?isHeadOffice=null`);
                                }}
                              >
                                Add<span></span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="reach_box">
                        {locationList?.length !== 0
                          ? locationList.map((loc, indx) => {
                              return (
                                <div className="unit_box">
                                  <h6 className="unit_per_name">
                                    <span>Business Name:</span>{" "}
                                    {loc?.businessName}
                                  </h6>
                                  <h6>
                                    <span>Location Name:</span>{" "}
                                    {loc?.locationName}{" "}
                                  </h6>
                                  <h6>
                                    <span>Address:</span> {loc?.locationName}
                                  </h6>
                                  {/* <h6>
                                  <span>Email Address:</span> test123@gmail.com
                                </h6> */}
                                  <div className="action_btn">
                                    <h6>
                                      <span>Action:</span>
                                      <a
                                        type="button"
                                        onClick={(e) => {
                                          navigate(`/edit_location/${loc?.id}`);
                                        }}
                                        className="edit_btn"
                                      >
                                        <i className="fas fa-edit"></i>
                                      </a>
                                      <a
                                        type="button"
                                        onClick={(e) => {
                                          deleteList(loc?.id);
                                        }}
                                        className="del_btn"
                                      >
                                        <i className="fas fa-trash-alt"></i>
                                      </a>
                                    </h6>
                                  </div>
                                </div>
                              );
                            })
                          : "No Data"}
                      </div>
                    </div>
                    <div className="mt-2">
                      {locationList.length != 0 && (
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <div className="business_modal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Business Details
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="modal-body">
                <div className="cardBox">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card_number">
                        <div className="form-group">
                          <input
                            type="text"
                            id="corporation"
                            className="form-control"
                            placeholder="Corporation Name."
                            onChange={(e) => {
                              editedCorporationName(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          id="incorporation"
                          type="text"
                          className="form-control"
                          placeholder="Incorporation No."
                          onChange={(e) => {
                            editedCorporationNo(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          id="hst"
                          className="form-control"
                          placeholder="HST No."
                          onChange={(e) => {
                            editedHst(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pay_submit_box">
                <button
                  type="button"
                  disabled={loading}
                  data-bs-dismiss={!loading ? "modal" : ""}
                  class="pay_submit_btn"
                  onClick={(e) => updateBussinessDetails()}
                >
                  {loading ? "Updating..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkLocation;
