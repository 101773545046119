import React from 'react';
import './footer.scss';

const Footer = () => {
  return (
    <>
      <footer>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="footerBox">
              <p><span className='left'><i className="fas fa-copyright"></i></span> Copyright | Go-Guard.com</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    </>
  )
}

export default Footer;
