import React, { useState } from "react";
import LogoIcon from "../../components/Logo/Logo";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import "./reset_password.scss";
import { re } from "../../constant";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import ApiClient from "../../api/apiClient";
import { API_ResetPassword } from "../../api/Constant";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import { toast } from "react-toastify";
import { FaArrowRight, FaLock, FaTimes, FaCheck, FaEye } from "react-icons/fa";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [oldPass, setOldPass] = useState({ values: "", err: "" });
  const [password, setPassword] = useState({ value: "", err: "" });
  const [conpassword, setConPassword] = useState({
    value: "",
    err: null,
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowCPassword, setIsShowCPassword] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [match, setMatch] = useState(false);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);
  const [issuccess, setIsSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);

  // Reset Password Function
  async function callResetPasswordApi() {
    const token = localStorage.getItem("userInfo");
    const refToken = JSON.parse(token);
    const data = {
      password: newPassword,
    };
    let response = await ApiClient.post(
      API_ResetPassword + searchParams.get("token"),
      data,
      `Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1`
    );
    if (response.data.code === 200) {
      toast.success(response.data.message, "green");
      setOldPass({ value: "" });
      setPassword({ value: "" });
      setConPassword({ value: "" });
      navigate(`/login`);
    } else {
      toast.error(response.data.message, "red");
    }
  }

  // Toggle Show Password
  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };
  // Toggle Show Confirm Password
  const handleShowCPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowCPassword(true);
    } else {
      setIsShowCPassword(false);
    }
  };

  // Check the length of the input
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      setSpecialCharValid(true);
    } else {
      setSpecialCharValid(false);
    }
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  };

  // Function to handle changes in the new password field
  const handlePasswordChange = (event) => {
    // Update state with the new password value
    setNewPassword(event.target.value);

    // Check for password length and update corresponding state
    checkPasswordLength(event.target.value);

    // Check for special characters and update corresponding state
    checkSpecialCharacters(event.target.value);

    // Check for uppercase letters and update corresponding state
    checkUppercase(event.target.value);

    // Check for numbers and update corresponding state
    checkNumber(event.target.value);
  };

  // Function to handle changes in the confirm password field
  const handleConfirmPasswordChange = (event) => {
    // Update state with the confirm password value
    setConfirmPassword(event.target.value);

    // Compare the new password with the confirm password
    if (newPassword !== event.target.value) {
      // If passwords do not match, set match state to false
      setMatch(false);
    } else {
      // If passwords match, set match state to true
      setMatch(true);
    }
  };

  return (
    <>
      <section className="reset_pass">
        <div className="container">
          <LogoIcon />
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="resetForm">
                <form>
                  <div className="signUpBox">
                    <div className="row align-items-lg-center">
                      <div className="col-md-12 text-center">
                        {/* <Link to="/login" className="backBtn"><span></span></Link>  */}
                        <h4>Reset Password</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type={!isShowPassword ? "password" : "text"}
                          className="form-control"
                          aria-describedby="passwordHelp"
                          placeholder="New Password"
                          // value={password.value}
                          onChange={(event) => handlePasswordChange(event)}
                        />
                        <span>
                          {!isShowPassword ? (
                            <i
                              className="fas fa-eye"
                              onClick={(e) => handleShowPassword(e, "show")}
                            />
                          ) : (
                            <i
                              className="fas fa-eye-slash"
                              onClick={(e) => handleShowPassword(e, "hide")}
                            />
                          )}
                        </span>
                      </div>
                      <ErrorMessage messerror={password.err} />
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <input
                          type={!isShowCPassword ? "password" : "text"}
                          className="form-control"
                          aria-describedby="confirmHelp"
                          placeholder="Confirm Password"
                          onChange={(event) =>
                            handleConfirmPasswordChange(event)
                          }
                        />
                        <span>
                          {!isShowCPassword ? (
                            <i
                              className="fas fa-eye"
                              onClick={(e) => handleShowCPassword(e, "show")}
                            />
                          ) : (
                            <i
                              className="fas fa-eye-slash"
                              onClick={(e) => handleShowCPassword(e, "hide")}
                            />
                          )}
                        </span>
                      </div>
                      <ErrorMessage messerror={conpassword.err} />
                    </div>
                    <div>
                      <div className="validation">
                        <div className="validator">
                          {charNumberValid ? (
                            <FaCheck className="success" />
                          ) : (
                            <FaTimes className="error" />
                          )}
                          <p className="validation-item">8-20 characters</p>
                        </div>
                        <div className="validator">
                          {specialCharValid ? (
                            <FaCheck className="success" />
                          ) : (
                            <FaTimes className="error" />
                          )}
                          <p className="validation-item">1 special character</p>
                        </div>
                        <div className="validator">
                          {uppercaseValid ? (
                            <FaCheck className="success" />
                          ) : (
                            <FaTimes className="error" />
                          )}
                          <p className="validation-item">1 uppercase letter</p>
                        </div>
                        <div className="validator">
                          {numberValid ? (
                            <FaCheck className="success" />
                          ) : (
                            <FaTimes className="error" />
                          )}
                          <p className="validation-item">1 number</p>
                        </div>
                        <div className="validator">
                          {match ? (
                            <FaCheck className="success" />
                          ) : (
                            <FaTimes className="error" />
                          )}
                          {!match ? (
                            <p className="validation-item">
                              Confirm Password not match with new password
                            </p>
                          ) : (
                            <p className="validation-item">
                              Password confirmation successful{" "}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nextBtn">
                    {/* <Link to="/register"> */}
                    <a
                      type="button"
                      // onClick={() => (conpassword.err !== "" ? "" : validate())}
                      onClick={(e) => (!match ? "" : callResetPasswordApi(e))}
                    >
                      Submit <i class="fa-solid fa-arrow-right"></i>
                    </a>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
