import React from 'react';
import PdfIcon from '../../assets/img/pdf.png';
import { Link } from 'react-router-dom';
import './previous_ticket.scss';

const PreviousTicket = () => {
  return (
    <>
      <section className="previous_ticket">
         <div className="container">
             <div className="row justify-content-center">
                 <div className="col-md-12 col-lg-12">
                     <div className="content">
                         <div className='row'>
                             <div className='col-md-12'>
                                <div className="title">
                                    <div className="backArrow"><Link to="/support"><span className='left'><i className="fas fa-arrow-left"></i></span></Link></div>
                                    <div className="heading">
                                        <h1>Previous Tickets</h1>
                                    </div>
                                </div>
                             </div>
                         </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <Link to="/ticketDetail">
                                    <div className='descBox'>
                                        <h6>Ticket Title</h6>
                                        <p className='totEarn'><span>Ticket Date :</span> 12/09/2021</p>
                                        <p className='totEarn'><span>Job Title :</span> Mall Security Guard</p>
                                        <p className='pdfFile'><img src={PdfIcon} alt="PdfImage" className='img-fluid' /><span>Report File.pdf</span></p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-md-6'>
                                <Link to="/ticketDetail">
                                    <div className='descBox'>
                                        <h6>Ticket Title</h6>
                                        <p className='totEarn'><span>Ticket Date :</span> 12/09/2021</p>
                                        <p className='totEarn'><span>Job Title :</span> Mall Security Guard</p>
                                        <p className='pdfFile'><img src={PdfIcon} alt="PdfImage" className='img-fluid' /><span>Report File.pdf</span></p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-md-6'>
                                <Link to="/ticketDetail">
                                    <div className='descBox'>
                                        <h6>Ticket Title</h6>
                                        <p className='totEarn'><span>Ticket Date :</span> 12/09/2021</p>
                                        <p className='totEarn'><span>Job Title :</span> Mall Security Guard</p>
                                        <p className='pdfFile'><img src={PdfIcon} alt="PdfImage" className='img-fluid' /><span>Report File.pdf</span></p>
                                    </div>
                                </Link>
                            </div>
                            <div className='col-md-6'>
                                <Link to="/ticketDetail">
                                    <div className='descBox'>
                                        <h6>Ticket Title</h6>
                                        <p className='totEarn'><span>Ticket Date :</span> 12/09/2021</p>
                                        <p className='totEarn'><span>Job Title :</span> Mall Security Guard</p>
                                        <p className='pdfFile'><img src={PdfIcon} alt="PdfImage" className='img-fluid' /><span>Report File.pdf</span></p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                     </div>
                 </div>
             </div>
         </div>
      </section>
    </>
  )
}

export default PreviousTicket;
