import React, { useState, useEffect, useRef } from "react";
// import LogoIcon from '../../components/Logo/Logo';
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import Header from "../../components/Header/Header";
import { Link, useNavigate, useParams } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import {
  nameVaidation,
  emailRegex,
  alphanumeric,
  firstNameAndLastName,
  re,
  numbers,
} from "../../constant";
import "./addUnit.scss";
import ApiClient from "../../api/apiClient";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "@material-ui/core/Modal";
import {
  API_GetProfile,
  API_UnitDetails,
  API_UpdateUnitList,
} from "../../api/Constant";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import { googleAddressKey } from "./../../api/Constant";

let autoComplete;
const UpdateUnits = () => {
  const { locationId, id } = useParams();
  //console.log("locationId", locationId, "id", id);

  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const [fullName, setFullName] = useState({ value: "", err: "" });
  const [names, setNames] = useState({ value: "", err: "" });
  const [email, setEmail] = useState({ value: "", err: "" });
  const [phoneNumber, setPhoneNumber] = useState({ value: "", err: "" });
  let [locationName, setLocationName] = useState({ value: "", err: "" });
  const [locationType, setLocationType] = useState({ value: "", err: "" });
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [businessRegistrationNumber, setBusinessRegistrationNumber] = useState({
    value: "",
    err: "",
  });
  const [HSTNo, setHSTNo] = useState({ value: "", err: "" });
  const [profilePhoto, setProfilePhoto] = useState("");
  const [contactPerson, setContactPerson] = useState({ value: "", err: "" });
  const [contactNumber, setContactNumber] = useState({ value: "", err: "" });
  const [contactEmail, setContactEmail] = useState({ value: "", err: "" });
  const [pass, setPass] = useState({ value: "", err: "" });
  const [confirmPass, setConfirmPass] = useState({ value: "", err: "" });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowCPassword, setIsShowCPassword] = useState(false);

  // const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [documentFields, setDocumentFields] = useState([
    {
      businessDocumentType: "",
      idNumber: "",
      fileName: "",
    },
  ]);
  const [identityFile, setIdentityFile] = useState([]);
  const [documentErrors, setDocumentErrors] = useState([{}]);

  let [loading, setLoading] = useState(false);

  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;

  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "ca" } }
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  };

  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = autoComplete.getPlace();
    const latitude = addressObject.geometry.location.lat();
    setLatitude(latitude);
    const longitude = addressObject.geometry.location.lng();
    setLongitude(longitude);
    const query = addressObject.formatted_address;
    updateQuery(query);
    //console.log(addressObject, query, latitude, longitude);
    setLocationName({ value: addressObject.formatted_address, err: "" });
    locationName.value = addressObject.formatted_address;
  };

  useEffect(async () => {
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    } else {
      getProfileData();
      unitDetail();
    }
    loadScript(
      // `https://maps.googleapis.com/maps/api/js?key=AIzaSyCM3LLCqIN8v0uaQyzMcxvEuKVa3iTZYCY&libraries=places`,
      `https://maps.googleapis.com/maps/api/js?key=${googleAddressKey}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, [userId]);

  // Get Unit details
  const unitDetail = async () => {
    const data = "";
    const res = await ApiClient.get(
      API_UnitDetails + id,
      data,
      `Bearer ${token}`
    );
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    // //console.log(res, "unit details");
    if (res.data.code === 200) {
      //console.log("data=====", res.data.data);
      setQuery(res.data.data.locationName);
      setLocationType({ value: res.data.data.locationType, err: "" });
      setLocationName({ value: res.data.data.locationName, err: "" });
      // locationName.value = addressObject.formatted_address;
      setBusinessRegistrationNumber({
        value: res.data.data.businessRegistrationNumber,
        err: "",
      });
      setHSTNo({ value: res.data.data.HSTNo, err: "" });
      setContactPerson({ value: res.data.data.contactPerson, err: "" });
      setContactNumber({ value: res.data.data.mobileNumber, err: "" });
      setContactEmail({ value: res.data.data.email, err: "" });
      var tempArray = [];

      for (var i = 0; i < res.data.data.businessProofDocuments.length; i++) {
        // console.log(
        //   "file path",
        //   res.data.data.businessProofDocuments[i].file?.split("/")
        // );
        let fileData = res.data.data.businessProofDocuments[i].file?.split("/");
        // identityFile.push(res.data.data.businessProofDocuments[i].file);
        tempArray.push({
          businessDocumentType:
            res.data.data.businessProofDocuments[i].businessDocumentType,
          idNumber: res.data.data.businessProofDocuments[i].idNumber,
          fileName: res.data.data.businessProofDocuments[i].file,
        });
      }
      setDocumentFields(tempArray);
    }
  };

  //Get Profile Data
  const getProfileData = async () => {
    // const response = await getGuardProfile({ id: id });
    const response = await ApiClient.get(
      API_GetProfile + userId,
      `Basic ${token}`
    );
    if (response.data.code === 200) {
      // console.log('data=====', response.data.data)
      if (response.data && response.data.data) {
        setEmail({ value: response.data.data.email, err: "" });
        setPhoneNumber({ value: response.data.data.mobileNumber, err: "" });
        setFullName({
          value:
            response.data.data.firstName +
            " " +
            response.data.data.middleName +
            " " +
            response.data.data.lastName,
          err: "",
        });
      }
    }
  };

  /**
   * Validates and updates the state for the business name field.
   * @param {string} value - The value of the business name.
   */
  const _namevalidate = (value) => {
    // Check if the value is empty
    if (value === "") {
      setLocationName({ value: value, err: "*Please enter business name." });
    } else {
      setLocationName({ value: value, err: "" });
    }

    // Check if the value is not empty and doesn't match the validation pattern
    if (value && !nameVaidation.test(value)) {
      setLocationName({
        value: value,
        err: "*Please enter valid business name.",
      });
    } else {
      // If the value is empty or matches the validation pattern, clear the error message
      setLocationName({ value: value, err: "" });
    }
  };

  // Validate and update business registration number input
  const _businessNumbervalidate = (value) => {
    //console.log("call number----", value);

    // Check if value is empty
    if (value === "") {
      setBusinessRegistrationNumber({
        value: value,
        err: "*Please enter business registration number.",
      });
    } else {
      // Clear error message if value is provided
      setBusinessRegistrationNumber({ value: value, err: "" });
    }

    // Check if value is provided and if it's a valid alphanumeric value
    if (value && !alphanumeric.test(value)) {
      setBusinessRegistrationNumber({
        value: value,
        err: "*Please enter valid business registration number.",
      });
    } else {
      // Clear error message if value is valid
      setBusinessRegistrationNumber({ value: value, err: "" });
    }
  };

  // Validate and update HST number input
  const _hstNovalidate = (value) => {
    // Check if value is provided and if it's a valid alphanumeric value
    if (value && !alphanumeric.test(value)) {
      setHSTNo({ value: value, err: "*Please enter valid HST number." });
    } else {
      // Clear error message if value is valid
      setHSTNo({ value: value, err: "" });
    }
  };

  // Validate and update location type input
  const _locationTypevalidate = (value) => {
    //console.log("check type====", value);

    // Check if value is empty
    if (value === "") {
      setLocationType({ value: value, err: "*Please enter location type." });
    } else {
      // Clear error message if value is provided
      setLocationType({ value: value, err: "" });
    }

    // Check if value is provided and if it's a valid name value
    if (value && !nameVaidation.test(value)) {
      setLocationType({
        value: value,
        err: "*Please enter valid location type.",
      });
    } else {
      // Clear error message if value is valid
      setLocationType({ value: value, err: "" });
    }
  };

  // Add a new empty document field when the user clicks "Add Document"
  const handleAddDocumentFields = async () => {
    // Create a new document field with default values and add it to the list
    setDocumentFields([
      ...documentFields,
      { businessDocumentType: "", idNumber: "", fileName: "" },
    ]);
  };

  // Remove a specific identification row when the user clicks "Remove" for that row
  const removeIdentificationRow = async (index) => {
    // Check if there is more than one document field before removing
    if (documentFields.length > 1) {
      // Create a new array without the specified index and update the state
      documentFields.splice(index, 1);
      setDocumentFields([...documentFields]);
    }
  };

  // Handle changes in the document fields when the user inputs data
  const handleChange = async (index, e) => {
    //console.log("e", e.target.type, documentFields);
    const value = [...documentFields];

    // Check if the input type is text or select-one (dropdown)
    if (e.target.type === "text" || e.target.type == "select-one") {
      // Update the specific field value at the specified index
      value[index][e.target.name] = e.target.value;
    } else {
      const file = [...identityFile];
      file[index] = e.target.files[0];
      setIdentityFile(file);
      // Update the file name in the document field at the specified index
      value[index][e.target.name] = e.target.files[0].name;
    }
    // Update the documentFields state with the new values
    setDocumentFields(value);

    // Perform document validation after a change
    checkDocumentValidationOnSubmit();
  };

  // Check the validity of document fields when submitting
  const checkDocumentValidationOnSubmit = async () => {
    var valid = true;
    const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
    let errorMsg = documentFields.map((data, key) => {
      let error = {};

      // Check if identification number is empty
      if (data.idNumber === "") {
        error.errorIdNumber = "Identification number is required";
        valid = false;
      } else if (alphanumericRegex.test(data.idNumber) === false) {
        error.errorIdNumber = "Please enter valid identification number";
        valid = false;
      } else {
        error.errorIdNumber = "";
      }

      // Check if identification type is empty
      if (data.businessDocumentType === "") {
        error.errorIdType = "Identification type is required";
        valid = false;
      } else {
        error.errorIdType = "";
      }

      // Check if identification file name is empty
      if (data.fileName === "") {
        error.errorFileName = "Identification file is required";
        valid = false;
      } else {
        error.errorFileName = "";
      }

      return error;
    });

    // Set error messages for document validation
    setDocumentErrors(errorMsg);
    return valid;
  };

  // Validate contact person's name
  const _contactPerson = (name) => {
    if (name === "") {
      setContactPerson({
        value: name,
        err: "*Please enter contact person name.",
      });
    }
    if (name && !firstNameAndLastName.test(name)) {
      setContactPerson({
        value: name,
        err: "*Please enter valid contact person name.",
      });
    } else {
      setContactPerson({ value: name, err: "" });
    }
  };

  // Validate contact number
  const _contactNumber = (num) => {
    if (num === "") {
      setContactNumber({ value: num, err: "*Please enter contact number." });
    } else if (!numbers.test(num)) {
      setContactNumber({
        value: num,
        err: "*Please enter valid contact number.",
      });
    } else if (num.length < 10) {
      setContactNumber({
        value: num,
        err: "*Please enter valid contact number.",
      });
    } else {
      setContactNumber({ value: num, err: "" });
    }
  };

  // Validate contact email
  const _contactEmail = (email) => {
    if (email === "") {
      setContactEmail({ value: email, err: "*Please enter email." });
    } else if (!emailRegex.test(email)) {
      setContactEmail({ value: email, err: "*Please enter valid email." });
    } else if (email.length < 10) {
      setContactEmail({ value: email, err: "*Please enter valid email." });
    } else {
      setContactEmail({ value: email, err: "" });
    }
  };

  // Toggle password visibility
  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };

  // Toggle confirm password visibility
  const handleShowCPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowCPassword(true);
    } else {
      setIsShowCPassword(false);
    }
  };

  // Validate password
  const _passwordvalidate = (pass) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pass === "") {
      setPass({ value: pass, err: "*Please enter password." });
    } else if (/([A-Z]+)/g.test(pass) && pass.length < 8) {
      setPass({
        value: pass,
        err: "*Password must be at least 8 characters in length and include lowercase letters (a-z), uppercase letters (A-Z), numbers (0-9), and special characters ($@$!%*?&).",
      });
    } else if (!pattern.test(pass)) {
      setPass({
        value: pass,
        err: "*Password must include at least one special character ($@$!%*?&).",
      });
    } else {
      setPass({ value: pass, err: "" });
    }
  };

  // Confirm password validator
  const _confirmpass = (passs) => {
    if (passs === "") {
      setConfirmPass({
        value: passs,
        err: "*Please enter confirm password.",
      });
    } else if (pass.value != passs) {
      setConfirmPass({
        value: passs,
        err: "*New password and confirm password do not match.",
      });
    } else {
      setConfirmPass({ value: passs, err: "" });
    }
  };

  // Update Unit Function
  const handleSubmit = async () => {
    //console.log("idfile", identityFile);
    var isDocument = await checkDocumentValidationOnSubmit();
    if (locationName.value === "") {
      setLocationName({
        value: locationName.value,
        err: "*Please enter location name.",
      });
    }
    if (locationType.value === "") {
      setLocationType({
        value: locationType.value,
        err: "*Please enter location type.",
      });
    }
    // if (
    //   businessRegistrationNumber.value === "" ||
    //   !businessRegistrationNumber.value
    // ) {
    //   setBusinessRegistrationNumber({
    //     value: businessRegistrationNumber.value,
    //     err: "*Please enter business register number.",
    //   });
    // }
    // if (HSTNo.value === "") {
    //     setHSTNo({ value: HSTNo.value, err: "*Please enter HST number." });
    // }

    if (contactPerson.value === "" || !contactPerson.value) {
      setContactPerson({
        value: contactPerson.value,
        err: "*Please enter contact name.",
      });
    }

    if (contactNumber.value === "" || !contactNumber.value) {
      setContactNumber({
        value: contactNumber.value,
        err: "*Please enter contact number.",
      });
    }

    if (contactEmail.value === "" || !contactEmail.value) {
      setContactEmail({
        value: contactEmail.value,
        err: "*Please enter email.",
      });
    }
    // if (pass.value === "") {
    //   setPass({ value: pass.value, err: "*Please enter password." });
    // }
    // if (confirmPass.value === "") {
    //   setConfirmPass({
    //     value: confirmPass.value,
    //     err: "*Please enter confirm password.",
    //   });
    // } else if (pass.value !== confirmPass.value) {
    //   setConfirmPass({
    //     value: confirmPass.value,
    //     err: "*New password and confirm password do not match.",
    //   });
    //   return;
    // }
    // console.log(
    //   "check value---",
    //   isDocument,
    //   locationName.value,
    //   locationType.value,
    //   businessRegistrationNumber.value,
    //   HSTNo.value,
    //   contactPerson.value,
    //   contactEmail.value,
    //   contactNumber.value
    // );
    if (
      locationName.value &&
      locationType.value &&
      contactPerson.value &&
      contactEmail.value &&
      contactNumber.value
    ) {
      if (isDocument) {
        var formData = new FormData();
        formData.append("locationId", id);
        formData.append("userId", userId);
        formData.append("locationName", locationName.value);
        formData.append("locationType", locationType.value);
        formData.append(
          "businessRegistrationNumber",
          businessRegistrationNumber.value
        );
        formData.append("HSTNo", HSTNo.value);
        formData.append("contactPerson", contactPerson.value);
        formData.append("email", contactEmail.value);
        formData.append("mobileNumber", contactNumber.value);
        formData.append("password", pass.value);
        formData.append("lat", latitude);
        formData.append("long", longitude);
        formData.append("profileImage", profilePhoto);
        formData.append(
          "businessProofDocuments",
          JSON.stringify(documentFields)
        );
        for (let i = 0; i < identityFile.length; i++) {
          if (identityFile[i] !== undefined) {
            formData.append(`businessDocument`, identityFile[i]);
          }
        }
        // identityFile.map((item, i) =>
        //   formData.append(`businessDocument`, item)
        // );
        // formData.append("isRegister",true)
        setLoading(true);
        const response = await ApiClient.multipart(
          "patch",
          API_UpdateUnitList + id,
          formData,
          `Bearer ${token}`
        );
        // console.log(JSON.stringify(response), "response after signup");
        // setLoading(false);
        if (response?.data?.code === 401) {
          // setIsloading(false);
          toast.error(response?.data?.message);
          navigate(`/login`);
        }
        if (response && response.data.code === 200) {
          // navigate(`/work_location`,{isRegister:true});
          toast.success(response.data.message, "green");
          // navigate(`/my_units?isHeadOffice=` + 2);
          navigate(`/work_location`);
        } else if (response?.data?.code === 401) {
          toast.error(response?.data?.message);
          navigate(`/login`);
        } else {
          //console.log(response.data.message);
          toast.error(response.data.message, "red");
        }
      }
    }
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Header />
      <section className="add_unit">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="regForm">
                <form>
                  <div className="regBox">
                    <div className="location_title">
                      <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                          {/* <Link to="/work_location" className="backBtn"><span className='left'><i className="fas fa-arrow-left"></i></span></Link> */}
                          <h4>Update Unit</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="avatar">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-md-12">
                        <h5 className="head_title"> Location Details</h5>
                      </div>
                      <div className="col-12">
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                aria-describedby=""
                                placeholder="Location Name"
                                // value={locationName.value}
                                maxLength={100}
                                required
                                ref={autoCompleteRef}
                                value={query}
                                onChange={(event) => {
                                  setQuery(event.target.value);
                                  _namevalidate(event.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-briefcase"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={locationName.err} />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <select
                                className="form-control"
                                value={locationType.value}
                                required
                                defaultValue={locationType.value}
                                onChange={(e) => {
                                  setLocationType(e.target.value);
                                  _locationTypevalidate(e.target.value);
                                }}
                              >
                                <option selected value="">
                                  Location Type
                                </option>
                                <option value="Branch">Branch</option>
                                {/* <option value="Head Office">Head Office</option> */}
                              </select>
                              <span>
                                <i className="fas fa-caret-down"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={locationType.err} />
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                aria-describedby=""
                                placeholder="HST No."
                                maxLength={25}
                                value={HSTNo.value}
                                required
                                onChange={(e) => {
                                  setHSTNo(e.target.value);
                                  _hstNovalidate(e.target.value);
                                }}
                              />
                              {/* <span><i className="fas fa-birthday-cake"></i></span> */}
                            </div>
                            <ErrorMessage messerror={HSTNo.err} />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <h5 className="head_title"> Contact Details</h5>
                      </div>
                      <div className="col-12">
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                aria-describedby=""
                                placeholder="Contact Person"
                                maxLength={64}
                                value={contactPerson.value}
                                onChange={(e) => {
                                  setContactPerson(e.target.value);
                                  _contactPerson(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-briefcase"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={contactPerson.err} />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                aria-describedby=""
                                placeholder="Email"
                                maxLength={120}
                                value={contactEmail.value}
                                onChange={(e) => {
                                  setContactEmail(e.target.value);
                                  _contactEmail(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-envelope"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={contactEmail.err} />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                aria-describedby=""
                                placeholder="Contact No."
                                maxLength={10}
                                value={contactNumber.value}
                                onChange={(e) => {
                                  setContactNumber(e.target.value);
                                  _contactNumber(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-phone-alt"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={contactNumber.err} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="identityBox">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="identityTitle">
                          {/* <h5>Upload Business Proof</h5> */}
                          <h5>Upload Account Creator's Identification</h5>
                          <div className="editDetail">
                            <a
                              className="btn addBtn"
                              type="button"
                              onClick={handleAddDocumentFields}
                            >
                              <span style={{ marginRight: "5px" }}>
                                <i className="fas fa-plus-circle"></i>
                              </span>
                              <span className="add-span">Add</span>
                            </a>
                          </div>
                        </div>
                      </div>
                      {documentFields.map((identificationField, i) => {
                        return (
                          <>
                            <div className="row">
                              <div className="col-12">
                                <div className="identiBox" key={i}>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <select
                                          className="form-control"
                                          aria-label="Default select example"
                                          name="businessDocumentType"
                                          value={
                                            identificationField.businessDocumentType
                                          }
                                          defaultValue="Select Identification Type"
                                          onChange={(e) => handleChange(i, e)}
                                        >
                                          <option selected value="">
                                            Select Identification Type
                                          </option>
                                          <option value="Drivers license">
                                            Drivers license
                                          </option>
                                          <option value="Permanent resident card">
                                            Permanent resident card
                                          </option>
                                          <option value="Other">Other</option>
                                          <option value="Canadian passport">
                                            Canadian passport
                                          </option>
                                        </select>
                                        <span>
                                          <i className="fas fa-caret-down"></i>
                                        </span>
                                      </div>
                                      <ErrorMessage
                                        messerror={
                                          documentErrors.length - 1 >= i &&
                                          documentErrors[i].errorIdType
                                        }
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="exampleInputtext"
                                          aria-describedby="textHelp"
                                          onChange={(e) => handleChange(i, e)}
                                          name="idNumber"
                                          value={identificationField.idNumber}
                                          maxLength={15}
                                          placeholder="Identification No."
                                        />
                                      </div>
                                      <ErrorMessage
                                        messerror={
                                          documentErrors.length - 1 >= i &&
                                          documentErrors[i].errorIdNumber
                                        }
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <div className="form-group">
                                        <label className="update_files">
                                          <input
                                            type="file"
                                            className="form-control d-none"
                                            htmlFor="exampleInputtext"
                                            aria-describedby="textHelp"
                                            name="fileName"
                                            // value={identificationField.fileName}
                                            id="fileName"
                                            accept="image/*"
                                            placeholder="Upload Identification"
                                            onChange={(e) => handleChange(i, e)}
                                          />
                                          {identificationField?.fileName
                                            ? identificationField?.fileName
                                            : "No file chosen"}
                                          {/* {identityFile.length === 0
                                ? item?.state?.item?.file
                                : ""} */}
                                          <span>
                                            <i className="fas fa-paperclip"></i>
                                          </span>
                                        </label>
                                      </div>
                                      <ErrorMessage
                                        messerror={
                                          documentErrors.length - 1 >= i &&
                                          documentErrors[i].errorFileName
                                        }
                                      />
                                    </div>
                                  </div>
                                  {documentFields &&
                                  documentFields.length > 1 ? (
                                    <span
                                      className="closeBtn"
                                      onClick={(e) =>
                                        removeIdentificationRow(i)
                                      }
                                    >
                                      <i
                                        className="fas fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div className="nextBtn">
                    {/* <Link to="/work_location">Submit</Link> */}
                    <a type="button" onClick={() => handleSubmit()}>
                      Submit
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UpdateUnits;
