import React from 'react';
import {Link} from 'react-router-dom';
import Wel_Header from '../../Pages/Wel_header/Wel_header';
import './otp.scss';

const Otp = () => {
  return (
    <>
    <Wel_Header />
      <section className="otp_page">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-md-7 col-lg-5">
                      <div className="regForm">
                          <form>
                                <div className='regBox'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <h4>Enter OTP</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className='avatar'>
                                    <div className='row align-items-center'>
                                        <div className='col-12'>
                                            <div className='row align-items-center'>
                                                <div className='col-12'>
                                                    <div className="form-group">
                                                        <input type="text" className="form-control" id="exampleInputtext" aria-describedby="textHelp" placeholder="Enter OTP" />
                                                        <span><i className="fas fa-envelope"></i></span>
                                                    </div>
                                                    <p>Enter 4 Digit OTP send on your email address.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="nextBtn">
                                    <Link to="/work_location">Submit <i className="fa-solid fa-arrow-right"></i></Link>
                                    <Link to="/" className='resend_btn'> Resend OTP?</Link>
                                </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default Otp;
