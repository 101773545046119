import React, { useState, useEffect } from "react";
import "./setting.scss";
import {
  FaAngleRight,
  FaFacebookF,
  FaGoogle,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import Header from "../../components/Header/Header";
import {
  API_LOGIN,
  API_SocialLogIn,
  API_ChangePassword,
  API_UpdateNotification,
  API_GetUserProfile,
  clientIdKey,
} from "../../api/Constant";
import ApiClient from "../../api/apiClient";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";

const Settings = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [description, setDescription] = useState("");
  const [termDescription, setTermDescription] = useState("");
  const [password, setPassword] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordIsRequiredError, setPasswordIsRequiredError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [oldPasswordIsRequiredError, setOldPasswordIsRequiredError] =
    useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [confirmPasswordIsRequiredError, setNewPasswordIsRequiredError] =
    useState(false);
  const [charNumberValid, setCharNumberValid] = useState(false);
  const [specialCharValid, setSpecialCharValid] = useState(false);
  const [uppercaseValid, setUppercaseValid] = useState(false);
  const [numberValid, setNumberValid] = useState(false);

  let [newJobSms, setNewJobSms] = useState(false);
  let [newJobEmail, setNewJobEmail] = useState(false);
  let [newJobNotification, setNewJobNotification] = useState(false);

  let [chatSms, setChatSms] = useState(false);
  let [chatEmail, setChatEmail] = useState(false);
  let [chatNotification, setChatNotification] = useState(false);

  let [jobAwardSms, setJobAwardSms] = useState(false);
  let [jobAwardEmail, setJobAwardEmail] = useState(false);
  let [jobAwardNotification, setJobAwardNotification] = useState(false);

  let [jobCancelSms, setJobCancelSms] = useState(false);
  let [jobCancelEmail, setJobCancelEmail] = useState(false);
  let [jobCancelNotification, setJobCancelNotification] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [guard, setGuard] = useState("");
  const [isShowOldPassword, setIsShowOldPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowCnfPassword, setIsShowCnfPassword] = useState(false);

  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;
  let notificationStatus = false;
  let [notification, setNotification] = useState(false);

  var err = false;

  useEffect(() => {
    if (token) {
      // getPrivacyPolicy();
      // getTermsAndCondition();
      getGuardProfilef();
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
    // setToken(sessionStorage.getItem("token"))
  }, []);

  // Get Guard Profile Data
  const getGuardProfilef = async () => {
    // const id = cookie.get("guardId");
    const data = "";
    let response = await ApiClient.get(
      API_GetUserProfile + `${userId}`,
      data,
      `Bearer ${token}`
    );
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/login`);
    }
    // const response = await getGuardProfile({ id });
    // //console.log("guard profile response", response);
    if (response?.data?.code === 200) {
      //   setGuardOtherInfo(response.data.data);
      setGuard(response?.data?.data);
      setChatSms(response?.data?.data?.chat?.isSMS);
      setChatEmail(response?.data?.data?.chat?.isEmail);
      setChatNotification(response?.data?.data?.chat?.isNotification);
      setNewJobSms(response?.data?.data?.newJob?.isSMS);
      setNewJobEmail(response?.data?.data?.newJob?.isEmail);
      setNewJobNotification(response?.data?.data?.newJob?.isNotification);
      setJobAwardSms(response?.data?.data?.jobAward?.isSMS);
      setJobAwardEmail(response?.data?.data?.jobAward?.isEmail);
      setJobAwardNotification(response?.data?.data?.jobAward?.isNotification);
      setJobCancelNotification(response?.data?.data?.jobCancel?.isNotification);
      setJobCancelEmail(response?.data?.data?.jobCancel?.isEmail);
      setJobCancelSms(response?.data?.data?.jobCancel?.isSMS);
      notificationStatus = response?.data?.data?.isNotification;
      setNotification(response?.data?.data?.isNotification);
    }
  };

  // Handle Old Password Function
  const handleOldPass = (value) => {
    if (value === "") {
      err = true;
      setOldPasswordIsRequiredError(true);
      return;
    } else {
      setOldPasswordIsRequiredError(false);
      err = false;
      setOldPassword(value);
    }
  };

  // Check the length of the input
  const checkPasswordLength = (password) => {
    if (password.length >= 8) {
      err = true;
      setCharNumberValid(true);
    } else {
      err = false;
      setCharNumberValid(false);
    }
  };

  // Check for special characters
  const checkSpecialCharacters = (password) => {
    const pattern = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    if (pattern.test(password)) {
      err = true;
      setSpecialCharValid(true);
    } else {
      err = false;
      setSpecialCharValid(false);
    }
  };

  // Check for an uppercase character
  const checkUppercase = (password) => {
    const pattern = /[A-Z]/;
    if (pattern.test(password)) {
      err = true;
      setUppercaseValid(true);
    } else {
      err = false;
      setUppercaseValid(false);
    }
  };

  // Check for a number
  const checkNumber = (password) => {
    const pattern = /[0-9]/;
    if (pattern.test(password)) {
      err = true;
      setNumberValid(true);
    } else {
      err = false;
      setNumberValid(false);
    }
  };

  // Function to validate password criteria
  const passwordFunction = () => {
    if (numberValid && uppercaseValid && charNumberValid && specialCharValid) {
      setPasswordError(false);
      err = false;
    } else {
      err = true;
      setPasswordError(true);
    }
  };

  // Function to handle changes in the new password field
  const handleNewPass = (value) => {
    if (value === "") {
      err = true;
      // Reset password-related state when the field is empty
      setPasswordError(false);
      setNumberValid(true);
      setUppercaseValid(true);
      setSpecialCharValid(true);
      setCharNumberValid(true);
      return;
    } else {
      // Validate password criteria
      checkPasswordLength(value);
      checkSpecialCharacters(value);
      checkUppercase(value);
      checkNumber(value);
      passwordFunction();
      setPasswordIsRequiredError(false);
      err = false;
      // Update state with the new password value
      setPassword(value);
    }
  };

  // Function to handle changes in push notification settings
  const handlePushNotification = (value) => {
    //console.log(value);
    setNotification(!notification);
    //console.log(notification);
    // if (value === "on" && notificationStatus === false) {
    //   notificationStatus = true;
    // } else {
    //   notificationStatus = false;
    // }
  };

  // Function to handle changes in new job notification settings
  const handleNewJobNotification = (value) => {
    //console.log("handlePushNotification value", JSON.stringify(value));
    // Toggle appropriate new job notification state
    if (value === "newJobSms") {
      setNewJobSms(!newJobSms);
    } else if (value === "newJobEmail") {
      setNewJobEmail(!newJobEmail);
    } else if (value === "newJobNotification") {
      setNewJobNotification(!newJobNotification);
    } else {
      // If none of the values match, reset all new job notification states
      setNewJobSms(false);
      setNewJobEmail(false);
      setNewJobNotification(false);
    }
  };

  // Function to handle changes in chat notification settings
  const handleChatNotification = (value) => {
    //console.log("handleChatNotification value", JSON.stringify(value));
    // Toggle appropriate chat notification state
    if (value === "chatSms") {
      setChatSms(!chatSms);
    } else if (value === "chatEmail") {
      setChatEmail(!chatEmail);
    } else if (value === "chatNotification") {
      setChatNotification(!chatNotification);
    } else {
      // If none of the values match, reset all chat notification states
      setChatSms(false);
      setChatEmail(false);
      setChatNotification(false);
    }
  };

  // Function to handle changes in job award notification settings
  const handleJobAwardNotification = (value) => {
    //console.log("handleJobAwardNotification value", JSON.stringify(value));
    // Toggle appropriate job award notification state
    if (value === "jobAwardSms") {
      setJobAwardSms(!jobAwardSms);
    } else if (value === "jobAwardEmail") {
      setJobAwardEmail(!jobAwardEmail);
    } else if (value === "jobAwardNotification") {
      setJobAwardNotification(!jobAwardNotification);
    } else {
      // If none of the values match, reset all job award notification states
      setJobAwardSms(false);
      setJobAwardEmail(false);
      setJobAwardNotification(false);
    }
  };

  // Function to handle changes in job cancel notification settings
  const handleJobCancelNotification = (value) => {
    //console.log("handleJobCancelNotification value", JSON.stringify(value));
    // Toggle appropriate job cancel notification state
    if (value === "jobCancelSms") {
      setJobCancelSms(!jobCancelSms);
    } else if (value === "jobCancelEmail") {
      setJobCancelEmail(!jobCancelEmail);
    } else if (value === "jobCancelNotification") {
      setJobCancelNotification(!jobCancelNotification);
      //console.log(jobCancelNotification);
    } else {
      // If none of the values match, reset all job cancel notification states
      setJobCancelSms(false);
      setJobCancelEmail(false);
      setJobCancelNotification(false);
    }
  };

  // Update Notification Setting Function
  const handleUpdateNotification = async () => {
    const requestedData = {
      newJob: {
        isSMS: newJobSms,
        isEmail: newJobEmail,
        isNotification: newJobNotification,
      },
      chat: {
        isSMS: chatSms,
        isEmail: chatEmail,
        isNotification: chatNotification,
      },
      jobAward: {
        isSMS: jobAwardSms,
        isEmail: jobAwardEmail,
        isNotification: jobAwardNotification,
      },
      jobCancel: {
        isSMS: jobCancelSms,
        isEmail: jobCancelEmail,
        isNotification: jobCancelNotification,
      },
      isNotification: notification,
    };
    let response = await ApiClient.post(
      API_UpdateNotification + `${userId}`,
      requestedData,
      `Bearer ${token}`
    );
    if (response?.data?.code === 401) {
      // setIsloading(false);
      toast.error(response?.data?.message);
      navigate(`/login`);
    }
    // const response = await updateNotificationType(requestedData);
    if (response?.data?.code === 200) {
      navigate(`/setting`);
      // window.location.href = "/setting";
      toast.success(response?.data?.message);
    }
    // //console.log("updateNotificationType", JSON.stringify(response));
  };
  // Handle Change Password Function
  const handlePassword = async () => {
    setOldPasswordIsRequiredError(true);
    setPasswordIsRequiredError(true);
    setNewPasswordIsRequiredError(true);
    // setConfirmPasswordError(true);
    handleOldPass(oldpassword);
    handleNewPass(password);
    handleConfirmPass(confirmPassword);
    //console.log(oldpassword);

    if (oldpassword === "") {
      return;
    }

    if (password === "") {
      return;
    }

    if (oldpassword !== "" && password !== "") {
      if (oldpassword === password) {
        err = true;
        toast.warning("Old password and new password should be differnt");
        return;
      }
    }

    //console.log("Called");

    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
      toast.warning("Password mismatch");
      return;
    }

    if (err === false) {
      const requestedData = {
        oldPassword: oldpassword,
        password: password,
      };
      //console.log("i");
      // const response = await changePassword(requestedData);

      let response = await ApiClient.post(
        API_ChangePassword,
        requestedData,
        `Bearer ${token}`
      );
      // //console.log("handlePassword response", response);
      if (response?.data?.code === 401) {
        // setIsloading(false);
        toast.error(response?.data?.message);
        navigate(`/login`);
      }
      if (response.data.code === 200) {
        localStorage.removeItem("userInfo");
        navigate(`/login`);
        toast.success(response?.data?.message);
      } else {
        toast.error(response.data.message);
      }
    }
  };

  // Handle Confirm Password Validation
  const handleConfirmPass = (value) => {
    value === ""
      ? setNewPasswordIsRequiredError(true)
      : setNewPasswordIsRequiredError(false);

    if (password !== value) {
      setConfirmPasswordError(true);
      err = true;
      return;
    } else {
      err = false;
      setConfirmPassword(value);
      setNewPasswordIsRequiredError(false);
      setConfirmPasswordError(false);
    }
  };

  //Call Facebook with Login
  async function responseFacebook(res) {
    //console.log(res);
    //console.log("login with facebook res", JSON.stringify(res));
    const data = {
      email: res.email,
      socialType: 1,
      socialId: res.id,
      deviceToken: "dfdf456789",
      deviceId: "",
      deviceType: 1,
    };
    const response = await ApiClient.post(
      API_SocialLogIn,
      data,
      "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
    );
    // //console.log('response', JSON.stringify(response))
    if (response.data.code == 200) {
      // alert(response.data.message)
      toast.success("Connected", "green");
      // localStorage.setItem("userInfo", JSON.stringify(response.data.data));
      // navigate(`/my_profile`);
      // navigate(`/thankYou?isLicense=` + "full");
    } else {
      toast.error(response.data.message, "red");
      //console.log(response.data.message);
    }
  }

  const handleOldPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowOldPassword(true);
    } else {
      setIsShowOldPassword(false);
    }
  };

  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };

  const handleShowCnfPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowCnfPassword(true);
    } else {
      setIsShowCnfPassword(false);
    }
  };

  const onLoginSuccess = (res) => {
    //console.log("Login Success:", res.profileObj);
    localStorage.setItem("userInfo", JSON.stringify(res.profileObj));
    if (res.profileObj.googleId && res.profileObj.email) {
      res.profileObj["id"] = res.profileObj.googleId;
      responseFacebook(res.profileObj);
    }
    // setShowloginButton(false);
    // setShowlogoutButton(true);
    // navigate(`/thankYou?isLicense=` + "full");
  };

  const onLoginFailure = (res) => {
    //console.log("Login Failed:", res);
  };

  return (
    <>
      <Header />
      <section className="settingBox">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="row justify-content-center">
                <div className="col-md-6 col-lg-4">
                  <div className="alertOuter">
                    <div className="social_login">
                      <h6>LogIn with Social Media</h6>
                      <div className="fbBtn">
                        <FacebookLogin
                          className="facebookButton"
                          cssClass="button"
                          isDisabled={guard?.socialFacebookId !== ""}
                          textButton={
                            guard?.socialFacebookId !== ""
                              ? "Connected"
                              : "Login With Facebook"
                          }
                          appId="705391504029824" //APP ID NOT CREATED YET
                          fields="name,email,picture"
                          icon={<FaFacebookF className="icon-fb" />}
                          callback={responseFacebook}
                        />
                        {/* <button type="button">
                            {" "}
                            <span>
                              <FaFacebookF />
                            </span>
                            Login With Facebook
                          </button> */}
                      </div>
                      <div className="googleBtn">
                        {/* <button type="button" onClick={(e) => socialLogin(e)}>
                            {" "}
                            <span>
                              <img src={Google} className="img-fluid" />
                            </span>{" "}
                            LogIn With Google
                          </button> */}
                        <GoogleLogin
                          className="googleButton"
                          clientId={clientIdKey} //CLIENTID NOT CREATED YET
                          disabled={guard?.socialGmailId !== ""}
                          buttonText={
                            guard?.socialGmailId !== ""
                              ? "Connected"
                              : "Login With Google"
                          }
                          // textButton={
                          //   guard?.socialGmailId !== ""
                          //     ? "Connected"
                          //     : "Login With Google"
                          // }
                          cssClass="button"
                          onSuccess={() =>
                            guard?.socialGmailId === "" ? onLoginSuccess : ""
                          }
                          onFailure={onLoginFailure}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="alertOuter mt-3">
                    <h6>Change Password</h6>
                    <div className="form-group">
                      <input
                        onChange={(event) => handleOldPass(event.target.value)}
                        type={!isShowOldPassword ? "password" : "text"}
                        className="form-control"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="Old Password"
                      />
                      <span>
                        {!isShowOldPassword ? (
                          <FaEye
                            onClick={(e) => handleOldPassword(e, "show")}
                          />
                        ) : (
                          <FaEyeSlash
                            onClick={(e) => handleOldPassword(e, "hide")}
                          />
                        )}
                      </span>
                    </div>
                    {/* {oldPasswordError ? (
                      <div className="invalid-feedback">
                        Password not match to your current password
                      </div>
                    ) : (
                      ""
                    )} */}
                    {oldPasswordIsRequiredError ? (
                      <div className="invalid-feedback">
                        Old Password is required
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <input
                        onChange={(event) => handleNewPass(event.target.value)}
                        type={!isShowPassword ? "password" : "text"}
                        className="form-control"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="New Password"
                      />
                      <span>
                        {!isShowPassword ? (
                          <FaEye
                            onClick={(e) => handleShowPassword(e, "show")}
                          />
                        ) : (
                          <FaEyeSlash
                            onClick={(e) => handleShowPassword(e, "hide")}
                          />
                        )}
                      </span>
                    </div>
                    {passwordError ? (
                      <div className="invalid-feedback">
                        Password at least 8 characters in length.
                        <br />
                        Lowercase letters (a-z)
                        <br />
                        Uppercase letters (A-Z)
                        <br />
                        Numbers (0-9)
                        <br />
                        Special characters ($@$!%*?&) <br />
                      </div>
                    ) : (
                      ""
                    )}
                    {passwordIsRequiredError ? (
                      <div className="invalid-feedback">
                        Password is required
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="form-group">
                      <input
                        onChange={(event) =>
                          handleConfirmPass(event.target.value)
                        }
                        type={!isShowCnfPassword ? "password" : "text"}
                        className="form-control"
                        id="exampleInputtext"
                        aria-describedby="textHelp"
                        placeholder="Confirm Password"
                      />
                      <span>
                        {!isShowCnfPassword ? (
                          <FaEye
                            onClick={(e) => handleShowCnfPassword(e, "show")}
                          />
                        ) : (
                          <FaEyeSlash
                            onClick={(e) => handleShowCnfPassword(e, "hide")}
                          />
                        )}
                      </span>
                    </div>
                    {confirmPasswordError ? (
                      <div className="invalid-feedback">Password mismatch</div>
                    ) : (
                      ""
                    )}
                    {confirmPasswordIsRequiredError ? (
                      <div className="invalid-feedback">
                        Confirm Password is required
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="submitBtn">
                      <a type="button" onClick={handlePassword}>
                        Submit
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="notiFi">
                    <h6>Push Notifications</h6>
                    <div className="toggle-button-cover">
                      <div className="button-cover">
                        <div className="button r" id="button-1">
                          <input
                            type="checkbox"
                            checked={notification === true}
                            onChange={(e) => {
                              handlePushNotification(e.target.value);
                            }}
                            value="notification"
                            className="checkbox"
                          />
                          <div className="knobs"></div>
                          <div className="layer"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="alertOuter mt-3">
                    <h6>Notification Alert Type</h6>
                    <div className="alertInner">
                      <div className="alertBox">
                        <div className="row mb-3">
                          <div className="col-4 col-md-3"></div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <p>SMS</p>
                              </div>
                              <div className="col-3 col-md-3">
                                <p>Email</p>
                              </div>
                              <div className="col-6 col-md-6">
                                <p>Notification</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4 col-md-3">
                            <h6>New Job</h6>
                          </div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={newJobSms === true}
                                    onChange={(e) =>
                                      handleNewJobNotification(e.target.value)
                                    }
                                    type="checkbox"
                                    value="newJobSms"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={newJobEmail === true}
                                    onChange={(e) =>
                                      handleNewJobNotification(e.target.value)
                                    }
                                    type="checkbox"
                                    value="newJobEmail"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={newJobNotification === true}
                                    onChange={(e) =>
                                      handleNewJobNotification(e.target.value)
                                    }
                                    type="checkbox"
                                    value="newJobNotification"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4 col-md-3">
                            <h6>Chat</h6>
                          </div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    checked={chatSms === true}
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleChatNotification(e.target.value)
                                    }
                                    value="chatSms"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    checked={chatEmail === true}
                                    className="form-check-input"
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleChatNotification(e.target.value)
                                    }
                                    value="chatEmail"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={chatNotification === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleChatNotification(e.target.value)
                                    }
                                    value="chatNotification"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4 col-md-3">
                            <h6>Job Award</h6>
                          </div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobAwardSms === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobAwardNotification(e.target.value)
                                    }
                                    value="jobAwardSms"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobAwardEmail === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobAwardNotification(e.target.value)
                                    }
                                    value="jobAwardEmail"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobAwardNotification === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobAwardNotification(e.target.value)
                                    }
                                    value="jobAwardNotification"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-4 col-md-3">
                            <h6>Job Cancel</h6>
                          </div>
                          <div className="col-8 col-md-9">
                            <div className="row">
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobCancelSms === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobCancelNotification(
                                        e.target.value
                                      )
                                    }
                                    value="jobCancelSms"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-3 col-md-3">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobCancelEmail === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobCancelNotification(
                                        e.target.value
                                      )
                                    }
                                    value="jobCancelEmail"
                                    id="flexCheckChecked"
                                  />
                                </div>
                              </div>
                              <div className="col-6 col-md-6">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    checked={jobCancelNotification === true}
                                    type="checkbox"
                                    onChange={(e) =>
                                      handleJobCancelNotification(
                                        e.target.value
                                      )
                                    }
                                    value="jobCancelNotification"
                                    id="jobCancelNotification"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="submitBtn">
                              <a
                                type="button"
                                onClick={() => handleUpdateNotification()}
                              >
                                Submit
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Settings;
