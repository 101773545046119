export const emailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const numbers = /^[0-9]*$/;

export const password =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;

export const string = /^[a-zA-Z !é]{2,}(?: [a-zA-Z !é]+){0,2}$/;

export const Vat = /[^a-zA-Z0-9 ]/;

export const location =
  /^(\d{1,}) [a-zA-Z0-9\s]+(\.)? [a-zA-Z]+(\,)? [A-Z]{2} [0-9]{5,6}/; //88 Journal Square Jersey City, NJ 07306

export const unit_number = /^[0-9a-zA-Z]*$/;
export const alphanumeric = /^[0-9a-zA-Z ]*$/;
export const alpha = /^[a-zA-Z]{2,}$/;
export const firstNameAndLastName = /^[a-zA-Z]{2,}(?: [a-zA-Z]+){0,2}$/;
// export const firstNameAndLastName = /^[a-zA-Z]+\s[a-zA-Z]+$/;
export const alphanumericWithSpec = /^[0-9a-zA-Z-/ ]*$/;
export const nameVaidation = /^[0-9a-zA-Z ]*$/;

export const re =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/;
