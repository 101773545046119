import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiClient from "../../api/apiClient";
import { API_Forgot } from "../../api/Constant";
import { emailRegex } from "../../constant";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import "./forgot_password.scss";
import { toast } from 'react-toastify';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errorEmail, setErrorEmail] = useState(null);
  const [mess, setMess] = useState("");
  const _emailvalidate = (mail) => {
    if (mail === "") {
      setErrorEmail("*Please enter email.");
    } else if (!emailRegex.test(mail)) {
      setErrorEmail("*Please enter valid email.");
    } else {
      setErrorEmail(null);
    }
  };

  const validate = async () => {
    if (!emailRegex.test(email)) {
      setErrorEmail("*Please enter valid email.");
    }
    else {
      callApiToForgot();
    }
  };

  //call forgot api
  async function callApiToForgot() {
    // alert("hello");
    let data = {
      email: email,
    };
    // let response = await ApiClient.post(API_LOGIN, data, null);
    let response = await ApiClient.post(API_Forgot, data, "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1")
    // console.log(response, "response1")
    if (response.data.code == 200) {
      setEmail("")
      //   alert(response.data.message)
      setMess(response.data.message);
      toast.success(response.data.message, "green");
      navigate(`/login`);
    }
    else {
      toast.error(response.data.message, "red");
      // alert(response.data.message)
    }
  }

  return (
    <>
      <Wel_Header />
      <section className="forgot_page">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-7 col-lg-5">
              <div className="regForm">
                <form>
                  <div className="regBox">
                    <div className="row">
                      <div className="col-12">
                        <h4>Forget Password</h4>
                      </div>
                    </div>
                  </div>
                  <div className="avatar">
                    <div className="row align-items-center">
                      <div className="col-12">
                        <div className="row align-items-center">
                          <div className="col-12">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control"
                                id="exampleInputtext"
                                aria-describedby="textHelp"
                                placeholder="Email Address"
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                  _emailvalidate(e.target.value);
                                }}
                                value={email}
                              />
                              <span>
                                <i className="fas fa-envelope"></i>
                              </span>
                            </div>
                            {errorEmail != null ? (
                              <div>
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: 13,
                                    fontFamily: "GothamMedium",
                                    marginHorizontal: 35,
                                    textAlign: "left",
                                  }}
                                >
                                  {errorEmail}
                                </p>
                              </div>
                            ) : null}
                            <p>
                              A Reset Password link will sent to your email
                              address.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="nextBtn">
                    {/* <Link
                      to="/"
                      data-bs-toggle="modal"
                      data-bs-target="#forgetModal"
                    > */}
                    <a type="button" onClick={() => validate()}>
                      Send Link <i className="fas fa-arrow-right"></i>
                    </a>
                    {/* </Link> */}

                    <Link to="/login" className="back_login">
                      <i className="fas fa-caret-left"></i> <span>Back To</span> LogIn.
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal forgetModal fade"
        id="forgetModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {/* A link has been sent to your email address. Please check */}
                {mess}
              </h5>
            </div>
            <div className="modal-footer">
              <Link to="/" data-bs-dismiss="modal">
                Go To LogIn <i className="fas fa-caret-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
