import React, { useState, useEffect } from "react";
// import LogoIcon from '../../components/Logo/Logo';
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import Profile from "../../assets/img/user placeholder.png";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import { nameVaidation, alphanumeric } from "../../constant";
import "./business_location.scss";
import ApiClient from "../../api/apiClient";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "@material-ui/core/Modal";
import {
  API_GetProfile,
  API_UpdateUserProfileAfterSignup,
  url,
} from "../../api/Constant";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";

const BusinessLocation = () => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  const navigate = useNavigate();
  const [fullName, setFullName] = useState({ value: "", err: "" });
  const [names, setNames] = useState({ value: "", err: "" });
  const [email, setEmail] = useState({ value: "", err: "" });
  const [phoneNumber, setPhoneNumber] = useState({ value: "", err: "" });
  const [businessName, setBusinessName] = useState({ value: "", err: "" });
  const [businessRegisterNumber, setBusinessRegisterNumber] = useState({
    value: "",
    err: "",
  });
  const [businessType, setBusinessType] = useState({ value: "", err: "" });
  const [identificationNo, setIdentificationNo] = useState({
    value: "",
    err: "",
  });

  const [HSTNo, setHSTNo] = useState({ value: "", err: "" });
  const [profilePhoto, setProfilePhoto] = useState("");
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [documentFields, setDocumentFields] = useState([
    {
      idType: "",
      idNumber: "",
      fileName: "",
    },
  ]);
  const [identityFile, setIdentityFile] = useState([]);
  const [documentErrors, setDocumentErrors] = useState([{}]);
  const [profile, setProfile] = useState("");
  let [notification, setNotification] = useState(false);

  let [loading, setLoading] = useState(false);

  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  useEffect(async () => {
    if (!token) {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    } else {
      getProfileData();
    }
  }, [userId]);

  // Get Profile data
  const getProfileData = async () => {
    // const response = await getGuardProfile({ id: id });
    const response = await ApiClient.get(
      API_GetProfile + userId,
      `Basic ${token}`
    );
    if (response.data.code === 200) {
      //console.log("data=====", response.data.data);
      if (response.data && response.data.data) {
        setEmail({ value: response.data.data.email, err: "" });
        setPhoneNumber({ value: response.data.data.mobileNumber, err: "" });
        setFullName({
          value:
            response.data.data.firstName +
            " " +
            response.data.data.middleName +
            " " +
            response.data.data.lastName,
          err: "",
        });
        setProfile(response.data.data.profileImage);
        if (profile === undefined) {
          setProfile(Profile);
        }
        //console.log(profile);
      }
    }
  };

  // Function to handle push notification toggle
  const handlePushNotification = (value) => {
    setNotification((notification = !notification)); // Toggle the push notification setting
  };

  // Function to validate and set the corporation name
  const _namevalidate = (value) => {
    if (value === "") {
      setBusinessName({ value: value, err: "*Please enter corporation name." });
    } else {
      setBusinessName({ value: value, err: "" });
    }
    if (value && !nameVaidation.test(value)) {
      setBusinessName({
        value: value,
        err: "*Please enter valid corporation name.",
      });
    } else {
      setBusinessName({ value: value, err: "" });
    }
  };

  // Function to validate and set the corporation register number
  const _businessNumbervalidate = (value) => {
    //console.log("call number----", value);
    if (value === "") {
      setBusinessRegisterNumber({
        value: value,
        err: "*Please enter corporation register number.",
      });
    } else {
      setBusinessRegisterNumber({ value: value, err: "" });
    }
    if (value && !alphanumeric.test(value)) {
      setBusinessRegisterNumber({
        value: value,
        err: "*Please enter valid corporation register number.",
      });
    } else {
      setBusinessRegisterNumber({ value: value, err: "" });
    }
  };

  // Function to validate and set the HST number
  const _hstNovalidate = (value) => {
    if (value && !alphanumeric.test(value)) {
      setHSTNo({ value: value, err: "*Please enter valid HST number." });
    } else {
      setHSTNo({ value: value, err: "" });
    }
  };

  // Function to validate and set the corporation type
  const _businessTypevalidate = (value) => {
    //console.log("check type====", value);
    if (value === "") {
      setBusinessType({ value: value, err: "*Please enter corporation type." });
    } else {
      setBusinessType({ value: value, err: "" });
    }
    if (value && !nameVaidation.test(value)) {
      setBusinessType({
        value: value,
        err: "*Please enter valid corporation type.",
      });
    } else {
      setBusinessType({ value: value, err: "" });
    }
  };

  // Function to add a new row to the document fields
  const handleAddDocumentFields = async () => {
    setDocumentFields([
      ...documentFields,
      { idType: "", idNumber: "", fileName: "" },
    ]);
  };

  // Function to remove a specific identification row
  const removeIdentificationRow = async (index) => {
    const value = [...documentFields];
    if (value.length > 1) {
      //console.log("value====", value);
      value.splice(index, 1);
      setDocumentFields(value);
    }
    const file = [...identityFile];
    if (file.length > 1) {
      //console.log("file====", file);
      file.splice(index, 1);
      setIdentityFile(file);
    }
  };

  // Function to handle changes in the document fields
  const handleChange = async (index, e) => {
    const value = [...documentFields];
    if (e.target.type === "text" || e.target.type == "select-one") {
      value[index][e.target.name] = e.target.value;
    } else {
      const allowedBytes = 2 * 1024 * 1024;
      if (e.target.files[0].size > allowedBytes) {
        alert("Size is more than 2 MB");
        return;
      }
      const file = [...identityFile];
      file[index] = e.target.files[0];
      setIdentityFile(file);
      value[index][e.target.name] = e.target.files[0].name;
    }
    setDocumentFields(value);
    checkDocumentValidationOnSubmit();
  };

  // Function to validate document fields on submission
  const checkDocumentValidationOnSubmit = async () => {
    var valid = true;
    const alphanumericRegex = /^[a-zA-Z0-9-. ]+$/;
    let errorMsg = documentFields.map((data, key) => {
      let error = {};
      if (data.idNumber === "") {
        error.errorIdNumber = "Identification number is required";
        valid = false;
      } else if (alphanumericRegex.test(data.idNumber) === false) {
        error.errorIdNumber = "Please enter valid identification number";
        valid = false;
      } else {
        error.errorIdNumber = "";
      }
      if (data.idType === "") {
        error.errorIdType = "Identification type is required";
        valid = false;
      } else {
        error.errorIdType = "";
      }
      if (data.fileName === "") {
        error.errorFileName = "Identification file is required";
        valid = false;
      } else {
        error.errorFileName = "";
      }
      return error;
    });
    setDocumentErrors(errorMsg);
    return valid;
  };

  // Function to submit form without notification
  const handleSubmitWithoutNotification = async () => {
    if (businessName.value === "") {
      setBusinessName({
        value: businessName.value,
        err: "*Please enter corporation name.",
      });
    }
    if (businessType.value === "") {
      setBusinessType({
        value: businessType.value,
        err: "*Please enter incorporation number.",
      });
    }
    if (businessName.value && businessType.value) {
      var formData = new FormData();
      formData.append(
        "businessDetails",
        JSON.stringify([
          {
            businessName: businessName.value,
            businessRegisterNumber: businessRegisterNumber.value,
            businessType: businessType.value,
            HSTNo: HSTNo.value,
          },
        ])
      );

      setLoading(true);
      const response = await ApiClient.multipart(
        "patch",
        API_UpdateUserProfileAfterSignup + userId,
        formData,
        `Basic ${token}`
      );

      setLoading(false);
      if (response?.data?.code === 401) {
        toast.error(response?.data?.message);
        navigate(`/login`);
      }
      if (response.data.code === 200) {
        toast.success(response.data.message, "green");
        navigate(`/add_location?isHeadOffice=` + 2);
        names({ value: "", err: "" });
        email({ value: "", err: "" });
        phoneNumber({ value: "", err: "" });
      } else {
        //console.log(response.data.message);
        toast.error(response.data.message, "red");
      }
    }
  };

  // Add Bussiness location function
  const handleSubmit = async () => {
    var isDocument = await checkDocumentValidationOnSubmit();
    const identification = [...documentFields];

    if (businessName.value === "") {
      setBusinessName({
        value: businessName.value,
        err: "*Please enter corporation name.",
      });
    }
    if (businessType.value === "") {
      setBusinessType({
        value: businessType.value,
        err: "*Please enter incorporation number.",
      });
    }

    if (
      businessType.value === "Partnership" ||
      businessType.value === "Corporation" ||
      businessType.value === "Cooperative"
    ) {
      if (
        businessRegisterNumber.value === "" ||
        !businessRegisterNumber.value
      ) {
        setBusinessRegisterNumber({
          value: businessRegisterNumber.value,
          err: "*Please enter corporation register number.",
        });
      }
    } else {
      setBusinessRegisterNumber({
        value: businessRegisterNumber.value,
        err: "",
      });
    }
    // if (HSTNo.value === "") {
    //     setHSTNo({ value: HSTNo.value, err: "*Please enter HST number." });
    // }
    // return;
    if (businessName.value && businessType.value) {
      if (isDocument) {
        var formData = new FormData();
        // formData.append("firstName", names.value);
        // formData.append("email", email.value);
        // formData.append("mobileNumber", phoneNumber.value);
        // formData.append("dob", dates.value);
        // formData.append("Identification_Type", add);
        formData.append("profileImage", profilePhoto);
        formData.append("identityDocuments", JSON.stringify(documentFields));
        formData.append(
          "businessDetails",
          JSON.stringify([
            {
              businessName: businessName.value,
              businessRegisterNumber: businessRegisterNumber.value,
              businessType: businessType.value,
              HSTNo: HSTNo.value,
            },
          ])
        );
        identityFile.map((item, i) => {
          formData.append(`identityFile${i}`, item);
        });
        // formData.append("isRegister",true)
        setLoading(true);
        const response = await ApiClient.multipart(
          "patch",
          API_UpdateUserProfileAfterSignup + userId,
          formData,
          `Basic ${token}`
        );
        // ////console.log(JSON.stringify(response), "response after signup");
        setLoading(false);
        if (response?.data?.code === 401) {
          // setIsloading(false);
          toast.error(response?.data?.message);
          navigate(`/login`);
        }
        if (response.data.code === 200) {
          // navigate(`/work_location`,{isRegister:true});
          toast.success(response.data.message, "green");
          navigate(`/add_location?isHeadOffice=` + 2);
          names({ value: "", err: "" });
          email({ value: "", err: "" });
          phoneNumber({ value: "", err: "" });
          // dates({ value: "", err: "" });
        } else {
          //console.log(response.data.message);
          toast.error(response.data.message, "red");
        }
      }
    }
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Wel_Header />
      <section className="add_business">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10 col-xl-9 col-xxl-8">
              <div className="regForm">
                <form>
                  <div className="regBox">
                    <div className="location_title">
                      <div className="row align-items-center">
                        <div className="col-md-12 text-center">
                          {/* <Link to="/work_location" className="backBtn"><span className='left'><i className="fas fa-arrow-left"></i></span></Link> */}
                          <h4>Add Business Location</h4>

                          <div className="editDetail">
                            <a
                              onClick={(e) => {
                                navigate("/");
                              }}
                              type="button"
                            >
                              <i class="fa-solid fa-arrow-left"></i>
                              <span>Back To Home</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="avatar">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-md-12 col-xl-9">
                        <div className="profileImg">
                          <div className="row align-items-center">
                            <div className="col-md-2">
                              <div className="icon">
                                <span>
                                  <img
                                    src={
                                      profile !== "" && profile !== undefined
                                        ? url +
                                          "/media/preview?filename=" +
                                          profile
                                        : Profile
                                    }
                                    style={{ height: 40, width: 40 }}
                                    alt="image profile"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-10">
                              <div className="business_pro_content">
                                <h4>
                                  <span>
                                    {/* <i className="fas fa-user"></i> */}
                                  </span>{" "}
                                  {fullName.value}
                                </h4>
                                <p>
                                  <span>
                                    <i className="fas fa-envelope"></i>
                                  </span>{" "}
                                  {email.value}
                                </p>
                                <p>
                                  <span>
                                    <i className="fas fa-phone-alt"></i>
                                  </span>{" "}
                                  {phoneNumber.value}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <h5 className="head_title"> Head Office Details</h5>
                      </div>
                      <div className="col-12">
                        <div className="row align-items-center">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                aria-describedby=""
                                placeholder="Corporation Name"
                                value={businessName.value}
                                maxLength={100}
                                required
                                onChange={(e) => {
                                  setBusinessName(e.target.value);
                                  _namevalidate(e.target.value);
                                }}
                              />
                              <span>
                                <i className="fas fa-briefcase"></i>
                              </span>
                            </div>
                            <ErrorMessage messerror={businessName.err} />
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                name="incorporation number"
                                placeholder="Incorporation Number"
                                className="form-control"
                                value={businessType.value}
                                required
                                maxLength={25}
                                onChange={(e) => {
                                  setBusinessType(e.target.value);
                                  _businessTypevalidate(e.target.value);
                                }}
                              />
                            </div>
                            <ErrorMessage messerror={businessType.err} />
                          </div>
                          {(businessType.value === "Partnership" ||
                            businessType.value === "Corporation" ||
                            businessType.value === "Cooperative") && (
                            <div className="col-md-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control"
                                  aria-describedby=""
                                  placeholder="Corporation Reg No."
                                  maxLength={25}
                                  value={businessRegisterNumber.value}
                                  required
                                  onChange={(e) => {
                                    setBusinessRegisterNumber(e.target.value);
                                    _businessNumbervalidate(e.target.value);
                                  }}
                                />
                                <span>
                                  <i className="fas fa-envelope"></i>
                                </span>
                              </div>
                              <ErrorMessage
                                messerror={businessRegisterNumber.err}
                              />
                            </div>
                          )}
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control"
                                aria-describedby=""
                                placeholder="HST No."
                                maxLength={25}
                                value={HSTNo.value}
                                required
                                onChange={(e) => {
                                  setHSTNo(e.target.value);
                                  _hstNovalidate(e.target.value);
                                }}
                              />
                              {/* <span><i className="fas fa-birthday-cake"></i></span> */}
                            </div>
                            <ErrorMessage messerror={HSTNo.err} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="identityBox">
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <div className="notiFi">
                          <h6>Upload Account Creator's Identification.</h6>
                          <div className="button r" id="button-1">
                            <input
                              type="checkbox"
                              checked={notification === true}
                              onChange={(e) =>
                                handlePushNotification(e.target.value)
                              }
                              value="notification"
                              className="checkbox"
                            />
                            <div className="knobs"></div>
                            <div className="layer"></div>
                          </div>
                        </div>
                        {!notification && (
                          <div className="identityTitle">
                            {/* <h5>Upload Business Proof</h5> */}
                            {/* <h5>Upload Account Creator's Identification</h5> */}
                            <div className="editDetail business-loca-edit-btn">
                              <a
                                className="btn addBtn"
                                type="button"
                                onClick={handleAddDocumentFields}
                              >
                                <span style={{ marginRight: "5px" }}>
                                  <i className="fas fa-plus-circle"></i>
                                </span>
                                <span className="add-span">Add</span>
                              </a>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {!notification && (
                      <div>
                        {documentFields.map((identificationField, i) => {
                          return (
                            <>
                              <div className="row">
                                <div className="col-12">
                                  <div className="identiBox" key={i}>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <select
                                            className="form-control"
                                            aria-label="Default select example"
                                            name="idType"
                                            value={identificationField.idType}
                                            defaultValue="Select Identification Type"
                                            onChange={(e) => handleChange(i, e)}
                                          >
                                            <option selected value="">
                                              Select Identification Type
                                            </option>
                                            <option value="Drivers license">
                                              Drivers license
                                            </option>
                                            <option value="Permanent resident card">
                                              Permanent resident card
                                            </option>
                                            <option value="Other">Other</option>
                                            <option value="Canadian passport">
                                              Canadian passport
                                            </option>
                                          </select>
                                          <span>
                                            <i className="fas fa-caret-down"></i>
                                          </span>
                                        </div>
                                        <ErrorMessage
                                          messerror={
                                            documentErrors.length - 1 >= i &&
                                            documentErrors[i].errorIdType
                                          }
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <input
                                            type="text"
                                            className="form-control"
                                            id="exampleInputtext"
                                            aria-describedby="textHelp"
                                            onChange={(e) => handleChange(i, e)}
                                            name="idNumber"
                                            value={identificationField.idNumber}
                                            maxLength={15}
                                            placeholder="Identification No."
                                          />
                                        </div>
                                        {/* <ErrorMessage messerror={businessType.err} /> */}

                                        <ErrorMessage
                                          messerror={
                                            documentErrors.length - 1 >= i &&
                                            documentErrors[i].errorIdNumber
                                          }
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label
                                            className="update_files"
                                            htmlFor={`file` + i}
                                          >
                                            <input
                                              type="file"
                                              className="form-control d-none"
                                              aria-describedby="textHelp"
                                              name="fileName"
                                              id={`file` + i}
                                              // value={identificationField.fileName}
                                              placeholder="Upload Identification"
                                              onChange={(e) =>
                                                handleChange(i, e)
                                              }
                                              accept="image/*"
                                              // style={{"visibility":"hidden"}}
                                            />
                                            {identificationField?.fileName
                                              ? identificationField?.fileName
                                              : "No file chosen"}
                                            <span>
                                              <i className="fas fa-paperclip"></i>
                                            </span>
                                          </label>
                                        </div>
                                        <div className="invalid-feedback mt-1">
                                          {documentErrors.length - 1 >= i &&
                                            documentErrors[i].errorFileName}
                                        </div>
                                      </div>
                                    </div>
                                    {documentFields &&
                                    documentFields.length > 1 ? (
                                      <span
                                        className="closeBtn"
                                        onClick={(e) =>
                                          removeIdentificationRow(i)
                                        }
                                      >
                                        <i
                                          className="fas fa-times"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    )}

                    <div className="nextBtn">
                      {!notification ? (
                        <a type="button" onClick={() => handleSubmit()}>
                          Submit
                        </a>
                      ) : (
                        <a
                          type="button"
                          onClick={
                            () => handleSubmitWithoutNotification()
                            // navigate(`/add_location?isHeadOffice=` + 2)
                          }
                        >
                          Submit
                        </a>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BusinessLocation;
