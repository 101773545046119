import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Img from "../../assets/img/userPlace.png";
import Pdf from "../../assets/img/pdf.png";
// import Img from "../../../build/static/media/user placeholder.0da3ad62167327d568cb.png"
import "./all_job.scss";
import ApiClient from "../../api/apiClient";
import Cookies from "universal-cookie";
import { API_GetAllJobsList, API_GetJobDetails, url } from "../../api/Constant";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import NoDataImg from "../../assets/img/no_data_found.png";
import LoaderModal from "@material-ui/core/Modal";
import { Modal } from "react-bootstrap";
import ScaleLoader from "react-spinners/ScaleLoader";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { SOCKET_URL } from "../../api/Constant";
import Swal from "sweetalert2";
import Map from "../Map/Map";

import io from "socket.io-client";

const AllJobs = () => {
  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [jobid, setJobId] = useState([0]);
  const [jobDetails, setjobDetails] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const cookie = new Cookies();
  const userdata = JSON.parse(localStorage.getItem("userInfo"));
  const [isLoading, setIsloading] = useState(false);
  const [isJobDetails, setIsJobDetails] = useState(false);
  // const token = userdata.tokens.refresh.token;
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const userId = tdata?.user?.id;
  const [pageNo, setPageNo] = useState(1);
  const [totalHrs, setTotalHrs] = useState(0);
  const [smShow, setSmShow] = useState(false);
  const [guardData, setGuardData] = useState({});
  const [experience, setExperience] = useState("");
  const [daysInExp, setDaysInExp] = useState("");
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(10);
  const [socket, setSocket] = useState(null);
  // const [called, setCalled] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const customerInfo = cookie.get("customerInfo");
  // const [map, setMap] = useState({
  //   zoom: 15,
  // });

  useEffect(() => {
    setSocket(io(SOCKET_URL));
  }, []);

  // subscribe to the socket event
  useEffect(() => {
    if (!socket) return;
    socket.on("connect", () => {
      setSocketConnected(socket.connected);
      subscribeToDateEvent();
    });
    socket.on("disconnect", () => {
      setSocketConnected(socket.connected);
    });
    handleSocketConnection();
  }, [socket]);

  // UseEffect for checking session and fetching job list
  useEffect(() => {
    //console.log("tdata?.us=====", token);
    if (token) {
      callGetAllJobsList(1, "");
    } else {
      toast.warning("Your session has expired. Please log in again.", "yellow");
      navigate(`/login`);
    }
  }, []);

  // Subscribe to the socket event to provide user information
  const subscribeToDateEvent = () => {
    socket.emit("init", {
      userId: userId,
      userName: tdata?.user.firstName + " " + tdata?.user.lastName,
      type: "customer",
    });
  };

  // Handle socket connection
  const handleSocketConnection = () => {
    if (socketConnected) socket.disconnect();
    else {
      socket.connect();
    }
  };

  // Get guard's latitude and longitude
  const getLatLong = (gid, sid, locationData) => {
    callGetJobDetails(jobid);
    var data = {};
    for (let i = 0; i < locationData.length; i++) {
      const element = locationData[i];
      if (element.guardId === gid) {
        data = element;
      }
    }
    //console.log("call function----", data, gid, sid, locationData);
    socket.emit("getGuardLatLong", {
      customerId: userId,
      guardId: gid,
      shiftId: sid,
    });

    socket.on("updated_lat_long", (resData) => {
      // //console.log("updated_lat_long list====", resData);
      if (resData) {
        data["isLocation"] = true;
        data = resData;
      }
    });
    //console.log("call function----", data);
    if (data?.isLocation === false) {
      Swal.fire({
        title:
          "We are unable to fetch guard location because guard location is not on or guard app is not opened.",
        icon: "warning",
        // showDenyButton: true,
        confirmButtonText: "OK",
        // denyButtonText: "Ok",
        confirmButtonColor: "#3085d6",
        denyButtonColor: "#6e7881",
      }).then((result) => {
        // if (result.isConfirmed) {
        //   setSmShow(true);
        // }
      });
    } else {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = new window.google.maps.LatLng(data?.lat, data?.long);
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            // setAddress(results[0].formatted_address);
            //console.log("faddress", results[0].formatted_address);
            window.open(
              `https://maps.google.com/maps?q=${results[0].formatted_address}&z=15`,
              "_blank"
            );
          } else {
            alert("No results found");
          }
        } else {
          alert("Geocoder failed due to: " + status);
        }
      });
    }
  };

  // Handle page change in job list pagination
  const handlePageChange = (pageNumber) => {
    //console.log(`Active page is ${pageNumber}`);
    setPageNo(pageNumber);
    callGetAllJobsList(pageNumber); // Fetch jobs list for the selected page number
  };

  // Filter jobs list by status
  const statusFilter = (status) => {
    callGetAllJobsList(pageNo, status); // Fetch jobs list with the specified status
    if (status === "") {
      setStatus(10);
    } else {
      setStatus(status);
    }
  };

  // Function to fetch all jobs list based on page number and status
  async function callGetAllJobsList(pageNumber, status) {
    const data = "";
    setIsloading(true);
    const res = await ApiClient.get(
      API_GetAllJobsList +
        `?page=${pageNumber}&sortBy=asc&limit=${10}&searchBy=&isComplete=${status}`,
      data,
      `Bearer ${token}`
    );
    if (res.data.code === 200) {
      setIsloading(false);
      setJobs(res.data.data.docs);
      // setjobDetails(res.data.data.docs[0]);
      if (res?.data?.data?.docs[0]?.id !== undefined) {
        callGetJobDetails(res?.data?.data?.docs[0]?.id);
      }
      setJobId(res?.data?.data?.docs[0]?.id);
      settotalItemsCount(res.data.data.total);
    } else if (res?.data?.code === 401) {
      setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    } else {
      setIsloading(false);
      toast.error(res.message);
    }
  }

  // Function to fetch job details based on ID
  async function callGetJobDetails(id) {
    const data = "";
    setIsJobDetails(true);
    const res = await ApiClient.get(
      API_GetJobDetails + `${id}`,
      data,
      `Bearer ${token}`
    );
    if (res.data.code === 200) {
      var total = 0;
      setIsJobDetails(false);
      res?.data?.data?.shifts?.map((res) => {
        total += res.totalShiftHours;
        setTotalHrs(total);
        return res;
      });
      setjobDetails(res.data.data);
      //console.log(jobDetails);
    } else {
      setIsJobDetails(false);
      toast.error(res.message);
    }
  }

  // Set the selected job ID and fetch its details
  const setJobsid = (i, id) => {
    callGetJobDetails(id); // Fetch job details using the provided ID
    setJobId(id);
  };

  // Function to handle info display
  const handleInfo = () => {
    //console.log("hello");
    <p style={{ color: "red" }}>hello</p>;
  };
  // const calculateHrs = (val) => {
  //   //console.log(val);
  //   let c = val?.reduce((accumulator, item) => {
  //     return accumulator + item.totalShiftHours;
  //   });
  //   //console.log("c", c);
  //   // return c;
  // };

  // Show guard profile
  const showGuardProfile = (guardData) => {
    var totalNoOfDays = 0;

    for (let i = 0; i < guardData.employmentDetails.length; i++) {
      const ele = guardData.employmentDetails[i];
      var days = 0;
      var startDate = moment.utc(new Date(ele.from));
      var endDate = moment.utc(new Date(ele.to));
      var sameDate = moment.utc(startDate).isSame(endDate);
      days = endDate.diff(startDate, "days");
      days = sameDate === true ? 1 : days;
      totalNoOfDays += days;
    }
    ////console.log(totalNoOfDays, "totalNoOfDays");
    var years = Math.floor(totalNoOfDays / 365);
    var months = Math.floor((totalNoOfDays % 365) / 30);
    ////console.log(years, months, "years months");
    let exp = `${years} years ${months} months`;
    setDaysInExp(totalNoOfDays);
    ////console.log("Guard Data---", guardData, totalNoOfDays);
    setGuardData(guardData);
    setExperience(exp);
    setSmShow(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const openMap = (loc) => {
    window.open(`https://maps.google.com/maps?q=${loc}&z=15`, "_blank");
  };

  return (
    <>
      <Header />
      {/* {!isLoading && ( */}
      <section className="all_awrded_job">
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-12">
              <div className="dateFormat">
                <div className="form-group">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      statusFilter(e.target.value);
                    }}
                  >
                    <option value="">All Jobs</option>
                    <option value="0">Awaiting Approval</option>
                    <option value="1">Approved</option>
                    <option value="2">De-Activate(Reject)</option>
                    <option value="3">Canceled</option>
                    <option value="4">Ongoing</option>
                    <option value="5">Closed</option>
                    <option value="6">Upcoming</option>
                    <option value="7">Previous</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {jobs.length > 0 ? (
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12">
                <div className="row justify-content-center">
                  <div className="col-md-3">
                    <div className="left_sidebar">
                      <h5 style={{ marginBottom: "0" }}>
                        {status == 0
                          ? "Awaiting Approval"
                          : status == 1
                          ? "Approved"
                          : status == 2
                          ? "De-Activate(Reject)"
                          : status == 3
                          ? "Cancel"
                          : status == 4
                          ? "Ongoing"
                          : status == 5
                          ? "Closed"
                          : status == 6
                          ? "Upcoming"
                          : status == 7
                          ? "Previous"
                          : "All"}{" "}
                        Jobs
                      </h5>
                      {jobs.map((res, i) => {
                        return (
                          <div className="nav_box">
                            <div
                              className="nav flex-column nav-pills"
                              id="v-pills-tab"
                              role="tablist"
                              aria-orientation="vertical"
                            >
                              <button
                                // className="nav-link active"
                                class={
                                  "nav-link" +
                                  (jobid === res.id ? " active" : "")
                                }
                                id="first-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#first"
                                type="button"
                                role="tab"
                                key={i}
                                aria-controls="first"
                                aria-selected="true"
                                onClick={(e) => {
                                  setJobsid(i, res?.id);
                                }}
                              >
                                {res.jobTitle}
                                <span>
                                  <i className="fa-solid fa-location-dot"></i>{" "}
                                  {res.locationId?.locationName}
                                </span>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    {jobs?.length !== 0 && (
                      <div className="mt-2">
                        <Pagination
                          color="primary"
                          activePage={pageNo}
                          itemsCountPerPage={10}
                          totalItemsCount={totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={(e) => handlePageChange(e)}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-5">
                    {isJobDetails && (
                      <div
                        class="text-center"
                        style={{
                          position: "absolute",
                          left: "45%",
                          padding: "15px",
                          top: "50%",
                        }}
                      >
                        <div class="spinner-border" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    )}
                    <div className="content">
                      <div
                        className="tab-content total_count_box"
                        id="v-pills-tabContent"
                      >
                        <div
                          className={
                            "tab-pane fade" +
                            (jobid?.length !== 0 ? "show active" : "")
                          }
                          id="first"
                          role="tabpanel"
                          aria-labelledby="first-tab"
                        >
                          <h5 className="awrded_job_title">
                            {jobDetails?.jobTitle}{" "}
                            {/* <span>
                              {" "}
                              <i className="fa-solid fa-clock"></i>{" "}
                              {jobDetails?.jobDate}
                            </span> */}
                          </h5>
                          <p className="adderess">
                            {jobDetails?.locationId?.locationName}
                          </p>

                          {/* <p className="Job Type">
                            {jobDetails?.locationId?.jobType}
                          </p> */}
                          <div className="desc">
                            <div className="flexBox">
                              <p>
                                <span>Status</span> :{" "}
                                {jobDetails?.status == 0
                                  ? "Awaiting Approval"
                                  : jobDetails?.status == 1
                                  ? "Approved"
                                  : jobDetails?.status == 2
                                  ? "De-Activate(Reject)"
                                  : jobDetails?.status == 3
                                  ? "Cancel"
                                  : jobDetails?.status == 4
                                  ? "Ongoing"
                                  : jobDetails?.status == 5
                                  ? "Closed"
                                  : jobDetails?.status == 6
                                  ? "Upcoming"
                                  : jobDetails?.status == 7
                                  ? "Previous"
                                  : "N/A"}
                              </p>
                              {jobDetails.jobType !== 3 ? (
                                <div className="row">
                                  <div className="col-md-6">
                                    <p>
                                      <span>Job Start Date</span> :{" "}
                                      {moment
                                        .utc(jobDetails?.jobDate)
                                        .format("DD-MM-YYYY")}
                                    </p>
                                  </div>
                                  <div className="col-md-6">
                                    <p>
                                      <span>Job End Date</span> :{" "}
                                      {moment
                                        .utc(jobDetails?.jobEndDate)
                                        .format("DD-MM-YYYY")}
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                <div className="row">
                                  <div className="col-md-12">
                                    <p>
                                      <span>Job Date</span> :{" "}
                                      {jobDetails.customDates.map(
                                        (date) =>
                                          moment
                                            .utc(date)
                                            .format("DD-MM-YYYY") + " , "
                                      )}
                                    </p>
                                  </div>
                                </div>
                              )}
                              <div className="row">
                                <div className="col-md-6">
                                  <p>
                                    <span>Transpotation</span> :{" "}
                                    {jobDetails?.isVehicleRequired
                                      ? "Own Vehicle"
                                      : "Doesn't matter"}
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    <span>Dress Code</span> :{" "}
                                    {jobDetails?.dressCode == 1
                                      ? "HV Vest"
                                      : jobDetails?.dressCode == 2
                                      ? "Casual"
                                      : jobDetails?.dressCode == 3
                                      ? "Suited"
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-md-6">
                                  <p>
                                    <span>Job Type</span> :{" "}
                                    {jobDetails?.jobType == 1
                                      ? "One Day"
                                      : jobDetails?.jobType == 2
                                      ? "Long Term"
                                      : jobDetails?.jobType == 3
                                      ? "Custom"
                                      : ""}
                                  </p>
                                </div>
                              </div>
                              <p>
                                <span>Job Description</span> :{" "}
                                {jobDetails?.description}
                              </p>
                              <ul className="ul-links">
                                <li>
                                  <Link
                                    to={`/enotes?jobId=${jobDetails?._id}&page=${pageNo}`}
                                  >
                                    View E Notes
                                  </Link>
                                </li>
                                {/* <li>
                                  <Link
                                    onClick={(e) => {
                                      getLatLong();
                                    }}
                                  >
                                    View Guard Location
                                  </Link>
                                </li> */}
                                <li>
                                  <Link
                                    className="ml-5"
                                    to={`/chat?jobId=${jobDetails?._id}`}
                                  >
                                    <i
                                      className="fas fa-comment"
                                      style={{ marginRight: "5px" }}
                                    ></i>
                                    Messages
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            {/* <div className="flexBox spaceRight">
                              <p>
                                <span>Speciality</span> :{" "}
                                {jobs[jobid]?.jobTitle}
                              </p>
                            </div> */}
                          </div>
                          {/* <div className="desc">
                            <div className="flexBox">
                              <p>
                                <span>Guards</span> : Michael Jordan, Prince
                                Charles, Alfred Novel
                              </p>
                            </div>
                          </div> */}
                          {/* AIzaSyCprWnsG8o_V4tmIo-EsgyvgEkhO3pouTc */}
                          <div className="mapBox">
                            {/* <iframe
                              // src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCM3LLCqIN8v0uaQyzMcxvEuKVa3iTZYCY&q=${jobDetails?.locationId?.lat},${jobDetails?.locationId?.long}`}
                              src={`https://maps.google.com/maps?q=${jobDetails?.locationId?.locationName}&z=15&output=embed&icon=https://maps.google.com/mapfiles/kml/shapes/parking_lot_maps.png`}
                              frameBorder="0"
                              id="myIframe"
                              style={{
                                color: "red",
                                borderRadius: "10px",
                                width: "100%",
                                height: "200px",
                                border: "1px solid #d3d3d3",
                              }}
                            ></iframe> */}
                            {/* <GoogleMap
                              mapContainerStyle={{
                                width: "100%",
                                height: "400px",
                              }}
                              center={{
                                lat: jobDetails?.locationId?.lat,
                                lng: jobDetails?.locationId?.long,
                              }}
                              zoom={map.zoom}
                              onLoad={(map) => setMap(map)}
                            >
                              <Marker
                                onClick={() => {
                                  openMap(jobDetails?.locationId?.locationName);
                                }}
                                icon={
                                  "https://res.cloudinary.com/df7gwlrj4/image/upload/c_scale,h_32,w_32/v1682486049/logoicon_bznp2l.png"
                                }
                                position={{
                                  lat: jobDetails?.locationId?.lat,
                                  lng: jobDetails?.locationId?.long,
                                }}
                                title={jobDetails?.locationId?.locationName}
                                url={`https://maps.google.com/maps?q=${jobDetails?.locationId?.locationName}&z=15&output=embed&icon=https://maps.google.com/mapfiles/kml/shapes/parking_lot_maps.png`}
                              />
                            </GoogleMap> */}
                            <Map jobDetails={jobDetails} />
                          </div>

                          <div className="total_count_box mt-4">
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                              <div
                                className="handle_tool_box"
                                style={{ float: "right" }}
                              >
                                <Tooltip
                                  PopperProps={{
                                    disablePortal: true,
                                  }}
                                  onClose={handleTooltipClose}
                                  open={open}
                                  disableFocusListener
                                  disableHoverListener
                                  disableTouchListener
                                  title="Clients who require reoccurring security details that extend beyond 7 days will initially be billed for 7 days. On the 7th day, clients will be required to reload their Ewallets in order to secure payment for the remaining days. For example, ABC company requires security for 10 consecutive days. Let's assume the total cost of the job is $2000. In this scenario, clients will be required to pay the cost for 7 days first, and let's assume that cost is $1500. In this case the client will be billed the remaining $500 on the 7th day."
                                >
                                  <span
                                    onClick={handleTooltipOpen}
                                    className="handle_tooltip_span"
                                  >
                                    {" "}
                                    <i class="fa fa-info-circle"></i>
                                  </span>
                                </Tooltip>
                              </div>
                            </ClickAwayListener>
                            {/* <i
                              className="fa fa-info-circle search-icon"
                              // onClick={()=>handleInfo()}
                              title="Clients who require reoccurring security details that extend beyond 7 days will initially be billed for 7 days. On the 7th day, clients will be required to reload their Ewallets in order to secure payment for the remaining days. For example, ABC company requires security for 10 consecutive days. Let's assume the total cost of the job is $2000. In this scenario, clients will be required to pay the cost for 7 days first, and let's assume that cost is $1500. In this case the client will be billed the remaining $500 on the 7th day."
                              style={{ float: "right" }}
                            ></i> */}
                            <div className="count_inner_box">
                              <div className="lBox">
                                <div className="box">
                                  <h6>Total Amount </h6>
                                  <span>
                                    $
                                    {jobDetails?.withoutTotalAmount?.toFixed(2)}
                                  </span>
                                </div>
                                <div className="box">
                                  <h6>Total Paid</h6>
                                  <span>
                                    $
                                    {jobDetails?.withoutTotalAmount?.toFixed(2)}
                                  </span>
                                </div>
                                <div className="box">
                                  <h6>Per Shift Hours</h6>
                                  <span>{totalHrs.toFixed(2)}</span>
                                </div>
                              </div>
                              <div className="rBox">
                                <div className="box">
                                  <h6>Total HST</h6>
                                  <span>
                                    ${jobDetails?.totalHst?.toFixed(2)}
                                  </span>
                                </div>
                                <div className="box">
                                  <h6>Amount Used</h6>
                                  <span>
                                    ${jobDetails?.totalAmount?.toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {jobDetails?.shifts?.map((sdata, i) => {
                      return (
                        <div className="reach_box" key={i}>
                          <div className="shift_title">
                            <h6>Shift {i + 1}</h6>
                            <span>
                              {moment
                                .utc(sdata?.jobStartDate)
                                .format("MMMM Do YYYY")}
                            </span>
                            <span>
                              From{" "}
                              {moment.utc(sdata?.startTime).format("h:mm A")} To{" "}
                              {moment.utc(sdata?.endTime).format("h:mm A")}
                            </span>
                          </div>
                          <div className="row align-items-center">
                            <div className="col-md-12">
                              {sdata?.approvedGuardIds.length !== 0 ? (
                                sdata?.approvedGuardIds?.map((gdata, i) => {
                                  return (
                                    <>
                                      <div
                                        className="box"
                                        onClick={() => showGuardProfile(gdata)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <div class="imgBox">
                                          <img
                                            src={
                                              gdata?.profileImage !== "" &&
                                              gdata?.profileImage !== undefined
                                                ? url +
                                                  "/media/preview?filename=" +
                                                  gdata?.profileImage
                                                : Img
                                            }
                                            alt="image profile"
                                          />
                                        </div>
                                        <label
                                          for={
                                            gdata?.firstName + gdata?.lastName
                                          }
                                          className="col-form-label"
                                          style={{ fontWeight: "700" }}
                                        >
                                          {gdata?.firstName} {gdata?.lastName}
                                        </label>
                                        <div className="form-group">
                                          <select
                                            className="form-select"
                                            aria-label="Default select example"
                                          >
                                            <option
                                              selected
                                              defaultValue={sdata?.role.name}
                                            >
                                              {sdata?.role.name}
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      {jobDetails?.status === 4 && (
                                        <ul className="ul-links mt-2">
                                          <li>
                                            <Link
                                              onClick={(e) => {
                                                getLatLong(
                                                  gdata?._id,
                                                  sdata?._id,
                                                  jobDetails?.approvedGuardsLatLong
                                                );
                                              }}
                                            >
                                              View Guard Location
                                            </Link>
                                          </li>
                                        </ul>
                                      )}
                                    </>
                                  );
                                })
                              ) : (
                                <div className="box">
                                  <label
                                    htmlFor="no guard"
                                    className="col-form-label"
                                    style={{ fontWeight: "700" }}
                                  >
                                    No guard found
                                  </label>
                                  <div className="form-group">
                                    <select
                                      className="form-select"
                                      aria-label="Default select example"
                                    >
                                      <option selected>
                                        {sdata?.role.name}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <div className="nodata_img">
                  <img
                    src={NoDataImg}
                    className="img-fluid"
                    alt="No Data Found"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      {/* )} */}

      <Modal
        className="guard_profile_modal"
        size="l"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <h5 className="modal-title" id="exampleModalLabel">
            {" "}
            Guard Details{" "}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="cardBox">
            <div className="row">
              <div
                className="col-md-12 mb-4"
                style={{ justifyContent: "center", display: "flex" }}
              >
                <div class="guard-imgBox">
                  <img
                    src={
                      guardData?.profileImage !== "" &&
                      guardData?.profileImage !== undefined
                        ? url +
                          "media/preview?filename=" +
                          guardData?.profileImage
                        : Img
                    }
                    alt="image profile"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.firstName}
                    placeholder="First Name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.lastName}
                    placeholder="Last Name"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.email}
                    placeholder="Email"
                    maxLength={25}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Mobile Number</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.mobileNumber}
                    placeholder="Mobile Number"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Experience</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={daysInExp > 365 ? experience : "0-2 years"}
                    placeholder="Experience"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>License Number</label>
                  <input
                    type="text"
                    disabled
                    className="form-control"
                    value={guardData?.licenseNumber}
                    placeholder="License Number"
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>License Image</label>
                  {guardData?.securityRegistrationDocument !== "" &&
                  guardData?.securityRegistrationDocument !== undefined &&
                  guardData?.securityRegistrationDocument.split(".").at(-1) ===
                    "pdf" ? (
                    <a
                      target="_blank"
                      href={
                        url +
                        "media/preview?filename=" +
                        guardData?.securityRegistrationDocument
                      }
                    >
                      <img src={Pdf} className="img-fluid licenceImg" />
                    </a>
                  ) : (
                    <img
                      style={{
                        width: "150px",
                        height: "100px",
                        marginLeft: "20px",
                      }}
                      src={
                        guardData?.securityRegistrationDocument !== "" &&
                        guardData?.securityRegistrationDocument !== undefined
                          ? url +
                            "media/preview?filename=" +
                            guardData?.securityRegistrationDocument
                          : Img
                      }
                      alt="image profile"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AllJobs;
