import React, { useEffect, useState, useRef } from "react";
// import LogoIcon from "../../components/Logo/Logo";
import { Link, useNavigate, useParams } from "react-router-dom";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import Header from "../../components/Header/Header";
import "./edit_location.scss";
import {
  location,
  numbers,
  string,
  unit_number,
  alphanumeric,
  nameVaidation,
} from "../../constant";
import ApiClient from "../../api/apiClient";
import { API_EditWorkLocation } from "../../api/Constant";
import Cookies from "universal-cookie";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "@material-ui/core/Modal";
import { toast } from "react-toastify";
import { googleAddressKey } from "./../../api/Constant";
let autoComplete;

const EditLocation = () => {
  const [query, setQuery] = useState("");
  const [locationName, setLocationName] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [building, setBuilding] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [zip, setZip] = useState("");
  const [country, setCountry] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  const [errorLocation, setErrorLocation] = useState(null);
  const [errorUnitNumber, setErrorUnitNumber] = useState(null);
  const [errorBuilding, setErrorBuilding] = useState(null);
  const [errorStreet, setErrorStreet] = useState(null);
  const [errorCity, setErrorCity] = useState(null);
  const [errorProvince, setErrorProvince] = useState(null);
  const [errorZip, setErrorZip] = useState(null);
  const [errorContactPerson, setErrorContactPerson] = useState(null);
  const [errorContactNumber, setErrorContactNumber] = useState(null);
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const autoCompleteRef = useRef(null);
  let [loading, setLoading] = useState(false);
  let [businessName, setBusinessName] = useState("");
  const [errorBuss, setErrorBuss] = useState(null);
  const { id } = useParams();
  // console.log('id useParams', id)

  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  // Function to dynamically load a script
  const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function () {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  // Function to handle script loading for Google Places Autocomplete
  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: "ca" } }
    );
    autoComplete.setFields([
      "address_components",
      "formatted_address",
      "geometry",
    ]);
    autoComplete.addListener("place_changed", () =>
      handlePlaceSelect(updateQuery)
    );
  };

  // Function to handle place selection from Google Places Autocomplete
  const handlePlaceSelect = async (updateQuery) => {
    const addressObject = autoComplete.getPlace();
    const latitude = addressObject.geometry.location.lat();
    setLatitude(latitude);
    const longitude = addressObject.geometry.location.lng();
    setLongitude(longitude);
    const query = addressObject.formatted_address;
    updateQuery(query);
    setLocationName(addressObject.formatted_address);

    // Extract address components
    for (let i = 0; i < addressObject.address_components.length; i++) {
      const element = addressObject.address_components[i];
      if (element.types[0] === "country") {
        const country = element.long_name;
        setCountry(country);
      } else if (element.types[0] === "street_number") {
        const streetVal = element.long_name;
        setStreet(streetVal);
        _street(streetVal);
      } else if (element.types[0] === "administrative_area_level_1") {
        const state = element.long_name;
        setProvince(state);
        _province(state);
      } else if (
        element.types[0] === "locality" ||
        element.types[0] === "sublocality_level_1"
      ) {
        const city = element.long_name;
        setCity(city);
        _city(city);
      } else if (element.types[0] === "postal_code") {
        const zip = element.long_name;
        zip.replace(/ /g, "");
        setZip(zip.replace(/ /g, ""));
        _zip(zip.replace(/ /g, ""));
      }
    }
  };

  useEffect(async () => {
    // await getSpeciality();
    getWorkLocationDetails();
    loadScript(
      // `https://maps.googleapis.com/maps/api/js?key=AIzaSyCM3LLCqIN8v0uaQyzMcxvEuKVa3iTZYCY&libraries=places`,
      `https://maps.googleapis.com/maps/api/js?key=${googleAddressKey}&libraries=places`,
      () => handleScriptLoad(setQuery, autoCompleteRef)
    );
  }, [id]);

  // Function to get work location details from the API
  const getWorkLocationDetails = async () => {
    setLoading(true);
    const response = await ApiClient.get(
      API_EditWorkLocation + id,
      {},
      `Basic ${token}`
    );

    if (response?.data?.code === 401) {
      // Redirect to login if unauthorized
      toast.error(response?.data?.message);
      navigate(`/login`);
    }

    if (response.data.code === 200) {
      setLoading(false);
      // Extract and set various location details from the API response
      if (response.data.data) {
        setQuery(response.data.data.locationName);
        setLocationName(response.data.data.locationName);
        setBuilding(response.data.data.building);
        setUnitNumber(response.data.data.unitNumber);
        setProvince(response.data.data.province);
        setStreet(response.data.data.street);
        setCity(response.data.data.city);
        setZip(response.data.data.postalCode);
        setBusinessName(response?.data?.data?.businessName);
        document.getElementById("businessName").value =
          response?.data?.data?.businessName;
        setContactNumber(response.data.data.contactNumber);
        setContactPerson(response.data.data.contactPerson);
        setLatitude(response.data.data.lat);
        setLongitude(response.data.data.long);
      }
    }
  };

  // Function to validate the location input
  const _location = (loc) => {
    if (loc === "") {
      setErrorLocation("*Please enter location.");
      return;
    }
    // Additional validation logic can be added here
    setErrorLocation(""); // Clear error message
  };

  // Function to validate the unit number input
  const _unitNo = (unit) => {
    if (unit === "") {
      setErrorUnitNumber("*Please enter unit number.");
      return;
    }
    if (unit && !alphanumeric.test(unit)) {
      setErrorUnitNumber("*Please enter valid unit number.");
    } else {
      setErrorUnitNumber(null); // Clear error message
    }
  };

  // Function to validate building input
  const _building = (building) => {
    if (building === "") {
      setErrorBuilding("*Please enter building.");
    }
    if (building && !alphanumeric.test(building)) {
      setErrorUnitNumber("*Please enter valid unit number."); // This error message seems to be incorrect, should it be setErrorBuilding?
    } else {
      setErrorBuilding(null); // Clear error message
    }
  };

  // Function to validate street input
  const _street = (num) => {
    if (num === "") {
      setErrorStreet("*Please enter street.");
    } else {
      setErrorStreet(null); // Clear error message
    }
  };

  // Function to validate city input
  const _city = (num) => {
    if (num === "") {
      setErrorCity("*Please enter city.");
    } else {
      setErrorCity(null); // Clear error message
    }
  };

  // Function to validate province input
  const _province = (num) => {
    if (num === "") {
      setErrorProvince("*Please enter province.");
    } else if (!string.test(num)) {
      // Please ensure that the 'string' variable is defined and has the correct regex pattern
      setErrorProvince("*Please enter valid province.");
    } else {
      setErrorProvince(null); // Clear error message
    }
  };

  // Function to validate postal code input
  const _zip = (num) => {
    if (num === "") {
      setErrorZip("*Please enter postal code.");
    }
    if (num.length < 3) {
      setErrorZip("*Please enter valid postal code.");
    } else {
      setErrorZip(null); // Clear error message
    }
  };

  // Function to validate contact person name input
  const _contactPerson = (name) => {
    if (name === "") {
      setErrorContactPerson("*Please enter contact person name.");
    }
    if (name && !nameVaidation.test(name)) {
      setErrorContactPerson("*Please enter valid contact person name.");
    } else {
      setErrorContactPerson(null); // Clear error message
    }
  };

  // Function to validate contact number input
  const _contactNumber = (num) => {
    if (num === "") {
      setErrorContactNumber("*Please enter contact number.");
    } else if (!numbers.test(num)) {
      setErrorContactNumber("*Please enter valid contact number.");
    } else if (num.length < 10) {
      setErrorContactNumber("*Please enter valid contact number.");
    } else {
      setErrorContactNumber(null); // Clear error message
    }
  };

  // Function to validate form before submission
  const validate = async () => {
    if (businessName === "") {
      setErrorBuss("*Please enter business name.");
      return;
    }

    if (locationName == "") {
      setErrorLocation("*Please enter location.");
    }

    if (building == "") {
      setErrorBuilding("*Please enter building.");
    }
    if (street == "") {
      setErrorStreet("*Please enter street.");
    }
    if (city == "") {
      setErrorCity("*Please enter city.");
    }
    if (province == "") {
      setErrorProvince("*Please enter province.");
    }
    if (zip == "") {
      setErrorZip("*Please enter postal code.");
    }
    if (contactPerson == "") {
      setErrorContactPerson("*Please enter contact person.");
    }
    if (contactNumber == "") {
      setErrorContactNumber("*Please enter contact number.");
    }

    // If all fields are filled, call the AddLocation() API function
    if (
      locationName !== "" &&
      street !== "" &&
      city !== "" &&
      province !== "" &&
      zip !== "" &&
      contactPerson !== "" &&
      contactNumber !== "" &&
      businessName !== ""
    ) {
      //console.log("call API");
      AddLocation(); // Make the API call to add the location
    } else {
      return; // If any field is missing, do nothing
    }
  };

  // call API_AddLocation
  async function AddLocation() {
    // const token = localStorage.getItem("userId");
    // const refToken = JSON.parse(token);
    const data = {
      locationName: locationName, //88 Journal Square Jersey City, NJ 07306
      unitNumber: unitNumber,
      businessName: businessName,
      building: building,
      street: street,
      city: city,
      postalCode: zip,
      province: province,
      contactPerson: contactPerson,
      contactNumber: contactNumber,
      lat: latitude,
      long: longitude,
      userId: userId,
      unitId: null,
      type: 1, //1 is Work Location, 2 is Head Office
    };
    //console.log(data);
    setLoading(true);
    const res = await ApiClient.patch(
      API_EditWorkLocation + id,
      data,
      `Bearer ${token}`
    );
    //console.log(res, "res");
    setLoading(false);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res.data.code === 200) {
      toast.success(res.data.message, "green");
      navigate(`/work_location`);
      setLocationName("");
      setBusinessName("");
      setUnitNumber("");
      setBuilding("");
      setStreet("");
      setCity("");
      setProvince("");
      setZip("");
      setContactPerson("");
      setContactNumber("");
    } else {
      toast.error(res.data.message, "red");
      setIsError(true);
      setError(res.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  }

  // Get Bussiness name
  const getBussinesName = (v) => {
    //console.log(v);
    if (v == "") {
      setBusinessName(v);
      setErrorBuss("*Please enter bussiness name.");
      return;
    } else {
      setErrorBuss(null);
      setBusinessName(v);
    }
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Header />
      <section className="add_location">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="signupForm">
                <form>
                  <div className="location_title">
                    <div className="row align-items-center">
                      <div className="col-md-12 text-center">
                        {/* <Link to="/work_location" className="backBtn">
                          <span className="left">
                            <i className="fas fa-arrow-left"></i>
                          </span>
                        </Link> */}
                        <h4>Edit Location</h4>

                        <div className="editDetail">
                          <a
                            onClick={(e) => {
                              navigate("/work_location");
                            }}
                            type="button"
                          >
                            <i class="fa-solid fa-arrow-left"></i>
                            <span>Back</span>
                          </a>
                        </div>
                        {iserror ? (
                          <div className="alert alert-danger">{error}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          id="businessName"
                          placeholder="Business Name*"
                          // value={businessName}
                          onKeyUp={(e) => {
                            getBussinesName(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      {errorBuss != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorBuss}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Location Name*"
                          ref={autoCompleteRef}
                          value={query}
                          onChange={(event) => {
                            setQuery(event.target.value);
                            _location(event.target.value);
                          }}
                          // value={locationName}
                          // onChange={(e) => {
                          //   setLocationName(e.target.value);
                          //   _location(e.target.value);
                          // }}
                        />
                        <span>
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      {errorLocation != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorLocation}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          value={unitNumber}
                          maxLength={15}
                          placeholder="Unit No."
                          onChange={(e) => {
                            setUnitNumber(e.target.value);
                            _unitNo(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-map"></i>
                        </span>
                      </div>
                      {errorUnitNumber != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorUnitNumber}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    {/* <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Building"
                          value={building}
                          onChange={(e) => {
                            setBuilding(e.target.value);
                            _building(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-building"></i>
                        </span>
                      </div>
                      {errorBuilding != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorBuilding}
                          </p>
                        </div>
                      ) : null}
                    </div> */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Street*"
                          value={street}
                          onChange={(e) => {
                            setStreet(e.target.value);
                            _street(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-road"></i>
                        </span>
                      </div>
                      {errorStreet != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorStreet}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="City*"
                          maxLength={15}
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                            _city(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      {errorCity != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorCity}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Province*"
                          maxLength={15}
                          value={province}
                          onChange={(e) => {
                            setProvince(e.target.value);
                            _province(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      {errorProvince != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorProvince}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Postal Code*"
                          maxLength={7}
                          value={zip}
                          onChange={(e) => {
                            setZip(e.target.value);
                            _zip(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                      {errorZip != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorZip}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-12">
                      <h5>Contact Person</h5>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Contact Person*"
                          maxLength={64}
                          value={contactPerson}
                          onChange={(e) => {
                            setContactPerson(e.target.value);
                            _contactPerson(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-user"></i>
                        </span>
                      </div>
                      {errorContactPerson != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorContactPerson}
                          </p>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby=""
                          placeholder="Contact No.*"
                          maxLength={10}
                          value={contactNumber}
                          onChange={(e) => {
                            setContactNumber(e.target.value);
                            _contactNumber(e.target.value);
                          }}
                        />
                        <span>
                          <i className="fas fa-phone-alt"></i>
                        </span>
                      </div>
                      {errorContactNumber != null ? (
                        <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: 13,
                              fontFamily: "GothamMedium",
                              marginHorizontal: 35,
                              textAlign: "left",
                            }}
                          >
                            {errorContactNumber}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="nextBtn">
                    {/* <Link to="/privacy"> */}
                    <a type="button" onClick={() => validate()}>
                      Submit <span></span>
                    </a>
                    {/* </Link> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EditLocation;
