import React from "react";
import { Link } from "react-router-dom";
import "./mall_job.scss";

const MallJob = () => {
  return (
    <>
      <section className="mall_job">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="dateBox">
                <div className="row">
                  <div className="col-md-12">
                    <div className="content">
                      <h4>Mall Security Job</h4>
                      <p>
                        <span className="left">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>{" "}
                        123, Location, City
                      </p>
                      <p>
                        <span className="left">
                          <i className="fas fa-calendar"></i>
                        </span>{" "}
                        10 November, 2021
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="shiftBox">
                      <h5>
                        Reached office on time, checked records of visitors and
                        gate passes
                      </h5>
                      <p>
                        <span className="left">
                          <i className="fas fa-clock"></i>
                        </span>{" "}
                        10:30 AM, 12/11/2021
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="shiftBox">
                      <h5>
                        Reached office on time, checked records of visitors and
                        gate passes
                      </h5>
                      <p>
                        <span className="left">
                          <i className="fas fa-clock"></i>
                        </span>{" "}
                        10:30 AM, 12/11/2021
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="shiftBox">
                      <div className="descBox">
                        <h5>Walked around building</h5>
                        <a href="#">
                          <i className="fas fa-play"></i>
                          <span></span>
                        </a>
                        <p>
                          <span className="left">
                            <i className="fas fa-clock"></i>
                          </span>
                          01:30 PM, 12/12/2021
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="shiftBox">
                      <h5>
                        Reached office on time, checked records of visitors and
                        gate passes
                      </h5>
                      <p>
                        <span className="left">
                          <i className="fas fa-clock"></i>
                        </span>{" "}
                        10:30 AM, 12/11/2021
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="shiftBox">
                      <h5>
                        Reached office on time, checked records of visitors and
                        gate passes
                      </h5>
                      <p>
                        <span className="left">
                          <i className="fas fa-clock"></i>
                        </span>{" "}
                        10:30 AM, 12/11/2021
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="shiftBox">
                      <h5>
                        Reached office on time, checked records of visitors and
                        gate passes
                      </h5>
                      <p>
                        <span className="left">
                          <i className="fas fa-clock"></i>
                        </span>{" "}
                        10:30 AM, 12/11/2021
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MallJob;
