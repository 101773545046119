import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./containers/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Login from "./containers/Login/Login";
import Signup from "./containers/Signup/Signup";
import Registration from "./containers/Registration/Registration";
import WorkLocation from "./containers/WorkLocation/WorkLocation";
import AddLocation from "./containers/AddLocation/AddLocation";
import EditLocation from "./containers/EditLocation/EditLocation";
import AddJob from "./containers/AddJob/AddJob";
import LongTermJob from "./containers/LongTermJob/LongTermJob";
import RecurringJob from "./containers/RecurringJob/Recurring_Job";
import BusinessLocation from "./containers/BusinessLocation/BusinessLocation";
import HeadOffice from "./containers/HeadOffice/HeadOffice";
import MyUnit from "./containers/MyUnits/MyUnits";
import AddUnit from "./containers/AddUnit/addUnit";
import AddUnitAfterLogin from "./containers/AddUnit/unitAddAfterLogin";
import UpdateUnits from "./containers/AddUnit/updateUnit";
import MyProfile from "./containers/MyProfile/MyProfile";
import Notification from "./containers/Notification/Notification";
import Notifications from "./containers/AllNotification/AllNotification";
import AddNotification from "./containers/AddNotification/AddNotification";
import UnAwrdedJob from "./containers/UnAwardedJob/UnAwardedJOb";
import AwrdedJob from "./containers/AwardedJob/AwardedJob";
import AllJobs from "./containers/AllJobs/AllJobs";
import OngoingJob from "./containers/OnGoingJob/OngoingJob";
import OnGoingJobList from "./containers/onGoingJobList/onGoingJobList";
import ENotes from "./containers/ENotes/ENotes";
import MallJob from "./containers/MallJob/MallJob";
import ApproveAlert from "./containers/ApproveNotification/ApproveAlert";
import AddDocs from "./containers/AddDocs/AddDocs";
import EditPro from "./containers/EditProfile/EditProfile";
import Otp from "./containers/Otp/Otp";
import ForgotPassword from "./containers/ForgotPassword/ForgotPassword";
import MyDocument from "./containers/MyDocument/MyDocument";
import EditDocument from "./containers/EditDocument/EditDocuments";
import MyRating from "./containers/MyRating/MyRating";
import Privacy from "./containers/Privacy/Privacy";
import TermsUse from "./containers/Terms/TermsUse";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import MyWallet from "./containers/MyWallet/MyWallet";
import Settings from "./containers/Setting/Setting";
import Ticket2 from "./containers/Ticket2/Ticket2";
import Support from "./containers/Support/Support";
import ReportIssue from "./containers/Report_Issue/Report_Issue";
import JobRating from "./containers/JobRaing/JobRating";
import PreviousTicket from "./containers/PreviousTicket/PreviousTicket";
import TicketDetail from "./containers/TicketDetail/TicketDetail";
import AwardedJobUpdate from "./containers/AwardedJobUpdate/AwardedJobUpdate";
import Payment from "./containers/Payment/Payment";
import Tracker from "./containers/Tracker/Tracker";
import AddCard from "./containers/AddCard/AddCard";
import EditCard from "./containers/EditCard/EditCard";
import MallInvoice from "./containers/MallInvoice/MallInvoice";
import SOS from "./containers/SOS/SOS";
import WalletBalance from "./containers/WalletBalance/WalletBalance";
import InnerAddJob from "./containers/InnerAddJob/InnerAddJob";
import ThankYou from "./containers/ThankYou/thankYou";
import Injury from "./containers/Injury/Injury";
import axios from "axios";
import { url } from "./api/Constant";
import Chat from "./containers/Chats/chat";
import Page404 from "./containers/ErrorPage/Page404";
import { fetchToken, onMessageListener } from "./firebase";
import { toast } from "react-toastify";

function App() {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  fetchToken(setTokenFound);

  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setShow(true);
      console.log(payload);
      toast.success(payload?.notification?.body);
    })
    .catch((err) => console.log("failed: ", err));
  axios.defaults.baseURL = url;
  window.authToken = "";
  return (
    <>
      <Router>
        {/* <Header /> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/tracker" element={<Tracker />} />
          <Route path="/register" element={<Registration />} />
          <Route path="/work_location" element={<WorkLocation />} />
          <Route path="/add_location" element={<AddLocation />} />
          <Route path="/edit_location/:id" element={<EditLocation />} />
          <Route path="/addJob" element={<AddJob />} />
          <Route path="/long_term_Job" element={<LongTermJob />} />
          <Route path="/add_recurring_job" element={<RecurringJob />} />
          <Route path="/business_location" element={<BusinessLocation />} />
          <Route path="/head_office" element={<HeadOffice />} />
          <Route path="/my_units" element={<MyUnit />} />
          <Route path="/add-unit/:locationId" element={<AddUnit />} />
          <Route path="/addUnit" element={<AddUnitAfterLogin />} />

          <Route path="/update_unit/:id" element={<UpdateUnits />} />

          <Route path="/notifications" element={<Notifications />} />
          <Route path="/my_profile" element={<MyProfile />} />
          <Route path="/notification/:id" element={<Notification />} />
          <Route path="/add_notification/:id" element={<AddNotification />} />
          <Route path="/unawarded_job" element={<UnAwrdedJob />} />
          <Route path="/awarded_job" element={<AwrdedJob />} />
          <Route path="/alljobs" element={<AllJobs />} />
          <Route path="/awarded_update" element={<AwardedJobUpdate />} />
          <Route path="/ongoing_job_list" element={<OnGoingJobList />} />
          <Route path="/ongoing_job/:id" element={<OngoingJob />} />
          <Route path="/enotes/" element={<ENotes />} />
          <Route path="/mall_job" element={<MallJob />} />
          <Route path="/approve_alert" element={<ApproveAlert />} />
          <Route path="/add_documennt" element={<AddDocs />} />
          <Route path="/edit_profile" element={<EditPro />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/my_document" element={<MyDocument />} />
          <Route path="/edit_document" element={<EditDocument />} />
          <Route path="/my_rating" element={<MyRating />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms_use" element={<TermsUse />} />
          <Route path="/chat" element={<Chat />} />
          {/* <Route path="/reset_password" element={<ResetPassword />} /> */}

          <Route path="/my_wallet" element={<MyWallet />} />
          <Route path="/setting" element={<Settings />} />
          <Route path="/ticket2" element={<Ticket2 />} />
          <Route path="/support" element={<Support />} />
          <Route path="/report_issue" element={<ReportIssue />} />
          <Route path="/jobRating" element={<JobRating />} />
          <Route path="/pre_ticket" element={<PreviousTicket />} />
          <Route path="/ticket_detail/:id" element={<TicketDetail />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/add_card" element={<AddCard />} />
          <Route path="/edit_card" element={<EditCard />} />
          <Route path="/mall_invoice" element={<MallInvoice />} />
          <Route path="/sos" element={<SOS />} />
          <Route path="/wallet_balance" element={<WalletBalance />} />
          <Route path="/thankYou" element={<ThankYou />} />
          <Route path="/injury" element={<Injury />} />
          <Route path="/inner_job" element={<InnerAddJob />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
