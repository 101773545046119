import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Docs from "../../assets/img/docs.png";
import { Link } from "react-router-dom";
import "./injury.scss";

const Injury = () => {
  return (
    <>
      <Header />
      <section className="previous_injury">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12">
              <div className="ticket_box">
                <h6>Previous Injuries</h6>
                <div className="table-responsive border-1">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Job Title</th>
                        <th>Job Location</th>
                        <th>Date</th>
                        <th>Attachment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Mall Security Guard Job</td>
                        <td> 123,Street,City,Pin</td>
                        <td>10:30 AM,12 Jan,2022</td>
                        <td>
                          <div className="attach_docs">
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                          </div>
                        </td>
                        <td>
                          <a href="#">View More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Mall Security Guard Job</td>
                        <td> 123,Street,City,Pin</td>
                        <td>10:30 AM,12 Jan,2022</td>
                        <td>
                          <div className="attach_docs">
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                          </div>
                        </td>
                        <td>
                          <a href="#">View More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Mall Security Guard Job</td>
                        <td> 123,Street,City,Pin</td>
                        <td>10:30 AM,12 Jan,2022</td>
                        <td>
                          <div className="attach_docs">
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                          </div>
                        </td>
                        <td>
                          <a href="#">View More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Mall Security Guard Job</td>
                        <td> 123,Street,City,Pin</td>
                        <td>10:30 AM,12 Jan,2022</td>
                        <td>
                          <div className="attach_docs">
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                          </div>
                        </td>
                        <td>
                          <a href="#">View More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Mall Security Guard Job</td>
                        <td> 123,Street,City,Pin</td>
                        <td>10:30 AM,12 Jan,2022</td>
                        <td>
                          <div className="attach_docs">
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                          </div>
                        </td>
                        <td>
                          <a href="#">View More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Mall Security Guard Job</td>
                        <td> 123,Street,City,Pin</td>
                        <td>10:30 AM,12 Jan,2022</td>
                        <td>
                          <div className="attach_docs">
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                          </div>
                        </td>
                        <td>
                          <a href="#">View More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Mall Security Guard Job</td>
                        <td> 123,Street,City,Pin</td>
                        <td>10:30 AM,12 Jan,2022</td>
                        <td>
                          <div className="attach_docs">
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                          </div>
                        </td>
                        <td>
                          <a href="#">View More</a>
                        </td>
                      </tr>
                      <tr>
                        <td>Mall Security Guard Job</td>
                        <td> 123,Street,City,Pin</td>
                        <td>10:30 AM,12 Jan,2022</td>
                        <td>
                          <div className="attach_docs">
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                            <img src={Docs} className="img-fluid" />
                          </div>
                        </td>
                        <td>
                          <a href="#">View More</a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Injury;
