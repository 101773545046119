import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import "./mall_invoice.scss";
import {
  API_GetUserBilings,
  API_DownloaadUserBilings,
} from "../../api/Constant";
import ApiClient from "../../api/apiClient";
import moment from "moment";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import DateRangePicker from "rsuite/DateRangePicker";

const MallInvoice = () => {
  const navigate = useNavigate();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const [billings, setBillings] = useState([]);
  const [totalItemsCount, setTotalItemsCount] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");
  const [isLoading, setIsloading] = useState(false);

  var filterDate = "";

  useEffect(() => {
    if (token) {
      getBillings(1, "", "");
    } else {
      toast.warning(
        "your session has been expired ...kindly login again.",
        "yellow"
      );
      navigate(`/login`);
    }
  }, []);

  // Function to fetch billings based on pagination and date range
  const getBillings = async (pageNo, fromDate, toDate) => {
    setIsloading(true);
    const data = "";
    const res = await ApiClient.get(
      // API endpoint to fetch user billings with pagination and date range parameters
      API_GetUserBilings +
        `?page=${pageNo}&sortBy=asc&limit=${10}&fromDate=${fromDate}&toDate=${toDate}`,
      data,
      `Bearer ${token}`
    );
    // Handle API response
    if (res?.data?.code === 401) {
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res?.data?.code === 200) {
      setIsloading(false);
      //console.log(res);
      setBillings(res?.data?.data?.docs); // Set the billings using state
      setTotalItemsCount(res?.data?.data?.total); // Set the total count of items using state
    } else {
      setIsloading(false);
      toast.error(res?.data?.mesage);
    }
  };

  // Function to handle page change in pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
    // Call function to fetch billings based on the selected page number and current date range
    getBillings(pageNumber, "", "");
  };

  // Function to filter billings based on a specific date
  const filter = (val) => {
    //console.log(val);
    if (val === "") {
      // If no date is selected, fetch billings without any date filter
      getBillings();
    } else {
      filterDate = moment.utc(val).format("MM/DD/YYYY");
      //console.log(filterDate);
      getBillings();
    }
  };

  // Function to fetch billings based on a selected date range
  const selectedRange = (e) => {
    // Call function to fetch billings within the selected date range
    getBillings(
      1,
      moment.utc(e[0]).format("yyyy-MM-DD"),
      moment.utc(e[1]).format("yyyy-MM-DD")
    );
  };

  // Download Invoice Function
  const downloadInvoice = async (id) => {
    const data = "";
    const res = await ApiClient.get(
      API_DownloaadUserBilings + `${id}`,
      data,
      `Bearer ${token}`
    );
    // //console.log(res);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res?.data?.code === 200) {
      window.open(res?.data?.data, "_blank");
      // window.location.href = res?.data?.data;
    } else {
      toast.error(res?.data?.message);
    }
  };

  // Calander Clear Function
  const onClear = () => {
    getBillings(1, "", "");
  };

  return (
    <>
      <Header />
      <section className="invoice">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10">
              <div className="content">
                <div className="title">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-sm-6 col-md-9">
                      <h1>Billing Invoices</h1>
                    </div>
                    <div className="col-sm-6 col-md-3">
                      <DateRangePicker onOk={selectedRange} onClean={onClear} />
                    </div>
                  </div>
                </div>
                <div className="historyBox">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive border-1">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Job Title/ Title</th>
                              {/* <th>Location</th> */}
                              <th>Date</th>
                              {/* <th>End Date</th> */}
                              <th>Total Amount</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {isLoading && (
                            <tbody>
                              <tr>
                                <td className="text-justify" colSpan={5}>
                                  Loading...
                                </td>
                              </tr>
                            </tbody>
                          )}
                          {!isLoading && (
                            <tbody>
                              {billings?.length !== 0 ? (
                                billings?.map((res, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{res?.title}</td>
                                      {/* <td>
                                        {res?.jobId?.locationId?.locationName
                                          ? res?.jobId?.locationId?.locationName
                                          : "--"}
                                      </td> */}
                                      <td>
                                        {moment
                                          .utc(res?.createdAt)
                                          .format("DD-MM-YYYY")}
                                      </td>
                                      {/* <td>12/02/2022</td> */}
                                      <td>${res?.amount}</td>
                                      <td>
                                        <a
                                          type="button"
                                          onClick={(e) => {
                                            downloadInvoice(res?.id);
                                          }}
                                        >
                                          <span>
                                            <i className="fas fa-download"></i>
                                          </span>
                                          Download
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={5} className="text-center">
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          )}
                        </table>
                      </div>
                      <div className="mt-2">
                        {billings.length != 0 && (
                          <Pagination
                            color="primary"
                            activePage={pageNo}
                            itemsCountPerPage={10}
                            totalItemsCount={totalItemsCount}
                            pageRangeDisplayed={5}
                            onChange={(e) => handlePageChange(e)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MallInvoice;
