import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import "./notification.scss";
import ApiClient from "../../api/apiClient";
import Cookies from "universal-cookie";
import { API_GetNotificationListByJob } from "../../api/Constant";
import { toast } from "react-toastify";
import moment from "moment";
import Pagination from "react-js-pagination";
import Modal from "@material-ui/core/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";

const Notification = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  //console.log(id);
  const [nlist, setNlist] = useState([]);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsloading] = useState(false);
  const cookie = new Cookies();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata.tokens.refresh.token;
  const userId = tdata.user.id;
  // var pageNo = 1;

  // This effect runs when the component mounts
  useEffect(() => {
    if (token) {
      // If there's a valid token, fetch the notification list
      getNotificationList();
    } else {
      // If the token is invalid or missing, show a warning toast
      toast.warning("Your session has expired. Kindly log in again.", "yellow");
      // Note: Navigation code might be uncommented when needed
      // navigate(`/login`);
    }
  }, []);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    // Update the page number in the state
    setPageNo(pageNumber);
    // Fetch the notification list for the updated page
    getNotificationList();
  };

  // Function to fetch the notification list
  async function getNotificationList() {
    const data = "";
    setIsloading(true);

    // Fetch the notification list from the API
    const res = await ApiClient.get(
      API_GetNotificationListByJob + `/${id}/job`,
      data,
      `Bearer ${token}`
    );

    // Handle API response
    if (res?.data?.code === 401) {
      // If unauthorized, navigate to login
      toast.error(res?.data?.message);
      navigate(`/login`);
    } else if (res.data.code === 200) {
      // If success, update the state with the fetched notification list
      setIsloading(false);
      setNlist(res.data.data);
      settotalItemsCount(res.data.data?.alerts?.length);
    } else {
      // If any other response, show an error toast
      setIsloading(false);
      toast.error(res.message);
    }
  }

  return (
    <>
      <div>
        <Modal
          open={isLoading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Header />
      {!isLoading && (
        <section className="notification_Alert">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-9">
                <div className="content">
                  <div className="title">
                    <div className="backArrow">
                      <Link to={`/add_notification/${id}`}>
                        <span className="left">
                          <i className="fas fa-circle-plus"></i>
                        </span>
                        <span className="add-span"> Add</span>
                      </Link>
                    </div>
                    <div className="heading">
                      <h4>Notification Alerts</h4>
                    </div>
                  </div>
                  <div className="notifyBox confirmJob">
                    <div className="row"></div>
                    <div className="row">
                      <div className="col-md-12">
                        <h6>{nlist?.jobTitle}</h6>
                        <div className="time">
                          <p>
                            <span className="left">
                              <i className="fas fa-clock"></i>
                            </span>{" "}
                            {moment
                              .utc(nlist.jobDate)
                              .format("DD-MM-YYYY, h:mm:ss A")}
                          </p>
                          {/* <span>01:30 PM</span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {nlist?.alerts?.map((data, i) => {
                    return (
                      <div className="notifyBox" key={i}>
                        <h5>{data?.title}</h5>
                        <p>{data?.description}</p>
                        <div className="time">
                          <span>
                            {moment(data?.createdAt).format("h:mm:ss A")}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {nlist?.alerts?.length !== 0 && (
                <div className="mt-2 justify-content-center">
                  <Pagination
                    color="primary"
                    activePage={pageNo}
                    itemsCountPerPage={10}
                    totalItemsCount={totalItemsCount}
                    pageRangeDisplayed={5}
                    onChange={(e) => handlePageChange(e)}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Notification;
