import React from "react";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import { Link } from "react-router-dom";
import "./inner_add_job.scss";

const InnerAddJob = () => {
  return (
    <>
      <Wel_Header />
      <section className="add_inner_location">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="location_title">
                <div className="row align-items-center">
                  <div className="col-md-12 text-center">
                    <h4>Add Location</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="signupForm">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby=""
                        placeholder="Location Name"
                      />
                      <span>
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby=""
                        placeholder="Unit No."
                      />
                      <span>
                        <i className="fas fa-map"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby=""
                        placeholder="Building"
                      />
                      <span>
                        <i className="fas fa-building"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby=""
                        placeholder="Street"
                      />
                      <span>
                        <i className="fas fa-road"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby=""
                        placeholder="City"
                      />
                      <span>
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby=""
                        placeholder="Province"
                      />
                      <span>
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby=""
                        placeholder="Zip"
                      />
                      <span>
                        <i className="fas fa-map-marker-alt"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <h5>Contact Person</h5>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby=""
                        placeholder="Contact Person"
                      />
                      <span>
                        <i className="fas fa-user"></i>
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby=""
                        placeholder="Contact No."
                      />
                      <span>
                        <i className="fas fa-phone-alt"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mapBox">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"
                  frameBorder="0"
                  style={{
                    color: "red",
                    borderRadius: "6px",
                    width: "100%",
                    height: "175px",
                    border: "1px solid #adadad",
                  }}
                ></iframe>
              </div>
            </div>
            <div className="col-md-12">
              <div className="nextBtn">
                {/* <Link to="/privacy"> */}
                <a type="button" className="text-white">
                  Submit <span></span>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InnerAddJob;
