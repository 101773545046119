import React from 'react'

function ErrorMessage(props) {
  return (
   
      <div>
        <p
          style={{
            color: "red",
            fontSize: 13,
            fontFamily: "GothamMedium",
            marginHorizontal: 35,
            textAlign: "left",
            margin:"0px 0 10px"
          }}
        >
          {props.messerror}
        </p>
      </div>
   
  )
}

export default ErrorMessage