import React from "react";
import Profile from "../../assets/img/Profile.png";
import { Link } from "react-router-dom";
import "./my_wallet.scss";

const MyWallet = () => {
  return (
    <>
      <section className="my_wallet">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="profile">
                <div className="row align-items-center">
                  <div className="col-md-12">
                    <div className="editProBtn">
                      <h4>My Profile</h4>
                      <Link to="/edit_profile">
                        <span>
                          <i className="fas fa-edit"></i>{" "}
                        </span>{" "}
                        <span className="edit-span">Edit</span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flexBox">
                  <div className="left">
                    <div className="box">
                      <div className="imgBox">
                        <img src={Profile} />
                      </div>

                      <div className="contentBox">
                        <p>
                          <span>
                            <i className="fas fa-user-circle"></i>
                          </span>{" "}
                          John Doe
                        </p>
                        <ul>
                          <li>
                            <span>
                              <i className="fas fa-envelope"></i>
                            </span>
                            johndoe123@gmail.com
                          </li>
                          <li>
                            <span>
                              <i className="fas fa-phone-alt"></i>
                            </span>{" "}
                            0987654321
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <div className="flexInner">
                      <div className="left">
                        <p>Total Jobs</p>
                        <span className="coins"> 20</span>
                      </div>
                      <div className="right">
                        <Link to="/myRating">
                          My Rating{" "}
                          <span className="left">
                            <i className="fas fa-arrow-right"></i>
                          </span>
                        </Link>
                        <span className="starIcon">
                          <i className="fas fa-star"></i>
                        </span>
                        <span className="starIcon">
                          <i className="fas fa-star"></i>
                        </span>
                        <span className="starIcon">
                          <i className="fas fa-star"></i>
                        </span>
                        <span className="starIcon">
                          <i className="fas fa-star"></i>
                        </span>
                        <span className="starIcon">
                          <i className="fas fa-star-half"></i>
                        </span>
                        <span className="count">(4.5)</span>
                      </div>
                    </div>
                    <div className="flexInner">
                      <div className="left">
                        <p>My Wallet</p>
                        <span className="coins">
                          <i className="fas fa-coins me-1"></i>$123
                        </span>
                      </div>
                      <div className="right">
                        <p>My Spending</p>
                        <span className="coins">
                          <i className="fas fa-coins"></i> $123
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="btnBox">
                      <Link to="/my_document" className="docBtn">
                        My Documents
                      </Link>
                      <Link to="/myAccount" className="accBtn">
                        My Wallet
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyWallet;
