import React, { useState } from "react";

import LogoIcon from "../../components/Logo/Logo";
import Google from "../../assets/img/google.png";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import { emailRegex, re } from "../../constant";
import ApiClient from "../../api/apiClient";
import {
  API_LOGIN,
  API_SocialLogIn,
  clientId,
  clientIdKey,
} from "../../api/Constant";
import ErrorMessage from "../../components/ErrorMessage/ ErrorMessage";
import axios from "axios";
import FacebookLogin from "react-facebook-login";
import Cookies from "universal-cookie";
import ScaleLoader from "react-spinners/ScaleLoader";
import Modal from "@material-ui/core/Modal";
import Wel_Header from "../../Pages/Wel_header/Wel_header";
import { toast } from "react-toastify";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  apiKey,
  appId,
  authDomain,
  measurementId,
  messagingSenderId,
  projectId,
  storageBucket,
  vapidKey,
} from "../../api/Constant";

const Login = () => {
  var firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    measurementId: measurementId,
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const messaging = getMessaging(firebaseApp);
  const [notificationToken, setNotificationToken] = useState("");

  // Fetch Firebase Token
  const fetchToken = (setTokenFound) => {
    return getToken(messaging, {
      vapidKey: vapidKey,
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          setNotificationToken(currentToken);
          // setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  };

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
  const clientId = clientIdKey;
  // "510146802758-as463dor5jtmidtpl5k7j2l8efspljv5.apps.googleusercontent.com";

  const navigate = useNavigate();
  // const [email, setEmail] = useState({ value: "pankaj123@gmail.com", err: "" });
  // const [password, setPassword] = useState({ value: "Pankaj@123", err: "" });
  const [email, setEmail] = useState({ value: "", err: "" });
  const [password, setPassword] = useState({ value: "", err: "" });
  const [iserror, setIsError] = useState(false);
  const [error, setError] = useState("");
  let [loading, setLoading] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const locId = localStorage.getItem("locationId");

  React.useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userInfo);
    const token = userInfo?.tokens?.refresh.token;
    fetchToken();
    onMessageListener();
  }, []);

  // Email Validation
  const _emailvalidate = (mail) => {
    if (mail === "") {
      setEmail({ value: mail, err: "*Please enter email." });
    } else if (!emailRegex.test(mail)) {
      setEmail({ value: mail, err: "*Please enter valid email." });
    } else {
      setEmail({ value: mail, err: "" });
    }
  };
  // Password Validation Function
  const _passwordvalidate = (pass) => {
    if (pass === "") {
      setPassword({ value: pass, err: "*Please enter password." });
    } else {
      setPassword({ value: pass, err: "" });
    }
  };

  // Validation Check Function
  const validate = async () => {
    if (email.value === "") {
      setEmail({ value: email.value, err: "*Please enter email." });
    } else if (!emailRegex.test(email.value)) {
      setEmail({ value: email.value, err: "*Please enter valid email." });
    }
    if (password.value === "") {
      setPassword({ value: password.value, err: "*Please enter password." });
    }
    //  else if (!re.test(password.value)) {
    //   setPassword({ value: password.value, err: "*Please enter at least 1 capital letter, 1 special character, 1 small letter and minimum 8 characters." });
    // }
    if (email.value && password.value) {
      // navigate(`/my_profile`);
      callLoginApi();
    }
  };

  //call login api
  async function callLoginApi() {
    let data = {
      email: email.value,
      password: password.value,
      deviceToken: notificationToken,
      deviceType: 3,
    };
    setLoading(true);
    let response = await ApiClient.post(
      API_LOGIN,
      data,
      "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
    );
    // console.log(JSON.stringify(response.data, "response"));
    setLoading(false);
    const cookie = new Cookies();
    console.log(response);
    if (response.data.code == 200) {
      const { user, tokens } = response.data.data;
      console.log(user);
      //  alert(JSON.stringify(response.data.data.tokens.access.token))
      localStorage.setItem("userInfo", JSON.stringify(response.data.data));
      axios.defaults.headers.Token = tokens.refresh.token;
      cookie.set("token", axios.defaults.headers.Token);
      cookie.set("userId", user.id);
      cookie.set("customerInfo", user);
      if (
        response.data.data.user.registerAs === "Individual" ||
        (response.data.data.user.registerAs === "Small Business" &&
          response.data.data.isProcessComplete === 1 &&
          response.data.data.user.identityDocuments.length === 0)
      ) {
        navigate("/register");

        // response.data.isProcessComplete = user.data && user.data.identityDocuments.length == 0 ? 1 : 2;
      }
      if (
        response.data.data.user.registerAs === "Incorporation" &&
        // response.data.data.isProcessComplete === 1
        response.data.data.user.businessDetails.length === 0 &&
        !response.data.data.user.isIdentityDocumentsSkip
      ) {
        navigate("/business_location");

        // isProcessComplete = user.data && user.data.businessDetails.length == 0 ? 1 : 2;
      }

      if (
        (response.data.data.user.registerAs === "Individual" ||
          response.data.data.user.registerAs === "Small Business") &&
        response.data.data.isProcessComplete === 2 &&
        (response.data.data.user.identityDocuments.length !== 0 ||
          response.data.data.user.isIdentityDocumentsSkip)
      ) {
        console.log("isProcessComplete ==2 ");
        // var checkWorkLocation = await WorkLocationModel.countDocuments({ userId: user.data.id });
        // isProcessComplete = checkWorkLocation == 0 ? 2 : 3;
        navigate("/add_location?isHeadOffice=1");
        return;
      }

      if (
        response.data.data.user.registerAs === "Incorporation" &&
        response.data.data.isProcessComplete === 3 &&
        response.data.data.locationId === "" &&
        response.data.data.user.businessDetails.length !== 0 &&
        response.data.data.user.userType === 0
      ) {
        console.log("isProcessComplete ==2 ");
        navigate("/add_location?isHeadOffice=2");
      } else {
        // navigate("/my_profile");
      }

      if (
        response.data.data.user.registerAs === "Incorporation" &&
        response.data.data.isProcessComplete === 3 &&
        response.data.data.locationId !== "" &&
        response.data.data?.user.isUnitSkip !== true
      ) {
        console.log("isProcessComplete ==3 ");
        navigate(`/add-unit/${response.data.data.locationId}`);
      }

      if (
        (response.data.data.user.registerAs === "Individual" ||
          response.data.data.user.registerAs === "Small Business") &&
        response.data.data.isProcessComplete === 3 &&
        response.data.data.user.status === 0
      ) {
        console.log(user.status);
        console.log("isProcessComplete == 3 ");
        navigate("/thankYou?isLicense=full");
        return;
      }

      if (
        (response.data.data.user.registerAs === "Individual" ||
          response.data.data.user.registerAs === "Small Business") &&
        response.data.data.isProcessComplete === 3 &&
        response.data.data.user.status !== 0
      ) {
        console.log(user.status);
        console.log("isProcessComplete == 3 ");
        navigate("/my_profile");
        return;
      }

      if (
        (response.data.data.isProcessComplete === 4 &&
          response.data.data.user.status === 0) ||
        (response.data.data.user.isUnitSkip &&
          response.data.data.user.status === 0)
      ) {
        console.log(user.status);
        console.log("isProcessComplete ==4 ");
        navigate("/thankYou?isLicense=full");
        return;
      }

      if (
        (response.data.data.isProcessComplete === 4 &&
          response.data.data.user.status !== 0) ||
        response.data.data.user.isIdentityDocumentsSkip ||
        (response.data.data.user.isUnitSkip &&
          response.data.data.user.status !== 0)
      ) {
        console.log(user.status);
        console.log("isProcessComplete ==4 ");
        navigate("/my_profile");
        return;
      }

      // else {
      //   navigate(`/my_profile`);
      // }

      // navigate(`/my_profile`);
    } else {
      setIsError(true);
      setError(response.data.message);
      setTimeout(() => {
        setError("");
        setIsError(false);
      }, 5000);
    }
  }

  //Call Facebook with Login
  async function responseFacebook(res) {
    console.log(res);
    console.log("login with facebook res", JSON.stringify(res));
    const data = {
      email: res.email,
      socialType: 1,
      socialId: res.id,
      deviceToken: notificationToken,
      deviceType: 3,
      deviceId: "",
    };
    if (res?.status === "unknown") {
      return;
    } else {
      const response = await ApiClient.post(
        API_SocialLogIn,
        data,
        "Basic Z29ndWFyZDpnb2d1YXJkQDEyMzQ1"
      );
      console.log("response", response);
      if (response.data.code == 200) {
        // alert(response.data.message)
        toast.success(response.data.message, "green");
        localStorage.setItem("userInfo", JSON.stringify(response.data.data));
        // navigate(`/my_profile`);
        if (response?.data?.data?.user?.status === 0) {
          navigate(`/thankYou?isLicense=` + "full");
        } else {
          navigate(`/my_profile`);
        }
      } else {
        toast.error(response.data.message, "red");
        console.log(response.data.message);
      }
    }
  }

  const handleShowPassword = (event, type) => {
    event.preventDefault();
    if (type === "show") {
      setIsShowPassword(true);
    } else {
      setIsShowPassword(false);
    }
  };

  const onLoginSuccess = (res) => {
    console.log("Login Success:", res.profileObj);
    localStorage.setItem("userInfo", JSON.stringify(res.profileObj));
    if (res.profileObj.googleId && res.profileObj.email) {
      res.profileObj["id"] = res.profileObj.googleId;
      responseFacebook(res.profileObj);
    }
    // setShowloginButton(false);
    // setShowlogoutButton(true);
    // navigate(`/thankYou?isLicense=` + "full");
  };

  const onLoginFailure = (res) => {
    console.log("Login Failed:", res);
  };

  return (
    <>
      <div>
        <Modal
          open={loading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      <Wel_Header />
      <section className="loginPage">
        {/* <ScaleLoader loading={loading} height={35} width={4} color="#36D7B7" />  */}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-9">
              <div className="loginForm">
                <div className="row">
                  <div className="col-md-6">
                    <form>
                      <h4>LogIn with Email/Password</h4>
                      <div className="login_with_pass">
                        {iserror ? (
                          <div className="alert alert-danger">{error}</div>
                        ) : null}
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Email Address"
                            value={email.value}
                            onChange={(e) => {
                              _emailvalidate(e.target.value);
                            }}
                          />
                          <span>
                            <i className="fas fa-envelope"></i>
                          </span>
                        </div>
                        <ErrorMessage messerror={email.err} />
                        <div className="form-group">
                          <input
                            value={password.value}
                            type={!isShowPassword ? "password" : "text"}
                            onChange={(e) => {
                              _passwordvalidate(e.target.value);
                            }}
                            className="form-control"
                            id="exampleInputPassword1"
                            placeholder="Password"
                          />
                          <span>
                            {!isShowPassword ? (
                              <i
                                className="fas fa-eye"
                                onClick={(e) => handleShowPassword(e, "show")}
                              />
                            ) : (
                              <i
                                className="fas fa-eye-slash"
                                onClick={(e) => handleShowPassword(e, "hide")}
                              />
                            )}
                          </span>
                        </div>
                        <ErrorMessage messerror={password.err} />

                        <div className="forgot-password">
                          <Link to="/forgot-password">Forgot Password?</Link>
                        </div>
                        <div className="loginBtn">
                          {/* <Link to="/my_profile" onClick={() => validate()}> */}
                          <a type="button" onClick={() => validate()}>
                            LogIn
                            <span className="left">
                              <i className="fas fa-arrow-right"></i>
                            </span>
                          </a>
                          {/* </Link> */}
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-6">
                    <div className="social_login">
                      <h4>LogIn with Social Media</h4>
                      <div className="fbBtn">
                        {/* <button type="button">
                            <span>
                              <i className="fab fa-facebook-f"></i>
                            </span>
                            LogIn With Facebook
                          </button> */}
                        <FacebookLogin
                          appId="902086723802902"
                          autoLoad={false}
                          fields="name,email,picture"
                          icon={
                            <span>
                              <i className="fab fa-facebook-f"></i>
                            </span>
                          }
                          // onClick={responseFacebook}
                          callback={responseFacebook}
                        />
                      </div>
                      <div className="googleBtn">
                        {/* <button type="button">
                          <span>
                            <img src={Google} className="img-fluid" />
                          </span>
                          Login With Google
                        </button> */}
                        <GoogleLogin
                          clientId={clientId}
                          className="googleButton"
                          buttonText="Login With Google"
                          cssClass="button"
                          onSuccess={onLoginSuccess}
                          onFailure={onLoginFailure}
                          cookiePolicy={"single_host_origin"}

                          // isSignedIn={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="notReg">
                      <p>
                        Don't Have An Account?{" "}
                        <Link to="/signup" className="signupBtn">
                          SignUp
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
