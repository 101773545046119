// Base_URL
// export const url = "https://go-guard.omnisttechhub.com/v1/"; // For Tesing
// export const SOCKET_URL = "https://guardsocket.omnisttechhub.com";
// export const STRIPE_PUBLIC_KEY =
//   "pk_test_51Kue8SF3N1Gw5OeKUJnpEGWlvn73kPgI99MsmuahwTknftZO2YzYdaHzQmw22iaMywLkvtjSoWoza8tZbrP18hLJ00iixITpcM"; // Testing Server
//   export const guardURL = 'https://go-gaurd.web.app/guard/login' // Testing Server
export const googleAddressKey = "AIzaSyAPS-SckWYi0UK8lbHK_fbQqUVBJKoL91U";
// firebase config
export const vapidKey =
  "BK0x2yhw8eydVUehpfuJEOMPfhVgR4EQX_moBF-qqr65OHk_W4Sx-dLX4j6GNmXBGu3maL70AcIpQLUrRei128M";
export const apiKey = "AIzaSyCM3LLCqIN8v0uaQyzMcxvEuKVa3iTZYCY";
export const authDomain = "iconic-being-340812.firebaseapp.com";
export const projectId = "iconic-being-340812";
export const messagingSenderId = "858285694999";
export const storageBucket = "iconic-being-340812.appspot.com";
export const appId = "1:858285694999:web:027b1f54d0d4509e107c52";
export const measurementId = "G-EGZTTZ1ED4";
export const clientIdKey =
  "858285694999-bf5681k3kebfdvfobiaructldlk17fod.apps.googleusercontent.com";

// export const url = "http://localhost:3000/v1/"; // For Local
// export const url = "https://go-guard-api.go-guard.com/v1"; // Live Server
// export const SOCKET_URL = "https://go-guard-api.go-guard.com/";
// export const STRIPE_PUBLIC_KEY =
//   "pk_live_51Kue8SF3N1Gw5OeKDY8OEwKHN0epEK2PsekrLkovseuaTwUW6cy9XORSozjPdu1ekGMttzm9QM3RuBMmgrJ6JvBS00IwtW2Gvo"; // Live Server
// export const guardURL = "https://go-guard.com/guard/login"; // Live Server

// export const url = "https://go-guard.apikart.co/v1/"; // For Staging
// export const SOCKET_URL = "https://guardsocket.apikart.co";
// export const STRIPE_PUBLIC_KEY =
//   "pk_test_51Kue8SF3N1Gw5OeKUJnpEGWlvn73kPgI99MsmuahwTknftZO2YzYdaHzQmw22iaMywLkvtjSoWoza8tZbrP18hLJ00iixITpcM"; // Testing Server
// export const guardURL = "https://web-guard.apikart.co/guard/login"; // Staging Server

// New Live Server
export const url = "https://go-guard-api.go-guard.com/v1"; // Live Server
export const SOCKET_URL = "https://go-guard-api.go-guard.com/";
export const STRIPE_PUBLIC_KEY =
  "pk_live_51Kue8SF3N1Gw5OeKDY8OEwKHN0epEK2PsekrLkovseuaTwUW6cy9XORSozjPdu1ekGMttzm9QM3RuBMmgrJ6JvBS00IwtW2Gvo"; // Live Server
export const guardURL = "https://go-guard.com/guard/login"; // Live Server

// All Api name
export const API_LOGIN = "user/auth/login";
export const API_Signup = "user/auth/register";
export const API_Forgot = "user/auth/forgot-password";
export const API_ChangePassword = "user/change-password";
export const API_ResetPassword = "user/auth/reset-password?token=";
export const API_GetProfile = "user/profile/";
export const API_SocialLogIn = "user/auth/social-login";
export const API_UpdateUserProfile = "user/profile/";

export const API_UpdateUserProfileAfterSignup = "user/profile/";
export const API_AddWorkLocation = "work-location";
export const API_WorkLocationList = "work-location";
export const API_EditWorkLocation = "work-location/";
export const API_LOGOUT = "/user/auth/logout";
export const API_DELETEWORKLOCATION = "work-location/";
export const API_ADDJOB = "job";

export const API_AddUNIT = "unit";
export const API_GETUNITLIST = "unit";
export const API_DeleteUnitList = "unit/";
export const API_UpdateUnitList = "unit/";
export const API_UnitDetails = "unit/";

export const API_GetJobRoleList = "job-role/user/list";

export const API_AddNewDocument = "customer-document";
export const API_DeleteNewDocument = "customer-document/";
export const API_Speciality = "speciality/user/list";
export const API_DelectAll = "customer-document/delete/multiple";

export const API_PrivacyPolicy = "static/pages/privacy";
export const API_TermOfCondition = "static/pages/terms";

export const API_GetAllJobsList = "job";
export const API_GetJobPrice = "job/get/price";
export const API_GetAllOnGoingJobsList = "job";
export const API_GetJobDetails = "job/";
export const API_GetAllAlertsList = "alert";
export const API_GetAllEnotes = "user/e-note";

export const API_GetNotificationListByJob = "alert";
export const API_AddNotificationToJob = "alert";
export const API_GetAllNotification = "notification/user/list";

export const API_GetRating = "users/rating";
export const API_UpdateNotification = "user/profile/";
export const API_GetUserProfile = "user/profile/";
export const API_GetUserBilings = "billing/customer";
export const API_DownloaadUserBilings = "billing/customer/download/";

export const API_AddTicket = "users/ticket";
export const API_AllJobsDropDown = "job/customer/job-history";
export const API_GetTicket = "users/ticket";
export const API_GetTicketDetail = "users/ticket/";

export const API_AddAmount = "wallet";
export const API_GetUserWallet = "wallet/user/";
export const API_AddCard = "customer/card";

// export const API_DeleteWorkLocation="work-location/" /billing/customer/download
