import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import Header from '../../components/Header/Header';
import './sos.scss';

const SOS = () => {
  return (
    <>
     <Header />
      <section className="sos">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-md-8 col-lg-6">
                      <div className="sosBox">
                        <div className="location_title">
                            <div className="row align-items-lg-center">
                                <div className="col-md-12 text-center">
                                    {/* <Link to="/work_location" className="backBtn"><span className='left'><i className="fas fa-arrow-left"></i></span></Link>  */}
                                    <h4>SOS</h4>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='mapBox'>
                                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"  frameborder="0" style={{color:'red',borderRadius: '20px',width:'100%',height:'100px',border:'1px solid #adadad'}}></iframe> */}
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"  frameborder="0" style={{color:'red',borderRadius: '10px',width:'100%',height:'250px',border:'1px solid #e5e5e5'}}></iframe>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className='content'>
                                    <p><span className='span-name'>SOS Location </span> <span  className='dot'> :</span> 123,Location,City Name</p>
                                    <p><span  className='span-name'>Guard Name   </span> <span  className='dot'> :</span> John Doe</p>
                                    <p><span  className='span-name'>Alert Time   </span> <span  className='dot'> :</span> 01:20 PM,12/12/21</p>
                                </div>
                            </div>
                        </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default SOS;
