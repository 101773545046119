import React from 'react';
import {Link} from 'react-router-dom';
import Header from '../../components/Header/Header';
import PdfIcon from "../../assets/img/pdf.png";
import './ticket2.scss';

const Ticket2 = () => {
  return (
    <>
    <Header />
      <section className="ticket2">
         <div className="container">
             <div className="row justify-content-center">
                 <div className="col-md-10 col-lg-9">
                    <div className="content">
                    <div className='contentBox'>
                        <form>
                            <div className="title">
                                {/* <div className="backArrow"><Link to="/previousTicket"><FaArrowLeft/></Link></div> */}
                                <div className="heading">
                                    <h1>Ticket Detail</h1>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-md-4'>
                                    <div className="form-group">
                                        <input className="form-control" type="text" placeholder="Security Job Issue" aria-label="default input example" />
                                    </div> 
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-group">
                                        <input className="form-control" type="text" placeholder="Mall Security Job" aria-label="default input example" />
                                    </div> 
                                </div>
                                <div className='col-md-4'>
                                    <div className="form-group">
                                        <input type="text" className="form-control timeInput" id="exampleInputtext" aria-describedby="textHelp" placeholder="01:20 PM,12/11/2021" />
                                        <span className='mapIcon'><i className="fas fa-clock"></i></span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="descBox">
                                    <h6>Client's Behaviour Not Good</h6>
                                    <h6 className='descPara'>Description</h6>
                                    <p>Marzipan tootsie roll oat cake croissant muffin gingerbread tart. Cotton candy lemon drops shortbread caramels pudding oat cake macaroon dragée wafer. Pastry cake topping dessert icing. Bear claw tart topping shortbread bear claw sugar plum liquorice bonbon cake.</p>
                                    <p><img src={PdfIcon} alt="" className='img-fluid'/> <span>Report File.pdf</span></p>
                                </div>
                            </div>
                            <div className='col-md-12'>
                                <div className="descBox reponse">
                                    <h6 className='descPara'>Reponse Title</h6>
                                    <p>Marzipan tootsie roll oat cake croissant muffin gingerbread tart. Cotton candy lemon drops shortbread caramels pudding oat cake macaroon dragée wafer. Pastry cake topping dessert icing. Bear claw tart topping shortbread bear claw sugar plum liquorice bonbon cake.</p>
                                </div>
                            </div> 
                            <div className='col-md-12'>
                                <div className='textMessage'>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="exampleInputtext" maxLength="" aria-describedby="textHelp" placeholder="Type Message Here..." />
                                        <a href="#"><i className="fas fa-paper-plane"></i></a>
                                    </div>
                                </div>
                            </div> 
                        </form>
                    </div>
                    </div>
                 </div>
             </div>
         </div>
      </section>
    </>
  )
}

export default Ticket2;
