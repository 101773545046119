import React, { useState } from 'react';
import LogoIcon from '../../components/Logo/Logo';
import Wel_Header from '../../Pages/Wel_header/Wel_header';
import {Link} from 'react-router-dom';
import './recurring_job.scss';

const RecurringJob = () => {
  return (
    <>
    <Wel_Header />
      <section className="add_recurring_job">
          <div className="container">
              <div className="row justify-content-center">
                  <div className="col-md-12 col-lg-9">
                      <div className="signupForm">
                          <form>
                                <div className="job_title">
                                    <div className="row align-items-lg-center">
                                        <div className="col-md-12 text-center">
                                            {/* <Link to="/edit_location" className="backBtn"><span className='left'><i className="fas fa-arrow-left"></i></span></Link>  */}
                                            <h4>Add Recurring Job</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-md-start  justify-content-center">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" aria-describedby="" placeholder="Job Title" />
                                            <span><i className="fas fa-briefcase"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select  className="form-control" >
                                                <option selected>Job Location</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <span><i className="fas fa-caret-down"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select  className="form-control" >
                                                <option selected>Recurring Job</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <span><i className="fas fa-caret-down"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <select  className="form-control" >
                                                <option selected>Transportation</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <span><i className="fas fa-caret-down"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <select  className="form-control" >
                                                <option selected>Dress Code</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <span><i className="fas fa-caret-down"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <select  className="form-control" >
                                                <option selected>Rotation</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <span><i className="fas fa-caret-down"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <select  className="form-control" >
                                                <option selected>Shift/Day</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <span><i className="fas fa-caret-down"></i></span>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='guard_title'>
                                            <h5>Guard Profile</h5>
                                            <a href="#"><span><i className="fas fa-plus-circle"></i></span><span className='add-span'>Add</span></a>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <select  className="form-control" >
                                                <option selected>Speciality</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <span><i className="fas fa-caret-down"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <select  className="form-control" >
                                                <option selected>Experience</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <span><i className="fas fa-caret-down"></i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <select  className="form-control" >
                                                <option selected>Rate</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <span><i className="fas fa-caret-down"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row align-items-center'>
                                        <div className='col-md-2'>
                                            <h6 className='title_inner'>Job Detail</h6>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" aria-describedby="" placeholder="Job Date" />
                                                <span><i className="fas fa-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row align-items-center'>
                                        <div className='col-md-2'>
                                            <h6 className='title_inner'>Shift Details</h6>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" aria-describedby="" placeholder="Shift 1 Start Time" />
                                                <span><i className="fas fa-clock"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" aria-describedby="" placeholder="Shift 1 End Time" />
                                                <span><i className="fas fa-clock"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row align-items-center'>
                                        <div className='col-md-2'></div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" aria-describedby="" placeholder="Shift 2 Start Time" />
                                                <span><i className="fas fa-clock"></i></span>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" className="form-control" aria-describedby="" placeholder="Shift 2 End Time" />
                                                <span><i className="fas fa-clock"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                <div className='row justify-content-center'>
                                    <div className='col-md-6'>
                                        <div className="nextBtn">
                                            <Link to="/my_profile">Submit</Link>
                                        </div>
                                    </div>
                                </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default RecurringJob;
