import React, { useEffect, useState, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import cardImg from "../../assets/img/VISA-logo.png";
import Header from "../../components/Header/Header";
import "./payment.scss";
import ApiClient from "../../api/apiClient";
import axios from "axios";
import { toast } from "react-toastify";
import { API_AddCard, STRIPE_PUBLIC_KEY } from "../../api/Constant";
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Pagination from "react-js-pagination";
import useResponsiveFontSize from "./useResponsiveFontSize";
import Swal from "sweetalert2";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import ScaleLoader from "react-spinners/ScaleLoader";
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const Payment = ({ cardAdded }) => {
  const navigate = useNavigate();
  // const stripe = useStripe();
  // const elements = useElements();
  const tdata = JSON.parse(localStorage.getItem("userInfo"));
  const token = tdata?.tokens?.refresh?.token;
  const clientSectet = tdata?.user?.stripe_customer_id;
  const [cardList, setCardList] = useState([]);
  const [cardNo, setCardNo] = useState("");
  const [cardId, setCardId] = useState("");
  const [exp_year, setExp_year] = useState("");
  const [exp_month, setExp_month] = useState("");
  const [error, setError] = useState(false);
  const [totalItemsCount, settotalItemsCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsloading] = useState(false);
  const [cardLoading, setCardloading] = useState(false);
  var err = false;
  const location = useLocation();
  const currentPath = location.pathname;
  // const cardElement = elements.getElement(CardElement);
  // const stripe = require("stripe")("sk_test_4eC39HqLyjWDarjtT1zdp7dc");
  useEffect(() => {
    //console.log(currentPath);
    // creteToken();
    getAllCards();
  }, []);

  // Add Card Function
  async function addCard(cardT) {
    const data = {
      token: cardT,
      custId: clientSectet,
    };
    setCardloading(true);
    const res = await ApiClient.post(API_AddCard, data, `Bearer ${token}`);
    // //console.log("card", res);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res.data.code === 200) {
      setCardloading(false);
      cardAdded(true);
      getAllCards();
      toast.success(res?.data?.message);
    } else {
      cardAdded(false);
      setCardloading(false);
      toast.error(res.message);
    }
  }

  // Get All Cards List
  const getAllCards = async () => {
    setIsloading(true);
    const data = "";
    const res = await ApiClient.get(
      API_AddCard + `?custId=${clientSectet}`,
      data,
      `Bearer ${token}`
    );
    // //console.log("card list", res);
    setIsloading(false);
    if (res?.data?.code === 401) {
      // setIsloading(false);
      toast.error(res?.data?.message);
      navigate(`/login`);
    }
    if (res.data.code === 200) {
      setCardList(res?.data?.data);
      settotalItemsCount(res?.data?.data?.length);
      sessionStorage.setItem("length", res?.data?.data.length);
      // toast.success(res?.data?.message);
    } else {
      setIsloading(false);
      toast.error(res.message);
    }
  };
  // Handle Page Change Function
  const handlePageChange = (pageNumber) => {
    // //console.log(`active page is ${pageNumber}`);
    setPageNo(pageNumber);
    // pageNo = pageNumber;
    getAllCards();
    // this.setState({activePage: pageNumber});
  };
  // Delete Card Function
  const deleteCard = async (cid) => {
    const data = "";
    const res = await ApiClient.delete(
      API_AddCard + `?custId=${clientSectet}&cardId=${cid}`,
      data,
      `Bearer ${token}`
    );

    //console.log(res);
    if (res?.data?.code === 200) {
      getAllCards();
      toast.success(res?.data?.message);
    } else {
      toast.warning(res?.data?.message);
    }
  };

  // Edit Card Function
  const editCard = (res) => {
    //console.log(res);
    setCardId(res?.id);
    setCardNo(res?.last4);
    setExp_month(res?.exp_month);
    setExp_year(res?.exp_year);
    let m = res?.exp_month;
    if (m === 1) {
      m = "0" + res?.exp_month;
    } else if (m === 2) {
      m = "0" + res?.exp_month;
    } else if (m === 3) {
      m = "0" + res?.exp_month;
    } else if (m === 4) {
      m = "0" + res?.exp_month;
    } else if (m === 5) {
      m = "0" + res?.exp_month;
    } else if (m === 6) {
      m = "0" + res?.exp_month;
    } else if (m === 7) {
      m = "0" + res?.exp_month;
    } else if (m === 8) {
      m = "0" + res?.exp_month;
    } else if (m === 9) {
      m = "0" + res?.exp_month;
    }
    document.getElementById("expDate").value = res?.exp_year + "-" + m;
    //console.log(m);
  };

  // Handle Selected Expiry Date Function
  const selectedExp = (val) => {
    const date = val.split("/");
    //console.log(date);
    const current = new Date();
    const year = current.getFullYear();
    const today = current.getMonth();
    //console.log(year);
    //console.log(today);
    if (date[1] < year) {
      setError(true);
      toast.error("Your card's expiration date is in the past.");
      return;
    } else if (date[1] === year && date[0] < today) {
      setError(true);
      toast.error("Your card's expiration date is in the past.");
      return;
    } else {
      setError(false);
      setExp_year(date[1]);
      setExp_month(date[0]);
    }
  };
  // Update Card Function
  const updateCared = async () => {
    if (cardId === "" || exp_year === "" || exp_month === "" || error) {
      return;
    } else {
      const data = {
        custId: clientSectet,
        cardId: cardId,
        exp_year: exp_year,
        exp_month: exp_month,
      };

      //console.log(data);
      const res = await ApiClient.patch(API_AddCard, data, `Bearer ${token}`);

      //console.log(res);

      if (res?.data?.code === 200) {
        getAllCards();
        toast.success(res?.data?.message);
      } else {
        toast.error(res?.data?.message);
      }
    }
  };

  // Delete Card Warning
  const deleteList = async (id) => {
    Swal.fire({
      title: "Are you sure",
      text: "You want to delete?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        // Logout();
        //console.log(id);
        deleteCard(id);
      }
    });
    // //console.log('delete list id', id)
  };

  const options = useOptions();

  // Payment Form Handler
  const SplitForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();
    // var cardCvcElement = elements.create("cardCvc", {
    //   // style: style,
    //   placeholder: "Custom CVC placeholder",
    // });
    // elements?.getElement(CardCvcElement)?.update({
    //   placeholder: "CVV",
    // });

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }
      const payload = await stripe.createToken(
        elements.getElement(CardNumberElement)
      );
      //console.log("[PaymentMethod]", payload);
      if (payload && payload.token) {
        //console.log("[token]", payload?.token.id);
        addCard(payload?.token.id);
        elements.getElement(CardNumberElement).clear();
        elements.getElement(CardExpiryElement).clear();
        elements.getElement(CardCvcElement).clear();
      } else {
        if (payload && payload?.error) {
          toast.error(payload.error?.message);
        }
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <div className="row">
          <label className="col-md-4">
            Card number
            <CardNumberElement
              options={options}
              onReady={() => {
                //console.log("CardNumberElement [ready]");
              }}
              onChange={(event) => {
                //console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                //console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                //console.log("CardNumberElement [focus]");
              }}
            />
          </label>
          <label className="col-md-4">
            Expiration date
            <CardExpiryElement
              options={options}
              onReady={() => {
                //console.log("CardNumberElement [ready]");
              }}
              onChange={(event) => {
                //console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                //console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                //console.log("CardNumberElement [focus]");
              }}
            />
          </label>
          <label className="col-md-4">
            CVC
            <CardCvcElement
              options={options}
              placeholder="CVV"
              onReady={() => {
                //console.log("CardNumberElement [ready]");
              }}
              onChange={(event) => {
                //console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
                //console.log("CardNumberElement [blur]");
              }}
              onFocus={() => {
                //console.log("CardNumberElement [focus]");
              }}
            />
          </label>

          <div className="col-md-12">
            <div className="addDocs_btn">
              <button type="submit" disabled={!stripe}>
                {cardLoading ? "Please Wait..." : "Add Card"}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      <div>
        <Modal
          open={isLoading}
          style={{
            position: "absolute",
            height: 80,
            width: 50,
            margin: "auto",
          }}
        >
          <ScaleLoader loading={true} height={35} width={4} color="#36D7B7" />
        </Modal>
      </div>
      {currentPath === "/payment" && <Header />}

      {!isLoading && (
        <section className="mypayment">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="myDocBox">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="topBox">
                        <h4>Manage Payment Method</h4>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <Elements stripe={stripePromise} options={options}>
                      <SplitForm />
                    </Elements>
                  </div>
                  {currentPath === "/payment" && (
                    <div className="uploded_docs">
                      <div className="row">
                        <div className="col-md-12">
                          <h5>Saved Cards</h5>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="table-responsive border-1">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Card Type</th>
                                <th>Card No.</th>
                                <th>Exp.Date</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cardList?.length !== 0 ? (
                                cardList?.map((res) => {
                                  return (
                                    <tr>
                                      <td>{res?.brand}</td>
                                      <td>XXXX XXXX XXXX {res?.last4}</td>
                                      <td>
                                        {res?.exp_month}/{res?.exp_year}
                                      </td>
                                      <td>
                                        <div className="action_btn">
                                          <a
                                            onClick={(e) => {
                                              editCard(res);
                                            }}
                                            className="edit_btn"
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                          >
                                            <i className="fas fa-edit"></i>Edit
                                          </a>
                                          <a
                                            type="button"
                                            onClick={(e) => deleteList(res?.id)}
                                            className="del_btn"
                                          >
                                            <i className="fas fa-trash"></i>
                                            Delete
                                          </a>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td className="text-justify" colSpan={5}>
                                    No Data Found
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}

                  {currentPath === "/payment" && cardList?.length !== 0 && (
                    <div className="mt-2">
                      <Pagination
                        color="primary"
                        activePage={pageNo}
                        itemsCountPerPage={10}
                        totalItemsCount={totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <div className="payment_modal">
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Card Details
                </h5>
              </div>
              <div class="modal-body">
                <div className="cardBox">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="card_number">
                        <div className="form-group">
                          <input
                            type="text"
                            value={"XXXX XXXX XXXX" + cardNo}
                            className="form-control"
                            placeholder="Card No."
                            readOnly
                          />
                          <span>
                            <i className="fas fa-credit-card"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-4">
                      <div className="expiry_card_date">
                        <div className="form-group">
                          <select placeholder="Month" className="form-control">
                            <option value="">Month</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </select>
                          <span>
                            <i className="fas fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-6">
                      <div className="expiry_card_date">
                        <div className="form-group">
                          <input
                            type="month"
                            className="form-control"
                            // value={expDate}
                            id="expDate"
                            placeholder="Year"
                            onChange={(e) => {
                              // console.log(
                              //   moment.utc(e.target.value).format("MM/yyyy")
                              // );
                              selectedExp(
                                moment.utc(e.target.value).format("MM/yyyy")
                              );
                            }}
                          />
                          {/* <span>
                            <i className="fas fa-calendar"></i>
                          </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pay_submit_box">
                <button
                  onClick={(e) => {
                    updateCared();
                  }}
                  disabled={error}
                  type="button"
                  class="pay_submit_btn"
                  data-bs-dismiss={error ? "" : "modal"}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
